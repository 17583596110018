import React, { useContext, useEffect } from 'react';

import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';

import { useForm, Controller } from 'react-hook-form';

import { useCalendarContext } from 'modules/calendar/Context/CalendarContext';

import LabelName from 'components/UI/LabelName/LabelName';
import { updateCalendarSettings } from 'services/Calender/calendarServices';

import { GoogleContext } from 'context/GoogleContext';
import { OutLookContext } from 'context/OutlookContext';
import { getLocalStorageItem } from 'utils/localStorageUtils';
import { useGoogleCalendar } from 'hooks/useGoogleCalendar';

function CalendarSettings() {
  const { control, handleSubmit, setValue, watch } = useForm();

  const { showCalendarSettings, setShowCalendarSettings, calendarSettings, setCalendarSettings } = useCalendarContext();

  let { googleSignIn, googleUser } = useContext(GoogleContext);
  const { user, signIn, signOut } = useContext(OutLookContext);
  const { googleSignOut } = useGoogleCalendar();

  let syncGoogleWatcher = watch('sync_with_google_calendar');
  let syncOutlookWatcher = watch('sync_with_outlook');

  useEffect(() => {
    if (calendarSettings) {
      let googleAccessToken = getLocalStorageItem('google_access_token');

      setValue('show_weekends', calendarSettings?.show_weekends);
      setValue('show_sol', calendarSettings?.show_sol);
      setValue('show_birthdays', calendarSettings?.show_birthdays);
      setValue('show_business_times_only', calendarSettings?.show_business_times_only);
      setValue('sync_with_google_calendar', calendarSettings?.sync_with_google_calendar && googleAccessToken ? true : false);
      setValue('sync_with_outlook', calendarSettings?.sync_with_outlook && user ? true : false);
    }
  }, [calendarSettings]);

  useEffect(() => {
    if (syncGoogleWatcher && !window?.localStorage?.getItem('google_access_token')) {
      googleSignIn(true);
    }
  }, [syncGoogleWatcher]);

  useEffect(() => {
    if (!user && syncOutlookWatcher) {
      signIn();
    }
  }, [syncOutlookWatcher]);

  function hideSideBar() {
    setShowCalendarSettings(false);
  }

  function handleSettingsSubmit(data) {
    updateCalendarSettings(data)
      .then((res) => {
        setCalendarSettings(data);
        // google
        if (!data.sync_with_google_calendar && localStorage?.getItem('google_access_token')) {
          googleSignOut();
        } else {
          if (googleUser) {
            localStorage?.setItem('google_access_token', googleUser?.access_token);
            const expiresIn = googleUser?.expires_in;
            const expiryTime = new Date().getTime() + expiresIn * 1000;
            localStorage.setItem('expiryTime', expiryTime);
          }
        }
        // outlook
        if (!data.sync_with_outlook && user) {
          signOut();
        }
        hideSideBar();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Sidebar
      visible={showCalendarSettings}
      position="right"
      onHide={hideSideBar}
      showCloseIcon={false}
      className="p-sidebar-md filter-slidebar eventsetting-slider"
    >
      <div className="row">
        <div className="col-12 d-flex justify-content-between salign-items-center py-2 mb-4">
          <div className="d-flex align-items-center">
            <div>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  hideSideBar();
                }}
              >
                <div className="icon-box me-2">
                  <i className="pi pi-angle-left"></i>
                </div>
              </a>
            </div>
            <h5 className="sub-title mb-0"> Calendar Settings</h5>
          </div>
          <Button type="submit" className="p-button p-button-secondary" onClick={handleSubmit(handleSettingsSubmit)} label="Save Changes" />
        </div>
      </div>
      <div className="row px-5">
        <div className="col-12 px-0">
          <h5 className="caption-bold">preview option</h5>
        </div>
      </div>
      <div className="row ps-5 pe-3">
        <div className="col-12 px-0 d-flex align-items-center justify-content-between mb-3">
          <LabelName>Show Weekends</LabelName>
          <Controller
            name="show_weekends"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <InputSwitch
                id={field.name}
                value={field.value}
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                className="input-swich"
              />
            )}
          />
        </div>
        <div className="col-12 px-0 d-flex align-items-center justify-content-between mb-3">
          <LabelName>Show Business Time Only</LabelName>
          <Controller
            name="show_business_times_only"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <InputSwitch
                id={field.name}
                value={field.value}
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                className="input-swich"
              />
            )}
          />
        </div>
        <div className="col-12 px-0 d-flex align-items-center justify-content-between mb-3">
          <LabelName>Show Birthdays</LabelName>
          <Controller
            name="show_birthdays"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <InputSwitch
                id={field.name}
                value={field.value}
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                className="input-swich"
              />
            )}
          />
        </div>
        <div className="col-12 px-0 d-flex align-items-center justify-content-between">
          <LabelName>Show SOL</LabelName>
          <Controller
            name="show_sol"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <InputSwitch
                id={field.name}
                value={field.value}
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                className="input-swich"
              />
            )}
          />
        </div>
      </div>
      <div className="row mt-4 px-5">
        <div className="col-12 px-0">
          <h5 className="caption-bold"> Synchronization</h5>
        </div>
      </div>
      <div className="row ps-5 pe-3">
        <div className="col-12 px-0 d-flex align-items-center justify-content-between mb-3">
          <LabelName>Sync with Google Calendar</LabelName>
          <Controller
            name="sync_with_google_calendar"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <InputSwitch
                id={field.name}
                value={field.value}
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                className="input-swich"
              />
            )}
          />
        </div>
      </div>
      <div className="row ps-5 pe-3">
        <div className="col-12 px-0 d-flex align-items-center justify-content-between">
          <LabelName>Sync with Outlook</LabelName>
          <Controller
            name="sync_with_outlook"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <InputSwitch
                id={field.name}
                value={field.value}
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                className="input-swich"
              />
            )}
          />
        </div>
      </div>
    </Sidebar>
  );
}

export default CalendarSettings;
