import { actionTypes } from "constants/types";
import { listAccount } from "services/accountServices";

export const listAccounts = (pageLimit=10,pagination_token="",pagination_direction="forward")=>{

    return (dispatch)=>{
        dispatch({type:actionTypes.GET_ACCOUNTS_LIST_STARTED})
        listAccount(pageLimit,pagination_token,pagination_direction).then(response=>{
            if(response && response.data){
                dispatch({type:actionTypes.GET_ACCOUNTS_LIST_SUCCESS,data:response.data})
            }
            //console.log(response)
           
        }).catch(err=>{
            console.log(err)
            dispatch({type:actionTypes.GET_ACCOUNTS_LIST_FAILED,error:err})
        })
    }
}

