import { useState } from 'react';
import { useLeadContext } from 'modules/lead/Context/LeadContext';
import { generateFullName } from 'utils/generateFullNameUtils';

export function useAssignToFilter() {
  const { userList } = useLeadContext();
  const [searchTerm, setSearchTerm] = useState('');

  const filteredUserList = userList.filter(
    (item) => generateFullName(item).toLowerCase().includes(searchTerm?.toLowerCase()) || !searchTerm
  );

  return {
    searchTerm,
    setSearchTerm,
    filteredUserList,
  };
}
