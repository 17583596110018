import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import AddLocationForm from './AddLocationForm';
import LabelName from 'components/UI/LabelName/LabelName';

import { useCalendarContext } from 'modules/calendar/Context/CalendarContext';

function EventLocation({ control, setValue }) {
  const { locationsList } = useCalendarContext();
  const [addLocation, setAddLocation] = useState(false);

  return (
    <div className="d-flex py-2 flex-wrap">
      <div className="col-md-4 col-12 mt-2">
        <LabelName required={false} htmlFor="location">
          Location
        </LabelName>
      </div>
      <div className="col-md-7 col-11 align-items-center">
        {addLocation ? (
          <AddLocationForm setAddLocation={setAddLocation} setValue={setValue} />
        ) : (
          <Controller
            name="location"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Dropdown
                id={field.name}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={locationsList}
                placeholder="Select Location"
                className="w-100"
                filter
              />
            )}
          />
        )}
      </div>
      <div className="col-sm-1 d-flex justify-content-end mt-2 ps-2">
        <span className="pointer">
          <i className="fas fa-plus-circle location-add" onClick={() => setAddLocation(true)}></i>
          <Tooltip content="Add Location" position="top" target=".location-add" showDelay={500} />
        </span>
      </div>
    </div>
  );
}

export default EventLocation;
