import { actionTypes } from "constants/types";
let initialState = {
  isLoading: true,
  automation_rules: "",
  automation_rules_error: "",
};

export default function WorkFlowAutomationReducer(state = initialState, action={}) {
  switch (action.type) {
   
    case actionTypes.GET_AUTOMATION_RULES:
      return {
        ...state,

        isLoading: true,
      };
    case actionTypes.GET_AUTOMATION_RULES_SUCCESS:
      return {
        // ...state,
        automation_rules: action.payload,

        isLoading: false,
      };
    case actionTypes.GET_AUTOMATION_RULES_ERROR:
      return {
        ...state,
        isLoading: false,
        automation_rules_error: action.payload,
      };

    default:
      return state;
  }
}
