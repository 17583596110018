import { Client, PageIterator } from '@microsoft/microsoft-graph-client';
//import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { endOfWeek, startOfWeek } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
//import { User, Event } from 'microsoft-graph';

let graphClient = undefined;

function ensureClient(authProvider) {
  if (!graphClient) {
    graphClient = Client.initWithMiddleware({
      authProvider: authProvider,
    });
  }

  return graphClient;
}

export async function getUser(authProvider) {
  ensureClient(authProvider);

  // Return the /me API endpoint result as a User object
  const user = await graphClient
    .api('/me')
    // Only retrieve the specific fields needed
    .select('displayName,mail,mailboxSettings,userPrincipalName')
    .get();

  return user;
}

export async function getUserWeekCalendar(authProvider, timeZone, start_date, end_date) {
  ensureClient(authProvider);

  // Generate startDateTime and endDateTime query params
  // to display a 7-day window
  const now = new Date();
  let startDateTime = zonedTimeToUtc(startOfWeek(now), timeZone).toISOString();
  let endDateTime = zonedTimeToUtc(endOfWeek(now), timeZone).toISOString();

  if (start_date) startDateTime = new Date(start_date).toISOString();
  if (end_date) endDateTime = new Date(end_date).toISOString();

  // GET /me/calendarview?startDateTime=''&endDateTime=''
  // &$select=subject,organizer,start,end
  // &$orderby=start/dateTime
  // &$top=50
  var response = await graphClient
    .api('/me/calendarview')
    .header('Prefer', `outlook.timezone="${timeZone}"`)
    .query({ startDateTime: startDateTime, endDateTime: endDateTime })
    .select('subject,organizer,start,end,webLink,onlineMeetingUrl')
    .orderby('start/dateTime')
    .top(1000)
    .get();

  if (response['@odata.nextLink']) {
    // Presence of the nextLink property indicates more results are available
    // Use a page iterator to get all results
    var events = [];

    // Must include the time zone header in page
    // requests too
    var options = {
      headers: { Prefer: `outlook.timezone="${timeZone}"` },
    };

    var pageIterator = new PageIterator(
      graphClient,
      response,
      (event) => {
        events.push(event);
        return true;
      },
      options
    );

    await pageIterator.iterate();

    return events;
  } else {
    return response.value;
  }
}

export async function createOutlookEvent(authProvider, newEvent) {
  ensureClient(authProvider);

  // POST /me/events
  // JSON representation of the new event is sent in the
  // request body
  return await graphClient.api('/me/events').post(newEvent);
}

export const outLookEvent = (data) => {
  let attendees = [];
  if (data.guests && data.guests.length > 0) {
    attendees = data.guests.map((index) => {
      return {
        emailAddress: {
          address: index.email,
          name: index.name,
        },
      };
    });
  }
  const event = {
    subject: data.title,
    body: {
      contentType: 'text',
      content: data.description,
    },
    start: {
      dateTime: new Date(data.meeting_start_time).toISOString(),
      timeZone: 'Pacific Standard Time',
    },
    end: {
      dateTime: new Date(data.meeting_end_time).toISOString(),
      timeZone: 'Pacific Standard Time',
    },
    location: {
      displayName: data.location,
    },
    attendees: attendees,
  };

  return event;
};

export const formatEventsOutLook = (list) => {
  return list.map((item) => ({
    title: item.subject ?? 'No Title',
    start: new Date(item.start.dateTime).toISOString() || new Date(item.start.date).toISOString(),
    end: new Date(item.end.dateTime).toISOString() || new Date(item.end.date).toISOString(),
    id: item.id,
    res_type: 'outlook',
    calendar_link: item?.webLink,
    meet_link: item?.onlineMeetingUrl,
  }));
};

export async function deleteOutlookEvent(authProvider, eventId) {
  ensureClient(authProvider);
  return await graphClient.api(`/me/events/${eventId}`).delete();
}

export async function updateOutlookEvent(authProvider, eventId, event) {
  ensureClient(authProvider);
  return await graphClient.api(`/me/events/${eventId}`).update(event);
}
