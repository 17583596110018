import { useEffect, useState } from 'react';
import { listGlobalTags } from 'services/Settings';
import { totalCountEvent } from 'services/generalServices';

export function useGlobalTagList() {
  const [List, setList] = useState([]);
  const [tagList, setTagList] = useState(null);
  const loadGlobalList = () => {
    totalCountEvent('global-tag').then((response) => {
      listGlobalTags(response.data)
        .then((response) => {
          setList(response?.data?.global_tag);
        })
        .catch((error) => console.log(error));
    });
  };

  useEffect(() => {
    loadGlobalList();
  }, []);

  useEffect(() => {
    if (List?.length > 0) {
      let tagsArray = [];
      List.forEach((item, index) => {
        tagsArray.push({ name: item.tag, value: item.tag });
      });
      setTagList(tagsArray);
    }
  }, [List]);

  return { globalList: List, formattedGlobalTag: tagList };
}
