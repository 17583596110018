import { pagination_preference } from 'constants/paginationPreferences';

import { updateUserProfileDetails } from 'services/profileServices';
import { checkKeyIsPresentInObject } from 'utils/utils';

export function handlePageNumberInPagination(
  userContext,
  module,
  subModule,
  listName,
  pageNumber,
  filterName,
  filterData,
  excludeFromLoading
) {
  let newPrefernces = JSON.parse(JSON.stringify(pagination_preference));
  const isAlreadyPresent = subModule
    ? userContext?.firmDetails?.pagination_preference?.hasOwnProperty(module) &&
      userContext?.firmDetails?.pagination_preference?.settings?.hasOwnProperty(subModule) &&
      userContext?.firmDetails?.pagination_preference?.settings?.custom_descriptors?.hasOwnProperty(listName)
    : userContext?.firmDetails?.pagination_preference?.hasOwnProperty(module) &&
      userContext?.firmDetails?.pagination_preference?.settings?.custom_descriptors?.hasOwnProperty(listName);

  if ('pagination_preference' in userContext?.firmDetails && isAlreadyPresent) {
    if (subModule && !filterName) {
      userContext.firmDetails.pagination_preference[module][subModule][listName] = pageNumber;
    } else if (subModule && filterName) {
      userContext.firmDetails.pagination_preference[module][subModule][listName][filterName] = filterData;
    } else {
      userContext.firmDetails.pagination_preference[module][listName] = pageNumber;
    }
  } else {
    if ('pagination_preference' in userContext?.firmDetails && !isAlreadyPresent) {
      newPrefernces = userContext.firmDetails.pagination_preference;
    }
    if (subModule && !filterName) {
      // newPrefernces[module][subModule][listName] = pageNumber;
      (newPrefernces[module][subModule] ??= {})[listName] = pageNumber;
    } else if (subModule && filterName) {
      (newPrefernces[module] ??= {})[subModule] ??= {};
      (newPrefernces[module][subModule][listName] ??= {})[filterName] = filterData;
    } else {
      newPrefernces[module][listName] = pageNumber;
    }
  }
  let sendData = {};
  sendData.pk = userContext?.firmDetails.pk;
  sendData.sk = userContext?.firmDetails.sk;
  sendData.pagination_preference =
    'pagination_preference' in userContext?.firmDetails && isAlreadyPresent
      ? userContext?.firmDetails?.pagination_preference
      : newPrefernces;
  if (sendData) {
    updateUserProfileDetails(sendData, excludeFromLoading)
      .then((data) => {
        if (data) {
          userContext
            .loadUserDetails('', excludeFromLoading)
            .then((userDetails) => {
              sendData = {};
            })
            .catch((err) => console.log({ err }));
        }
      })
      .catch((err) => console.log({ err }));
  }
}

export const updateProfile = (userContext, sendData, excludeFromLoading) => {
  updateUserProfileDetails(sendData, excludeFromLoading)
    .then((data) => {
      if (data) {
        userContext
          .loadUserDetails('', excludeFromLoading)
          .then((userDetails) => {})
          .catch((err) => console.log({ err }));
      }
    })
    .catch((err) => console.log({ err }));
};

export function handleFilterUpdateInUserContext(userContext, module, subModule, listName, filterName, filterData, excludeFromLoading) {
  let newPrefernces = userContext?.firmDetails?.user_preferences ? userContext?.firmDetails?.user_preferences : userContext?.firmDetails;

  const isAlreadyPresent = userContext?.firmDetails?.user_preferences
    ? (listName === null
        ? checkKeyIsPresentInObject(userContext?.firmDetails?.user_preferences, subModule)
        : checkKeyIsPresentInObject(userContext?.firmDetails?.user_preferences, listName)) &&
      checkKeyIsPresentInObject(userContext?.firmDetails?.user_preferences, filterName)
    : false;

  if ('user_preferences' in userContext?.firmDetails && isAlreadyPresent) {
    if (listName) {
      userContext.firmDetails.user_preferences[module][subModule][listName][filterName] = filterData;
    } else {
      userContext.firmDetails.user_preferences[module][subModule][filterName] = filterData;
    }
  } else if ('user_preferences' in userContext?.firmDetails && !isAlreadyPresent) {
    if (subModule && filterName && !listName) {
      (newPrefernces[module] ??= {})[subModule] ??= {};
      newPrefernces[module][subModule][filterName] = filterData;
    } else if (subModule && filterName && listName) {
      (newPrefernces[module] ??= {})[subModule] ??= {};
      (newPrefernces[module][subModule][listName] ??= {})[filterName] = filterData;
    }
  } else {
    if (!userContext?.firmDetails.hasOwnProperty('user_preferences') && !listName) {
      newPrefernces['user_preferences'] = {};
      (newPrefernces['user_preferences'][module] ??= {})[subModule] ??= {};
      newPrefernces['user_preferences'][module][subModule][filterName] ??= {};
      newPrefernces['user_preferences'][module][subModule][filterName] = filterData;
    } else if (!userContext?.firmDetails.hasOwnProperty('user_preferences') && listName) {
      newPrefernces['user_preferences'] = {};
      (newPrefernces['user_preferences'][module] ??= {})[subModule] ??= {};
      (newPrefernces['user_preferences'][module][subModule][listName] ??= {})[filterName] = {};
      newPrefernces['user_preferences'][module][subModule][listName][filterName] = filterData;
    }
  }
  newPrefernces = newPrefernces?.user_preferences ? newPrefernces?.user_preferences : newPrefernces;
  let sendData = {};
  sendData.pk = userContext?.firmDetails.pk;
  sendData.sk = userContext?.firmDetails.sk;
  sendData.user_preferences =
    'user_preferences' in userContext?.firmDetails && isAlreadyPresent ? userContext?.firmDetails?.user_preferences : newPrefernces;
  if (sendData) {
    updateProfile(userContext, sendData, excludeFromLoading);
  }
}

export function handleFilterResetInTask(userContext, module, subModule, listName, filterDataSet, excludeFromLoading) {
  let newPrefernces = userContext?.firmDetails?.user_preferences ? userContext?.firmDetails?.user_preferences : userContext?.firmDetails;
  let isAlreadyPresent = false;
  for (var filterName in filterDataSet) {
    isAlreadyPresent = userContext?.firmDetails?.user_preferences
      ? (listName === null
          ? checkKeyIsPresentInObject(userContext?.firmDetails?.user_preferences, subModule)
          : checkKeyIsPresentInObject(userContext?.firmDetails?.user_preferences, listName)) &&
        checkKeyIsPresentInObject(userContext?.firmDetails?.user_preferences, filterName)
      : false;

    if ('user_preferences' in userContext?.firmDetails && isAlreadyPresent) {
      if (listName) {
        userContext.firmDetails.user_preferences[module][subModule][listName][filterName] = filterDataSet[filterName];
      } else {
        userContext.firmDetails.user_preferences[module][subModule][filterName] = filterDataSet[filterName];
      }
    } else if ('user_preferences' in userContext?.firmDetails && !isAlreadyPresent) {
      if (subModule && filterName && !listName) {
        (newPrefernces[module] ??= {})[subModule] ??= {};
        newPrefernces[module][subModule][filterName] = filterDataSet[filterName];
      } else if (subModule && filterName && listName) {
        (newPrefernces[module] ??= {})[subModule] ??= {};
        (newPrefernces[module][subModule][listName] ??= {})[filterName] = filterDataSet[filterName];
      }
    } else {
      if (!userContext?.firmDetails.hasOwnProperty('user_preferences') && !listName) {
        newPrefernces['user_preferences'] = {};
        (newPrefernces['user_preferences'][module] ??= {})[subModule] ??= {};
        newPrefernces['user_preferences'][module][subModule][filterName] ??= {};
        newPrefernces['user_preferences'][module][subModule][filterName] = filterDataSet[filterName];
      } else if (!userContext?.firmDetails.hasOwnProperty('user_preferences') && listName) {
        newPrefernces['user_preferences'] = {};
        (newPrefernces['user_preferences'][module] ??= {})[subModule] ??= {};
        (newPrefernces['user_preferences'][module][subModule][listName] ??= {})[filterName] = {};
        newPrefernces['user_preferences'][module][subModule][listName][filterName] = filterDataSet[filterName];
      }
    }
  }
  newPrefernces = newPrefernces?.user_preferences ? newPrefernces?.user_preferences : newPrefernces;
  let sendData = {};
  sendData.pk = userContext?.firmDetails.pk;
  sendData.sk = userContext?.firmDetails.sk;
  sendData.user_preferences =
    'user_preferences' in userContext?.firmDetails && isAlreadyPresent ? userContext?.firmDetails?.user_preferences : newPrefernces;
  if (sendData) {
    updateProfile(userContext, sendData, excludeFromLoading);
  }
}
