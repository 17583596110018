import { actionTypes } from "constants/types";

let initialState = {
  roles: [],
  isLoading: false,
  error: "",
};

export default function UserRolesReducer(state = initialState, action={}) {
  switch (action.type) {
    case actionTypes.GET_USER_ROLES_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_USER_ROLES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        roles: action.data,
      };
    case actionTypes.GET_USER_ROLES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
}
