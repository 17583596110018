import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputSwitch } from 'primereact/inputswitch';
import { Checkbox } from 'primereact/checkbox';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';

import { useToggle } from 'hooks/useToggle';
import { useFirmUserList } from 'hooks/useFirmUserList';
import { generateFullName } from 'utils/generateFullNameUtils';
import { handleRedirection } from 'utils/utils';
import { handleSettingSkAndPkToLocalStorage } from 'utils/localStorageUtils';
import useTeamMember from 'modules/cases/hooks/useTeamMember';
import ImageComponent from 'shared/ImageComponent';
import { Tag } from 'primereact/tag';

const getLeadRole = (m) => {
  const leadRoles = {
    is_lead_case_manager: 'Lead Case Manager',
    is_lead_paralegal: 'Lead Paralegal',
    is_lead_attorney: 'Lead Attorney',
    is_lead_associate: 'Lead Associate',
  };
  const leadRole = Object.keys(leadRoles).find((key) => m?.[key]);
  return leadRole ? leadRoles[leadRole] : m?.user_type;
};

export default function TeamMember({ caseDetails, isLeadSpecific, isCaseClosed, isLeadClosed, getDetails }) {
  const [isTeamMemberAdd, toggleAddView] = useToggle(false);

  const history = useHistory();
  const { userList } = useFirmUserList();
  const { deleteTeamMember, handleTeamMemberLawftAcces, updateTeamMembers } = useTeamMember({ caseDetails, isLeadSpecific, getDetails });

  const [selectedList, setSelectedList] = useState([]);
  const teamMembers = useMemo(
    () => caseDetails?.team_member?.map((v) => ({ ...v, is_selected: true }))?.sort((a, b) => a?.user_type - b?.user_type),
    [caseDetails]
  );

  const onChangeHandler = (e) => {
    setSelectedList((preVal) =>
      preVal?.map((val) => {
        if (val?.firm_user_id === e.value) {
          if (e.target.name === 'is_selected' || e.target.name === 'notify_case_updates') {
            if (e.target.name === 'notify_case_updates' && e.checked) {
              return { ...val, [e.target.name]: e.checked, is_selected: true };
            }
            if (e.target.name === 'is_selected' && !e.checked) {
              return {
                ...val,
                [e.target.name]: e.checked,
                is_lead_associate: false,
                is_lead_attorney: false,
                is_lead_paralegal: false,
                is_lead_case_manager: false,
                notify_case_updates: false,
              };
            }
            return { ...val, [e.target.name]: e.checked };
          } else {
            return {
              ...val,
              is_lead_associate: false,
              is_lead_attorney: false,
              is_lead_paralegal: false,
              is_lead_case_manager: false,
              [e.target.name]: true,
              is_selected: true,
            };
          }
        } else if (!(e.target.name === 'is_selected' || e.target.name === 'notify_case_updates')) {
          return { ...val, [e.target.name]: false };
        }
        return { ...val };
      })
    );
  };

  const getSelectedItem = (name) => selectedList?.find((v) => v?.[name] === true)?.firm_user_id;

  const confirmDeleteMember = (tMember) => {
    confirmDialog({
      message: 'Are you sure want to delete this team member?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => deleteTeamMember(tMember),
    });
  };

  const handleSave = () => {
    updateTeamMembers(selectedList?.filter((v) => v?.is_selected)).then(() => toggleAddView());
  };

  const nameTemplate = (rowData) => {
    const { sk, pk, firm_user_id } = rowData;
    let url = '/settings/team-management';
    let stateToPass = {};

    if (firm_user_id) {
      url = firm_user_id ? `/settings/team-management/${firm_user_id}` : '/settings/team-management';
      stateToPass = rowData;
    }

    return (
      <div
        className="d-flex flex-column flex-sm-row w-100 align-items-center"
        onClick={() => {
          handleRedirection(history, url, stateToPass);
          handleSettingSkAndPkToLocalStorage(sk, pk);
        }}
      >
        <span className="p-column-title text-break d-none">Lead Attorney</span>
        <ImageComponent filePath={rowData?.profile_image} fileName={rowData?.first_name} fileSize="small.jpg" className="col-1 me-2" />
        {firm_user_id && <i class="fas fa-user-tie me-2"></i>}
        <span className="d-flex align-items-center text-break redirection-link">{generateFullName(rowData)}</span>
      </div>
    );
  };

  const notifyTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column flex-sm-row w-100 justify-content-center">
        <span className="p-column-title text-break d-none">Enable Lawft Access</span>
        <Checkbox
          id="team_notify_checkbox"
          checked={rowData.notify_case_updates}
          name="notify_case_updates"
          value={rowData.firm_user_id}
          onChange={onChangeHandler}
          disabled={!(rowData?.status === 'ACTIVE')}
        />
      </div>
    );
  };
  const selectColumnTemplate = (rowData) => {
    return (
      <Checkbox
        id="is_selected"
        checked={rowData?.is_selected}
        name="is_selected"
        value={rowData.firm_user_id}
        onChange={onChangeHandler}
        disabled={!(rowData.status === 'ACTIVE')}
      />
    );
  };

  const tagFormatting = (option) => {
    if (option?.is_confirmed === false && option?.status === 'ACTIVE') {
      return <Tag className="m-1 ms-0 tag-cases border-0" value="UNCONFIRMED" rounded />;
    } else if (option?.is_confirmed && option?.status === 'ARCHIVED') {
      return <Tag className="m-1 ms-0 tag-cases border-0" value={option?.status} rounded />;
    } else if (option?.is_confirmed === false && option?.status === 'ARCHIVED') {
      return <Tag className="m-1 ms-0 tag-cases border-0" value={option?.status} rounded />;
    }
  };

  const assignToItemTemplate = (option) => {
    return (
      <>
        {option?.firm_user_id && <i class="fas fa-user-tie me-2"></i>}
        {generateFullName(option)}
      </>
    );
  };
  useEffect(() => {
    setSelectedList(
      teamMembers.concat(
        userList
          .map((v) => ({ ...v, sk: undefined, pk: undefined, case_id: caseDetails?.case_id }))
          .filter((item) => !teamMembers.some((i) => i.firm_user_id === item.firm_user_id))
      )
    );
  }, [userList, teamMembers]);

  return (
    <Card title={isLeadSpecific ? 'Assign To' : 'Team Members'}>
      {isTeamMemberAdd ? (
        <>
          <div className="d-flex justify-content-end">
            <div className="text-end">
              <Button
                className="p-button p-button-secondary"
                onClick={handleSave}
                disabled={!Boolean(selectedList?.filter((v) => v?.is_selected)?.length)}
              >
                {isLeadSpecific ? 'Update' : 'Update Team'}
              </Button>
              <Button className="p-button p-button-secondary outline ms-2" onClick={toggleAddView}>
                Cancel
              </Button>
            </div>
          </div>

          <div className="mt-2 d-flex align-items-center py-2 flex-wrap">
            <div className="col-4 p-medium">Lead Attorney</div>
            <div className="col-6">
              <Dropdown
                name="is_lead_attorney"
                optionLabel={(v) => generateFullName(v)}
                optionValue="firm_user_id"
                options={selectedList}
                onChange={onChangeHandler}
                className="input-shadow w-100 border-0"
                value={getSelectedItem('is_lead_attorney')}
                itemTemplate={assignToItemTemplate}
              />
            </div>
          </div>

          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-4 p-medium">Lead Associate</div>
            <div className="col-6">
              <Dropdown
                name="is_lead_associate"
                optionLabel={(v) => generateFullName(v)}
                optionValue="firm_user_id"
                options={selectedList}
                onChange={onChangeHandler}
                className="input-shadow w-100 border-0"
                value={getSelectedItem('is_lead_associate')}
                itemTemplate={assignToItemTemplate}
              />
            </div>
          </div>
          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-4 p-medium">Lead Paralegal</div>
            <div className="col-6">
              <Dropdown
                name="is_lead_paralegal"
                optionLabel={(v) => generateFullName(v)}
                optionValue="firm_user_id"
                options={selectedList}
                onChange={onChangeHandler}
                className="input-shadow w-100 border-0"
                value={getSelectedItem('is_lead_paralegal')}
                itemTemplate={assignToItemTemplate}
              />
            </div>
          </div>
          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-4 p-medium">Lead Case Manager</div>
            <div className="col-6">
              <Dropdown
                name="is_lead_case_manager"
                optionLabel={(v) => generateFullName(v)}
                optionValue="firm_user_id"
                options={selectedList}
                onChange={onChangeHandler}
                className="input-shadow w-100 border-0"
                value={getSelectedItem('is_lead_case_manager')}
                itemTemplate={assignToItemTemplate}
              />
            </div>
          </div>
          <div className="d-flex mb-3">
            <div className="col-12">
              <DataTable
                value={selectedList}
                responsiveLayout="scroll"
                emptyMessage="No Data Found."
                selectionMode="checkbox"
                className="case-team-member"
              >
                <Column body={selectColumnTemplate} style={{ width: 25 }} headerStyle={{ width: 25 }} />
                <Column field="name" header="Name" body={nameTemplate} />
                {!Boolean(isLeadSpecific) && (
                  <Column
                    field="notify"
                    header="Notify About Case Updates"
                    style={{ width: 100, textAlign: 'center' }}
                    headerStyle={{ textAlign: 'center' }}
                    body={notifyTemplate}
                  />
                )}
              </DataTable>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="d-flex mb-3 pb-3 border-bottom">
            <div className="col col-sm-4 text-muted F-size12">Name</div>
            <div className="col col-sm-3 text-muted F-size12">Role</div>
            <div className="col col-sm-5 text-muted F-size12">Notify About Case Updates</div>
          </div>

          {teamMembers?.map((m, i) => (
            <div className="d-flex mb-3 align-items-center avatarcurved" key={i}>
              <div
                className="col col-sm-4"
                onClick={() => {
                  const { sk, pk, firm_user_id } = m;
                  const url = firm_user_id ? `/settings/team-management/${firm_user_id}` : '/settings/team-management';
                  handleSettingSkAndPkToLocalStorage(sk, pk);
                  handleRedirection(history, url);
                }}
              >
                <div className="d-flex align-items-center  pe-2 text-break">
                  <ImageComponent
                    filePath={`${m?.tenant_id}/${m?.firm_user_id ? m?.firm_user_id : m?.user_id}/profile_image`}
                    fileName={m?.first_name}
                    fileSize="small.jpg"
                    className="col-1 me-2"
                  />
                  <i className="fas fa-user-tie me-2"></i>
                  <span className="pointer F-size12 redirection-link">{`${m.first_name} ${m.last_name}`}</span>
                </div>
                <div className="archived-tags">{tagFormatting(m)}</div>
              </div>
              <div className={`col col-sm-3 text-capitalize`}>{getLeadRole(m)}</div>
              <div className="col col-sm-5 d-flex align-items-center justify-content-between">
                <InputSwitch
                  className="input-swich"
                  checked={m.notify_case_updates}
                  onChange={() => handleTeamMemberLawftAcces(m)}
                  disabled={!(m && m.status && m.status === 'ACTIVE') || isCaseClosed || isLeadClosed}
                />
                <i
                  className={'icon-delete icon-red cursor-pointer ' + (isCaseClosed || isLeadClosed ? 'pe-none opacity-25' : '')}
                  onClick={() => confirmDeleteMember(m)}
                  disabled={!(m && m.status && m.status === 'ACTIVE')}
                />
                <Tooltip content="Delete Team Member" position="left" target=".icon-delete" showDelay={500} />
              </div>
            </div>
          ))}
          {!teamMembers?.length && (
            <div className="d-flex mb-3 align-items-center">
              <div className="col-12">N/A</div>
            </div>
          )}
          <div className="my-2">
            <a
              onClick={toggleAddView}
              className={'addfield cursor-pointer ' + (isCaseClosed || isLeadClosed ? 'pe-none opacity-25' : '')}
              href
            >
              <i className="icon-add F-size14 me-1" />
              {isLeadSpecific ? 'Change Assign to' : 'Update Team'}
            </a>
          </div>
        </>
      )}
    </Card>
  );
}
