import React from 'react';

import Thumbnail from './Thumbnail';
import Name from './Name';

import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';

function GridItem({ item }) {
  const { handleBreadCrumbs, modals, setSelectedPreviewItem, onFilterChange, setPreviewList, files } = useFileManagerContext();

  const { setShowPreview } = modals;

  const selectFile = (e) => {
    if (e.entry_type === 'folder') {
      handleBreadCrumbs(e);
      // setSelectedFolder(e.object_id);
      // let obj = {
      //   case_id: isGlobalFileManager || isClientFileManager ? '' : caseDetails.case_id,
      //   selectedFolder: e.object_id,
      //   selectedType: '',
      //   tag: '',
      //   query: '',
      //   folder_id: '',
      // };
      // getFiles(obj, currentView, pageLimit);
      let data = {
        target: {
          name: 'selectedFolder',
        },
        value: e.object_id,
      };
      onFilterChange(data);
    } else {
      setShowPreview(true);
      setPreviewList(files);
      setSelectedPreviewItem(e);

      // if (
      //   selectedItem &&
      //   selectedItem.file_type &&
      //   (selectedItem.file_type === "application/pdf" ||
      //     selectedItem.file_type === docTypeVal)
      // ) {
      //   pdfTronFile(selectedItem, selectedIndex, false);
      // } else {
      //   let find_index = fileList
      //     .filter((item) => item.entry_type !== "folder")
      //     .findIndex((item) => item.sk === e.sk && item.pk === e.pk);
      //   setSelectedIndex(find_index);
      //   setIsFilePreview(true);
      // }
    }
  };

  return (
    <div
      style={{
        ...fileManagerItemStyles,
        ...(item.entry_type === 'image' ? { height: '100%' } : {}),
      }}
      className="pointer"
    >
      <Thumbnail item={item} selectFile={selectFile} />
      <Name item={item} selectFile={selectFile} />
    </div>
  );
}

export default GridItem;

const fileManagerItemStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '16px',
  height: '200px',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
};
