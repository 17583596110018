import { performRequest, performRequestSecond } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';
import { getTenantDetails } from 'utils/utils';
import Cookies from 'universal-cookie';
const cookie = new Cookies();

export const createContact = (data, is_lead = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.contact.create}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${is_lead ? `&is_lead=${is_lead}` : ''}`;
  return performRequest(apiEndPoints.methodType.POST, url, data);
};

export const phoneValidation = (phone) => {
  let tenantId = cookie.get('tenantId');
  let url = `${apiEndPoints.contact.phoneValidation}/${phone}?tenant_id=${tenantId}`;
  return performRequest(apiEndPoints.methodType.GET, url);
};

export const listContacts = (
  contactType,
  contactGroup,
  contactStatus = 'ACTIVE',
  keyWord = '',
  is_court_location = '',
  is_event_location = '',
  next = '',
  pageLimit = 10,
  pagination_token = '',
  pagination_direction = 'forward',
  in_lead = false
) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.contact.list}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&contact_type=${contactType}&contact_status=${contactStatus}&keyword=${keyWord}&is_court_location=${is_court_location}&is_event_location=${is_event_location}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}&in_lead=${in_lead}`;
  return performRequest(apiEndPoints.methodType.GET, url);
};

export const contactDetails = (sk, pk, contact_id) => {
  let tenantId = cookie.get('tenantId');
  let url = `${apiEndPoints.contact.details}?tenant_id=${tenantId}&sk=${sk}&pk=${pk}&contact_id=${contact_id}`;
  return performRequest(apiEndPoints.methodType.GET, url);
};

export const updateContactDetails = (data, is_lead = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.contact.update}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${is_lead ? `&is_lead=${is_lead}` : ''}`;
  return performRequest(apiEndPoints.methodType.PUT, url, data);
};

export const upcomingDates = (contactId) => {
  const [tenantId] = getTenantDetails();
  let url = `${apiEndPoints.contact.upcomingDates}?tenant_id=${tenantId}&contact_id=${contactId}`;
  return performRequestSecond(apiEndPoints.methodType.GET, url);
};
export const relatedTasks = (contactId) => {
  const [tenantId] = getTenantDetails();
  let url = `${apiEndPoints.contact.relatedTasks}?tenant_id=${tenantId}&contact_id=${contactId}`;
  return performRequestSecond(apiEndPoints.methodType.GET, url);
};
export const relatedFiles = (contactId) => {
  const [tenantId] = getTenantDetails();
  let url = `${apiEndPoints.contact.relatedFiles}?tenant_id=${tenantId}&contact_id=${contactId}`;
  return performRequestSecond(apiEndPoints.methodType.GET, url);
};
