import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { classNames } from 'primereact/utils';

import { handleRedirection } from 'utils/utils';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import { UserDetailsContext } from 'context/userDetailsContext';
import { Card } from 'primereact/card';
import { handleSettingSkAndPkToLocalStorage } from 'utils/localStorageUtils';
import { generateFullName } from 'utils/generateFullNameUtils';
import constants from 'constants/index';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import { Checkbox } from 'primereact/checkbox';
import { updateCaseNote } from 'services/CaseNoteServices';
import { ReactComponent as IncomingIcon } from 'assets/svg/incoming_call_dashboard.svg';

export default function NeedResolution(props) {
  const { caseNoteList, fetchCaseNotes, leadDetails } = props;
  const { addToast } = useToast();
  const history = useHistory();
  const userDetails = useContext(UserDetailsContext);
  const [resolved, setResolved] = useState({});
  const emptyDataValue = '-';

  const handleRedirectionInLeadOverViewPage = (params) => {
    const { history, url, stateToPass, sk, pk } = params || {};
    handleSettingSkAndPkToLocalStorage(sk, pk);
    handleRedirection(history, url, stateToPass);
  };

  const updateCaseNoteDetails = (note, value) => {
    let data = JSON.parse(JSON.stringify(note));
    if (data.resolved_status !== value) {
      data.resolved_status = value;
    }
    updateCaseNote(data)
      .then((response) => {
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.NOTE_UPDATED_CREATED);
        fetchCaseNotes();
      })
      .catch((error) => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.NOTE_UPDATED_FAILED);
      });
  };

  return (
    <>
      <div className="caption-bold mb-2">{`Needs Resolution (${
        caseNoteList.filter((val) => val.resolution_status && !val.resolved_status).length || 0
      })`}</div>
      {caseNoteList?.length ? (
        caseNoteList.filter((dat) => dat?.resolution_status && !dat?.resolved_status).length ? (
          <Card
            className="mb-4 shadow-middle"
            style={{
              height: caseNoteList.filter((dat) => dat.resolution_status && !dat.resolved_status).length > 2 ? '400px' : 'auto',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            {caseNoteList
              .filter((dat) => dat?.resolution_status && !dat?.resolved_status)
              .map((note, index) => {
                const { sk, pk, case_id, created_by_firm_user_id, call_type } = note;

                return (
                  <Card className="mb-3 shadow-middle" key={index}>
                    <div className="row">
                      <div className="col-sm-3">
                        <div className="d-flex align-items-center F-size14 text-medium p-lg-0 py-2 w-100">
                          {note?.note_type ? (
                            <div className="icon-container icon-bg text-primary-dark mx-1">
                              {note?.call_type === 'incoming' ? (
                                <IncomingIcon width={15} height={15} className="text-primary-dark" />
                              ) : (
                                <i
                                  className={`${
                                    note?.note_type === 'case_note'
                                      ? 'icon-casedocument text-primary-dark'
                                      : 'icon-callBack text-primary-dark'
                                  }`}
                                ></i>
                              )}
                            </div>
                          ) : null}
                          {note?.title ? (
                            <span
                              title={note?.title}
                              onClick={(e) => {
                                e.preventDefault();
                                const caseId = case_id || leadDetails?.case_id;
                                let url = caseId ? `/leads/${caseId}/note` : '/leads';
                                let stateToPass = { caseNoteData: note };

                                handleRedirectionInLeadOverViewPage({ history, url, stateToPass, sk, pk });
                              }}
                              className={`note text-truncate w-100 call-back ${note?.title ? 'pointer text-capitalize' : ''}`}
                            >
                              {note?.title}
                            </span>
                          ) : (
                            emptyDataValue
                          )}
                        </div>
                      </div>
                      <div className="col-sm-3">
                        {note?.note_type === 'case_note' ? (
                          <div
                            className={`mx-2 ${classNames({
                              'redirection-link': Boolean(generateFullName(note?.created_by_name[0])),
                            })}`}
                            onClick={() => {
                              let url = created_by_firm_user_id
                                ? `/settings/team-management/${created_by_firm_user_id}`
                                : '/settings/team-management';

                              let stateToPass = { caseNoteData: note };

                              handleRedirectionInLeadOverViewPage({ history, url, stateToPass, sk, pk });
                            }}
                          >
                            {note?.created_by_name?.length ? generateFullName(note?.created_by_name[0]) : emptyDataValue}
                          </div>
                        ) : (
                          <>
                            <div className="mx-2">
                              Caller:{' '}
                              <span
                                className={classNames({
                                  'redirection-link': Boolean(note?.caller_name?.client_name || note?.person_called?.client_name),
                                })}
                                onClick={() => {
                                  const clientId = call_type === 'incoming' ? note?.caller_name?.client_id : note?.person_called?.client_id;

                                  let url = clientId ? `/contacts/${clientId}` : '/contacts';
                                  let stateToPass = { caseNoteData: note };

                                  handleRedirectionInLeadOverViewPage({ history, url, stateToPass, sk, pk });
                                }}
                              >
                                {note?.call_type === 'incoming'
                                  ? note.caller_name?.client_name || emptyDataValue
                                  : note?.person_called?.client_name || emptyDataValue}
                              </span>
                              <p
                                className="text-muted mb-0 pointer"
                                onClick={() => {
                                  let url = created_by_firm_user_id
                                    ? `/settings/team-management/${created_by_firm_user_id}`
                                    : '/settings/team-management';
                                  let stateToPass = { caseNoteData: note };

                                  handleRedirectionInLeadOverViewPage({ history, url, stateToPass, sk, pk });
                                }}
                              >
                                {note?.created_by_name?.length ? generateFullName(note?.created_by_name[0]) : 'N/A'}
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="col-sm-3">
                        <div className="text-muted">
                          {note && note.time
                            ? handleDateTimeOffset(userDetails?.userDetails?.timezone, note.time, constants.month_date_year_time_12_format)
                            : emptyDataValue}
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="d-flex align-items-center flex-wrap ">
                          <div className="col-sm-8 d-flex align-items-center">
                            <Checkbox
                              onChange={(e) => {
                                updateCaseNoteDetails(note, e.checked);
                                setResolved({
                                  ...resolved,
                                  [e.target.id]: e.checked,
                                });
                              }}
                              id={note.case_note_id}
                              checked={resolved[note.case_note_id]}
                            />
                            <label htmlFor={note.case_note_id} className="ps-2 p-checkbox-label">
                              Resolved
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                );
              })}
          </Card>
        ) : (
          <div className="p-card p-component mb-3 shadow-middle">
            <div className="p-card-body">
              <div className="p-card-content F-size14 mb-0">No records found</div>
            </div>
          </div>
        )
      ) : (
        <div className="p-card p-component mb-3 shadow-middle">
          <div className="p-card-body">
            <div className="p-card-content F-size14 mb-0">N/A</div>
          </div>
        </div>
      )}
    </>
  );
}
