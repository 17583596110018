import React from 'react';

function LabelName({ children, required, htmlFor, className }) {
  return (
    <label className={`p-medium ${className}`} htmlFor={htmlFor}>
      {children}
      {required && <span className="alert-star">*</span>}
    </label>
  );
}

export default LabelName;
