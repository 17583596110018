import { performRequest } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';
import { getTenantDetails } from 'utils/utils';

export const createCaseNote = (tenantId, tenantSk, data, is_lead = false, timeEntryData = '') => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.createCaseNote}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${is_lead ? `&is_lead=${is_lead}` : ''}`,
    data,
    timeEntryData
  );
};

export const createNotes = ({ data, is_lead = false, timeEntryData = '' }) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.createCaseNote}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${is_lead ? `&is_lead=${is_lead}` : ''}`,
    data,
    timeEntryData
  );
};

export const getCaseNote = (case_id, note_type, keyword, is_assignee, limit, pagination_token, pagination_direction) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.listCaseNote}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&case_id=${case_id}${
      note_type ? `&note_type=${note_type}` : ''
    }${
      keyword ? `&keyword=${keyword}` : ''
    }&limit=${limit}&is_assignee=${is_assignee}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`
  );
};

export const deleteCaseNote = (tenantId, tenantSk, pk, sk, is_lead = false) => {
  return performRequest(
    apiEndPoints.methodType.DELETE,
    `${apiEndPoints.deleteCaseNote}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&pk=${pk}&sk=${sk}${is_lead ? `&is_lead=${is_lead}` : ''}`
  );
};

export const updateCaseNote = (data, is_lead = false, timeEntryData = '') => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.PUT,
    `${apiEndPoints.updateCaseNote}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${is_lead ? `&is_lead=${is_lead}` : ''}`,
    data,
    timeEntryData
  );
};

export const getNoteDetails = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.getNoteDetails}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&pk=${data.pk}&sk=${data.sk}`,
    data
  );
};
