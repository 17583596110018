import { actionTypes } from "constants/types";
let initialState = {
  isBusinessRoleLoading: false,
  businessRoleList: [],
  businessRoleError: "",
};

export default function BusinessRoleReducer(state = initialState, action={}) {
  switch (action.type) {
    case actionTypes.GET_BUSINESS_ROLE_REQUEST:
      return {
        ...state,
        isBusinessRoleLoading: true,
        businessRoleList: [],
        businessRoleError: "",
      };
    case actionTypes.GET_BUSINESS_ROLE_SUCCESS:
      return {
        ...state,
        businessRoleList: action.payload,
        businessRoleError: "",
        isBusinessRoleLoading: false,
      };
    case actionTypes.GET_BUSINESS_ROLE_ERROR:
      return {
        ...state,
        businessRoleList: [],
        businessRoleError: action.payload,
        isBusinessRoleLoading: false,
      };

    default:
      return state;
  }
}
