import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button } from 'primereact/button';

import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';
import { createContact } from 'services/contact';
import { useCalendarContext } from 'modules/calendar/Context/CalendarContext';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

function AddLocationForm({ setAddLocation, setValue }) {
  const { setLocationsList } = useCalendarContext();

  const {
    control: control2,
    resetField: resetField2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm();

  const { addToast } = useToast();

  const handleAddLocation = (data) => {
    let obj = {
      contact_type: 'location',
      title: data.newLocation,
      email: '',
      phone: '',
      country: 'United States',
      country_code: '+1',
    };
    addNewLocation(obj);
    setAddLocation(false);
    resetField2('newLocation');
    setValue('location', data.newLocation);
  };

  function addNewLocation(data) {
    createContact(data)
      .then((response) => {
        setLocationsList((prev) => [...prev, { label: data.title, value: data.title }]);
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.LOCATION_CREATE_SUCCESS);
      })
      .catch((err) => {
        console.log(err);
        addToast(false, toastConstant.toasterType.INFO, toastConstant.api.FAILED, toastConstant.message.LOCATION_CREATE_FAILURE);
      });
  }

  return (
    <div>
      <div className="p-inputgroup align-items-center">
        <Controller
          name="newLocation"
          control={control2}
          rules={{ required: true }}
          defaultValue=""
          render={({ field }) => (
            <TextSnippetWrapper type="text" id={field.name} {...field} className="input-shadow me-2" placeholder="Add new location" />
          )}
        />
        <Button
          icon="pi pi-times"
          className="p-button-secondary outline me-1"
          type="button"
          onClick={() => {
            setAddLocation(false);
            resetField2('newLocation');
          }}
        />
        <Button icon="pi pi-check" className="p-button-secondary outline" type="button" onClick={handleSubmit2(handleAddLocation)} />
      </div>
      <div>{errors2?.newLocation && <InputErrorMessage>Enter valid location</InputErrorMessage>}</div>
    </div>
  );
}

export default AddLocationForm;
