import { useState } from 'react';

export default function useHandleSelectedRows() {
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSingleCheckboxClick = (e, rowData) => {
    const selectedCheckbox = e.target;

    if (selectedCheckbox.checked) {
      setSelectedRows([...selectedRows, rowData]);
    } else {
      const filteredRows = selectedRows.filter((row) => row !== rowData);
      setSelectedRows(filteredRows);
    }
  };

  const handleSelectAllCheckboxClick = (files) => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(files);
    }
    setSelectAll(!selectAll);
  };

  return {
    selectedRows,
    selectAll,
    handleSingleCheckboxClick,
    handleSelectAllCheckboxClick,
    setSelectedRows,
  };
}
