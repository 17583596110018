import React from 'react';
import { Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import CustomFields from 'modules/lead/AddLead/components/ContactForm/CustomFields';

const CaseDetailsCustomFields = ({ control, userDetails, isCaseClosed, watch, setValue, caseCustomFieldList }) => {
  return (
    <>
      {userDetails?.checkSubPermission('custom_fields') && caseCustomFieldList?.length > 0 ? (
        <>
          {watch('case_description.custom_field')?.length > 0 && (
            <>
              <div className="d-flex justify-content-between ">
                <div className="caption-bold mb-3">Custom Fields</div>
              </div>
              <Card className="mb-4 shadow-middle">
                <CustomFields
                  field="case_description.custom_field"
                  control={control}
                  practiceArea={watch('case_description.case_practice_area')}
                  setValue={setValue}
                  watch={watch}
                  fieldDisable={isCaseClosed}
                />
              </Card>
            </>
          )}

          {watch('unassigned_custom_field')?.filter(
            (val) =>
              val?.selectedPracticeAreas?.length === 0 ||
              !val?.selectedPracticeAreas ||
              val?.selectedPracticeAreas?.includes(watch('case_description.case_practice_area'))
          )?.length > 0 && (
            <div className="d-flex align-items-center py-2">
              <Controller
                name="case_description.custom_field_toggle"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Button
                    id={field.name}
                    type="button"
                    className="p-button button-text text-medium addfield ps-0"
                    onClick={(e) => field.onChange(!field.value)}
                    disabled={isCaseClosed}
                  >
                    <i className={field?.value ? 'pi pi-minus F-size12 me-1' : 'icon-add F-size14 me-1 '}></i>Add Custom Fields
                  </Button>
                )}
              />
            </div>
          )}

          {watch('case_description.custom_field_toggle') &&
            watch('unassigned_custom_field')?.filter(
              (val) =>
                val?.selectedPracticeAreas?.length === 0 ||
                !val?.selectedPracticeAreas ||
                val?.selectedPracticeAreas?.includes(watch('case_description.case_practice_area'))
            )?.length > 0 && (
              <Card className="mb-4 shadow-middle">
                <CustomFields
                  field="unassigned_custom_field"
                  control={control}
                  practiceArea={watch('case_description.case_practice_area')}
                  setValue={setValue}
                  watch={watch}
                />
              </Card>
            )}
        </>
      ) : (
        <div className="row mb-2">
          <div className="col-12 small">
            <i className="fas fa-info-circle mx-2 mt-1"></i>
            <span className="text-hint">No Custom Fields Found.</span>{' '}
          </div>
        </div>
      )}
    </>
  );
};

export default CaseDetailsCustomFields;
