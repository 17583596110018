export const reduceTax = (amount_with_tax=0, tax_percentage=0) => {
    let amount_without_tax = 0;
    amount_without_tax = (parseFloat(amount_with_tax) * 100) / (parseFloat(tax_percentage) + 100);
    return amount_without_tax.toFixed(2);
}

export const addTax = (amount_without_tax=0, tax_percentage=0) => {
    let amount_with_tax = 0;
    amount_with_tax = ((parseFloat(amount_without_tax) * parseFloat(tax_percentage)) / 100) + (parseFloat(amount_without_tax));
    return amount_with_tax.toFixed(2);
}