import { performRequest, performRequestSecond } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';
import { getTenantDetails } from 'utils/utils';

export const stageUpdate = (sk, stage, is_lead = false, timeEntryData = '') => {
  const [tenantId, tenantSk] = getTenantDetails();
  let cardSk = sk ? encodeURIComponent(sk) : '';
  let laneSk = stage ? encodeURIComponent(stage) : '';
  return performRequest(
    apiEndPoints.methodType.PATCH,
    `${apiEndPoints.stageUpdate}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&sk=${cardSk}&stage=${laneSk}${
      is_lead ? `&is_lead=${is_lead}` : ''
    }`,
    {},
    timeEntryData
  );
};

export const manageColumnData = (data, is_lead = false, timeEntryData = '') => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestSecond(
    apiEndPoints.methodType.PATCH,
    `${apiEndPoints.manageTask}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${is_lead ? `&is_lead=${is_lead}` : ''}`,
    data,
    timeEntryData
  );
};
