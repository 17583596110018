import React, { useContext } from 'react';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { UserDetailsContext } from 'context/userDetailsContext';
import { useCalendarContext } from 'modules/calendar/Context/CalendarContext';
import constants from 'constants/index';

function SettingsAndCreateEventButton() {
  const userContext = useContext(UserDetailsContext);

  const { setShowCreateEventModal, setShowCalendarSettings, setShowUserFilter } = useCalendarContext();

  return (
    <div className="d-flex align-items-center my-2">
      <div
        className={'list-view gear-icon cal-gear '}
        onClick={() => {
          setShowCalendarSettings(true);
        }}
      >
        <Tooltip content="Calendar Settings" position="top" target=".cal-gear" showDelay={500} />
        <i className="icon-Setting cursor-pointer"></i>
      </div>

      <div className="">
        <label className="mx-1 text-muted small">{''}</label>
        {userContext.getPermission('cases') === constants.permissions.WRITE && (
          <Button
            type="button"
            className="p-button p-button-primary"
            onClick={() => {
              setShowCreateEventModal(true);
              setShowUserFilter(true);
            }}
          >
            <span className="p-button-icon p-c pi pi-plus p-button-icon-left"></span>
            Create Event
          </Button>
        )}
      </div>
    </div>
  );
}

export default SettingsAndCreateEventButton;
