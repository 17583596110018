import constants from "constants/index";

const initialState = {
  loading: false,
  userDetails: {},
  userProfileImgUrl: "",
  error: null,
};

export const userDetailsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.USER_DETAILS_STARTED:
      return {
        ...state,
        userDetails: {},
        loading: true,
      };
    case constants.USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: action.data,
      };
    case constants.USER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case constants.USER_DETAILS_IMAGE_UPDATE_STARTED:
      return {
        ...state,
        userProfileImgUrl: action.payload,
      };

    case constants.REMOVE_PROFILE_IMG_URL:
      return {
        ...state,
        userProfileImgUrl: "",
      };

    default:
      return state;
  }
};
