import React from 'react';
import { Controller } from 'react-hook-form';
import { formValidationOptions } from '../../configs/temporaryFundConfigs';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

const TemporaryFundDescription = (props) => {
  const { control } = props;
  return (
    <div className="d-flex align-items-top py-2 flex-wrap">
      <div className="col-12 col-sm-4 pt-2">Description</div>
      <div className="col-12 col-sm-8">
        <Controller
          name={formValidationOptions?.description?.name}
          control={control}
          render={({ field }) => (
            <TextSnippetWrapper
              type="textarea"
              rows="7"
              className="input-shadow w-100 border-0 p-2"
              placeholder="Type in"
              name={field?.name}
              value={field?.value}
              onChange={(e) => field?.onChange(e)}
            />
          )}
        />
      </div>
    </div>
  );
};

export default TemporaryFundDescription;
