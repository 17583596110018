import { useEffect, useState } from 'react';
import { listAccount } from 'services/accountServices';
import { totalCountEvent } from 'services/generalServices';

export function useAccounts(params) {
  const { accountType = '', shouldCallListAccountApi = true } = params || {};
  const [List, setList] = useState([]);

  const listAccounts = () => {
    totalCountEvent('account').then((response) => {
      const pageLimit = response?.data;
      const paginationToken = '';
      const paginationDirection = 'forward';
      const next = '';

      const payloadData = {
        account_type: accountType,
      };

      listAccount(pageLimit, paginationToken, paginationDirection, next, payloadData).then((res) => {
        setList(res.data?.account);
      });
    });
  };

  useEffect(() => {
    if (shouldCallListAccountApi) listAccounts();
  }, []);

  return { accountList: List };
}
