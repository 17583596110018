import { formValidationOptions } from 'modules/billing/TemporaryFund/configs/temporaryFundConfigs';
import { showFormErrorMessage } from 'modules/billing/TemporaryFund/helpers/temporaryFundHelpers';
import { InputNumber } from 'primereact/inputnumber';
import React from 'react';
import { Controller } from 'react-hook-form';

const TemporaryFundAmount = (props) => {
  const { control, errors } = props;
  return (
    <div className="d-flex align-items-top py-2 flex-wrap">
      <div className="col-12 col-sm-4 pt-2">
        Amount<span className="alert-star">*</span>
      </div>
      <div className="col-12 col-sm-8">
        <Controller
          control={control}
          name={formValidationOptions?.amount?.name}
          rules={formValidationOptions?.amount?.rules}
          render={({ field }) => (
            <InputNumber
              // onValueChange={(e) => {}}
              name={field?.name}
              value={field?.value}
              onValueChange={(e) => field?.onChange(e)}
              // onChange={(e) => field.onChange(e.value)}

              className="input-shadow w-100"
              mode="currency"
              currency="USD"
              locale="en-US"
              placeholder="Type in"
            />
          )}
        />
        {showFormErrorMessage(formValidationOptions?.amount?.name, errors)}
      </div>
    </div>
  );
};

export default TemporaryFundAmount;
