import { useState, useEffect } from 'react';
import { createCompanionCases, getCaseList, getCompanionCases, updateCompanionCases } from 'services/case/caseServices';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';

export default function useCompanionCase(case_id) {
  const [companionList, setCompanionList] = useState([]);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentCase, setCurrentCase] = useState({});
  const [field, setField] = useState('');
  const { addToast } = useToast();

  const exitingIds = [
    ...((Array.isArray(companionList) && companionList?.map((v) => v?.case_id)) || []),
    ...((Array.isArray(field) && field?.map((v) => v?.case_id)) || []),
  ];

  const searchAsync = async (e) => {
    try {
      setLoading(true);
      const response = await getCaseList({
        searchValue: e?.query,
        limit: 15,
        excludeFromLoading: true,
      });
      setResults(response?.data?.case?.filter((v) => !exitingIds?.includes(v?.case_id)) || []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const getCompanionList = () => {
    getCompanionCases({ case_id })
      .then((res) => {
        if (Array.isArray(res?.data)) {
          setCompanionList(res?.data?.filter((v) => v.case_id !== case_id));
          setCurrentCase(res?.data?.find((v) => v.case_id === case_id));
        }
      })
      .catch((err) => console.log(err));
  };

  const createCompanion = (target_case_id) => {
    const data = {
      target_case_id,
      current_case_id: case_id,
    };
    createCompanionCases({ companion_pk: currentCase?.pk, group_id: currentCase?.user_id }, data)
      .then(() => {
        setField('');
        getCompanionList();
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.CASE_COMPANION_UPDATED);
      })
      .catch((err) => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.CASE_COMPANION_UPDATED_FAILED);
        console.log(err);
      });
  };

  const deleteCompanion = (target_case_id) => {
    updateCompanionCases({ target_case_id: [target_case_id] })
      .then(() => {
        getCompanionList();
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.CASE_COMPANION_DELETE);
      })
      .catch((err) => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.CASE_COMPANION_DELETE_FAILED);
        console.log(err);
      });
  };

  useEffect(() => {
    getCompanionList();
  }, []);

  return { companionList, results, field, setField, searchAsync, loading, createCompanion, deleteCompanion };
}
