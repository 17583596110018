import { actionTypes } from "constants/types";
import { listExpenseType } from "services/billingServices";

export const listExpenseTypes = (filter="",pageLimit=10,pagination_token="",pagination_direction="forward")=>{

    return (dispatch)=>{
        dispatch({type:actionTypes.GET_EXPENSE_TYPE_LIST_STARTED})
        listExpenseType(filter,pageLimit,pagination_token,pagination_direction).then(response=>{
            if(response && response.data ){
                dispatch({type:actionTypes.GET_EXPENSE_TYPE_LIST_SUCCESS,data:response.data})
            }
           
        }).catch(err=>{
            console.log(err)
            dispatch({type:actionTypes.GET_EXPENSE_TYPE_LIST_FAILED,error:err})
        })
    }
}

