import React from 'react';

export default function Header({ isFromCase, isFromLead }) {
  return (
    <>
      <div className="d-flex align-items-center">
        {!isFromCase && !isFromLead && (
          <div className="col-6 d-flex">
            <h1 className="title gray-800 mb-0">Text Messages</h1>
          </div>
        )}
      </div>
    </>
  );
}
