import { actionTypes } from "constants/types";
let initialState = {
  showAddCasePopUp: false,
};

export default function ShowAddCasePopUpReducer(state = initialState, action={}) {
  switch (action.type) {
    case actionTypes.SHOW_ADD_CASE:
      return {
        ...state,

        showAddCasePopUp: true,
      };
    case actionTypes.HIDE_ADD_CASE:
      return {
        ...state,

        showAddCasePopUp: false,
      };

    default:
      return state;
  }
}


