import apiEndPoints from 'common/apiEndPoints';
import { performRequestSecond, performRequestThird } from 'common/apiHandler';
import { getTenantDetails } from 'utils/utils';

export const sendTextMessage = ({
  contact_id,
  text_message_thread_sk,
  file_path,
  media_content_type,
  display_filename,
  size = null,
  data = {},
  timeEntryData = '',
  is_lead = false,
}) => {
  const [tenantId, tenantSk] = getTenantDetails();
  const url =
    `${apiEndPoints.sendTextMessage}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&contact_id=${contact_id}` +
    `${text_message_thread_sk ? `&text_message_thread_sk=${text_message_thread_sk}` : ''}` +
    `${file_path ? `&file_path=${file_path}` : ''}` +
    `${media_content_type ? `&media_content_type=${media_content_type}` : ''}` +
    `${display_filename ? `&display_filename=${display_filename}` : ''}` +
    `${size ? `&size=${size}` : ''} &is_lead=${is_lead}`;

  return performRequestSecond(apiEndPoints.methodType.POST, url, data, timeEntryData);
};

export const listMessageHistory = ({ contact_id, message_date = false, phoneNumber = '', limit = 10, nextToken = null }) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestSecond(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.textMessageHistory}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&contact_id=${contact_id}${
      message_date ? `&message_date=${message_date}` : ''
    }&phone_number=${phoneNumber}&limit=${limit}${nextToken ? `&pagination_token=${nextToken}` : ''}`,
    null,
    '',
    true,
    true
  );
};

export const messageMarkRead = ({ contact_id, phoneNumber }) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.updateTextStatus}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&contact_id=${contact_id}&phone_number=${phoneNumber}`,
    null,
    '',
    true,
    true
  );
};
