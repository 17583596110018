import { actionTypes } from "constants/types";

let initialState = {
  full_screen: false,
};

export default function LeadDetailsPageScreenReducer(
  state = initialState,
  action={}
) {
  switch (action.type) {
    case actionTypes.CHANGE_TO_FULL_SCREEN:
      return {
        ...state,
        full_screen: true,
      };
    case actionTypes.HIDE_FULL_SCREEN_VIEW:
      return {
        ...state,
        full_screen: false,
      };

    default:
      return state;
  }
}
