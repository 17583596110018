import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ConfigureAmplify } from "config/amplify_config";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/index.scss";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "assets/scss/toggle.scss"
import { Provider } from "react-redux";
import { store } from "redux/store";
import 'react-step-progress/dist/index.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';
// import 'react-tippy/dist/tippy.css'
import 'tippy.js/dist/tippy.css';

/**
 * Amplify Configuration
 */
ConfigureAmplify();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
