import { useEffect, useState } from 'react';
import { getVenueList } from 'services/Settings';
import { totalCountEvent } from 'services/generalServices';

export const useVenueList = () => {
  const [venueOptions, setVenueOptions] = useState([]);

  const loadVenue = () => {
    totalCountEvent('venue').then((response) => {
      getVenueList(response.data, '', 'forward')
        .then((response) => {
          setVenueOptions(response?.data?.venues);
        })
        .catch((err) => {});
    });
  };

  useEffect(() => {
    loadVenue();
  }, []);

  return { venueOptions, loadVenue };
};
