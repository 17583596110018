import React, { useState, useEffect } from 'react';

import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { InteractionType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { outlookConfig } from '../config/calendar_config.js';
import { getUser } from 'services/outLookServices';

const OutLookContext = React.createContext();

const OutLookProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [error, setError] = useState();
  const msal = useMsal();

  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(msal.instance, {
    account: msal.instance.getActiveAccount(),
    scopes: outlookConfig.scopes,
    interactionType: InteractionType.Popup,
  });

  useEffect(() => {
    checkUser();
  }, []);

  const checkUser = async () => {
    if (!user) {
      try {
        // Check if user is already signed in
        const account = await msal.instance.getActiveAccount();
        if (account) {
          // Get the user from Microsoft Graph
          const user = await getUser(authProvider);

          setUser({
            displayName: user.displayName || '',
            email: user.mail || user.userPrincipalName || '',
            timeFormat: user.mailboxSettings?.timeFormat || '',
            timeZone: user.mailboxSettings?.timeZone || 'UTC',
          });

          return true;
        } else return false;
      } catch (err) {
        displayError(err.message);
        return false;
      }
    }
  };
  const displayError = (message, debug) => {
    setError({ message, debug });
  };
  const clearError = () => {
    setError(undefined);
  };
  const signIn = async () => {
    try {
      if (authProvider) {
        await msal.instance.loginPopup({
          scopes: outlookConfig.scopes,
          prompt: 'select_account',
        });
        const user = await getUser(authProvider);
        setUser({
          displayName: user.displayName || '',
          email: user.mail || user.userPrincipalName || '',
          timeFormat: user.mailboxSettings?.timeFormat || '',
          timeZone: user.mailboxSettings?.timeZone || 'UTC',
        });
        return true;
      } else return false;
    } catch (err) {
      setUser();
      return false;
    }
  };
  const signOut = async () => {
    // TODO
    try {
      await msal.instance.logoutPopup();
      setUser();
    } catch (err) {
      console.log('outlook error', err);
    }

    setUser(undefined);
  };

  return (
    <OutLookContext.Provider
      value={{
        user,
        error,
        signIn,
        signOut,
        displayError,
        clearError,
        authProvider,
        setUser,
      }}
    >
      {children}
    </OutLookContext.Provider>
  );
};

const OutLookConsumer = OutLookContext.Consumer;

export { OutLookContext, OutLookProvider, OutLookConsumer };
