import { useEffect, useState } from 'react';
import { listRelationRoles } from 'services/Settings';
import { totalCountEvent } from 'services/generalServices';

export function useRelationRole() {
  const [List, setList] = useState([]);

  const loadRelationRoles = () => {
    totalCountEvent('relation_role').then((response) => {
      listRelationRoles(response.data).then((res) => {
        setList(res.data?.relation_role);
      });
    });
  };

  useEffect(() => {
    loadRelationRoles();
  }, []);

  return { relationRoleList: List };
}
