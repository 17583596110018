import apiEndPoints from 'common/apiEndPoints';
import { performRequest, performRequestSecond, performRequestThird } from 'common/apiHandler';
import { getTenantDetails } from 'utils/utils';

export const viewLeadDetailsModified = ({ sk = '', lead_id = '' }) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.viewLeadDetails}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${sk ? `&lead_sk=${sk}` : ''}${
      lead_id ? `&lead_id=${lead_id}` : ''
    }`
  );
};

export const listContacts = ({
  contactType = '',
  contactStatus = 'ACTIVE',
  keyWord = '',
  is_court_location = '',
  is_event_location = '',
  pageLimit = 10,
  pagination_token = '',
  pagination_direction = 'forward',
  excludeFromLoading = false,
  in_lead = false,
}) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.contact.list}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&contact_type=${contactType}&contact_status=${contactStatus}&keyword=${keyWord}&is_court_location=${is_court_location}&is_event_location=${is_event_location}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}&in_lead=${in_lead}`;
  return performRequest(apiEndPoints.methodType.GET, url, null, '', true, excludeFromLoading);
};

export const getPracticeAreas = ({ pageLimit, pagination_token = '', pagination_direction = 'forward' }) => {
  const [tenantId, tenantSk] = getTenantDetails();

  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.practiceArea}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`
  );
};

export const getCaseMatter = ({ pageLimit, pagination_token = '', pagination_direction = 'forward' }) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.getCaseMatter}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`
  );
};

export const getCaseNumberValidation = (case_number) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.getCaseNumberValidation}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&case_number=${case_number}`,
    null,
    '',
    true,
    true
  );
};

export const createLeadSource = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.createLeadSource}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};

export const createLead = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(apiEndPoints.methodType.POST, `${apiEndPoints.createLead}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`, data);
};

export const getConflictCheck = (id, is_lead = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.getConflictCheck}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&id=${id}${is_lead ? `&is_lead=${is_lead}` : ''}`
  );
};

export const updateLeadDetails = (lead_sk, data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let leadSK = lead_sk ? encodeURIComponent(lead_sk) : '';
  return performRequest(
    apiEndPoints.methodType.PUT,
    `${apiEndPoints.updateLeadDetails}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&lead_sk=${leadSK}`,
    data
  );
};

export const deleteLead = (lead_sk) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let leadSK = lead_sk ? encodeURIComponent(lead_sk) : '';
  return performRequest(
    apiEndPoints.methodType.DELETE,
    `${apiEndPoints.deleteLead}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&lead_sk=${leadSK}`
  );
};

export const closeLead = (lead_sk, data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let leadSK = lead_sk ? encodeURIComponent(lead_sk) : '';
  return performRequest(
    apiEndPoints.methodType.PATCH,
    `${apiEndPoints.closeLead}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&lead_sk=${leadSK}`,
    data
  );
};

export const convertToCase = (lead_sk, data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let leadSK = lead_sk ? encodeURIComponent(lead_sk) : '';
  return performRequest(
    apiEndPoints.methodType.PATCH,
    `${apiEndPoints.convertToCase}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&lead_sk=${leadSK}`,
    data
  );
};

export const viewLeadClosureReasons = (is_lead) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestSecond(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.leadClosureReasons}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&is_lead=${is_lead}`
  );
};

export const saveLeadCloseReason = (leadSk, data, is_lead) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestSecond(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.leadCloseReason}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&lead_sk=${leadSk}&is_lead=${is_lead}`,
    data
  );
};

export const listLeadStages = ({ pageLimit, pagination_token = '', pagination_direction = 'forward', lead_stage_status = '' }) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.listLeadStages}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}&lead_stage_status=${lead_stage_status}`
  );
};

export const getLeads = ({ data = false, keyword = false, limit = 1000 }) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.listLeads}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${keyword ? `&keyword=${keyword}` : ''}&limit=${limit}`,
    data
  );
};
