import { actionTypes } from 'constants/types';
import { listPaymentRecord } from 'services/billingServices';

export const listPaymentRecords = (filters, pageLimit = 10, pagination_token = '', pagination_direction = 'forward') => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_PAYMENT_RECORD_LIST_STARTED });
    listPaymentRecord(filters, pageLimit, pagination_token, pagination_direction)
      .then((response) => {
        if (response && response.data) {
          dispatch({ type: actionTypes.GET_PAYMENT_RECORD_LIST_SUCCESS, data: response.data });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: actionTypes.GET_PAYMENT_RECORD_LIST_FAILED, error: err });
      });
  };
};
