/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useCallback, useState } from 'react';
import './static_temp/styles.css';

export default function PdfViewerComponent(props) {
  const containerRef = useRef(null);
  const assigneeRef = useRef(props.assignee);
  const clientsRef = useRef(props.clientOptions);
  const PSPDFKit = useRef(null);
  const instance = useRef(null);

  // useEffect(() => {
  //   setTimeout(() => {
  //     var embed_url = `https://sign.zoho.in/zsi/60033230312?frameorigin=${window.origin}#/request/viewer/74682000000033153`;

  //     var params = { access_token: '1000.a4300b6034c9f7d48ee05f10a236e412.a857d85f3bf2a5d5144e8a18e4a9e52d', redirect_url: window.origin }; //No I18N
  //     var iframe = document.createElement('iframe');
  //     iframe.name = 'signatureFrame';

  //     document.getElementById('frameContainer').append(iframe);

  //     var form = document.createElement('form');
  //     form.setAttribute('id', 'dummyform');
  //     form.setAttribute('method', 'post');
  //     form.setAttribute('action', embed_url);
  //     form.setAttribute('target', 'signatureFrame'); //Same iframe name given in html
  //     //uncomment this if the url needs to be opened in new tab instead of iframe
  //     // form.setAttribute("target", "_blank");

  //     console.log('heerrrrr');
  //     for (var i in params) {
  //       if (params.hasOwnProperty(i)) {
  //         var input = document.createElement('input');
  //         input.type = 'hidden';
  //         input.name = i;
  //         input.value = params[i];
  //         form.appendChild(input);
  //       }
  //     }
  //     document.body.appendChild(form);
  //     // var window_obj =  window;
  //     // var window_height = 200;
  //     // let window_width = 200;
  //     // let window_params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,	width=`+window_width*0.7+`px,height=`+window_height*0.7+`,left=`+window_width*0.3+`px,top=`+window_height*0.3+`px`;

  //     //uncomment this if the url needs to be opened in new tab instead of iframe
  //     // var newWindow = window.open("","theFrame",params);

  //     form.submit();
  //     console.log('1222222');
  //   }, 3000);
  // }, []);

  useEffect(() => {
    assigneeRef.current = props.assignee;
    clientsRef.current = props.clientOptions;
  }, [props.assignee, props.clientOptions]);

  function closestByClass(el, className) {
    return el && el.classList && el.classList.contains(className) ? el : el ? closestByClass(el.parentNode, className) : null;
  }

  useEffect(() => {
    return () => {
      const container = containerRef?.current;
      if (container && PSPDFKit && typeof PSPDFKit.current?.unload === 'function') {
        PSPDFKit.current?.unload(container);
      }

      props.setInstance && props.setInstance(null);
      props.setPSPDFKit && props.setPSPDFKit(false);

      // This is the cleanup function
      // It will be called when the component is unmounted
    };
  }, []);

  useEffect(() => {
    const container = containerRef.current;

    if (props?.document) {
      // let PSPDFKit, instance;
      // let isDragAndDropSupported = false;

      (async () => {
        PSPDFKit.current = await import('pspdfkit');

        await PSPDFKit.current.unload(container);

        let pspdfLoad = {
          // Container where PSPDFKit should be mounted.
          container,
          // The document to open.
          document: props.document,
          // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
          baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}/`,
          licenseKey: process.env.REACT_APP_PSPDFKIT_KEY,
          // styleSheets: ['/drag-and-drop/static/style.css'],
        };
        // if(props.signPage){
        // pspdfLoad = {
        //   ...pspdfLoad,
        // styleSheets: ["/static_temp/styles.css"],
        // initialViewState: new PSPDFKit.current.ViewState({
        //   showSignatureValidationStatus:
        //     PSPDFKit.current.ShowSignatureValidationStatusMode.IF_SIGNED,
        // }),
        // async trustedCAsCallback() {
        //   // The particular certificate + private key that we are going to use
        //   // for signing this example were issued by this CA that we are going
        //   // to use for validation after signing.
        //   const response = await fetch("/static_temp/ca.pem");
        //   const cert = await response.text();

        //   return [cert];
        // },
        // }

        // }
        try {
          instance.current = await PSPDFKit.current.load(pspdfLoad);

          typeof props.onLoad === 'function' && props.onLoad();

          if (props.signatureOption) {
            instance.current.setViewState((viewState) => viewState.set('enableAnnotationToolbar', !viewState.enableAnnotationToolbar));

            instance.current.setViewState((viewState) => viewState.set('interactionMode', PSPDFKit.current.InteractionMode.FORM_CREATOR));

            // We only allow dropping elements onto a PDF page.
            instance.current.contentDocument.ondragover = (event) => {
              // isDragAndDropSupported = true;

              const pageElement = closestByClass(event.target, 'PSPDFKit-Page');

              if (pageElement) {
                // Allow drop operation
                event.preventDefault();
              }
            };
            // instance.current.contentDocument.ondrop = (event) => {
            //   // isDragAndDropSupported = true;

            //   const pageElement = closestByClass(event.target, 'PSPDFKit-Page');

            //   if (pageElement) {
            //     // const pageIndex = parseInt(pageElement.dataset.pageIndex);

            //     const isExternalDrop = event.dataTransfer.files.length > 0;

            //     if (isExternalDrop) {
            //       // handleExternalDrop(event, pageIndex);
            //     } else {
            //       if (assigneeRef.current) {
            //         let client = clientsRef.current.find((c) => c.value === assigneeRef.current);
            //         console.log(instance, "lllllllllsssssssssssss")
            //         const pageIndex = instance.current.viewState.currentPageIndex;
            //         // const pageInfo = instance.current.pageInfoForIndex(pageIndex);
            //         // const target = event.target;

            //         // let containerHeight = 0;
            //         // for (let i = 0; i < instance.current.totalPageCount; i++) {
            //         //   containerHeight += instance.current.pageInfoForIndex(i).height + instance.current.viewState.spreadSpacing;
            //         // }
            //         // containerHeight *= instance.currentZoomLevel; // convert to client dimension
            //         // containerHeight += 44; // Toolbar height
            //         // containerHeight += instance.current.viewState.viewportPadding.vertical * 2; // viewport padding

            //         // const pointInPage = instance.current.transformClientToPageSpace(
            //         //   new PSPDFKit.current.Geometry.Point({
            //         //     x: event.clientX,
            //         //     y: event.clientY,
            //         //   }),
            //         //   instance.current.viewState.currentPageIndex,
            //         // );

            //         let un_id = PSPDFKit.current.generateInstantId();

            //         const widget = new PSPDFKit.current.Annotations.WidgetAnnotation({
            //           pageIndex: pageIndex,
            //           boundingBox: new PSPDFKit.current.Geometry.Rect({
            //             width: 150,
            //             height: 30,
            //             // left: pageInfo.width / 2 - event.clientX / 2,
            //             // top: 10,
            //             // left: event.x - 414,
            //             left: event.offsetX,
            //             top: event.offsetY,
            //           }),
            //           formFieldName: `${client.label} | ${un_id}`,
            //           id: `${client.value}|${un_id}`,
            //         });
            //         const formField = new PSPDFKit.current.FormFields.SignatureFormField({
            //           name: `${client.label} | ${un_id}`,
            //           annotationIds: PSPDFKit.current.Immutable.List([widget.id]),
            //           isFillable: false,
            //           label: client.label,
            //         });
            //         instance.current.create([widget, formField]);
            //       }

            //       // handleInternalDrop(event, pageIndex);
            //     }
            //   }
            // };
          }
          instance.current.setViewState((viewState) => viewState.set('showToolbar', !viewState.showToolbar));
          if (!props.signatureOption && !props.signPage) {
            instance.current.setViewState((v) => v.set('readOnly', true));
          }

          // if(props.signPage){
          //   instance.current.addEventListener(
          //     "annotations.create",
          //     async (createdAnnotations) => {
          //       const signatures = await instance.current.getSignaturesInfo();
          //       console.log(signatures, "signatureee")
          //       const isDocumentSigned = signatures.status !== "not_signed";
          //       if (isDocumentSigned) {
          //         // Bailing out since we just need to handle the scenario before a digital signature
          //         // has been placed.
          //         return;
          //       }

          //       const annotation = createdAnnotations.get(0);

          //       if (annotation.isSignature) {
          //           props.setIsSigned(true)
          //       }
          //     }
          //   );

          // }

          props.setInstance && props.setInstance(instance.current);
          props.setPSPDFKit && props.setPSPDFKit(PSPDFKit.current);
        } catch (error) {
          console.log(error, 'EEEEEEEEEE');
        }
      })();
    }

    // return () => PSPDFKit && typeof PSPDFKit.current?.unload === 'function' && PSPDFKit.current?.unload(container);
  }, [props.document]);

  async function insertTextAnnotation(pageRect, text, pageIndex, fontSize) {
    const annotation = new PSPDFKit.current.Annotations.TextAnnotation({
      boundingBox: pageRect,
      text: { format: 'plain', value: 'Welcom' },
      pageIndex,
      fontSize,
      horizontalAlign: 'center',
      verticalAlign: 'center',
      backgroundColor: PSPDFKit.current.Color.WHITE,
    });

    await instance.current.create(annotation).then((annotations) => instance.current.setSelectedAnnotation(annotations[0]));
  }

  async function insertSignatureAnnotation(pageRect, pageIndex, fontSize, client, un_id) {
    const widget = new PSPDFKit.current.Annotations.WidgetAnnotation({
      pageIndex: pageIndex,
      boundingBox: pageRect,
      fontSize,

      formFieldName: `${client.label} | ${un_id}`,
      id: `${client.value}|${un_id}`,
    });
    const formField = new PSPDFKit.current.FormFields.SignatureFormField({
      name: `${client.label} | ${un_id}`,
      annotationIds: PSPDFKit.current.Immutable.List([widget.id]),
      isFillable: false,
      label: client.label,
    });

    await instance.current.create([widget, formField]);
  }

  const handleDrop = useCallback(
    (event) => {
      (async function () {
        event.preventDefault();

        // const pointInPage = await instance.current.transformClientToPageSpace(
        //   new PSPDFKit.current.Geometry.Point({
        //     x: ev.clientX,
        //     y: ev.clientY,
        //   }),
        //   instance.current.viewState.currentPageIndex,
        // );

        // console.log("lllllllllllllllllllll", props.instance,)
        const pageElement = closestByClass(event.target, 'PSPDFKit-Page');
        const dragType = event.dataTransfer.getData('text/plain');

        console.log(dragType, 'llllllll');

        // if (pageElement) {
        // const pageIndex = parseInt(pageElement.dataset.pageIndex);

        const isExternalDrop = event.dataTransfer.files.length > 0;

        if (isExternalDrop) {
          // handleExternalDrop(event, pageIndex);
        } else {
          if (assigneeRef.current) {
            let client = clientsRef.current.find((c) => c.value === assigneeRef.current);
            const pageIndex = instance.current?.viewState.currentPageIndex;

            const un_id = PSPDFKit.current.generateInstantId();

            const clientRect = new PSPDFKit.current.Geometry.Rect({
              left: event.clientX,
              top: event.clientY,
              width: 150,
              height: 30,
            });
            const pageRect = instance.current.transformContentClientToPageSpace(clientRect, pageIndex);

            const fontSize = 26 / instance.current.currentZoomLevel;

            if (dragType === 'signature') {
              insertSignatureAnnotation(pageRect, pageIndex, fontSize, client, un_id);
            } else {
            }

            // const widget = new PSPDFKit.current.Annotations.WidgetAnnotation({
            //   pageIndex: pageIndex,
            //   boundingBox: pageRect,
            //   fontSize: 26 / instance.current.currentZoomLevel,

            //   formFieldName: `${client.label} | ${un_id}`,
            //   id: `${client.value}|${un_id}`,
            // });
            // const formField = new PSPDFKit.current.FormFields.SignatureFormField({
            //   name: `${client.label} | ${un_id}`,
            //   annotationIds: PSPDFKit.current.Immutable.List([widget.id]),
            //   isFillable: false,
            //   label: client.label,
            // });
            // instance.current.create([widget, formField]);
          }
        }
        // }
      })();
    },
    [props.instance?.current, props.PSPDFKit?.current]
  );

  return (
    <div onDrop={handleDrop} onDragOver={(ev) => ev.preventDefault()} style={{ width: '100%', height: '100%' }}>
      <div ref={containerRef} style={{ width: '100%', height: '100vh' }} className="PSPDFKit-div" />
    </div>
  );
}
