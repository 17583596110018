import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Column } from './Column';

export const Board = React.memo(({ leadList, stages, onDragEnd, cardAction }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {stages?.map((stage, index) => (
        <Droppable droppableId={'all-droppables' + index} direction="horizontal" type="column" key={index}>
          {(provided) => (
            <div className="columns" style={{ display: 'flex' }} {...provided.droppableProps} ref={provided.innerRef}>
              <Column
                key={stage?.sk}
                column={leadList[stage?.sk] || { id: stage?.sk, items: [] }}
                stage={stage}
                cardAction={cardAction}
                index={index}
                totalLength={stages?.length}
                stages={stages}
              />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      ))}
    </DragDropContext>
  );
});
