import React, { useState, useEffect, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';

import { assignToItemTemplate, checkOptionDisabled } from '../TaskBoard/Board/PriorityDropdownTemplate';
import { convertToTimezoneFormat } from 'utils/utility_functions/timezone';
import { UserDetailsContext } from 'context/userDetailsContext';
import { createTasks } from 'services/CaseServices';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import { ModalHeader } from 'shared/ModalHeader';
import { formatContact, formatContactList, parseRemiderPayload } from 'utils/utils';
import NestedSubTask from './NestedSubTask';
import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';
import PriorityDropdown from 'components/UI/PriorityDropdown/PriorityDropdown';
import { getClientUserList } from 'services/case/caseServices';
import CaseListDropDown from 'components/CaseListDropDown/CaseListDropDown';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import { usePracticeAreaList } from 'hooks/usePracticeAreaList';
import Reminders from 'modules/calendar/Components/CreateEventModal/Components/EventReminders';

export default function AddTask({ onHide, selectedStageId, setSelectedStageId, loadTaskList, isFromCase, caseDetails, isFromLead }) {
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    watch,
    reset,
    resetField,
  } = useForm();

  const notLinkedToCase = watch('not_linked_to_case');

  const [clientData, setClientData] = useState([]);
  const { practiceAreaList } = usePracticeAreaList();

  const { addToast } = useToast();
  const userContext = useContext(UserDetailsContext);

  const isGlobal = !Boolean(isFromCase || isFromLead);

  useEffect(() => {
    getContactList();
  }, []);

  const getContactList = (caseId = caseDetails?.case_id) => {
    const condition = Boolean(caseId);
    getClientUserList({
      caseId,
      is_mandatory_lawft_access: false,
      is_team_member_list: condition,
      all_contacts: condition,
      is_active: true,
      is_include_groups: true,
      active_groups: true,
    })
      .then((response) => setClientData(formatContactList(response.data)))
      .catch((error) => console.log(error));
  };

  const RequiredValidation = ({ field }) => {
    return (
      <>{errors?.[field]?.type === 'required' && <InputErrorMessage>{errors?.[field]?.message || 'Field Required'}</InputErrorMessage>}</>
    );
  };

  const FieldValidation = ({ field }) => {
    return (
      <>{errors?.[field]?.type === 'validate' && <InputErrorMessage>{errors?.[field]?.message || 'Field Required'}</InputErrorMessage>}</>
    );
  };

  const onSubmit = (data) => {
    const filteredSubTask = data?.sub_task?.filter((task) => Boolean(task.sub_task_description));

    const assign_to = data?.assign_to?.filter((v) => v?.res_type !== 'user-group').map((v) => formatContact(v));
    const groups = data?.assign_to
      ?.filter((v) => v?.res_type === 'user-group')
      .map((v) => ({ group_id: v?.user_group_id, group_name: v?.group_name, sk: v?.sk }));

    const formData = {
      task_name: data?.task_name,
      task_stage: selectedStageId ?? 'to_do',
      assign_to: assign_to,
      has_subtask: filteredSubTask?.length > 0,
      priority: data.priority,
      not_linked_to_case: data.not_linked_to_case,
      due_date: convertToTimezoneFormat(data.dueDate, userContext.userDetails.timezone),
      reminder: parseRemiderPayload(data?.reminder),
      accessible_list: assign_to?.map((v) => v.assignee_id),
      description: data?.description,
      sub_task: filteredSubTask,
      is_lead: isFromLead ? isFromLead : data?.case_id?.is_lead,
      groups,
    };

    if (!data.not_linked_to_case) {
      formData.case_id = data?.case_id?.id;
      formData.case_name = data?.case_id?.case_name;
      formData.practice_area = data?.case_id?.practice_area;
    } else {
      formData.practice_area = data?.practice_area;
    }

    if (caseDetails) {
      formData.case_id = caseDetails?.case_id;
      formData.case_name = caseDetails?.case_description?.case_name;
      formData.practice_area = caseDetails?.case_description?.case_practice_area;
    }

    createTasks(formData)
      .then(() => {
        loadTaskList();
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.TASK_CREATED_SUCCESSFULLY);
        reset();
        onHide();
        setSelectedStageId && setSelectedStageId(null);
      })
      .catch((err) => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.TAGS_UPDATED_FAILED);
        console.log(err);
      });
  };

  return (
    <Dialog className="new-task-popup" visible={true} onHide={onHide} header={ModalHeader} footer={<></>}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="modal-heading F-size16 text-bold">New Task</div>
        </div>
        <div>
          <div className="d-flex align-items-start py-2 flex-wrap">
            <div className="col-md-4 col-12 p-medium line_height3">
              {!isFromCase && !isFromLead ? 'Case/Lead' : 'Case'}
              {!(notLinkedToCase || isFromLead) && <span className="alert-star">*</span>}
            </div>
            <div className="col-md-8 col-12">
              {Boolean(isFromCase || isFromLead) ? (
                <div className="border p-2 w-100 new-task-popup input-shadow">{caseDetails?.case_description?.case_name || 'TBD'}</div>
              ) : (
                <Controller
                  name="case_id"
                  control={control}
                  rules={{ required: !notLinkedToCase, validate: (value) => typeof value === 'object' || notLinkedToCase }}
                  render={({ field }) => (
                    <CaseListDropDown
                      isClient={false}
                      value={field?.value || null}
                      onChange={(e) => {
                        if (field?.value !== e) {
                          field.onChange(e);
                          getContactList(e?.id || null);
                          resetField('assign_to');
                        }
                      }}
                      name="case"
                      isClearable
                      placeholder={!isFromCase && !isFromLead ? 'Select Case/Lead' : 'Select Case'}
                      noOptionsMessage="No Cases/Leads Found"
                      disabled={notLinkedToCase}
                    />
                  )}
                />
              )}
              <RequiredValidation field={'case_id'} />
              <FieldValidation field={'case_id'} />
              {!Boolean(isFromCase || isFromLead) && (
                <div className="mt-2 d-flex align-items-center">
                  <Controller
                    name="not_linked_to_case"
                    control={control}
                    defaultValue={false}
                    render={({ field, fieldState }) => (
                      <Checkbox
                        inputId={field.name}
                        ref={field.ref}
                        onChange={(e) => {
                          field.onChange(e.checked);
                          resetField('case_id');
                          e.checked && getContactList(null);
                          !e.checked && resetField('practice_area');
                        }}
                        checked={field.value}
                        className={{ 'p-invalid': fieldState.invalid }}
                      />
                    )}
                  />
                  <label htmlFor="not_linked_to_case" className="p-checkbox-label mt-1 ms-2">
                    Not Linked To Case/Lead
                  </label>
                </div>
              )}
            </div>
          </div>

          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-md-4 col-12 p-medium">
              Task Name<span className="alert-star">*</span>
            </div>
            <div className="col-md-8 col-12">
              <Controller
                name="task_name"
                control={control}
                rules={{ required: 'Task name is Required' }}
                render={({ field }) => <TextSnippetWrapper id={field.name} type="text" className="w-100 input-shadow" {...field} />}
              />
              <RequiredValidation field={'task_name'} />
            </div>
          </div>

          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-md-4 col-12 p-medium">
              Assign To<span className="alert-star">*</span>
            </div>
            <div className="col-md-8 col-12">
              <Controller
                name="assign_to"
                control={control}
                rules={{ required: 'Assign To is Required' }}
                render={({ field }) => (
                  <MultiSelect
                    options={clientData}
                    className="w-100 input-shadow F-size14 input-height"
                    filter
                    filterPlaceholder="Search"
                    placeholder="Select Assignee"
                    display="chip"
                    optionLabel="assignee_name"
                    optionDisabled={checkOptionDisabled}
                    itemTemplate={assignToItemTemplate}
                    optionValue={(v) => v}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    optionGroupLabel="assignee_name"
                    optionGroupChildren="items"
                    resetFilterOnHide
                    showSelectAll={false}
                  />
                )}
              />
              <RequiredValidation field={'assign_to'} />
            </div>
          </div>

          <div className="d-flex py-2 flex-wrap">
            <div className="col-md-4 col-12 py-2 p-medium">Sub Task</div>
            <div className="col-md-8 col-12">
              <NestedSubTask {...{ control, register }} />
            </div>
          </div>

          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-md-4 col-12 p-medium">
              Priority<span className="alert-star">*</span>
            </div>
            <div className="col-md-8 col-12">
              <Controller
                name="priority"
                control={control}
                rules={{ required: 'Priority is Required' }}
                render={({ field }) => (
                  <PriorityDropdown
                    className="input-shadow w-100 input-height"
                    filter
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                  />
                )}
              />
              <RequiredValidation field={'priority'} />
            </div>
          </div>

          {Boolean(notLinkedToCase) && (
            <div className="d-flex align-items-center py-2 flex-wrap">
              <div className="col-md-4 col-12 p-medium">Practice Area</div>
              <div className="col-md-8 col-12">
                <Controller
                  name="practice_area"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      className="input-shadow w-100 input-height"
                      filter
                      id={field.name}
                      value={field.value}
                      options={practiceAreaList}
                      optionLabel="practice_area_name"
                      optionValue="sk"
                      onChange={(e) => field.onChange(e.value)}
                      showClear
                    />
                  )}
                />
              </div>
            </div>
          )}

          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-md-4 col-12 p-medium">
              Due Date<span className="alert-star">*</span>
            </div>
            <div className="col-md-8 col-12">
              <Controller
                name="dueDate"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Calendar
                    className="w-100 input-shadow field-icon"
                    dateFormat="mm/dd/yy"
                    showIcon
                    monthNavigator
                    yearNavigator
                    yearRange="2023:3000"
                    minDate={new Date()}
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                  />
                )}
              />
              <RequiredValidation field={'dueDate'} />
            </div>
          </div>

          <div className="d-flex py-2 flex-wrap w-100">
            <Reminders control={control} watch={watch} isGlobal={isGlobal} />
          </div>

          <div className="d-flex align-items-start py-2 flex-wrap">
            <div className="col-md-4 col-12 p-medium">Description</div>
            <div className="col-md-8 col-12">
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextSnippetWrapper type="textarea" rows="7" className="w-100 input-shadow" id="description" {...field} />
                )}
              />
            </div>
          </div>

          <div className="footer-btn">
            <Button type="reset" className="ms-2 btn p-button-secondary outline" onClick={onHide}>
              Cancel
            </Button>
            <Button onClick={handleSubmit(onSubmit)} className="p-button-secondary ms-2">
              Save
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
}
