import { createContext, useContext, useMemo, useState, useEffect } from 'react';
import { findIana } from 'windows-iana';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import { UserDetailsContext } from 'context/userDetailsContext';
import { OutLookContext } from 'context/OutlookContext';
import { GoogleContext } from 'context/GoogleContext';

import { listContacts } from 'services/Contact/contactServices';
import { totalCountEvent } from 'services/generalServices';
import { getClientUserList, listUsers } from 'services/users/userServices';

import { getCalendarSettings, listEventTypes, listEvents } from 'services/Calender/calendarServices';
import { lisGoogleEvents } from 'services/Calender/googleServices';
import { formatEventsOutLook, getUserWeekCalendar } from 'services/outLookServices';

import { generateFullName } from 'utils/generateFullNameUtils';
import { getLocalStorageItem } from 'utils/localStorageUtils';
import { convertCaseToFilterFormat, formatMultiSelectContactList, getUserId } from 'utils/utils';

import { convertToTimezoneFormat } from 'utils/utility_functions/timezone';
import { useNavbarContext } from 'context/NavbarContext';
import { useGoogleCalendar } from 'hooks/useGoogleCalendar';

const CalendarContext = createContext({});

export const useCalendarContext = () => useContext(CalendarContext);

export const CalendarContextProvider = (props) => {
  const { caseDetails, isFromCaseSpecificModule, isFromLeadSpecificModule, isClientCalendar, isGlobal, isClosed } = props;
  const location = useLocation();
  //Email Redirection logic
  const queryParams = new URLSearchParams(location?.search);
  const event_id = queryParams.get('id');

  const userContext = useContext(UserDetailsContext);
  const { readClientNotifications, notificationCounts } = useNavbarContext();
  const { user, displayError, authProvider, setUser } = useContext(OutLookContext);
  const { setGoogleUser } = useContext(GoogleContext);
  const { isTokenExpired, getCalendarId } = useGoogleCalendar();

  const googleAccessToken = getLocalStorageItem('google_access_token');

  //lists
  const [locationsList, setLocationsList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [userList, setUserList] = useState();
  const [firmUserList, setFirmUserList] = useState([]);
  // event list
  const [eventList, setEventList] = useState([]);
  const [googleEventList, setGoogleEventList] = useState([]);
  const [outlookEventList, setOutlookEventList] = useState([]);

  //filters
  const [calendarSettings, setCalendarSettings] = useState();
  const [calendarFilters, setCalendarFilters] = useState({
    locationFilter: isGlobal ? userContext?.firmDetails?.user_preferences?.filters?.calender?.by_location || '' : '',
    eventTypeFilter: isGlobal ? userContext?.firmDetails?.user_preferences?.filters?.calender?.event_type || '' : '',
    categoryFilter: isGlobal ? userContext?.firmDetails?.user_preferences?.filters?.calender?.by_category || '' : '',
    attendeesFilter: isGlobal ? userContext?.firmDetails?.user_preferences?.filters?.calender?.users || [getUserId()] : [],
    caseFilter:
      isGlobal && !isClientCalendar
        ? userContext?.firmDetails?.user_preferences?.filters?.calender?.case || ''
        : isClientCalendar
        ? convertCaseToFilterFormat(userContext?.clientSelectedCase)
        : '',
  });

  //modals
  const [showCreateEventModal, setShowCreateEventModal] = useState(Boolean(location?.state?.createEvent));
  const [showEventPreviewModal, setShowEventPreviewModal] = useState(Boolean(event_id));
  const [editEventModal, setEditEventModal] = useState(false);
  const [showCalendarSettings, setShowCalendarSettings] = useState(false);
  const [showMoreEventsModal, setShowMoreEventsModal] = useState(false);

  const [selectedEvent, setSelectedEvent] = useState();
  const [uploadedAttachments, setUploadedAttachments] = useState([]);
  const [tempDeletedAttachments, setTempDeletedAttachments] = useState([]);
  const [showUserFilter, setShowUserFilter] = useState(false);

  //event details
  const [eventDetails, setEventDetails] = useState();

  const [currentView, setCurrentView] = useState('dayGridMonth');
  const [currentDates, setCurrentDates] = useState({
    currentStartValue: '',
    currentEndValue: '',
    currentStartDate: '',
    currentEndDate: '',
  });

  const loadLocations = () => {
    totalCountEvent('contact').then((response) => {
      let obj = {
        contactType: '',
        contactStatus: '',
        keyWord: '',
        is_court_location: true,
        is_event_location: true,
        pageLimit: response.data,
      };

      listContacts(obj)
        .then((response) => {
          if (response && response.data && response.data.contacts && response.data.contacts.length > 0) {
            let options = response.data.contacts.map((index) => {
              return {
                label: index.title ? index.title : index.office_name,
                value: index.title ? index.title : index.office_name,
              };
            });
            setLocationsList(options);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const loadTypes = () => {
    totalCountEvent('event-type')
      .then((response) => {
        let obj = {
          pageLimit: response.data,
          pagination_token: '',
          pagination_direction: '',
        };
        listEventTypes(obj)
          .then((res) => {
            let data = res?.data['event-type'];
            let tempArray = [];

            data.forEach((item) => {
              tempArray.push({
                event_type: item.event_type,
                event_type_icon: item.event_type_icon,
              });
            });

            setTypeList(tempArray);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchGoogleEvents = () => {
    if (calendarSettings?.sync_with_google_calendar && googleAccessToken) {
      getGoogleEvents();
    } else {
      setGoogleEventList([]);
    }
  };

  const fetchOutlookEvents = () => {
    if (calendarSettings?.sync_with_outlook && user) {
      getOutlookEvents();
    } else {
      setOutlookEventList([]);
    }
  };

  function getEventList() {
    let { currentStartDate, currentEndDate } = currentDates;
    // let case_id;
    // if (isFromCaseSpecificModule || isFromLeadSpecificModule) {
    //   if (caseDetails?.case_id) {
    //     case_id = caseDetails?.case_id;
    //   } else if (calendarFilters.caseFilter?.id) {
    //     case_id = calendarFilters.caseFilter?.id;
    //   }
    // } else if (isClientCalendar && userContext?.clientCaseList.length === 1) {
    //   case_id = userContext?.clientCaseList[0]?.case_id;
    // }

    if (!currentStartDate && !currentEndDate) {
      return;
    }

    let date_filter_param = convertToTimezoneFormat(moment(currentStartDate).startOf('day'), userContext?.userDetails?.timezone);
    let range_end_param = convertToTimezoneFormat(
      moment(currentEndDate).subtract(1, 'day').endOf('day'),
      userContext?.userDetails.timezone
    );

    let obj = {
      event_type: calendarFilters.eventTypeFilter,
      category_filter: calendarFilters.categoryFilter,
      location: calendarFilters.locationFilter,
      date_filter_param: date_filter_param,
      range_end_param: range_end_param,
      is_global_calendar: true,
      case_id: isFromCaseSpecificModule || isFromLeadSpecificModule ? caseDetails?.case_id : calendarFilters.caseFilter?.id,
      // case_id: case_id,
      next: '',
      data: {
        // firm_user: calendarFilters?.attendeesFilter,
        firm_user: [...new Set(calendarFilters.attendeesFilter.flat())],
        get_calendar_items: calendarFilters?.attendeesFilter?.length === userList?.[0]?.items?.length + userList?.[1]?.items?.length,
        is_year_filter: currentView === 'multiMonthYear',
      },
    };
    listEvents(obj)
      .then((response) => {
        let events = response.data.events ? response.data.events : [];
        let cases = response.data.case ? response.data.case : [];
        let tasks = response.data.tasks ? response.data.tasks : [];
        let dob = response.data.dob ? response.data.dob : [];
        if (events && events.length > 0) {
          events.forEach((index) => {
            index.start = index?.meeting_start_time;
            index.end = index?.meeting_end_time;
          });
        }
        if (cases && cases.length > 0 && calendarSettings?.show_sol) {
          cases.forEach((index) => {
            index.start = index?.important_dates?.statute_of_limitations;

            index.end = index?.important_dates?.statute_of_limitations;

            index.title = 'Statute Of Limitations';
            index.case_name = index.case_description?.case_name;
          });
        }
        if (tasks && tasks.length > 0) {
          tasks.forEach((index) => {
            index.start = index?.due_date;
            index.end = index?.due_date;
            index.title = index.task_name;
          });
        }
        if (dob && dob.length > 0 && calendarSettings?.show_birthdays) {
          dob.forEach((val) => {
            let name = val.first_name;
            if (val.middle_name) name = name + ' ' + val.middle_name;
            if (val.last_name) name = name + ' ' + val.last_name;
            let originalDate = new Date(val?.dob);
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            val.start = originalDate.setFullYear(currentYear);
            val.end = originalDate.setFullYear(currentYear);
            val.title = `${name}'s birthday`;
          });
        }

        let data = [...events, ...cases, ...tasks, ...dob];
        let _eventList = data.filter((index) => index.start !== '');
        if (_eventList.length > 0) {
          setEventList(_eventList);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getGoogleEvents() {
    try {
      let { currentStartDate, currentEndDate } = currentDates;

      if (!currentStartDate && !currentEndDate) {
        return;
      }

      if (!googleAccessToken && !isTokenExpired()) {
        return;
      }
      const calendarId = await getCalendarId();

      let obj = {
        start_date: currentStartDate,
        end_date: currentEndDate,
        accessToken: googleAccessToken,
        calendarId,
      };

      lisGoogleEvents(obj)
        .then((response) => {
          response = response?.filter((index) => !index.in_app_event);
          setGoogleEventList(response);
        })
        .catch((err) => {
          console.log(err);
          setGoogleEventList([]);
        });
    } catch (error) {
      console.log(error);
    }
  }

  async function getOutlookEvents() {
    if (user) {
      let { currentStartDate, currentEndDate } = currentDates;

      if (!currentStartDate && !currentEndDate) {
        return;
      }
      try {
        const ianaTimeZones = findIana(user?.timeZone);
        let events = await getUserWeekCalendar(authProvider, ianaTimeZones[0].valueOf(), currentStartDate, currentEndDate);
        setOutlookEventList(formatEventsOutLook(events));
      } catch (err) {
        displayError(err.message);
      }
    }
  }

  const getUserList = () => {
    // To get user list for filter user avatars
    if (isFromCaseSpecificModule || isFromLeadSpecificModule) {
      getCaseUserClientData(caseDetails?.case_id);
    } else {
      getCaseUserClientData('');
      getFirmUsersListData();
    }
  };

  function getCaseUserClientData(case_id = '') {
    let obj = {
      caseId: case_id,
      is_contact_list: false,
      is_team_member_list: true,
      all_contacts: true,
      contact_id: false,
      is_lead: isFromLeadSpecificModule ?? false,
      is_mandatory_lawft_access: false,
      is_active: true,
      is_include_groups: true,
      active_groups: !editEventModal ? true : false,
    };
    getClientUserList(obj)
      .then((response) => {
        let finalUserList = response?.data;

        // if (editEventModal) {
        //   finalUserList = response?.data?.filter(
        //     (group) =>
        //       (group.res_type === 'user-group' && group?.status === 'ARCHIVED' && eventDetails.group_list.includes(group.user_group_id)) ||
        //       group.status === 'ACTIVE' ||
        //       group.res_type !== 'user-group'
        //   );
        // }

        if (editEventModal) {
          finalUserList = response?.data?.filter((user) => {
            const contactUserList = user.access_level === 'client';

            const isActiveUser = user?.is_confirmed && user?.status === 'ACTIVE';
            const isArchivedConfirmed = user.res_type !== 'user-group' && (user.status === 'ARCHIVED' || user.is_confirmed === false);
            const isIndividualUser = eventDetails?.firm_users?.some(
              (firmUser) => firmUser?.is_individual_user && firmUser?.cognito_username === user?.client_cognito_username
            );

            const isActiveGroup = user.res_type === 'user-group' && user.status === 'ACTIVE';
            const isArchiveGroupList =
              user.res_type === 'user-group' && user?.status === 'ARCHIVED' && eventDetails.group_list.includes(user.user_group_id);

            return contactUserList || isActiveUser || (isArchivedConfirmed && isIndividualUser) || isActiveGroup || isArchiveGroupList;
          });
        }

        let combinedData = combineUserAndGroup(finalUserList);
        setUserList(combinedData);
        // for avatar list
        if (isFromCaseSpecificModule || isFromLeadSpecificModule) {
          const responseData = response?.data;
          const filterDataWithoutGroups = responseData.filter((item) => item?.res_type !== 'user-group');
          setFirmUserList(ChangeCaseUserData(filterDataWithoutGroups));
        }
      })
      .catch((err) => console.error(err));
  }

  function combineUserAndGroup(response) {
    const groupedContacts = {};

    if (response?.length > 0) {
      response?.forEach((element) => {
        const contactType = element?.res_type === 'user-group' ? 'Groups' : !element.contact_type ? 'Firm Users' : 'Contacts';
        function generateLabel(element) {
          if (element.contact_type === 'person' || element.first_name || element.middle_name || element.last_name) {
            return generateFullName(element);
          }
          return element.title;
        }

        if (!groupedContacts[contactType]) {
          groupedContacts[contactType] = {
            label: contactType,
            items: [],
          };
        }

        if (element?.res_type === 'user-group') {
          groupedContacts[contactType].items.push({
            label: element.group_name,
            value: element.user_group_id,
            ...element,
          });
        } else if (element?.access_level) {
          groupedContacts[contactType]?.items.push({
            label: generateLabel(element),
            value: element.client_cognito_username ? element.client_cognito_username : element.contact_id,
            access_level: element.access_level,
            ...element,
          });
        } else {
          const label = generateLabel(element);
          groupedContacts[contactType].items.push({
            label: label,
            value: element.client_cognito_username ? element.client_cognito_username : element.contact_id,
            email: element.email,
            is_create_client_portal: element.is_create_client_portal,
            access_level: element.access_level,
            ...element,
          });
        }
      });
    }
    // return Object.values(groupedContacts).reverse();
    return Object.values(groupedContacts);
  }

  function getFirmUsersListData() {
    totalCountEvent('user')
      .then((response) => {
        let obj = {
          status: 'ACTIVE',
          limit: response.data ?? 10,
          pagination_token: '',
          pagination_direction: 'forward',
          keyWord: '',
          next: '',
          is_include_contacts: true,
          is_detailed: false,
          is_confirmed_user: true,
          is_include_groups: true,
        };
        listUsers(obj)
          .then((response) => {
            // setFirmUserList(changeUserData(response.data?.firm_users));
            setFirmUserList(formatMultiSelectContactList(response.data?.firm_users));
          })
          .catch((err) => console.error(err));
      })
      .catch((error) => console.warn(error));
  }

  const ChangeCaseUserData = (data) =>
    data.map((v) => ({
      ...v,
      full_name: generateFullName(v),
      user_id: v.client_cognito_username || v.contact_id,
    }));

  function hideCreateEventModal() {
    setShowCreateEventModal(false);
    setSelectedEvent();
    setEventDetails();
    setEditEventModal();
    setUploadedAttachments();
    setShowUserFilter(false);
    // setCalendarFilters((prevValues) => ({
    //   ...prevValues,
    //   caseFilter: "",
    // }));
  }

  function hidePreviewModal() {
    setShowEventPreviewModal(false);
    setSelectedEvent();
    setEventDetails();
    setEditEventModal();
    setUploadedAttachments();
    // setCalendarFilters((prevValues) => ({
    //   ...prevValues,
    //   caseFilter: "",
    // }));
  }

  const generateFirmUserIdList = () => {
    const userIdList = firmUserList?.map((user, idx) => user?.user_id);
    return userIdList;
  };

  useEffect(() => {
    setShowCreateEventModal(Boolean(location?.state?.createEvent));
  }, [location]);

  useEffect(() => {
    if (!Boolean(isClientCalendar)) {
      loadLocations();
      loadTypes();
    }
  }, []);

  useEffect(() => {
    if (showUserFilter) {
      getUserList();
    }
    if (isFromCaseSpecificModule || isFromLeadSpecificModule) {
      getUserList();
    }
  }, [showUserFilter, isFromCaseSpecificModule, isFromLeadSpecificModule]);

  useEffect(() => {
    if (userContext?.userDetails?.contact_id && isClientCalendar && notificationCounts) {
      Boolean(notificationCounts?.event_count) && readClientNotifications('event', userContext.userDetails.contact_id);
    }
  }, [userContext?.userDetails?.contact_id, notificationCounts, isClientCalendar]);

  useEffect(() => {
    if (!isClientCalendar) {
      getCalendarSettings()
        .then((res) => {
          let data = res?.data?.calendar_settings[0];

          setCalendarSettings(data);

          if (!data?.sync_with_google_calendar) {
            setGoogleUser();
          }
          if (!data?.sync_with_outlook) {
            setUser();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  // fetch calendar events
  useEffect(() => {
    if (currentDates) {
      setEventList([]);
      getEventList();
    }
  }, [currentDates, calendarFilters, calendarSettings]);

  // fetch google and outlook events
  useEffect(() => {
    if (calendarSettings) {
      fetchGoogleEvents();
      fetchOutlookEvents();
    }
  }, [calendarSettings, googleAccessToken, user, currentDates]);

  useEffect(() => {
    if (firmUserList?.length > 0 && (isFromCaseSpecificModule || isFromLeadSpecificModule)) {
      setCalendarFilters((prevValues) => {
        return {
          ...prevValues,
          attendeesFilter: [...generateFirmUserIdList()],
        };
      });
    }
  }, [firmUserList]);

  const contextPayload = useMemo(
    () => ({
      userContext,
      caseDetails,
      isFromCaseSpecificModule,
      isFromLeadSpecificModule,
      isClientCalendar,
      isGlobal,
      loadLocations,
      locationsList,
      typeList,
      // events
      eventList,
      setEventList,
      googleEventList,
      setGoogleEventList,
      outlookEventList,
      setOutlookEventList,
      //modals
      showCreateEventModal,
      setShowCreateEventModal,
      showMoreEventsModal,
      setShowMoreEventsModal,
      loadTypes,
      showEventPreviewModal,
      setShowEventPreviewModal,
      selectedEvent,
      setSelectedEvent,
      editEventModal,
      setEditEventModal,
      getEventList,
      hideCreateEventModal,
      hidePreviewModal,
      uploadedAttachments,
      setUploadedAttachments,
      tempDeletedAttachments,
      setTempDeletedAttachments,
      currentView,
      setCurrentView,
      currentDates,
      setCurrentDates,
      //filters
      userList,
      setUserList,
      firmUserList,
      showCalendarSettings,
      setShowCalendarSettings,
      calendarSettings,
      setCalendarSettings,
      calendarFilters,
      setCalendarFilters,
      eventDetails,
      setEventDetails,
      setLocationsList,
      isClosed,
      generateFirmUserIdList,
      showUserFilter,
      setShowUserFilter,
    }),
    [
      userContext,
      locationsList,
      typeList,
      eventList,
      googleEventList,
      outlookEventList,
      showCreateEventModal,
      showEventPreviewModal,
      showMoreEventsModal,
      selectedEvent,
      editEventModal,
      uploadedAttachments,
      tempDeletedAttachments,
      currentView,
      currentDates,
      userList,
      firmUserList,
      showCalendarSettings,
      calendarFilters,
      calendarSettings,
      eventDetails,
      isClosed,
      showUserFilter,
      setShowUserFilter,
    ]
  );

  return <CalendarContext.Provider value={contextPayload} {...props} />;
};
