import { performRequest, performRequestSecond, performRequestThird } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';
import { FILEUPLOAD_S3, FILEUPLOAD_S3_LEVEL, SetS3Config } from 'config/amplify_config';
import { Storage } from 'aws-amplify';
import { getTenantDetails } from 'utils/utils';
import Cookies from 'universal-cookie';
const cookie = new Cookies();

export const createTemplate = (data) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  //as an object {}
  return performRequestSecond(apiEndPoints.methodType.POST, `/templates?tenant_id=${tenantId}&tenant_sk=${tenantSk}`, data);
};

export const getTemplates = (params) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return performRequestSecond(apiEndPoints.methodType.GET, `/templates?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=100${params}`);
};

export const getFile = (params) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.fileManager.file}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=100${params}`
  );
};

export const getSignedFile = async (entityFileInfo) => {
  let url = entityFileInfo.file_path.split('/');
  url.shift();
  SetS3Config(FILEUPLOAD_S3, FILEUPLOAD_S3_LEVEL);
  // let user = await Auth.currentAuthenticatedUser();
  // let userId = null;
  try {
    // userId = user.attributes.sub;
    return await Storage.get(`${url.join('/')}`, { download: true, useAccelerateEndpoint: true });
  } catch (err) {
    /*  console.log(err) */
  }
};

export const getSignedUrl = async (path) => {
  let url = path.split('/');
  url.shift();
  SetS3Config(FILEUPLOAD_S3, FILEUPLOAD_S3_LEVEL);
  // let user = await Auth.currentAuthenticatedUser();
  // let userId = null;
  try {
    // userId = user.attributes.sub;
    return await Storage.get(`${url.join('/')}`, { useAccelerateEndpoint: true });
  } catch (err) {
    console.log(err);
  }
};

export const getFolders = (params) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.fileManager.file}/folders?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=100&${params}`
  );
};

export const getFileClients = (case_id) => {
  const tenantId = cookie.get('tenantId') || null;
  return performRequestSecond(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.fileManager.fileClients}?tenant_id=${tenantId}&case_id=${case_id}`
  );
};

export const sendESigantureRequest = (case_id, file_pk, file_sk, data) => {
  const tenantId = cookie.get('tenantId') || null;
  //const tenant_sk = cookie.get('tenantSk') || null;
  //let tenantSk = tenant_sk ? tenant_sk.replace("#", "%23") : "";
  let filePk = file_pk ? file_pk.replaceAll('#', '%23') : '';
  let fileSk = file_sk ? file_sk.replaceAll('#', '%23') : '';
  //as an object {}
  return performRequestSecond(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.fileManager.sendESignature}?tenant_id=${tenantId}&case_id=${case_id}&file_pk=${filePk}&file_sk=${fileSk}`,
    data
  );
};

export const getFileDetails = (file_pk, file_sk) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replaceAll('#', '%23') : '';
  let filePK = file_pk ? file_pk.replaceAll('#', '%23') : '';
  let fileSK = file_sk ? file_sk.replaceAll('#', '%23') : '';
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.fileManager.getFile}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&pk=${filePK}&sk=${fileSK}`
  );
};

export const sendESigantureResponse = (case_id, file_pk, file_sk) => {
  const tenantId = cookie.get('tenantId') || null;
  //const tenant_sk = cookie.get('tenantSk') || null;
  //let tenantSk = tenant_sk ? tenant_sk.replace("#", "%23") : "";
  let filePk = file_pk ? file_pk.replaceAll('#', '%23') : '';
  let fileSk = file_sk ? file_sk.replaceAll('#', '%23') : '';
  //as an object {}
  return performRequestSecond(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.fileManager.sendESignatureToAdmin}?tenant_id=${tenantId}&case_id=${case_id}&file_pk=${filePk}&file_sk=${fileSk}`
  );
};

export const fileTag = (data) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replaceAll('#', '%23') : '';
  return performRequestSecond(
    apiEndPoints.methodType.PATCH,
    `${apiEndPoints.fileManager.tagFile}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};

export const getPresignedURL = (file_path, size = '') => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.getPresignedURL}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&file_path=${file_path}&file_size=${size}`;
  return performRequestSecond(apiEndPoints.methodType.GET, url);
};

export const presignedURLGet = (file_path, version_id = false, is_profile_image = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.presignedURLGet}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&file_path=${file_path}${
    version_id ? `&version_id=${version_id}` : ''
  }${is_profile_image ? `&is_profile_image=${is_profile_image}` : ''}`;
  return performRequestSecond(apiEndPoints.methodType.GET, url);
};

export const convertDocxApi = (data) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  //as an object {}
  return performRequestSecond(apiEndPoints.methodType.POST, `/templates/convert?tenant_id=${tenantId}&tenant_sk=${tenantSk}`, data);
};

export const updateTemplate = (data) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  //as an object {}
  return performRequestSecond(apiEndPoints.methodType.PUT, `/templates/edit?tenant_id=${tenantId}&tenant_sk=${tenantSk}`, data);
};

export const copyTemplateApi = (data) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  //as an object {}
  return performRequestSecond(apiEndPoints.methodType.POST, `/templates/copy?tenant_id=${tenantId}&tenant_sk=${tenantSk}`, data);
};

export const getTemplateDetail = (data) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  //as an object {}
  return performRequestSecond(
    apiEndPoints.methodType.POST,
    `/templates/detail?tenant_id=${tenantId}&tenant_sk=${tenantSk}&mode=${data.mode}`,
    data
  );
};

export const generateReportAPI = (data) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  //as an object {}
  return performRequestThird(apiEndPoints.methodType.POST, `/templates/report?tenant_id=${tenantId}&tenant_sk=${tenantSk}`, data);
};

export const updateFile = (data) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  //as an object {}
  return performRequest(apiEndPoints.methodType.PUT, `${apiEndPoints.fileManager.file}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`, data);
};

export const getActiveUsers = (doc_id) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  //as an object {}
  return performRequestThird(apiEndPoints.methodType.GET, `/template/users?tenant_id=${tenantId}&tenant_sk=${tenantSk}&doc_id=${doc_id}`);
};

export const getJwtToken = async (data) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  //as an object {}
  let response = await performRequestThird(
    apiEndPoints.methodType.POST,
    `/doc-collaboration/jwt?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
  console.log(response.data, 'resssssssssss');
  return response.data;
};

export const getDocumentKey = async (data) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  //as an object {}
  let response = await performRequestThird(
    apiEndPoints.methodType.POST,
    `/doc-collaboration/document-key?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
  console.log(response, 'resssssssssss');
  return response.data;
};
