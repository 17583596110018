/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

//import { Calendar } from "primereact/calendar";
import { Dropdown } from 'primereact/dropdown';
import { useDispatch } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Timer } from './timer';
import { createTimeEntry, dhm, updateTimeEntry, getUserHourRate } from 'services/timerServices';
//import { connectToSocket } from "services/notificationService";
import { nofifyTimeManager } from 'redux/actions/TimeEntryUpdateActions';
import { InputNumber } from 'primereact/inputnumber';
import { useCaseAndLeadsList } from 'hooks/useCaseAndLeadList';
import { InputSwitch } from 'primereact/inputswitch';
import useSpinner from 'hooks/useSpinner';
import useActivityTypes from 'hooks/useActivityTypes';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
const { forwardRef, useImperativeHandle } = React;

export const CreateTimer = forwardRef((props, ref) => {
  const { globalReduxData, selectedTimer } = props;
  const [selectedCase, setSelectedCase] = useState(selectedTimer?.new_case_name ? selectedTimer?.new_case_name : ' ');
  const [time, setTime] = useState({});
  const [, setTimerDate] = useState();
  const [description, setDescription] = useState(selectedTimer?.notes ? selectedTimer?.notes : ' ');
  const [rate, setRate] = useState('');
  const [markAsBillable, setMarkAsBillable] = useState(false);
  const dispatch = useDispatch();
  const { caseAndLeadsList } = useCaseAndLeadsList();
  const [caseList, setCaseList] = useState([]);
  const [selectedActivityType, setSelectedActivityType] = useState();
  const [, setCaseError] = useState(false);
  const [duration, setDuration] = useState('');
  const isFromTimeManger = props?.isFromTimeManger ?? false;
  const [spinner, showSpinner, hideSpinner] = useSpinner();

  const { activityTypes } = useActivityTypes();

  console.log('activityTypes', activityTypes);

  let [formErrors, setFormErrors] = useState({
    case: '',
    rate: '',
    duration: '',
  });

  useImperativeHandle(ref, () => ({
    getData() {
      getTimeEntryRequest();
    },
  }));

  useEffect(() => {
    if (globalReduxData && globalReduxData.length && globalReduxData[0] && globalReduxData[0].editMode) {
      let details = globalReduxData[0];
      if (details && details.editMode) {
        if (details?.actionBody?.total_times) {
          setDuration(details?.actionBody?.total_times);
        }
        if (details?.actionBody?.notes) {
          setDescription(details?.actionBody?.notes);
        }
        if (details?.actionBody?.hour_rate) {
          setRate(details?.actionBody?.hour_rate);
        }
      }
    }
  }, [globalReduxData]);

  useEffect(() => {
    if (caseAndLeadsList?.case_list && caseAndLeadsList?.case_list?.length) {
      let data = caseAndLeadsList?.case_list?.map((val) => {
        val.new_case_name =
          val && val.case_description && val.case_description.case_name
            ? `${val.unique_number ? `${val.unique_number} ` : ''}${val.case_description.case_name}`
            : 'TBD';
        return val;
      });
      if (globalReduxData && globalReduxData.length) {
        let details = globalReduxData[0];
        if (details && details.editMode && details.actionBody && details.actionBody.case_id) {
          let caseVal = data.find((val) => val.case_id === details.actionBody.case_id);
          caseVal?.case_id && setSelectedCase(caseVal);
        }
      }

      setCaseList(data);
    } else {
      setCaseList([]);
    }
  }, [caseAndLeadsList?.case_list]);

  const setHoursRate = () => {
    if (!props.selectedTimer?.hour_rate && isFromTimeManger) {
      getUserHourRate().then((response) => {
        if (response?.data?.rate?.rate) {
          setRate(response?.data?.rate?.rate);
        }
      });
    }
  };
  useEffect(() => {
    if (props.selectedTimer) {
      setTime(props.selectedTimer);
      setDuration(props.selectedTimer?.total_hours);
      setTimerDate(new Date(props.selectedTimer.time_entry_date));
      setMarkAsBillable(props.selectedTimer.billable);
      setDescription(props.selectedTimer.notes);
      if (props.selectedTimer?.hour_rate) {
        setRate(props.selectedTimer.hour_rate);
      } else if (props.selectedTimer?.hour_rate && isFromTimeManger) {
        setRate();
      } else {
        setHoursRate();
      }

      let case_id = props.selectedTimer.case_id;
      let activity_type_id = props.selectedTimer.activity_type_id;

      if (caseList && caseList.length > 0) {
        let selectedCase = caseList.filter((index) => index.case_id === case_id);
        setSelectedCase({ ...selectedCase[0] });
      }
      if (activityTypes && activityTypes.length > 0) {
        let selectedActivity = activityTypes.filter((index) => index.activity_type_id === activity_type_id);
        setSelectedActivityType({ ...selectedActivity[0] });
      }
    }
  }, [props.selectedTimer, caseList, activityTypes]);

  const onChangeCase = (e) => {
    setCaseError(false);
    formErrors.case = '';
    setFormErrors({ ...formErrors });
    setSelectedCase(e.value);
  };

  const getTimeEntryRequest = () => {
    try {
      let data = {
        start_time: new Date().getTime(),
        case_id: selectedCase?.case_id,
        case_name: selectedCase?.case_description?.case_name,
        billable: markAsBillable,
        notes: description,
        hour_rate: rate || '0',
        time_entry_status: isFromTimeManger ? 'OPEN' : 'PROGRESS',
        activity_type: selectedActivityType?.activity_type,
        activity_type_id: selectedActivityType?.activity_type_id,
        total_times: duration,
        amount: `${duration * rate}`,
      };
      if (props.selectedTimer) {
        let request_data;
        if (isFromTimeManger) {
          request_data = { ...props.selectedTimer, ...data };
        } else {
          request_data = { ...data, ...props.selectedTimer };
        }
        request_data.time_entry_status = 'OPEN';
        if (validateForm()) {
          props.getVal(request_data);
        } else {
          setCaseError(true);
        }
      } else {
        if (validateForm()) {
          let date = new Date();
          let dd = String(date.getDate()).padStart(2, '0');
          let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
          let yyyy = date.getFullYear();
          date = yyyy + '-' + mm + '-' + dd;
          data.time_entry_date = date;
          props.getVal(data);
        }
      }
    } catch (error) {}
  };

  const submitTimeEntry = () => {
    let data = {
      start_time: new Date().getTime(),
      case_id: selectedCase?.case_id,
      case_name: selectedCase?.case_description?.case_name,
      billable: markAsBillable,
      notes: description,
      hour_rate: rate ? `${rate}` : '0',
      time_entry_status: isFromTimeManger ? 'OPEN' : 'PROGRESS',
      activity_type: selectedActivityType?.activity_type,
      activity_type_id: selectedActivityType?.activity_type_id,
      activity_type_code: selectedActivityType?.activity_type_code,
      total_hours: duration,
      amount: `${duration * rate}`,
    };
    if (props.selectedTimer) {
      let request_data;
      if (isFromTimeManger) {
        request_data = { ...props.selectedTimer, ...data };
      } else {
        request_data = { ...data, ...props.selectedTimer };
      }
      // let request_data = { ...data, ...props.selectedTimer };

      request_data.time_entry_status = 'OPEN';
      if (validateForm()) {
        showSpinner();
        updateTimeEntry(request_data)
          .then((response) => {
            hideSpinner();
            if (isFromTimeManger) {
              setTime('');
              setDuration('');
              setTimerDate('');
              setMarkAsBillable('');
              setDescription('');
              setRate('');
              setSelectedCase('');
            }
            dispatch(nofifyTimeManager(response.data));
            props.createTimerSuccess();
          })
          .catch((err) => {
            console.log('err', err);
            hideSpinner();
          });
      } else {
        setCaseError(true);
      }
    } else {
      if (validateForm()) {
        showSpinner();
        let date = new Date();
        let dd = String(date.getDate()).padStart(2, '0');
        let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = date.getFullYear();
        date = yyyy + '-' + mm + '-' + dd;
        data.time_entry_date = date;
        createTimeEntry(data)
          .then((response) => {
            hideSpinner();
            if (isFromTimeManger) {
              setTime('');
              setDuration('');
              setTimerDate('');
              setMarkAsBillable('');
              setDescription('');
              setRate('');
              setSelectedCase('');
            }
            if (localStorage.getItem('currentTimer')) {
              let currentTimer = JSON.parse(localStorage.getItem('currentTimer'));
              if (currentTimer.sk && !isFromTimeManger) {
                let start_time = currentTimer.start_time;
                let end_time = currentTimer.end_time;

                if (start_time && !end_time) {
                  let end_time = new Date();
                  let duration = end_time - new Date(start_time);
                  currentTimer.end_time = end_time.getTime();
                  currentTimer.total_times = dhm(duration);
                  currentTimer.start_time = '';
                  updateTimeEntry(currentTimer).then((response) => {
                    props.createTimerSuccess();
                  });
                }
              } else {
                props.createTimerSuccess();
              }
            } else {
              props.createTimerSuccess();
            }
          })
          .catch((err) => {
            console.log('err', err);
            hideSpinner();
          });
      }
    }
  };

  const validateForm = () => {
    let isValid = true;
    if (!selectedCase?.case_id) {
      isValid = false;
      formErrors.case = 'Required';
    }

    if ((!rate || (rate && rate <= 0)) && markAsBillable) {
      isValid = false;
      formErrors.rate = 'Required a valid rate';
    }
    if (isFromTimeManger) {
      if (!duration || duration === '00:00:00') {
        isValid = false;
        formErrors.duration = 'required';
      }
      if (duration) {
        let values = duration.split(':');
        if (values && values.length > 0) {
          // let hh = values[0];
          let mm = values[1];
          let ss = values[2];
          if (mm >= 60 || ss >= 60) {
            isValid = false;
            formErrors.duration = 'Invalid';
          }
        }
      }
    }
    setFormErrors({ ...formErrors });
    return isValid;
  };

  return (
    <>
      {spinner}

      {!isFromTimeManger && !props.selectedTimer && (
        <div className="row">
          <div className="col-12">
            <Timer timer={time} hideActions={true} submitTimeEntry={submitTimeEntry} />
          </div>
        </div>
      )}
      {(isFromTimeManger || props.selectedTimer) && (
        <div className="timeentry-popup mt-3">
          <div className="row p-0">
            {!isFromTimeManger && <div className="sub-title"> {props.selectedTimer ? 'Update' : 'Add'} Time Entry</div>}
          </div>
          {isFromTimeManger && (
            <div className="d-flex justify-content-between align-items-center mt-3 pb-3">
              <div className="modal-heading F-size16 text-bold">{props.header}</div>
            </div>
          )}
          <div className="row time-entry-wrp">
            <div className="d-flex align-items-top py-2 px-0 flex-wrap">
              <div className="col-12 col-sm-4 pt-3">
                <label className="p-medium">Duration</label>
              </div>
              <div className="col-12 col-sm-8 mb-3">
                {isFromTimeManger ? (
                  <>
                    {/* <InputMask
                      className="input-shadow w-100 border-0 p-2"
                      placeholder="hh:mm:ss"
                      value={duration}
                      onChange={(e) => {
                        setDuration(e.value);
                        formErrors.duration = "";
                        setFormErrors({ ...formErrors });
                      }}
                      mask="99:99:99"
                    ></InputMask> */}
                    {/* <InputText
                        className="input-shadow w-100 border-0 p-2 p-d-block mb-2"
                        value={duration}
                        onChange={(e) => {
                          setDuration(diff_hours(e.target.value));
                          formErrors.duration = "";
                          setFormErrors({ ...formErrors });
                        }}
                      /> */}
                    <InputText
                      className="input-shadow w-100 border-0 p-2 p-d-block mb-2"
                      value={duration}
                      onChange={(e) => {
                        setDuration(e.target.value.replace(/\D/g, ''));
                        formErrors.duration = '';
                        setFormErrors({ ...formErrors });
                      }}
                    />
                    <small id="username1-help" className="p-d-block">
                      0.1 = 6 minutes
                    </small>
                    {formErrors.duration && <span className="text-danger">{formErrors.duration}</span>}
                  </>
                ) : (
                  <Timer timer={time} hideActions={true} />
                )}
              </div>
            </div>
            <div className="d-flex align-items-center py-2 px-0 flex-wrap">
              <div className="col-12 col-sm-4">
                <label className="p-medium">Case</label>
                <span className="text-danger">*</span>
              </div>
              <div className="col-12 col-sm-8 mb-3">
                <Dropdown
                  placeholder="By Case"
                  options={caseList}
                  value={selectedCase}
                  onChange={onChangeCase}
                  optionLabel={'new_case_name'}
                  // className="w-100 input-height input-line-height"
                  className="w-100 input-shadow input-height p-1"
                />
                {formErrors.case && <span className="text-danger">*Required</span>}
              </div>
            </div>
            <div className="d-flex align-items-center py-2 px-0 flex-wrap">
              <div className="col-12 col-md-4 mb-3 d-flex align-items-center">
                <label className="p-medium">Activity Type</label>
              </div>
              <div className="col-12 col-md-8 mb-3">
                <Dropdown
                  placeholder="Select Activity Type"
                  options={activityTypes}
                  value={selectedActivityType}
                  optionLabel={'activity_label'}
                  onChange={(e) => {
                    setSelectedActivityType(e.value);
                  }}
                  // className="w-100 input-height input-line-height"
                  className="w-100 input-height p-1"
                  filter
                  filterBy="activity_type,activity_type_code"
                  filterPlaceholder="search"
                />
              </div>
            </div>
            <div className="d-flex align-items-center py-2 px-0 flex-wrap">
              <div className="col-12 col-sm-4 mb-3">
                <label className="p-medium">Description</label>
              </div>
              <div className="col-12 col-sm-8 mb-3">
                <TextSnippetWrapper
                  type="textarea"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  className="input-shadow w-100"
                />
              </div>
            </div>
            <div className="d-flex align-items-center py-2 px-0 flex-wrap">
              <div className="col-12 col-sm-4">
                <label className="p-medium">
                  {/* Rate, <span className="text-muted">$/h</span> */}
                  Hourly Rate
                  {markAsBillable ? <span className="text-danger">*</span> : null}
                </label>
              </div>
              <div className="col-12 col-sm-8 mb-3">
                <InputNumber
                  id="rate"
                  name="rate"
                  placeholder="Rate"
                  className="input-shadow w-100"
                  value={rate}
                  onChange={(e) => {
                    setRate(e.value);
                    formErrors.rate = '';
                    setFormErrors({ ...rate });
                  }}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  min={0}
                />
                {formErrors.rate && <span className="text-danger">{formErrors.rate}</span>}
              </div>
            </div>

            <div className="d-flex align-items-center py-2 px-0 flex-wrap">
              <div className="col-12 col-sm-4">
                <label className="p-medium">Total Amount</label>
              </div>
              <div className="col-12 col-sm-8 mb-3">
                <InputNumber
                  id="total_amount"
                  placeholder="Duration * Rate"
                  className="input-shadow w-100"
                  value={!isNaN(rate * duration) ? rate * duration : ''}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  min={0}
                  disabled={true}
                />
              </div>
            </div>

            <div className="d-flex align-items-center px-0 py-2 flex-wrap">
              <InputSwitch
                checked={markAsBillable}
                onChange={(e) => {
                  setMarkAsBillable(e.target.value);
                }}
              />
              <label className="ms-2 p-medium">Mark as Billable</label>
            </div>

            {props.hideButtons ? null : (
              <div className="footer-btn px-0">
                <button className="p-button p-button-secondary outline me-2" onClick={props.cancelCreateTimer}>
                  Cancel
                </button>
                <button className="p-button p-button-secondary" onClick={submitTimeEntry}>
                  {props.selectedTimer ? 'Update' : 'Save'}
                </button>
              </div>
            )}
            {/* <div className="col-12 rounded-0 text-left border-top py-4 px-0">
              <button
                className="p-button p-button-secondary me-2"
                onClick={submitTimeEntry}
              >
                {props.selectedTimer ? "Update" : "Save"}
              </button>
              <button
                className="p-button p-button-secondary outline"
                onClick={props.cancelCreateTimer}
              >
                {" "}
                Cancel
              </button>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
});
