import { actionTypes } from "constants/types";

let initialState = {
  isLoading: false,
  eventTypeList: [],
  error: "",
};

export default function EventTypeListReducer(state = initialState, action={}) {
  switch (action.type) {
      case actionTypes.GET_EVENT_TYPE_LIST_STARTED:
          return{
              ...state,
              isLoading:true
          }
        case actionTypes.GET_EVENT_TYPE_LIST_SUCCESS:
            return{
                ...state,
                eventTypeList:action.data,
                isLoading:false
            }
        case actionTypes.GET_EVENT_TYPE_LIST_FAILURE:
            return{
                ...state,
                error:action.error,
                isLoading:false
            }


          default:
              return state;
  }
}