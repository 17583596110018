import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Column } from './Column';
import { Tooltip } from 'primereact/tooltip';

export const Board = React.memo(({ boardData, onDragEnd, handleAddStage, handleManageColumn, isClosed, cardAction }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="all-droppables" direction="horizontal" type="column">
        {(provided) => (
          <div className="columns" style={{ display: 'flex' }} {...provided.droppableProps} ref={provided.innerRef}>
            {boardData?.columnOrder?.map((columnId, index) => (
              <Column
                key={columnId}
                column={boardData.columns[columnId]}
                index={index}
                handleAddStage={handleAddStage}
                handleManageColumn={handleManageColumn}
                isClosed={isClosed}
                boardData={boardData}
                cardAction={cardAction}
                disableDrag={columnId === 'done'}
              />
            ))}
            {provided.placeholder}
            <Tooltip content="Add Task" position="top" target=".btn-add-task" showDelay={500} />
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
});
