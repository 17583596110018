import React, { useEffect, useState, useRef, useContext } from 'react';
import _ from 'lodash';
import { format } from 'date-fns';
import { dollarFormat } from 'utils/utils';
import { reduceTax } from 'utils/utility_functions/commonCalculations';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import { UserDetailsContext } from 'context/userDetailsContext';
import constants from 'constants/index';

export const InvoicePrintView = (props) => {
  const [invoiceDetails, setInvoiceDetails] = useState();
  const [isEdit, setIsEdit] = useState(false);
  let componentRef = useRef(null);
  const paymentStructure = {
    FLAT_FEE: 'Flat Fee',
    SUBSCRIPTION: 'Subscription',
    CONTINGENCY_FEE: 'Contingency Fee',
    EVERGREEN_RETAINER: 'Evergreen Retainer',
    STANDARD_RETAINER: 'Standard Retainer',
  };

  useEffect(() => {
    if (props.invoiceDetails) {
      setInvoiceDetails({ ...props.invoiceDetails });
    } else {
      setInvoiceDetails();
    }
  }, [props.invoiceDetails]);

  const userContext = useContext(UserDetailsContext);
  const userTimezone = userContext?.userDetails?.timezone;

  const subTotal = () => {
    let expenseTotal = invoiceDetails?.expenses?.total_amount;
    expenseTotal = expenseTotal ? parseFloat(expenseTotal) : 0;
    let timeEntriesTotal = invoiceDetails?.time_entries?.total_amount;
    timeEntriesTotal = timeEntriesTotal ? parseFloat(timeEntriesTotal) : 0;

    return expenseTotal + timeEntriesTotal;
  };

  return (
    <div>
      {!isEdit && invoiceDetails && (
        <div>
          <div className="">
            <div className="col-12"></div>
          </div>
          <div ref={componentRef}>
            <div className="d-flex py-2 view-invoice-wrap F-size14">
              <div className="col-12 col-md-4 px-2">
                <div className="caption-bold mb-3">billed from</div>
                {invoiceDetails.created_by_details && (
                  <div className="input-shadow p-2 bg-white">
                    <div className="col-12 py-2">
                      {invoiceDetails.created_by_details?.first_name} {invoiceDetails.created_by_details?.last_name}
                    </div>
                    {/*  <div className="col-12 py-2">{invoiceDetails.invoice?.street}</div>
                  <div className="col-12 py-2">{invoiceDetails.invoice?.city}</div>
                  <div className="col-12 py-2">{invoiceDetails.invoice?.state}</div>
                  <div className="col-12 py-2">
                    {invoiceDetails.invoice?.country}
                  </div> */}
                  </div>
                )}
              </div>
              <div className="col-md-4 px-2">
                <div className="caption-bold mb-3">billed to</div>
                <div className="input-shadow p-2 bg-white">
                  {invoiceDetails?.invoice && (
                    <>
                      <div className="col-12 py-2">{invoiceDetails?.invoice?.contact_name} </div>
                    </>
                  )}

                  <div className="col-12 py-2">
                    {invoiceDetails.invoice?.street} {invoiceDetails.invoice?.city}
                    {invoiceDetails.invoice?.state} {invoiceDetails.invoice?.country}
                  </div>
                  {/*   <div className="col-12 py-2">(308) 555-0121</div> */}
                </div>
              </div>
              <div className="col-12 col-md-4 justify-content-end px-2">
                <div className="caption-bold mb-3">invoice information</div>
                <table className="table invoice-table input-shadow p-2 bg-white">
                  <tbody>
                    <tr>
                      <td className="table-active">Invoice #</td>
                      <td>{invoiceDetails.invoice_number}</td>
                    </tr>
                    <tr>
                      <td className="table-active">Invoice Date</td>
                      <td>
                        {invoiceDetails?.invoice_date
                          ? handleDateTimeOffset(userTimezone, invoiceDetails?.invoice_date, constants.month_date_year_format)
                          : ''}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-active">Due Date</td>
                      <td>
                        {invoiceDetails.invoice?.due_date
                          ? handleDateTimeOffset(userTimezone, invoiceDetails?.invoice_date, constants.month_date_year_format)
                          : ''}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-active">Balance Due </td>
                      <td>{dollarFormat(invoiceDetails.due_amount)}</td>
                    </tr>
                    <tr>
                      <td className="table-active">Payment Terms</td>
                      <td>{invoiceDetails.invoice?.payment_terms || ''}</td>
                    </tr>
                    <tr>
                      <td className="table-active">Case / Matter</td>
                      <td>{invoiceDetails.invoice?.case_name}</td>
                    </tr>
                    <tr>
                      <td className="table-active">Status</td>
                      <td>{invoiceDetails.invoice_status ? _.startCase(_.toLower(invoiceDetails.invoice_status)) : ''}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="p-2 mb-4">
              {invoiceDetails?.flat_fees?.flat_fee_data && invoiceDetails?.flat_fees?.flat_fee_data.length > 0 && (
                <div className="d-flex py-2 flex-wrap time-entries p-2">
                  <div className="col-12 caption-bold mb-3">Flat Fees</div>
                  <div className="col-12 input-shadow p-2 mb-4 bg-white">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Members</th>
                          <th>Item</th>
                          <th>Flat Fee Notes</th>
                          {invoiceDetails?.flat_fees?.tax_percentage ? (
                            <>
                              <th>Actual Amount</th>
                              <th>Amount (with Tax)</th>
                            </>
                          ) : (
                            <>
                              <th>Amount</th>
                            </>
                          )}
                          <th>Billable</th>
                        </tr>
                      </thead>
                      {invoiceDetails?.flat_fees?.flat_fee_data.map((invoiceData, index) => {
                        return (
                          <tbody>
                            <tr key={index}>
                              <td>
                                {invoiceData.flat_fee_date
                                  ? handleDateTimeOffset(userTimezone, invoiceData.flat_fee_date, constants.month_date_year_format)
                                  : ''}
                              </td>
                              <td>{invoiceData.employee_name}</td>
                              <td>{invoiceData.item_name}</td>
                              <td>{invoiceData.flat_fee_notes}</td>
                              {invoiceDetails?.flat_fees?.tax_percentage && (
                                <td>{dollarFormat(reduceTax(invoiceData.flat_fee_amount, invoiceDetails?.flat_fees?.tax_percentage))}</td>
                              )}
                              <td>{dollarFormat(invoiceData.flat_fee_amount)}</td>
                              <td>
                                <label className="switch">
                                  <input type="checkbox" checked={invoiceData?.billable} name="non_billable" disabled />
                                  <span className="slider round"></span>
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                      <tfoot className="">
                        <tr>
                          <td></td>
                          <td></td>
                          {invoiceDetails?.flat_fees?.tax_percentage ? (
                            <>
                              <td></td>
                              <td className="text-bold">Total</td>
                              <td className="text-bold">
                                {dollarFormat(
                                  (invoiceDetails?.flat_fees?.flat_fee_data).reduce((accumulator, currentValue) => {
                                    if (currentValue?.tax_applied) {
                                      accumulator += parseFloat(
                                        reduceTax(currentValue?.flat_fee_amount, invoiceDetails?.flat_fees?.tax_percentage)
                                      );
                                    } else {
                                      accumulator += parseFloat(currentValue?.flat_fee_amount);
                                    }
                                    return accumulator;
                                  }, 0)
                                )}
                              </td>
                              <td className="text-bold">{dollarFormat(invoiceDetails?.flat_fees?.total_amount)}</td>
                            </>
                          ) : (
                            <>
                              <td></td>
                              <td className="text-bold">Total</td>
                              <td className="text-bold">{dollarFormat(invoiceDetails?.flat_fees?.total_amount)}</td>
                            </>
                          )}
                          <td></td>
                        </tr>
                        {invoiceDetails?.flat_fees?.tax_percentage && (
                          <tr>
                            <td className="disclaimer">Tax Rate @ {invoiceDetails?.flat_fees?.tax_percentage}%</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        )}
                      </tfoot>
                    </table>
                  </div>
                </div>
              )}

              {invoiceDetails?.time_entries?.time_entry_data && invoiceDetails?.time_entries?.time_entry_data.length > 0 && (
                <div className="d-flex py-2 flex-wrap time-entries p-2">
                  <div className="col-12 caption-bold mb-3">Time Entries</div>
                  <div className="col-12 input-shadow p-2 mb-4 bg-white">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Members</th>
                          <th>Case</th>
                          <th>Activity</th>
                          <th>Description</th>
                          <th>Fee</th>
                          <th>Duration</th>
                          <th>Billable</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      {invoiceDetails?.time_entries?.time_entry_data.map((index) => {
                        let name = index?.created_by_details?.first_name;
                        if (index?.created_by_details?.last_name) name = name + ' ' + index?.created_by_details?.last_name;
                        let hour_rate = index.hour_rate;
                        hour_rate = hour_rate ? parseFloat(hour_rate) : 0;
                        let total_hours = index.total_hours;
                        total_hours = total_hours ? parseFloat(total_hours) : 0;
                        let total = hour_rate * total_hours;
                        return (
                          <tbody>
                            <tr>
                              <td>
                                {index.time_entry_date
                                  ? handleDateTimeOffset(userTimezone, index.time_entry_date, constants.month_date_year_format)
                                  : ''}
                              </td>
                              <td>{name}</td>
                              <td>{index.case_name}</td>
                              <td>{index.activity_type}</td>
                              <td>{index.notes}</td>
                              <td>{dollarFormat(index.hour_rate)}</td>
                              <td>{index.total_hours}</td>
                              <td>
                                <label className="switch">
                                  <input type="checkbox" checked={index?.billable} name="non_billable" disabled />
                                  <span className="slider round"></span>
                                </label>
                              </td>
                              <td>{dollarFormat(total)}</td>
                            </tr>
                          </tbody>
                        );
                      })}
                      <tfoot className="">
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="text-bold">Total hours:</td>
                          <td className="text-bold">{invoiceDetails?.time_entries?.total_hours}</td>
                          <td className="text-bold">Total Amount</td>
                          <td className="text-bold">{dollarFormat(invoiceDetails?.time_entries?.total_amount)}</td>
                        </tr>
                        {(invoiceDetails?.payment_structure === paymentStructure.EVERGREEN_RETAINER ||
                          invoiceDetails?.payment_structure === paymentStructure.STANDARD_RETAINER) &&
                          invoiceDetails?.time_entries?.tax_percentage &&
                          invoiceDetails?.time_entries?.total_amount_with_tax && (
                            <>
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="text-bold">Tax %:</td>
                                <td className="text-bold">{invoiceDetails?.time_entries?.tax_percentage}%</td>
                                <td className="text-bold">Total Amount + Tax</td>
                                <td className="text-bold">{dollarFormat(invoiceDetails?.time_entries?.total_amount_with_tax)}</td>
                              </tr>
                            </>
                          )}
                      </tfoot>
                    </table>
                  </div>
                </div>
              )}

              {invoiceDetails?.expenses?.expense_data && invoiceDetails?.expenses?.expense_data.length > 0 && (
                <div className="d-flex py-2 flex-wrap time-entries p-2 mt-4">
                  <div className="col-12 caption-bold mb-3">Expenses</div>
                  <div className="col-12 input-shadow p-2 mb-4 bg-white">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Members</th>
                          <th>Case</th>
                          <th>Expense Type</th>
                          <th>Description</th>
                          <th>Fee</th>
                          <th>Quantity</th>
                          <th>Billable</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      {invoiceDetails?.expenses?.expense_data.map((index) => {
                        return (
                          <tbody>
                            <tr>
                              <td>{index.expense_date ? format(new Date(index.expense_date), 'MM/dd/yyyy') : ''}</td>
                              <td>{index.team_member_name}</td>
                              <td>{index.case_name}</td>
                              <td>{index.expense_type}</td>
                              <td>{index.description}</td>
                              <td>{dollarFormat(index.rate_per_unit)}</td>
                              <td>{index.quantity}</td>
                              <td>
                                <label className="switch">
                                  <input type="checkbox" checked={index?.billable} name="non_billable" disabled />
                                  <span className="slider round"></span>
                                </label>
                              </td>
                              <td>{dollarFormat(index.total_amount)}</td>
                            </tr>
                          </tbody>
                        );
                      })}
                      <tfoot className="">
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="text-bold">Total Amount</td>
                          <td className="text-bold">{dollarFormat(invoiceDetails?.expenses?.total_amount)}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              )}

              {invoiceDetails?.adjustments?.adjustment_data && invoiceDetails?.adjustments?.adjustment_data.length > 0 && (
                <div className="d-flex py-2 flex-wrap time-entries p-2">
                  <div className="col-12 caption-bold mb-3">Adjustments</div>
                  <div className="col-12 input-shadow p-2 mb-4 bg-white">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th>Applied To</th>
                          <th>Type</th>
                          <th>Notes</th>
                          <th>Basis</th>
                          <th>Percentage</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      {invoiceDetails?.adjustments?.adjustment_data.map((index) => {
                        return (
                          <tbody>
                            <tr>
                              <td>{index.adjustment_item}</td>
                              <td>{index.adjustment_to}</td>
                              <td>{index.adjustment_type}</td>
                              <td>{index.notes}</td>
                              <td>{dollarFormat(index.basic_amount)}</td>
                              <td>{index.percentage}</td>
                              <td>{dollarFormat(index.amount)}</td>
                            </tr>
                          </tbody>
                        );
                      })}
                      <tfoot className="">
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>

                          <td className="text-bold">Total Amount</td>
                          <td className="text-bold">Addition {dollarFormat(invoiceDetails?.adjustments?.addition_amount)}</td>
                          <td className="fw-bold">Discount {dollarFormat(invoiceDetails?.adjustments?.discount_amount)}</td>
                          <td></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              )}

              {invoiceDetails?.settlement && (
                <div className="d-flex py-2 flex-wrap time-entries p-2">
                  <div className="col-12 caption-bold mb-3">Settlement</div>
                  <div className="col-12 input-shadow p-2 mb-4 bg-white">
                    <table className="table table-striped mt-3">
                      <thead>
                        <tr>
                          <th>Medical Bill Balance</th>
                          <th>Total Amount to the Client</th>
                          <th>Total Legal Fee</th>
                          <th>Total Case Expenses</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{dollarFormat(invoiceDetails?.settlement?.balance_owed)}</td>
                          <td>{dollarFormat(invoiceDetails?.settlement?.check_to_client)}</td>
                          <td>{dollarFormat(invoiceDetails?.settlement?.attorney_fee_amount)}</td>
                          <td>{dollarFormat(invoiceDetails?.settlement?.total_case_expense)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              {invoiceDetails?.contingency?.contingency_data && invoiceDetails?.contingency?.contingency_data.length > 0 && (
                <div className="d-flex py-2 flex-wrap time-entries p-2">
                  <div className="col-12 caption-bold mb-3">Contingency Fee</div>
                  <div className="col-12 input-shadow p-2 mb-4 bg-white">
                    <div className="row">
                      <div className="col-12 col-md-6">Settlement Amount</div>
                      <div className="col-12 col-md-6">{dollarFormat(invoiceDetails?.contingency?.settled_amount)}</div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6">Litigation</div>
                      <div className="col-12 col-md-6">{invoiceDetails?.contingency?.is_litigation ? 'Yes' : 'No'}</div>
                    </div>
                    <table className="table table-striped mt-3">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Type</th>
                          <th>Notes</th>
                          <th>Quantity</th>
                          <th>Fee</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      {invoiceDetails?.contingency?.contingency_data.map((index) => {
                        return (
                          <tbody>
                            <tr>
                              <td>{index.date ? format(new Date(index.date), 'MM/dd/yyyy') : ''}</td>
                              <td>{index.type}</td>
                              <td>{index.notes}</td>
                              <td>{index.quantity}</td>
                              <td>{dollarFormat(index.rate)}</td>
                              <td>{dollarFormat(index.total)}</td>
                            </tr>
                          </tbody>
                        );
                      })}
                      <tfoot className="">
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>

                          <td className="text-bold">Total Amount</td>
                          <td></td>
                          <td className="text-bold">{dollarFormat(invoiceDetails?.contingency?.total_amount)}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              )}

              {invoiceDetails?.payment_plan?.payment_plan_data && invoiceDetails?.payment_plan?.payment_plan_data.length > 0 && (
                <div className="d-flex py-2 flex-wrap time-entries p-2">
                  <div className="col-12 caption-bold mb-3">Payment Plan</div>
                  <div className="col-12 col-md-12 input-shadow p-2 mb-4 bg-white">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Fee</th>
                        </tr>
                      </thead>
                      {invoiceDetails?.payment_plan?.payment_plan_data.map((index) => {
                        return (
                          <tbody>
                            <tr>
                              <td>{index.date ? format(new Date(index.date), 'MM/dd/yyyy') : ''}</td>
                              <td>{dollarFormat(index.amount)}</td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </table>
                  </div>
                </div>
              )}
              {invoiceDetails?.subscription?.subscription_data && invoiceDetails?.subscription?.subscription_data.due_date && (
                <div className="d-flex py-2 flex-wrap time-entries p-2">
                  <div className="col-12 caption-bold mb-3">Subscription Details</div>
                  <div className="col-12 input-shadow p-2 mb-4 bg-white">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Date</th>
                          <th>Notes</th>
                          <th>Due Date</th>
                          <th>Actual Amount</th>
                          {invoiceDetails?.subscription?.tax_percentage && <th>Amount (with Tax)</th>}
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>{invoiceDetails?.subscription?.subscription_data.frequency_of_payment}</td>
                          <td>{invoiceDetails?.subscription?.subscription_data.invoice_date}</td>
                          <td>{invoiceDetails?.subscription?.subscription_data.description}</td>
                          <td>
                            {invoiceDetails?.subscription?.subscription_data.due_date
                              ? format(new Date(invoiceDetails?.subscription?.subscription_data.due_date), 'MM/dd/yyyy')
                              : ''}
                          </td>
                          <td>{dollarFormat(invoiceDetails?.subscription?.subscription_data.rate)}</td>
                          {invoiceDetails?.subscription?.tax_percentage && (
                            <td>{dollarFormat(invoiceDetails?.subscription?.subscription_data.amount_with_tax)}</td>
                          )}
                        </tr>
                      </tbody>

                      <tfoot>
                        {invoiceDetails?.subscription?.total_amount && (
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            {invoiceDetails?.subscription?.tax_percentage ? (
                              <>
                                <td></td>
                                <td className="text-bold">Total Amount</td>
                                <td className="text-bold">{dollarFormat(invoiceDetails?.subscription?.total_amount)}</td>
                              </>
                            ) : (
                              <>
                                <td className="text-bold">Total Amount</td>
                                <td className="text-bold">{dollarFormat(invoiceDetails?.subscription?.total_amount)}</td>
                              </>
                            )}
                          </tr>
                        )}
                        {invoiceDetails?.subscription?.tax_percentage && (
                          <tr>
                            <td className="disclaimer">Tax Rate @ {invoiceDetails?.subscription?.tax_percentage}%</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        )}
                      </tfoot>
                    </table>
                  </div>
                </div>
              )}
              {invoiceDetails?.unpaid_invoices?.unpaid_invoice_data && invoiceDetails?.unpaid_invoices?.unpaid_invoice_data.length > 0 && (
                <div className="d-flex py-2 flex-wrap time-entries p-2">
                  <div className="col-12 caption-bold mb-3">Unpaid Invoice Balance Forward</div>
                  <div className="col-12 input-shadow p-2 mb-4 bg-white">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Invoice #</th>
                          <th>Balance Due</th>
                          <th>Amount Paid</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoiceDetails?.unpaid_invoices?.unpaid_invoice_data.map((index) => {
                          return (
                            <tr>
                              <td>{index.invoice_number}</td>
                              <td>{dollarFormat(index.due_amount)}</td>
                              <td>{dollarFormat(index.invoice_amount)}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              {invoiceDetails?.trust_account_data?.amount_paid && (
                <div className="d-flex py-2 flex-wrap time-entries p-2">
                  <div className="col-12 caption-bold mb-3">Available Trust Funds</div>
                  <div className="col-12 input-shadow p-2 mb-4 bg-white">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Client Name</th>
                          <th>Account</th>
                          <th>Amount Paid</th>
                          <th>Deposit Into</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{invoiceDetails?.trust_account_data?.client_name}</td>
                          <td>{invoiceDetails?.trust_account_data?.debit_account_name}</td>
                          <td>{dollarFormat(invoiceDetails?.trust_account_data?.amount_paid)}</td>
                          <td>{invoiceDetails?.trust_account_data?.credit_account_name}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              <div className="col-12 mt-3">
                {invoiceDetails?.retainer?.retainer_amount && (
                  <>
                    <div className="row">
                      <div className="col-12 col-md-6">Retainer Amount</div>
                      <div className="col-12 col-md-6">{dollarFormat(invoiceDetails?.retainer?.retainer_amount)}</div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-12 col-md-6">Retainer Date</div>
                      <div className="col-12 col-md-6">
                        {invoiceDetails?.retainer?.retainer_date
                          ? format(new Date(invoiceDetails?.retainer?.retainer_date), 'MM/dd/yyyy')
                          : ''}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="d-flex justify-content-end">
              <div className="col-12 col-md-4 F-size14">
                <div className="col-12 caption-bold mb-3">Total</div>
                <div className="input-shadow p-2 bg-white">
                  {invoiceDetails?.flat_fees?.total_amount > 0 && (
                    <div className="d-flex py-2">
                      <div className="col-6">Flat Fees Sub-Total</div>
                      <div className="col-6 text-bold">{dollarFormat(invoiceDetails?.flat_fees?.total_amount)}</div>
                    </div>
                  )}

                  {invoiceDetails?.time_entries?.total_amount_with_tax > 0 ||
                    (invoiceDetails?.time_entries?.total_amount > 0 && (
                      <div className="d-flex py-2">
                        <div className="col-6">Time Entries Sub-Total</div>
                        <div className="col-6 text-bold">
                          {dollarFormat(invoiceDetails?.time_entries?.total_amount_with_tax || invoiceDetails?.time_entries?.total_amount)}
                        </div>
                      </div>
                    ))}

                  {invoiceDetails?.expenses?.total_amount > 0 && (
                    <div className="d-flex py-2">
                      <div className="col-6">Expense Sub-Total</div>
                      <div className="col-6 text-bold">{dollarFormat(invoiceDetails?.expenses?.total_amount)}</div>
                    </div>
                  )}
                  {invoiceDetails.sub_total && invoiceDetails.sub_total > 0 && (
                    <div className="d-flex py-2">
                      <div className="col-6">Sub-Total</div>
                      <div className="col-6 text-bold">{dollarFormat(invoiceDetails.sub_total)}</div>
                    </div>
                  )}
                  {invoiceDetails?.unpaid_invoices?.total_amount && (
                    <div className="d-flex py-2">
                      <div className="col-6">Forwarded Sub-Total</div>
                      <div className="col-6 text-bold">{dollarFormat(invoiceDetails?.unpaid_invoices?.total_amount)}</div>
                    </div>
                  )}

                  {invoiceDetails?.adjustments?.discount_amount > 0 && (
                    <div className="d-flex py-2">
                      <div className="col-6">Discounts</div>
                      <div className="col-6 text-bold">{dollarFormat(invoiceDetails?.adjustments?.discount_amount)}</div>
                    </div>
                  )}
                  {invoiceDetails?.adjustments?.addition_amount > 0 && (
                    <div className="d-flex py-2">
                      <div className="col-6">Addition</div>
                      <div className="col-6 text-bold">{dollarFormat(invoiceDetails?.adjustments?.addition_amount)}</div>
                    </div>
                  )}

                  <div className="d-flex py-2">
                    <div className="col-6">Total</div>
                    <div className="col-6 text-bold">{dollarFormat(invoiceDetails.total_amount)}</div>
                  </div>
                  <div className="d-flex py-2">
                    <div className="col-6">Amount Paid</div>
                    <div className="col-6 text-bold">{dollarFormat(invoiceDetails.paid_amount)}</div>
                  </div>
                  <div className="d-flex py-2">
                    <div className="col-6">Balance Due</div>
                    <div className="col-6 text-bold">{dollarFormat(invoiceDetails.due_amount)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/*   </div> */}
    </div>
  );
};
