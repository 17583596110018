import React, { useState } from "react";
import { SpinnerComponent } from "shared/SpinnerComponent";

const useSpinner = (prop) => {
  const [visible, setVisible] = useState(false);

  const showSpinner = () => setVisible(true);
  const hideSpinner = () => setVisible(false);
  const spinner = visible ? <SpinnerComponent /> : null;

  return [spinner, showSpinner, hideSpinner];
};

export default useSpinner;
