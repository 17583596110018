import { actionTypes } from "constants/types";
let initialState = {
  isLoading: true,
  taskStageList: "",
  taskStageListError: "",
};

export default function TaskStageReducer(state = initialState, action={}) {
  switch (action.type) {
    case actionTypes.GET_TASKSTAGE_LIST_REQUEST:
      return {
        ...state,

        isLoading: true,
      };
    case actionTypes.GET_TASKSTAGE_LIST_SUCCESS:
      return {
        ...state,
        taskStageList: action.payload,

        isLoading: false,
      };
    case actionTypes.GET_TASKSTAGE_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        taskStageListError: action.payload,
      };

    default:
      return state;
  }
}
