import React from 'react';
import { Tooltip } from 'primereact/tooltip';

import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';

export default function GetSignature({ rowData }) {
  const { modals, setSelectedItem } = useFileManagerContext();

  return (
    <>
      <i
        className="icon-signature p-2 pointer float-end h2 mb-0"
        onClick={() => {
          setSelectedItem(rowData);
          modals.setShowSignatureModal(true);
        }}
      />
      <Tooltip content="Signature" position="top" target=".icon-signature" showDelay={500} />
    </>
  );
}
