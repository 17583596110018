import { actionTypes } from "constants/types";

let initialState = {
    isLoading:false,
    error:"",
    profile_image_url:''
}

export default function ProfileDetailReducer(state = initialState, action={}){

    switch(action.type){
        case actionTypes.UPDATE_PROFILE_IMAGE_START:
            return{
                ...state,
                isLoading:true,
                profile_image_url:action.payload
            }
        case actionTypes.UPDATE_PROFILE_IMAGE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                profile_image_url:action.payload
            }
        default:
            return state;
    }

}