import React from 'react';

import pdfImage from 'assets/images/pdf.png';
import folderImage from 'assets/images/folder.png';
import docImage from 'assets/images/doc.png';
import videoImage from 'assets/images/video.png';
import textImage from 'assets/images/text.png';
import xlsImage from 'assets/images/xls.png';
import fileImage from 'assets/images/file.png';

function Thumbnail({ item, selectFile }) {
  const returnFileType = (format) => {
    switch (format.toLowerCase()) {
      case 'pdf':
        return 'pdf';
      case 'docx':
      case 'doc':
        return 'doc';
      case 'png':
      case 'jpg':
      case 'jpeg':
        return 'image';
      case 'mp4':
      case 'avi':
      case 'mkv':
      case 'mov':
      case 'ogg':
        return 'video';
      case 'txt':
        return 'text';
      case 'xlsx':
      case 'xls':
        return 'xls';
      default:
        return 'folder';
    }
  };

  function getFileExtension(fileName) {
    if (fileName) {
      const lastIndex = fileName?.lastIndexOf('.');
      if (lastIndex === -1) {
        return '';
      } else {
        return fileName.substring(lastIndex + 1);
      }
    }
    return '';
  }

  return (
    <img
      src={
        item.entry_type === 'folder'
          ? folderImage
          : returnFileType(getFileExtension(item.display_name)) === 'image'
          ? item.url // Display the image
          : returnFileType(getFileExtension(item.display_name)) === 'doc'
          ? docImage
          : returnFileType(getFileExtension(item.display_name)) === 'video'
          ? videoImage
          : returnFileType(getFileExtension(item.display_name)) === 'pdf'
          ? pdfImage
          : returnFileType(getFileExtension(item.display_name)) === 'text'
          ? textImage
          : returnFileType(getFileExtension(item.display_name)) === 'xls'
          ? xlsImage
          : fileImage
      }
      alt={item.isFolder ? 'Folder' : 'File'}
      style={{
        ...thumbnailStyles,
        ...(returnFileType(getFileExtension(item.display_name)) === 'image' ? { width: '100%', height: '75%', padding: '8px' } : {}),
      }}
      onClick={() => selectFile(item)}
    />
  );
}

export default Thumbnail;

const thumbnailStyles = {
  width: '64px',
  height: '64px',
  margin: 'auto',
  borderRadius: '16px 16px 0px 0px',
  objectFit: 'cover',
};
