import { useEffect, useState } from 'react';
import { getNotificationCount, getNotifications } from 'services/notification/notificationService';
import Cookies from 'universal-cookie';

const cookie = new Cookies();
const useNotifications = () => {
  const [notificationCount, setNotificationCount] = useState();
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  const getFilteredNotifications = (notifications = []) => {
    let _notifications = notifications?.filter(
      (notification) => !['client_data_changed', 'firm_deactivated', 'dashboard']?.includes(notification?.notification_type)
    );

    return _notifications;
  };

  const loadNotifications = (params) => {
    const { notificationType = '', limit = 10, nextToken = '' } = params || {};
    setIsLoading(true);
    getNotifications(notificationType, limit, nextToken)
      .then((response) => {
        setIsLoading(false);
        if (response?.data?.notifications > 0) {
          response.data.notifications = response?.data?.notifications?.filter((item) => item?.notification_type !== 'dashboard');
        }

        setNotifications((prev) => {
          let notificationsList = [...response?.data?.notifications];
          if (prev?.notifications?.length) notificationsList = [...prev?.notifications, ...notificationsList];
          return {
            ...response?.data,
            // notifications: [...notificationsList],
            notifications: getFilteredNotifications([...notificationsList]),
          };
        });
      })
      .catch((error) => {
        console.log('error', error);
        setIsLoading(false);
        setNotifications([]);
      });
  };
  const loadNotificationCount = () => {
    getNotificationCount()
      .then((response) => {
        // console.log('response_notificationCount', response);
        setNotificationCount(response?.data);
      })
      .catch((error) => {
        console.log('error', error);
        setNotificationCount();
      });
  };

  const markasRead = (sk) => {
    setNotifications((preval) => {
      return {
        ...preval?.data,
        // notifications: [...preval?.notifications?.filter((item) => item?.sk !== sk)],
        notifications: getFilteredNotifications([...preval?.notifications?.filter((item) => item?.sk !== sk)]),
        total_notifications: Number(preval?.total_notifications - 1),
      };
    });
  };

  useEffect(() => {
    let tenantId = cookie.get('tenantId');
    let tenantSk = cookie.get('tenantSk');
    if (tenantId && tenantSk) {
      loadNotifications({});
      loadNotificationCount();
    }
  }, []);

  return {
    notificationCount,
    notifications,
    isLoading,
    loadNotifications,
    loadNotificationCount,
    setNotifications,
    setNotificationCount,
    markasRead,
    getFilteredNotifications,
  };
};

export default useNotifications;
