/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { CreatePaymentRequest } from './CreatePaymentRequest';
import { useSelector, useDispatch } from 'react-redux';
import { listPaymentRequests } from 'redux/actions/PaymentRequestAction';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { deletePaymentRequest } from 'services/billingServices';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import _ from 'lodash';
import { RecordPayment } from './RecordPayment';
import useSpinner from 'hooks/useSpinner';
import { Reminders } from './Reminders';
import { SendNotificationMail } from './SendNotificationMail';
import { useLocation } from 'react-router-dom';
import { dollarFormat, paginationDropdownOptions } from 'utils/utils';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { convertToTimezoneFormat, handleDateTimeOffset } from 'utils/utility_functions/timezone';
import { UserDetailsContext } from 'context/userDetailsContext';
import constants from 'constants/index';
import { handlePageNumberInPagination } from 'common/pagination';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

export const PaymentRequests = (props) => {
  const { isFromCaseSpecificModule, getCaseDetails, getTimeEntrySummarty, isFromGlobalBilling, handlePaymentRequestTableRedirection } =
    props || {};
  const [showModal, setShowModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [dateFilter, setDateFilter] = useState(null);
  const [showRecordModal, setShowRecordModal] = useState(false);
  const [confirmSendMail, setConfirmSendMail] = useState(false);
  const paymentRequests = useSelector((state) => state.paymentRequests.payment_requests);
  const isLoading = useSelector((state) => state.paymentRequests.isLoading);
  const next_Token = paymentRequests.next_token;
  const transition_Token = paymentRequests.transition_token;
  const history = useHistory();

  const dispatch = useDispatch();
  const { addToast } = useToast();
  const [spinner, showSpinner, hideSpinner] = useSpinner(true);
  const [showReminder, setShowReminder] = useState(false);
  const location = useLocation();
  const userContext = useContext(UserDetailsContext);
  const [selectedPractice, setSelectedPractice] = useState(null);
  const [pageLimit, setPageLimit] = useState(getPageLimit() ?? 10);
  const [isForward, setIsForward] = useState(true);
  //  const next_Token
  //  const transition_Token

  const practiceData = useSelector((state) => state.practiceArea.practiceAreaList['practice-area']);
  const [practiceAreaOptions, setPracticeAreaOptions] = useState([]);

  function getPageLimit() {
    if (props.isLeadSpecific && userContext) {
      return userContext?.firmDetails?.pagination_preference?.leads?.billings?.payments_request;
    } else if (isFromCaseSpecificModule && userContext) {
      return userContext?.firmDetails?.pagination_preference?.case?.billings?.payments_request;
    } else if (userContext) {
      return userContext?.firmDetails?.pagination_preference?.billings?.payments_request;
    }
  }

  function getFilePathForPagination() {
    if (props.isLeadSpecific && userContext) {
      return { module: 'leads', subModule: 'billings', listName: 'payments_request' };
    } else if (isFromCaseSpecificModule && userContext) {
      return { module: 'case', subModule: 'billings', listName: 'payments_request' };
    } else {
      return { module: 'billings', subModule: '', listName: 'payments_request' };
    }
  }

  useEffect(() => {
    if (practiceData && practiceData.length) {
      let practice_options = practiceData.map((val) => {
        return {
          label: val.practice_area_name,
          value: val.practice_area_name,
        };
      });
      setPracticeAreaOptions(practice_options);
    } else {
      setPracticeAreaOptions([]);
    }
  }, [practiceData]);

  useEffect(() => {
    loadPaymentRequests(getPageLimit() ?? 10, '');
    // if (localStorage.getItem("redirectToInvoice")) {
    //   setSelectedRequest();
    //   setShowModal(true);
    //   localStorage.removeItem("redirectToInvoice");
    // }
    if (localStorage.getItem('redirectToRequestPayment')) {
      setShowModal(true);
      localStorage.removeItem('redirectToRequestPayment');
    }
  }, []);

  useEffect(() => {
    if (location?.state && localStorage.getItem('viewPaymentRequest')) {
      setSelectedRequest(location?.state);
      setShowModal(true);
    }
    if (localStorage.getItem('viewPaymentRequest') && JSON.parse(window?.localStorage?.getItem('stateData'))) {
      setSelectedRequest(JSON.parse(window?.localStorage?.getItem('stateData')));
      setShowModal(true);
    }
    localStorage.removeItem('viewPaymentRequest');
  }, [location?.state, localStorage, window.localStorage]);

  useEffect(() => {
    if (isLoading) {
      showSpinner();
    } else {
      hideSpinner();
    }
  }, [isLoading]);

  const pickExtractedDate = (dateArray) => {
    return {
      payment_date_lower: dateArray?.[0]
        ? convertToTimezoneFormat(moment(dateArray[0]).startOf('day'), userContext?.userDetails.timezone)
        : '',
      payment_date_upper: dateArray?.[0]
        ? convertToTimezoneFormat(moment(dateArray[1] ? dateArray[1] : dateArray[0]).endOf('day'), userContext?.userDetails.timezone)
        : '',
    };
  };

  const loadPaymentRequests = (pageLimit = 10, pagination_token = '', pagination_direction = 'forward') => {
    let filters = {
      ...pickExtractedDate(dateFilter),
      payment_request_status: '',
      search_key: searchKey,
      practice_area: selectedPractice,
    };
    if (props.caseView) {
      filters.case_id = props?.caseDetails?.case_id;
    }
    dispatch(listPaymentRequests(filters, pageLimit, pagination_token, pagination_direction));
  };
  const onCreateSuccess = () => {
    setShowModal(false);
    setShowRecordModal(false);
    loadPaymentRequests(getPageLimit() ?? 10, '');
    typeof getTimeEntrySummarty === 'function' && getTimeEntrySummarty();
    if (
      (props?.caseDetails && !props?.caseDetails?.default_trust_account?.[0]?.account_id) ||
      !props?.caseDetails?.default_operating_account?.[0]?.account_id
    ) {
      typeof getCaseDetails === 'function' && getCaseDetails();
    }
  };

  // const sendNotificationMail = () => {
  //   if (selectedRequest) {
  //     setConfirmSendMail(false);
  //     sendPaymentRequestMail(selectedRequest.sk)
  //       .then(() => {
  //         addToast(
  //           false,
  //           toastConstant.toasterType.SUCCESS,
  //           toastConstant.api.SUCCESS,
  //           toastConstant.message.NOTIFY_PAYMENT_REQUEST_SUCCESS
  //         );
  //         //loadPaymentRequests();
  //       })
  //       .catch((err) => {
  //         addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.NOTIFY_PAYMENT_REQUEST_FAILED);
  //       });
  //   }
  // };

  const idBody = (rowData) => {
    return (
      <React.Fragment>
        <div
          onClick={() => {
            if (!(props.isCaseClosed || (props.isLeadSpecific && props.isLeadClosed))) {
              setSelectedRequest({ ...rowData });
              setShowModal(true);
            }
          }}
        >
          <span className="p-column-title text-break">ID</span>
          <span className={'text-break text-primary-dark text-decoration-none'} role={'button'}>
            {rowData.payment_request_number}
          </span>
        </div>
      </React.Fragment>
    );
  };

  const caseBody = (rowData, ...rest) => {
    const { case_id, is_lead } = rowData;
    const mainModuleName = is_lead ? '/leads' : '/cases';
    let route = case_id ? `${mainModuleName}/${case_id}` : mainModuleName;

    return (
      <React.Fragment>
        <span className="p-column-title text-break">Case</span>
        <span
          onClick={(e) => {
            handlePaymentRequestTableRedirection(e, rowData, route);
          }}
          // className="text-break pointer F-size14 text-bold call-back text-decoration-none"
          className="redirection-link-no-text-transform"
        >
          {rowData.case_name ? rowData.case_name : '-'}
        </span>
      </React.Fragment>
    );
  };

  const viewBody = (rowData) => {
    let viewed_status = rowData.viewed_status ? rowData.viewed_status : 'Not Viewed';

    if (viewed_status !== 'Not Viewed') {
      viewed_status = handleDateTimeOffset(userContext?.userDetails?.timezone, viewed_status, constants.month_date_year_time_12_format);
    }
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Viewed</span>
        <span className="text-break">{viewed_status}</span>
      </React.Fragment>
    );
  };

  const statusBody = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Status</span>
        <span className="text-break">{rowData.payment_request_status ? _.startCase(_.toLower(rowData.payment_request_status)) : ''}</span>
      </React.Fragment>
    );
  };

  const totalBody = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Total</span>
        <span className="text-break">{dollarFormat(rowData.amount)}</span>
      </React.Fragment>
    );
  };

  const dueBody = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Due</span>
        <span className="text-break">{dollarFormat(rowData.due_amount)}</span>
      </React.Fragment>
    );
  };
  const sentBody = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Sent</span>
        <span className="text-break">
          {handleDateTimeOffset(userContext?.userDetails?.timezone, rowData.payment_request_date, constants.month_date_year_format)}
        </span>
      </React.Fragment>
    );
  };

  const actionBody = (rowData) => {
    let due_amount = rowData?.due_amount;
    due_amount = due_amount ? parseInt(due_amount) : 0;
    return (
      <React.Fragment>
        <div>
          <span className="p-column-title text-break">Action</span>
          {due_amount > 0 && (
            <span
              role="button"
              onClick={(e) => {
                setSelectedRequest({ ...rowData });
                setConfirmSendMail(true);
              }}
              className={props.isCaseClosed || (props.isLeadSpecific && props.isLeadClosed) ? 'pe-none opacity-50' : ''}
            >
              <i className="icon-send-form" id="payReq"></i>
            </span>
          )}

          {due_amount > 0 && (
            <span role="button" className={props.isCaseClosed || (props.isLeadSpecific && props.isLeadClosed) ? 'pe-none opacity-50' : ''}>
              <i
                className="icon-radio ms-2"
                onClick={(e) => {
                  setSelectedRequest({ ...rowData });
                  setShowRecordModal(true);
                }}
                id="recordPay"
              ></i>
            </span>
          )}

          {due_amount > 0 && (
            <span
              role="button"
              onClick={(e) => {
                setSelectedRequest({ ...rowData });
                setShowReminder(true);
              }}
              className={props.isCaseClosed || (props.isLeadSpecific && props.isLeadClosed) ? 'pe-none opacity-50' : ''}
            >
              <i className="icon-notification ms-2" id="reminder"></i>
            </span>
          )}

          {rowData && !rowData.is_payment_record && (
            <span role="button" className={props.isCaseClosed || (props.isLeadSpecific && props.isLeadClosed) ? 'pe-none opacity-50' : ''}>
              <i
                className="icon-delete ms-2"
                onClick={(e) => {
                  setSelectedRequest({ ...rowData });
                  setConfirmDelete(true);
                }}
                id="delete"
              ></i>
            </span>
          )}
        </div>
      </React.Fragment>
    );
  };

  const deleteRequest = () => {
    if (selectedRequest) {
      setConfirmDelete(false);
      deletePaymentRequest(selectedRequest.sk, props.isLeadSpecific ? props?.isLeadSpecific : selectedRequest?.is_lead)
        .then(() => {
          addToast(
            false,
            toastConstant.toasterType.SUCCESS,
            toastConstant.api.SUCCESS,
            toastConstant.message.DELETE_PAYMENT_REQUEST_SUCCESS
          );
          loadPaymentRequests(getPageLimit() ?? 10, '');
        })
        .catch((err) => {
          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.DELETE_PAYMENT_REQUEST_FAILED);
        });
    }
  };

  const onSearchKeyDown = (e) => {
    let code = e.keyCode ? e.keyCode : e.which;
    if (code === 13) {
      dispatch(
        listPaymentRequests(
          {
            ...pickExtractedDate(dateFilter),
            payment_request_status: '',
            search_key: searchKey,
            practice_area: selectedPractice,
            case_id: props?.caseView ? props?.caseDetails?.case_id : '',
          },
          getPageLimit() ?? 10
        )
      );
    }
  };

  const onChangeSearch = (e) => {
    let value = e.target.value;
    setSearchKey(value);
    if (!value) {
      dispatch(
        listPaymentRequests(
          {
            ...pickExtractedDate(dateFilter),
            payment_request_status: '',
            search_key: '',
            practice_area: selectedPractice,
            case_id: props?.caseView ? props?.caseDetails?.case_id : '',
          },
          getPageLimit() ?? 10
        )
      );
    }
  };

  const onChangeCalendar = (e) => {
    setDateFilter(e.value);
    dispatch(
      listPaymentRequests(
        {
          ...pickExtractedDate(e.value),
          payment_request_status: '',
          search_key: searchKey,
          practice_area: selectedPractice,
          case_id: props?.caseView ? props?.caseDetails?.case_id : '',
        },
        getPageLimit() ?? 10
      )
    );
  };

  const onChangePractice = (e) => {
    setSelectedPractice([...e.value]);
    dispatch(
      listPaymentRequests(
        {
          ...pickExtractedDate(dateFilter),
          payment_request_status: '',
          search_key: searchKey,
          practice_area: [...e.value],
          case_id: props?.caseView ? props?.caseDetails?.case_id : '',
        },
        getPageLimit() ?? 10
      )
    );
  };

  const refreshCase = () => {
    setSearchKey('');
    setSelectedPractice();
    setDateFilter('');
    setSelectedRequest();
    dispatch(
      listPaymentRequests(
        {
          payment_date_upper: '',
          payment_date_lower: '',
          payment_request_status: '',
          search_key: '',
          practice_area: '',
          case_id: props?.caseView ? props?.caseDetails?.case_id : '',
        },
        getPageLimit() ?? 10
      )
    );
  };
  const paginatorTemplate = {
    layout: 'RowsPerPageDropdown PrevPageLink NextPageLink',
    RowsPerPageDropdown: (options) => {
      return (
        <React.Fragment>
          <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
            Item Per Page
          </span>
          <Dropdown value={options.value} options={paginationDropdownOptions} onChange={onPageChange} />
        </React.Fragment>
      );
    },
    NextPageLink: ({ iconClassName }) => {
      return (
        <Button
          onClick={onNextPageClick}
          disabled={next_Token === null && isForward}
          className={'p-paginator-next p-paginator-element p-link'}
          icon={iconClassName}
        />
      );
    },
    PrevPageLink: ({ iconClassName }) => {
      return (
        <Button
          onClick={onPrevPageClick}
          disabled={transition_Token === null || (transition_Token && next_Token === null && !isForward)}
          className={'p-paginator-next p-paginator-element p-link'}
          icon={iconClassName}
        />
      );
    },
  };

  const onNextPageClick = () => {
    loadPaymentRequests(pageLimit, !isForward ? transition_Token : next_Token, 'forward');
    setIsForward(true);
  };
  const onPrevPageClick = () => {
    loadPaymentRequests(pageLimit, isForward ? transition_Token : next_Token, 'reverse');
    setIsForward(false);
    //setPreviousToken(transition_Token);
  };
  const onPageChange = (event) => {
    setPageLimit(event.value);
    let path = getFilePathForPagination();
    handlePageNumberInPagination(userContext, path?.module, path?.subModule, path?.listName, event.value);
    loadPaymentRequests(event.value, '', 'forward');
  };

  return (
    <div>
      {spinner}
      {showModal && (
        <CreatePaymentRequest
          modal={showModal}
          setModal={setShowModal}
          onCreateSuccess={onCreateSuccess}
          selectedRequest={selectedRequest}
          caseView={props.caseView}
          isLeadSpecific={props?.isLeadSpecific}
          caseDetails={props.caseDetails}
          billingSummary={props.billingSummary}
          getCaseDetails={getCaseDetails}
        />
      )}
      <ConfirmDialog
        header="Confirm Delete"
        message={' Do you want to delete this payment request?'}
        visible={confirmDelete}
        rejectClassName="p-button-secondary outline"
        icon="pi pi-exclamation-triangle"
        className="confirm-delete-popup"
        accept={() => {
          deleteRequest();
          typeof getTimeEntrySummarty === 'function' && getTimeEntrySummarty();
        }}
        reject={() => {
          setConfirmDelete(false);
          setSelectedRequest();
        }}
        onHide={() => {
          setConfirmDelete(false);
          setSelectedRequest();
        }}
      />
      {/* <ConfirmDialog
       header="Confirm Send Notification"
        message={"Are you sure you want to send a notification mail?"}
        visible={confirmSendMail}
        accept={() => {
          sendNotificationMail();
        }}
        reject={() => {
          setConfirmSendMail(false);
          setSelectedRequest();
        }}
        onHide={()=>{setConfirmSendMail(false);
          setSelectedRequest();}}
      /> */}
      {showRecordModal ? (
        <RecordPayment
          show={showRecordModal}
          onHide={() => {
            setShowRecordModal(false);
          }}
          selectedRequest={selectedRequest}
          onSuccess={onCreateSuccess}
          isLeadSpecific={props?.isLeadSpecific}
          caseDetails={props?.caseDetails}
        />
      ) : null}

      {showReminder && (
        <Reminders
          show
          onHide={() => {
            setShowReminder(false);
          }}
          selectedRequest={selectedRequest}
          onSuccess={onCreateSuccess}
          isLeadSpecific={props?.isLeadSpecific}
        />
      )}
      <SendNotificationMail
        show={confirmSendMail}
        onHide={() => {
          setConfirmSendMail(false);
        }}
        selectedRequest={selectedRequest}
        isLeadSpecific={props?.isLeadSpecific}
      />
      <div className="row">
        <div className="col-12">
          <div className="d-flex align-items-center  mb-3">
            <Button
              label="Request Payment"
              icon="pi pi-plus"
              className="p-button p-button-primary ms-auto"
              onClick={() => {
                setSelectedRequest();
                setShowModal(true);
              }}
              disabled={props.isCaseClosed || (props.isLeadSpecific && props.isLeadClosed)}
            ></Button>
          </div>
        </div>
      </div>

      <div className="">
        <div className="d-flex flex-wrap align-items-center filter-wrap p-lg-0 p-2 mb-3 w-100 align-items-center justify-content-between">
          <div className="p-2 filter-wraper">
            <span className="p-input-icon-left input-shadow">
              <i className="icon-search F-size16"></i>
              <TextSnippetWrapper
                type="text"
                placeholder="Search"
                onChange={(e) => {
                  onChangeSearch(e);
                }}
                value={searchKey}
                onKeyDown={onSearchKeyDown}
                className="p-inputtext p-component input-search"
              />
            </span>
          </div>
          <div className="d-flex flex-wrap align-items-center p-2 filter-wraper">
            <label className="text-bold black-600 p-0 filter-label">Filters:</label>
            <div className="d-flex flex-column ms-2 filter-wraper">
              <Calendar
                className="input-shadow filter-calender"
                selectionMode="range"
                id="icon"
                value={dateFilter}
                onChange={(e) => {
                  onChangeCalendar(e);
                }}
                showIcon
                placeholder="Date"
              />
            </div>
            {!isFromCaseSpecificModule && (
              <div className="d-flex flex-column mx-lg-2 mx-0 filter-wraper">
                <MultiSelect
                  value={selectedPractice}
                  options={practiceAreaOptions}
                  onChange={onChangePractice}
                  placeholder="Practice Area"
                  maxSelectedLabels={1}
                  display="chip"
                  filter
                  className="input-shadow w-100 F-size14 input-width"
                  style={{ height: '40px' }}
                />
              </div>
            )}

            <div className="d-flex flex-column mx-2">
              <div className="pointer ms-0">
                <i className="fas fa-sync-alt" onClick={refreshCase}></i>
                <Tooltip content="Clear Filter" position="top" target=".fa-sync-alt" showDelay={500} />
              </div>
            </div>

            {/*  <div className="d-flex flex-column mx-2">
              <Dropdown
                options={statusOptions} onChange={onChangeStatus} value={selectedStatus} showClear
              />
            </div> */}

            {/* <div className="d-flex flex-column mx-2"></div> */}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 datatable-responsive">
          <DataTable
            value={paymentRequests.payment_request}
            className="p-datatable-responsive"
            paginator
            paginatorTemplate={paginatorTemplate}
            rows={pageLimit}
          >
            <Column header="ID" body={idBody} />
            {isFromGlobalBilling && <Column header="Case" body={caseBody} />}
            <Column header="Viewed" body={viewBody} />
            <Column header="Status" body={statusBody} />
            <Column header="Total" body={totalBody} />
            <Column header="Due" body={dueBody} />
            <Column header="Sent" body={sentBody} />
            <Column body={actionBody} />
          </DataTable>
          <Tooltip target={'#payReq'} position={'top'} showDelay={500} className="case-tooltip mb-3">
            Payment Request
          </Tooltip>
          <Tooltip target={'#recordPay'} position={'top'} showDelay={500} className="case-tooltip mb-3">
            Record Payment
          </Tooltip>
          <Tooltip target={'#reminder'} position={'top'} showDelay={500} className="case-tooltip mb-3">
            Reminder
          </Tooltip>
          <Tooltip target={'#delete'} position={'top'} showDelay={500} className="case-tooltip mb-3">
            Delete
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
