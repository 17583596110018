import { actionTypes } from "constants/types";

let initialState = {
    expense_types:[],
    isLoading:false,
    error:""
}

export default function ExpenseTypeListReducer(state = initialState, action={}){

    switch(action.type){
        case actionTypes.GET_EXPENSE_TYPE_LIST_STARTED:
            return{
                ...state,
                isLoading:true,
            }
        case actionTypes.GET_EXPENSE_TYPE_LIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                expense_types:action.data
            }
        case actionTypes.GET_EXPENSE_TYPE_LIST_FAILED:
            return{
                ...state,
                isLoading:false,
                error:action.error
            }

        default:
            return state;
    }

}