export const allTimeZoneList = [
  {
    value: 'Pacific/Midway',
    label: '(GMT-11:00) Midway Island, Samoa (Samoa Standard Time)',
    offset: -11,
    abbrev: 'SST',
    altName: 'Samoa Standard Time',
  },
  {
    value: 'Pacific/Honolulu',
    label: '(GMT-10:00) Hawaii (Hawaii-Aleutian Standard Time)',
    offset: -10,
    abbrev: 'HAST',
    altName: 'Hawaii-Aleutian Standard Time',
  },
  {
    value: 'America/Juneau',
    label: '(GMT-8:00) Alaska (Alaska Daylight Time)',
    offset: -8,
    abbrev: 'AKDT',
    altName: 'Alaska Daylight Time',
  },
  {
    value: 'America/Dawson',
    label: '(GMT-7:00) Dawson, Yukon (Mountain Standard Time)',
    offset: -7,
    abbrev: 'MST',
    altName: 'Mountain Standard Time',
  },
  {
    value: 'America/Phoenix',
    label: '(GMT-7:00) Arizona (Mountain Standard Time)',
    offset: -7,
    abbrev: 'MST',
    altName: 'Mountain Standard Time',
  },
  {
    value: 'America/Tijuana',
    label: '(GMT-7:00) Tijuana (Pacific Daylight Time)',
    offset: -7,
    abbrev: 'PDT',
    altName: 'Pacific Daylight Time',
  },
  {
    value: 'America/Los_Angeles',
    label: '(GMT-7:00) Pacific Time (Pacific Daylight Time)',
    offset: -7,
    abbrev: 'PDT',
    altName: 'Pacific Daylight Time',
  },
  {
    value: 'America/Boise',
    label: '(GMT-6:00) Mountain Time (Mountain Daylight Time)',
    offset: -6,
    abbrev: 'MDT',
    altName: 'Mountain Daylight Time',
  },
  {
    value: 'America/Chihuahua',
    label: '(GMT-6:00) Chihuahua, La Paz, Mazatlan (Mexican Pacific Standard Time)',
    offset: -6,
    abbrev: 'HNPMX',
    altName: 'Mexican Pacific Standard Time',
  },
  {
    value: 'America/Chicago',
    label: '(GMT-5:00) Central Time (Central Daylight Time)',
    offset: -5,
    abbrev: 'CDT',
    altName: 'Central Daylight Time',
  },
  {
    value: 'America/Bogota',
    label: '(GMT-5:00) Bogota, Lima, Quito (Colombia Standard Time)',
    offset: -5,
    abbrev: 'COT',
    altName: 'Colombia Standard Time',
  },
  {
    value: 'America/Lima',
    label: '(GMT-5:00) Pittsburgh (Peru Standard Time)',
    offset: -5,
    abbrev: 'PET',
    altName: 'Peru Standard Time',
  },
  {
    value: 'America/Detroit',
    label: '(GMT-4:00) Eastern Time (Eastern Daylight Time)',
    offset: -4,
    abbrev: 'EDT',
    altName: 'Eastern Daylight Time',
  },
  {
    value: 'America/Caracas',
    label: '(GMT-4:00) Caracas, La Paz (Venezuela Standard Time)',
    offset: -4,
    abbrev: 'VET',
    altName: 'Venezuela Standard Time',
  },
  {
    value: 'America/Santiago',
    label: '(GMT-4:00) Santiago (Chile Standard Time)',
    offset: -4,
    abbrev: 'CLT',
    altName: 'Chile Standard Time',
  },
  {
    value: 'America/Sao_Paulo',
    label: '(GMT-3:00) Brasilia (Brasilia Standard Time)',
    offset: -3,
    abbrev: 'BRT',
    altName: 'Brasilia Standard Time',
  },
  {
    value: 'America/Montevideo',
    label: '(GMT-3:00) Montevideo (Uruguay Standard Time)',
    offset: -3,
    abbrev: 'UYT',
    altName: 'Uruguay Standard Time',
  },
  {
    value: 'America/Argentina/Buenos_Aires',
    label: '(GMT-3:00) Buenos Aires, Georgetown (Argentina Standard Time)',
    offset: -3,
    abbrev: 'ART',
    altName: 'Argentina Standard Time',
  },
  {
    value: 'America/St_Johns',
    label: '(GMT-2:30) Newfoundland and Labrador (Newfoundland Daylight Time)',
    offset: -2.5,
    abbrev: 'HETN',
    altName: 'Newfoundland Daylight Time',
  },
  {
    value: 'America/Godthab',
    label: '(GMT-2:00) Greenland (West Greenland Summer Time)',
    offset: -2,
    abbrev: 'WGST',
    altName: 'West Greenland Summer Time',
  },
  {
    value: 'Atlantic/Cape_Verde',
    label: '(GMT-1:00) Cape Verde Islands (Cape Verde Standard Time)',
    offset: -1,
    abbrev: 'CVT',
    altName: 'Cape Verde Standard Time',
  },
  {
    value: 'Atlantic/Azores',
    label: '(GMT+0:00) Azores (Azores Summer Time)',
    offset: 0,
    abbrev: 'AZOST',
    altName: 'Azores Summer Time',
  },
  {
    value: 'Etc/GMT',
    label: '(GMT+0:00) UTC (British Standard Time)',
    offset: 0,
    abbrev: 'GMT',
    altName: 'British Standard Time',
  },
  {
    value: 'Africa/Casablanca',
    label: '(GMT+0:00) Casablanca, Monrovia (Morocco Standard Standard Time)',
    offset: 0,
    abbrev: 'WET',
    altName: 'Morocco Standard Standard Time',
  },
  {
    value: 'Europe/London',
    label: '(GMT+1:00) Edinburgh, London (British Summer Time)',
    offset: 1,
    abbrev: 'BST',
    altName: 'British Summer Time',
  },
  {
    value: 'Europe/Dublin',
    label: '(GMT+1:00) Dublin (Irish Standard Time)',
    offset: 1,
    abbrev: 'IST',
    altName: 'Irish Standard Time',
  },
  {
    value: 'Europe/Lisbon',
    label: '(GMT+1:00) Lisbon (Western European Summer Time)',
    offset: 1,
    abbrev: 'WEST',
    altName: 'Western European Summer Time',
  },
  {
    value: 'Atlantic/Canary',
    label: '(GMT+1:00) Canary Islands (Western European Summer Time)',
    offset: 1,
    abbrev: 'WEST',
    altName: 'Western European Summer Time',
  },
  {
    value: 'Africa/Algiers',
    label: '(GMT+1:00) West Central Africa (Central European Standard Time)',
    offset: 1,
    abbrev: 'CET',
    altName: 'Central European Standard Time',
  },
  {
    value: 'Europe/Belgrade',
    label: '(GMT+2:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague (Central European Summer Time)',
    offset: 2,
    abbrev: 'CEST',
    altName: 'Central European Summer Time',
  },
  {
    value: 'Europe/Sarajevo',
    label: '(GMT+2:00) Sarajevo, Skopje, Warsaw, Zagreb (Central European Summer Time)',
    offset: 2,
    abbrev: 'CEST',
    altName: 'Central European Summer Time',
  },
  {
    value: 'Europe/Brussels',
    label: '(GMT+2:00) Brussels, Copenhagen, Madrid, Paris (Central European Summer Time)',
    offset: 2,
    abbrev: 'CEST',
    altName: 'Central European Summer Time',
  },
  {
    value: 'Europe/Amsterdam',
    label: '(GMT+2:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (Central European Summer Time)',
    offset: 2,
    abbrev: 'CEST',
    altName: 'Central European Summer Time',
  },
  {
    value: 'Africa/Cairo',
    label: '(GMT+2:00) Cairo (Eastern European Standard Time)',
    offset: 2,
    abbrev: 'EET',
    altName: 'Eastern European Standard Time',
  },
  {
    value: 'Africa/Harare',
    label: '(GMT+2:00) Harare, Pretoria (Central Africa Standard Time)',
    offset: 2,
    abbrev: 'CAT',
    altName: 'Central Africa Standard Time',
  },
  {
    value: 'Europe/Berlin',
    label: '(GMT+2:00) Frankfurt (Central European Summer Time)',
    offset: 2,
    abbrev: 'CEST',
    altName: 'Central European Summer Time',
  },
  {
    value: 'Europe/Bucharest',
    label: '(GMT+3:00) Bucharest (Eastern European Summer Time)',
    offset: 3,
    abbrev: 'EEST',
    altName: 'Eastern European Summer Time',
  },
  {
    value: 'Europe/Helsinki',
    label: '(GMT+3:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (Eastern European Summer Time)',
    offset: 3,
    abbrev: 'EEST',
    altName: 'Eastern European Summer Time',
  },
  {
    value: 'Europe/Athens',
    label: '(GMT+3:00) Athens (Eastern European Summer Time)',
    offset: 3,
    abbrev: 'EEST',
    altName: 'Eastern European Summer Time',
  },
  {
    value: 'Asia/Jerusalem',
    label: '(GMT+3:00) Jerusalem (Israel Daylight Time)',
    offset: 3,
    abbrev: 'IDT',
    altName: 'Israel Daylight Time',
  },
  {
    value: 'Europe/Moscow',
    label: '(GMT+3:00) Istanbul, Minsk, Moscow, St. Petersburg, Volgograd (Moscow Standard Time)',
    offset: 3,
    abbrev: 'MSK',
    altName: 'Moscow Standard Time',
  },
  {
    value: 'Asia/Kuwait',
    label: '(GMT+3:00) Kuwait, Riyadh (Arabian Standard Time)',
    offset: 3,
    abbrev: 'AST',
    altName: 'Arabian Standard Time',
  },
  {
    value: 'Africa/Nairobi',
    label: '(GMT+3:00) Nairobi (East Africa Standard Time)',
    offset: 3,
    abbrev: 'EAT',
    altName: 'East Africa Standard Time',
  },
  {
    value: 'Asia/Baghdad',
    label: '(GMT+3:00) Baghdad (Arabian Standard Time)',
    offset: 3,
    abbrev: 'AST',
    altName: 'Arabian Standard Time',
  },
  {
    value: 'Asia/Tehran',
    label: '(GMT+3:30) Tehran (Iran Standard Time)',
    offset: 3.5,
    abbrev: 'IRST',
    altName: 'Iran Standard Time',
  },
  {
    value: 'Asia/Dubai',
    label: '(GMT+4:00) Abu Dhabi, Muscat (Gulf Standard Time)',
    offset: 4,
    abbrev: 'GST',
    altName: 'Gulf Standard Time',
  },
  {
    value: 'Asia/Baku',
    label: '(GMT+4:00) Baku, Tbilisi, Yerevan (Azerbaijan Standard Time)',
    offset: 4,
    abbrev: 'AZT',
    altName: 'Azerbaijan Standard Time',
  },
  {
    value: 'Asia/Kabul',
    label: '(GMT+4:30) Kabul (Afghanistan Standard Time)',
    offset: 4.5,
    abbrev: 'AFT',
    altName: 'Afghanistan Standard Time',
  },
  {
    value: 'Asia/Yekaterinburg',
    label: '(GMT+5:00) Ekaterinburg (Yekaterinburg Standard Time)',
    offset: 5,
    abbrev: 'YEKT',
    altName: 'Yekaterinburg Standard Time',
  },
  {
    value: 'Asia/Karachi',
    label: '(GMT+5:00) Islamabad, Karachi, Tashkent (Pakistan Standard Time)',
    offset: 5,
    abbrev: 'PKT',
    altName: 'Pakistan Standard Time',
  },
  {
    value: 'Asia/Kolkata',
    label: '(GMT+5:30) Chennai, Kolkata, Mumbai, New Delhi (India Standard Time)',
    offset: 5.5,
    abbrev: 'IST',
    altName: 'India Standard Time',
  },
  {
    value: 'Asia/Colombo',
    label: '(GMT+5:30) Sri Jayawardenepura (India Standard Time)',
    offset: 5.5,
    abbrev: 'IST',
    altName: 'India Standard Time',
  },
  {
    value: 'Asia/Kathmandu',
    label: '(GMT+5:45) Kathmandu (Nepal Standard Time)',
    offset: 5.75,
    abbrev: 'NPT',
    altName: 'Nepal Standard Time',
  },
  {
    value: 'Asia/Dhaka',
    label: '(GMT+6:00) Astana, Dhaka (Bangladesh Standard Time)',
    offset: 6,
    abbrev: 'BST',
    altName: 'Bangladesh Standard Time',
  },
  {
    value: 'Asia/Almaty',
    label: '(GMT+6:00) Almaty, Novosibirsk (East Kazakhstan Standard Time)',
    offset: 6,
    abbrev: 'ALMT',
    altName: 'East Kazakhstan Standard Time',
  },
  {
    value: 'Asia/Rangoon',
    label: '(GMT+6:30) Yangon Rangoon (Myanmar Standard Time)',
    offset: 6.5,
    abbrev: 'MMT',
    altName: 'Myanmar Standard Time',
  },
  {
    value: 'Asia/Bangkok',
    label: '(GMT+7:00) Bangkok, Hanoi, Jakarta (Indochina Standard Time)',
    offset: 7,
    abbrev: 'ICT',
    altName: 'Indochina Standard Time',
  },
  {
    value: 'Asia/Krasnoyarsk',
    label: '(GMT+7:00) Krasnoyarsk (Krasnoyarsk Standard Time)',
    offset: 7,
    abbrev: 'KRAT',
    altName: 'Krasnoyarsk Standard Time',
  },
  {
    value: 'Asia/Shanghai',
    label: '(GMT+8:00) Beijing, Chongqing, Hong Kong SAR, Urumqi (China Standard Time)',
    offset: 8,
    abbrev: 'CST',
    altName: 'China Standard Time',
  },
  {
    value: 'Asia/Kuala_Lumpur',
    label: '(GMT+8:00) Kuala Lumpur, Singapore (Malaysia Standard Time)',
    offset: 8,
    abbrev: 'MYT',
    altName: 'Malaysia Standard Time',
  },
  {
    value: 'Asia/Taipei',
    label: '(GMT+8:00) Taipei (Taipei Standard Time)',
    offset: 8,
    abbrev: 'CST',
    altName: 'Taipei Standard Time',
  },
  {
    value: 'Australia/Perth',
    label: '(GMT+8:00) Perth (Western Australia Standard Time)',
    offset: 8,
    abbrev: 'AWST',
    altName: 'Western Australia Standard Time',
  },
  {
    value: 'Asia/Irkutsk',
    label: '(GMT+8:00) Irkutsk, Ulaanbaatar (Irkutsk Standard Time)',
    offset: 8,
    abbrev: 'IRKT',
    altName: 'Irkutsk Standard Time',
  },
  {
    value: 'Asia/Seoul',
    label: '(GMT+9:00) Seoul (Korean Standard Time)',
    offset: 9,
    abbrev: 'KST',
    altName: 'Korean Standard Time',
  },
  {
    value: 'Asia/Tokyo',
    label: '(GMT+9:00) Osaka, Sapporo, Tokyo (Japan Standard Time)',
    offset: 9,
    abbrev: 'JST',
    altName: 'Japan Standard Time',
  },
  {
    value: 'Asia/Yakutsk',
    label: '(GMT+9:00) Yakutsk (Yakutsk Standard Time)',
    offset: 9,
    abbrev: 'YAKT',
    altName: 'Yakutsk Standard Time',
  },
  {
    value: 'Australia/Darwin',
    label: '(GMT+9:30) Darwin (Australian Central Standard Time)',
    offset: 9.5,
    abbrev: 'ACST',
    altName: 'Australian Central Standard Time',
  },
  {
    value: 'Australia/Adelaide',
    label: '(GMT+9:30) Adelaide (Central Australia Standard Time)',
    offset: 9.5,
    abbrev: 'ACST',
    altName: 'Central Australia Standard Time',
  },
  {
    value: 'Australia/Sydney',
    label: '(GMT+10:00) Canberra, Melbourne, Sydney (Eastern Australia Standard Time)',
    offset: 10,
    abbrev: 'AEST',
    altName: 'Eastern Australia Standard Time',
  },
  {
    value: 'Australia/Brisbane',
    label: '(GMT+10:00) Brisbane (Brisbane Standard Time)',
    offset: 10,
    abbrev: 'AEST',
    altName: 'Brisbane Standard Time',
  },
  {
    value: 'Australia/Hobart',
    label: '(GMT+10:00) Hobart (Eastern Australia Standard Time)',
    offset: 10,
    abbrev: 'AEST',
    altName: 'Eastern Australia Standard Time',
  },
  {
    value: 'Asia/Vladivostok',
    label: '(GMT+10:00) Vladivostok (Vladivostok Standard Time)',
    offset: 10,
    abbrev: 'VLAT',
    altName: 'Vladivostok Standard Time',
  },
  {
    value: 'Pacific/Guam',
    label: '(GMT+10:00) Guam, Port Moresby (Chamorro Standard Time)',
    offset: 10,
    abbrev: 'ChST',
    altName: 'Chamorro Standard Time',
  },
  {
    value: 'Asia/Magadan',
    label: '(GMT+11:00) Magadan, Solomon Islands, New Caledonia (Magadan Standard Time)',
    offset: 11,
    abbrev: 'MAGT',
    altName: 'Magadan Standard Time',
  },
  {
    value: 'Asia/Kamchatka',
    label: '(GMT+12:00) Kamchatka, Marshall Islands (Petropavlovsk-Kamchatski Standard Time)',
    offset: 12,
    abbrev: 'PETT',
    altName: 'Petropavlovsk-Kamchatski Standard Time',
  },
  {
    value: 'Pacific/Fiji',
    label: '(GMT+12:00) Fiji Islands (Fiji Standard Time)',
    offset: 12,
    abbrev: 'FJT',
    altName: 'Fiji Standard Time',
  },
  {
    value: 'Pacific/Auckland',
    label: '(GMT+12:00) Auckland, Wellington (New Zealand Standard Time)',
    offset: 12,
    abbrev: 'NZST',
    altName: 'New Zealand Standard Time',
  },
  {
    value: 'Pacific/Tongatapu',
    label: "(GMT+13:00) Nuku'alofa (Tonga Standard Time)",
    offset: 13,
    abbrev: 'TOT',
    altName: 'Tonga Standard Time',
  },
];

export const timeZonesToRemove = ['America/Regina', 'America/Mexico_City', 'America/Belize'];
