import React from 'react';

function FilesList({ filesWatcher, uploadingIndex, setValue }) {
  const removeFirstSlash = (itemPath = '') => {
    if (itemPath.charAt(0) === '/') {
      itemPath = itemPath.slice(1);
    }
    return itemPath;
  };

  const deleteFile = (index) => {
    let _files = [...filesWatcher];
    _files.splice(index, 1);
    setValue('files', _files);
  };

  return (
    <div className="file-list-container d-flex align-items-center py-2 flex-wrap">
      <div className="col-md-3 col-12"></div>
      <div className="col-md-9 col-12">
        {filesWatcher?.map((item, index) => {
          return (
            <div className="d-flex mb-3 pe-2 align-items-center justify-content-between" key={index}>
              <div className="d-flex align-items-center text-truncate" key={index}>
                <i className="icon-pdf fa-lg text-primary-dark"></i>
                <p className="mb-0 ms-2 text-primary-dark text-medium text-truncate">{removeFirstSlash(item?.path)}</p>
              </div>
              {uploadingIndex === index ? (
                <div>
                  <i className="fas fa-spinner fa-spin ms-2"></i>
                </div>
              ) : (
                <div className="d-flex align-items-center ms-2">
                  {item.completed ? (
                    <div>
                      <i onClick={() => deleteFile(index)} className="far fa-check-circle  ms-3  mt-1"></i>
                    </div>
                  ) : (
                    <i onClick={() => deleteFile(index)} className="icon-delete icon-red pointer"></i>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default FilesList;
