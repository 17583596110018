import React from 'react';

import { loadIcon } from 'utils/filesUtils';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import { presignedURLGet } from 'modules/file_manager/services';

function TitleTemplate({ rowData }) {
  const { modals, selectedItem, setPreviewList, setVersionFileUpload, setSelectedPreviewItem } = useFileManagerContext();

  const { setShowPreview } = modals;

  async function handleFileVersions() {
    setShowPreview(true);
    setVersionFileUpload(true);
    setSelectedPreviewItem(rowData);

    let arr = selectedItem?.versions;
    const _dataArray = await Promise.all(
      arr?.map(async (item) => {
        if (item.file_path) {
          let url_response = await presignedURLGet(item.file_path, item.version_id, false);
          return { ...item, url: url_response?.data.file_url };
        }
        return item;
      })
    );
    setPreviewList(_dataArray);
  }

  return (
    <React.Fragment>
      <div className="d-flex text-break flex-sm-row w-100" onClick={handleFileVersions}>
        <span className="p-column-title text-break">Title</span>
        <span className="d-flex pointer align-items-center">
          <span className="d-flex">
            {rowData?.entry_type === 'folder' ? (
              <i className="icon-folder fa-lg"></i>
            ) : (
              loadIcon(rowData?.display_name?.split('.')?.reverse()[0])
            )}
          </span>
          <span className="ms-2 table-title"> {rowData.display_name} </span>
        </span>
      </div>
    </React.Fragment>
  );
}

export default TitleTemplate;
