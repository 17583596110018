export const billableExpenseBody = (params) => {
  const { rowData, props, onChangeBillable, disableExpense } = params || {};
  return (
    <>
      <span className="p-column-title text-break">Billable</span>
      <label className="switch">
        <input
          type="checkbox"
          checked={rowData.billable}
          name="non_billable"
          onChange={(e) => {
            let index = props.rowIndex;
            onChangeBillable(e, index);
          }}
          disabled={disableExpense}
        />
        <span className="slider round"></span>
      </label>
    </>
  );
};
