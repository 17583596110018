import React, { useContext } from 'react';

import CalendarTotalCount from './CalendarTotalCount';
import CalendarFilter from './CalendarFilter';
import SettingsAndCreateEventButton from './SettingsAndCreateEventButton';

import { useCalendarContext } from 'modules/calendar/Context/CalendarContext';
import { UserDetailsContext } from 'context/userDetailsContext';

function CalendarHeader() {
  const { isClientCalendar, isClosed } = useCalendarContext();
  const userContext = useContext(UserDetailsContext);

  return (
    <div className="mx-0">
      <div className="col-12">
        <CalendarTotalCount />

        <div className="d-flex align-items-center justify-content-between flex-wrap mb-3 calendar-filter">
          {!isClientCalendar && <CalendarFilter />}
          {isClientCalendar && userContext?.clientCaseList.length > 1 && <CalendarFilter />}
          {!isClientCalendar && (
            <div className="d-flex align-items-center direction-column">{!isClosed && <SettingsAndCreateEventButton />}</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CalendarHeader;
