const { default: ImageComponent } = require('shared/ImageComponent');

export const userOptionTemplate = (option) => {
  return (
    <div className="country-item d-flex align-items-center">
      <ImageComponent
        filePath={option.profile_image}
        fileName={option.first_name}
        fileSize="small.jpg"
        style={{
          border: `2px solid ${option?.calendar_color ? option?.calendar_color : '#ffffff'}`,
        }}
      />
      {option?.firm_user_id && <i class="fas fa-user-tie me-2"></i>}
      <div>{option.first_name + ' ' + option.last_name}</div>
    </div>
  );
};

export const selectedUserTemplate = (option) => {
  if (option) {
    return (
      <div className="country-item country-item-value">
        <div>{option.first_name + ' ' + option.last_name}</div>
      </div>
    );
  }
  return 'Select Users';
};
