import { actionTypes } from "constants/types";
let initialState = {
  isLoading: true,
  taskList: "",
  taskListError: "",
};

export default function TaskReducer(state = initialState, action={}) {
  switch (action.type) {
    case actionTypes.GET_TASK_LIST_REQUEST:
      return {
        ...state,

        isLoading: true,
      };
    case actionTypes.GET_TASK_LIST_SUCCESS:
      return {
        ...state,
        taskList: action.payload,

        isLoading: false,
      };
    case actionTypes.GET_TASK_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        taskListError: action.payload,
      };

    case actionTypes.RESET_TASK_LIST:
      return {
        ...state,
        isLoading: false,
        taskList:"",
      };

    default:
      return state;
  }
}
