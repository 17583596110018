import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AutoComplete } from 'primereact/autocomplete';
import { Dialog } from 'primereact/dialog';
import { Chip } from 'primereact/chip';
import { Button } from 'primereact/button';

import { UserDetailsContext } from 'context/userDetailsContext';
import { useToast } from 'context/ToastContext';

import { toastConstant } from 'constants/toastmessage';

import { updateMessageCase } from 'services/messageServices';
import { generateFullName } from 'utils/generateFullNameUtils';
import { handleRedirection } from 'utils/utils';

function InviteDialog({
  showInviteUsers,
  selectedUser,
  filteredUserList,
  caseUserClients,
  setFilteredUserList,
  messageDetails,
  setSelectedUser,
  checkCaseRelatedUser,
  usersLinkedToCaseOfSelectedMessage,
  setUsersNotInChangedCase,
  selectedCase,
  usersNotInChangedCase,
  showConfirmDialog,
  setShowConfirmDialog,
  setShowInviteUsers,
  setInviteInitiated,
  handleInvitingUser,
  fetchCaseMessageDetails,
  setSelectedCase,
}) {
  const history = useHistory();

  useEffect(() => {
    setFilteredUserList([]);
    setSelectedUser([]);
  }, [showInviteUsers]);

  const [userError, setUserError] = useState('');

  const { addToast } = useToast();

  const userContext = useContext(UserDetailsContext);

  const searchUser = (event) => {
    setTimeout(() => {
      let _filteredUsers;
      if (!event.query.trim().length) {
        _filteredUsers = [...caseUserClients];
      } else {
        _filteredUsers = caseUserClients.filter((user) => {
          let name = generateFullName(user);

          user.recipient_name = name;
          user.fullName = name || user?.group_name;
          user.recipient_id = user.client_cognito_username;

          return (
            user.fullName.toLowerCase().includes(event.query.toLowerCase()) ||
            user?.group_name?.toLowerCase().includes(event?.query?.toLowerCase())
          );
        });
      }
      setFilteredUserList(_filteredUsers);
    }, 250);
  };

  function onSelectUser(e) {
    if (e?.value?.res_type === 'user-group') {
      const selectedGroup = e.value;

      const groupsInConversation = messageDetails?.groups;

      if (groupsInConversation.length > 0) {
        const checkGroupIsExist = groupsInConversation.some((group) => group?.group_id === selectedGroup?.user_group_id);

        if (checkGroupIsExist) {
          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, 'This group is already exists');
          let remainingUsers = selectedUser?.filter((user, key) => {
            return user?.user_group_id !== selectedGroup?.user_group_id;
          });

          setSelectedUser(remainingUsers);
          return;
        }
      }
    } else {
      let logginedUser = userContext?.firmDetails?.current_user;
      let usersInConversation = messageDetails?.message_to;

      if (e.value?.client_cognito_username === logginedUser) {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, 'You cannot invite yourself');
        let remainingUsers = selectedUser?.filter((user, key) => {
          return user?.client_cognito_username !== logginedUser;
        });

        setSelectedUser(remainingUsers);
        return;
      }

      if (usersInConversation?.length) {
        let result = usersInConversation?.find((usr, key) => {
          return e.value?.recipient_id === usr?.recipient_id && !usr.is_group_user;
        });

        if (result) {
          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, 'User already exists');
          let remainingUsers = selectedUser?.filter((user, key) => {
            return user?.recipient_id !== result?.recipient_id;
          });

          setSelectedUser(remainingUsers);
          return;
        }
      }
    }
  }

  const updateCaseDetailOnChange = async () => {
    let data = {
      case_id: selectedCase?.id ? selectedCase?.id : '',
      case_name: selectedCase?.case_name ? selectedCase?.case_name : '',
    };

    updateMessageCase(messageDetails?.pk, data)
      .then((response) => {
        if (response.data) {
          fetchCaseMessageDetails();
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, response?.data);
        }
      })
      .catch((err) => {
        console.log('error', err);
        setSelectedCase('');

        let errorMsg = err?.response?.data ? err?.response?.data : toastConstant.message.CASE_UPDATED_FAILED;

        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, errorMsg);
      });
  };

  //dialog header
  const dialogHeader = () => {
    let header = '';

    if (showInviteUsers) {
      header = 'Invite Users';
    } else {
      header = 'User Conflict';
    }
    return header;
  };

  function inviteSubmit() {
    if (showInviteUsers) {
      if (selectedUser?.length === 0) {
        setUserError('Select User');
        return;
      }
      checkCaseRelatedUser(selectedUser, usersLinkedToCaseOfSelectedMessage);

      if (selectedUser?.length > 0 && usersNotInChangedCase?.length > 0) {
        setShowConfirmDialog(false);
        setInviteInitiated(true);
      } else {
        //calling invite API
        if (selectedUser?.length > 0) {
          handleInvitingUser();
        }
      }
    } else {
      setShowConfirmDialog(false);
      updateCaseDetailOnChange();
    }
  }

  //render footer
  const renderFooter = (name) => {
    return (
      <div>
        <Button
          label="Cancel"
          onClick={() => {
            setShowConfirmDialog(false);
            setShowInviteUsers(true);
            setSelectedUser([]);
            setUserError('');
          }}
          className="p-button-text p-button-secondary outline"
        />
        <Button label={showInviteUsers ? 'Invite Users' : 'Yes, Change Case'} icon="pi pi-check" onClick={inviteSubmit} autoFocus />
      </div>
    );
  };

  function removeUser(item) {
    let usersArr = selectedUser?.filter((usr) => {
      return usr?.recipient_id !== item?.recipient_id;
    });

    setSelectedUser(usersArr);
    if (usersArr?.length === 0) {
      setUsersNotInChangedCase([]);
    }
  }

  const assignToItemTemplate = (option) => {
    return (
      <>
        {option?.firm_user_id && <i class="fas fa-user-tie me-2"></i>}
        {option?.fullName}
      </>
    );
  };

  return (
    <Dialog
      className="common-popup-style"
      header={dialogHeader()}
      visible={showConfirmDialog}
      style={{ width: '40vw' }}
      footer={renderFooter('displayBasic2')}
      onHide={() => {
        setShowConfirmDialog(false);
        setSelectedUser([]);
        setUserError('');
      }}
    >
      {showInviteUsers ? (
        <>
          <p>Type the name of users to invite to this conversation</p>
          <AutoComplete
            name="user"
            placeholder="Search User"
            value={selectedUser}
            suggestions={filteredUserList}
            completeMethod={searchUser}
            field="fullName"
            className="w-100 new-task-popup invite-user mb-2 input-shadow capitalize-text"
            multiple
            onSelect={(e) => onSelectUser(e)}
            onChange={(e) => {
              setSelectedUser(e.value);
              setUserError('');
              checkCaseRelatedUser(e.value, usersLinkedToCaseOfSelectedMessage);
            }}
            itemTemplate={assignToItemTemplate}
          />
          {userError ? <span className="text-danger">{userError}</span> : null}

          <div className="d-flex mt-2 flex-wrap">
            {selectedUser?.length > 0
              ? selectedUser?.map((item, key) => (
                  <React.Fragment key={item?.recipient_id || item?.user_group_id}>
                    <Chip
                      label={item?.fullName}
                      className="custom-chip tag p-mb-2 me-2 my-1 capitalize-text"
                      removable
                      onRemove={() => removeUser(item)}
                    />
                  </React.Fragment>
                ))
              : null}
          </div>
        </>
      ) : (
        <>
          <p>
            The following users are not linked to the case :{' '}
            <span
              className="fw-bold text-primary-dark pointer"
              onClick={() => {
                const url = `/cases/${selectedCase?.id}`;
                handleRedirection(history, url);
              }}
            >
              {selectedCase?.case_name ? selectedCase?.case_name : '---'}
            </span>
          </p>
          <ul>
            {usersNotInChangedCase?.length
              ? usersNotInChangedCase?.map((user, key) => {
                  return (
                    <li key={key} className="capitalize-text">{`${user?.recipient_name ? user?.recipient_name : ''} (${
                      user?.access_level ? user?.access_level : 'Client'
                    })`}</li>
                  );
                })
              : null}
          </ul>
          <p>Do you still want to change the case for this message</p>
        </>
      )}
    </Dialog>
  );
}

export default InviteDialog;
