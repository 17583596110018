import { paymentStructure } from '../configs/create_invoice_configs';
import { countriesList, stateList } from 'constants/stateList';

export const getFormattedCountryList = () => {
  let countries = [];

  if (countriesList?.length > 0) {
    countries = countriesList?.map((country) => {
      return {
        label: country?.name,
        value: country?.name,
      };
    });
  }

  return countries;
};

export const getFormattedStateList = () => {
  let states = [];

  if (stateList?.length > 0) {
    states = stateList?.map((state) => {
      return {
        label: state?.abbreviation + ' - ' + state?.name,
        value: state?.abbreviation + ' - ' + state?.name,
      };
    });
  }

  return states;
};

export const convertPaymentStructuresToLowerCase = () => {
  let paymentStructures = { ...paymentStructure };
  for (let key in paymentStructures) {
    paymentStructures[key] = paymentStructures[key].toLowerCase();
  }
  return paymentStructures;
};
