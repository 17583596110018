import _constants from 'constants/index';
import { listUsers } from 'services/teamManagement.service';

export const getUserList = (
  status,
  limit = 10,
  pagination_token = '',
  pagination_direction = 'forward',
  keyword = '',
  next = '',
  loadMore = false,
  is_include_contacts = false,
  is_detailed = false,
  is_confirmed_user = true,
  is_include_groups = false
) => {
  return (dispatch) => {
    dispatch(getUserListStarted());

    listUsers(
      status,
      limit,
      pagination_token,
      pagination_direction,
      keyword,
      next,
      is_include_contacts,
      is_detailed,
      false,
      is_include_groups
    )
      .then((response) => {
        let _next = '';
        if (response.data && response.data.next_page) _next = response.data.next_page;
        dispatch(getUserListSuccess(response.data, _next, loadMore));
      })
      .catch((err) => {
        dispatch(getUserListFailure(err.message));
      });
  };
};

const getUserListSuccess = (data, next, loadMore) => ({
  type: _constants.USER_LIST__SUCCESS,
  data: data,
  next: next,
  loadMore: loadMore,
});

const getUserListStarted = () => ({
  type: _constants.USER_LIST_STARTED,
});

const getUserListFailure = (error) => ({
  type: _constants.USER_LIST__FAILURE,
  error: error,
});
