import React, { useContext } from "react";
import { Button } from "primereact/button";

import constants from "constants/index";
import { useSecureMessageContext } from "../Context/SecureMessageContext";
import { UserDetailsContext } from "context/userDetailsContext";

function SecureMessageHeader({ setShow }) {
  const { isLeadSpecific, isGlobalMessagesScreen, isCaseClosed, isLeadClosed } =
    useSecureMessageContext();

  const userContext = useContext(UserDetailsContext);

  return (
    <div className="d-flex align-items-center">
      {isGlobalMessagesScreen && (
        <>
          <div className="col-6 d-flex">
            <h1 className="title gray-800 mb-0">Messages</h1>
          </div>
        </>
      )}
      <div
        className={
          isGlobalMessagesScreen
            ? "col-6 d-flex justify-content-end"
            : "col-12 d-flex justify-content-end"
        }
      >
        <div className="col-12 d-flex justify-content-end">
          {userContext.getPermission("messages") ===
            constants.permissions.WRITE && (
            <Button
              label="Create Message"
              className="btn-outline p-button-primary"
              icon="pi pi-plus"
              type="button"
              onClick={() => setShow(true)}
              disabled={isCaseClosed || (isLeadClosed && isLeadSpecific)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default SecureMessageHeader;
