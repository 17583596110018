/**
 * The GlobalLoader component is a React component that displays a spinner component when there is an
 * ongoing Axios request.
 * @returns The GlobalLoader component is returning either the SpinnerComponent or an empty string
 * based on the value of the loading variable. If loading is true, the SpinnerComponent is rendered,
 * otherwise, an empty string is rendered.
 */
import useAxiosLoader from 'hooks/useAxiosLoader';
import { SpinnerComponent } from 'shared/SpinnerComponent';

const GlobalLoader = () => {
  const [loading] = useAxiosLoader();
  return <>{loading ? <SpinnerComponent /> : ''}</>;
};

export default GlobalLoader;
