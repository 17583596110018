import { performRequestSecond } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';
import { getTenantDetails } from 'utils/utils';

export const createAccount = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.createAccount}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestSecond(apiEndPoints.methodType.POST, url, data);
};

export const updateAccount = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.updateAccount}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestSecond(apiEndPoints.methodType.PUT, url, data);
};

export const getAccount = (pk, sk) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.getAccount}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&sk=${sk}&pk=${pk}`;
  return performRequestSecond(apiEndPoints.methodType.GET, url);
};

export const listAccount = (pageLimit, pagination_token = '', pagination_direction = 'forward', next = '', payloadData) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints?.listAccount}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${pageLimit}&next=${next}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`;
  return performRequestSecond(apiEndPoints?.methodType?.POST, url, payloadData);
};

export const deleteAccount = (pk, sk) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.deleteAccount}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&sk=${sk}&pk=${pk}`;
  return performRequestSecond(apiEndPoints.methodType.DELETE, url);
};

export const getUserProfile = () => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.user.getProfile}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestSecond(apiEndPoints.methodType.GET, url);
};

export const updateUserProfile = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.user.updateProfile}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestSecond(apiEndPoints.methodType.PATCH, url, data);
};
