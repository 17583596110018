import { performRequestSecond, performRequestThird } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';
import { getTenantDetails } from 'utils/utils';

export const listExpenses = (filters, pageLimit = 10, pagination_token = '', pagination_direction = 'forward', next = '') => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.listExpenses}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&next=${next}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`;
  return performRequestSecond(apiEndPoints.methodType.POST, url, filters);
};

export const createExpense = (data, is_lead = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.createExpense}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${is_lead ? `&is_lead=${is_lead}` : ''}`;
  return performRequestSecond(apiEndPoints.methodType.POST, url, data);
};

export const getExpenseDetails = (expense_sk) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.getExpense}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&expense_sk=${expense_sk}`;
  return performRequestSecond(apiEndPoints.methodType.GET, url);
};

export const updateExpense = (data, is_lead = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.updateExpense}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${is_lead ? `&is_lead=${is_lead}` : ''}`;
  return performRequestSecond(apiEndPoints.methodType.PUT, url, data);
};

export const deleteExpense = (expense_sk, is_lead = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.deleteExpense}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&expense_sk=${expense_sk}${
    is_lead ? `&is_lead=${is_lead}` : ''
  }`;
  return performRequestSecond(apiEndPoints.methodType.DELETE, url);
};

export const createExpenseType = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.createExpenseType}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestSecond(apiEndPoints.methodType.POST, url, data);
};

export const getExpenseTypeDetails = (pk, sk) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.getExpenseType}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&sk=${sk}&pk=${pk}`;
  return performRequestSecond(apiEndPoints.methodType.GET, url);
};

export const updateExpenseType = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.updateExpenseType}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestSecond(apiEndPoints.methodType.PUT, url, data);
};

export const listExpenseType = (filters, pageLimit, pagination_token, pagination_direction, next = '') => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.listExpenseType}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${pageLimit}&next=${next}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`;
  return performRequestSecond(apiEndPoints.methodType.POST, url, filters);
};

export const deleteExpenseType = (pk, sk) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.deleteExpenseType}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&pk=${pk}&sk=${sk}`;
  return performRequestSecond(apiEndPoints.methodType.DELETE, url);
};

export const listPaymentStructure = (filters, limit = 1000, next = '') => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.listPaymentStructure}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${limit}&next=${next}`;
  return performRequestSecond(apiEndPoints.methodType.POST, url, filters);
};

export const updatePaymentStructure = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.updatePaymentStructure}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestSecond(apiEndPoints.methodType.PUT, url, data);
};

export const createTax = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.createTax}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestSecond(apiEndPoints.methodType.POST, url, data);
};

export const updateTax = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.updateTax}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestSecond(apiEndPoints.methodType.PUT, url, data);
};

export const listTax = () => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.listTax}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestSecond(apiEndPoints.methodType.GET, url);
};

export const createPaymentRequest = (data, is_lead = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.createPaymentRequest}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${is_lead ? `&is_lead=${is_lead}` : ''}`;
  return performRequestSecond(apiEndPoints.methodType.POST, url, data);
};

export const updatePaymentRequest = (data, is_lead = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.updatePaymentRequest}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${is_lead ? `&is_lead=${is_lead}` : ''}`;
  return performRequestSecond(apiEndPoints.methodType.PUT, url, data);
};

export const getPaymentRequest = (payment_request_sk) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.getPaymentRequest}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&payment_request_sk=${payment_request_sk}`;
  return performRequestSecond(apiEndPoints.methodType.GET, url);
};

export const deletePaymentRequest = (payment_request_sk, is_lead = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.deletePaymentRequest}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&payment_request_sk=${payment_request_sk}${
    is_lead ? `&is_lead=${is_lead}` : ''
  }`;
  return performRequestSecond(apiEndPoints.methodType.DELETE, url);
};

export const listPaymentRequest = (data, pageLimit, pagination_token, pagination_direction) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.listPaymentRequest}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}
  `;
  return performRequestSecond(apiEndPoints.methodType.POST, url, data);
};

export const createPaymentRecord = (data, payment_request_sk, isLead = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.createPaymentRecord}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&payment_sk=${payment_request_sk}&is_lead=${isLead}`;
  return performRequestSecond(apiEndPoints.methodType.POST, url, data);
};

export const getPaymentRecord = (payment_record_sk) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.getPaymentRecord}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&payment_record_sk=${payment_record_sk}`;
  return performRequestSecond(apiEndPoints.methodType.GET, url);
};

export const listPaymentRecord = (data, pageLimit = 10, pagination_token = '', pagination_direction = 'forward', next = '') => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.listPaymentRecord}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${pageLimit}&next=${next}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}
  `;
  return performRequestSecond(apiEndPoints.methodType.POST, url, data);
};

export const updatePaymentRecord = (data, isLead = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.updatePaymentRecord}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&is_lead=${isLead}`;
  return performRequestSecond(apiEndPoints.methodType.PUT, url, data);
};

export const deletePaymentRecord = (payment_record_sk, is_lead = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.deletePaymentRecord}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&payment_record_sk=${payment_record_sk}${
    is_lead ? `&is_lead=${is_lead}` : ''
  }`;
  return performRequestSecond(apiEndPoints.methodType.DELETE, url);
};

export const createPaymentRefund = (data, payment_record_sk, is_lead = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.createPaymentRefund}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&payment_record_sk=${payment_record_sk}${
    is_lead ? `&is_lead=${is_lead}` : ''
  }`;
  return performRequestSecond(apiEndPoints.methodType.POST, url, data);
};

export const sendPaymentRequestMail = (payment_request_sk, data, invoiceView = false, is_lead = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = '';
  if (invoiceView) {
    url = `${apiEndPoints.sendInvoiceEmail}?tenant_id=${tenantId}&tenant_sk=${tenantSk}"&invoice_sk=${payment_request_sk}${
      is_lead ? `&is_lead=${is_lead}` : ''
    }`;
  } else {
    url = `${apiEndPoints.sendRequestMail}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&payment_request_sk=${payment_request_sk}${
      is_lead ? `&is_lead=${is_lead}` : ''
    }`;
  }
  return performRequestSecond(apiEndPoints.methodType.POST, url, data);
};

export const getTrustAccountBalance = (trust_account_id, case_id) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.getTrustAccountBalance}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&trust_account_id=${
    trust_account_id ? trust_account_id : ''
  }${case_id ? `&case_id=${case_id}` : ''}`;
  return performRequestSecond(apiEndPoints.methodType.GET, url);
};

export const createPaymentReminder = (payment_request_sk, data, is_lead = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.createPaymentReminder}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&payment_sk=${payment_request_sk}&is_lead=${is_lead}`;
  return performRequestSecond(apiEndPoints.methodType.POST, url, data);
};

export const updatePaymentReminder = (data, payment_request_sk, is_lead = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.updatePaymentReminder}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&payment_sk=${payment_request_sk}&is_lead=${is_lead}`;
  return performRequestSecond(apiEndPoints.methodType.PUT, url, data);
};

export const createInvoice = (data, is_lead = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.createInvoice}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${is_lead ? `&is_lead=${is_lead}` : ''}`;
  return performRequestSecond(apiEndPoints.methodType.POST, url, data);
};

export const listInvoice = (filter, pageLimit = 10, pagination_token = '', pagination_direction = 'forward', next = '') => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.listInvoice}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${pageLimit}&next=${next}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`;
  return performRequestSecond(apiEndPoints.methodType.POST, url, filter);
};

export const getInvoiceNumber = () => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.getInvoiceNumber}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestSecond(apiEndPoints.methodType.GET, url);
};

export const getClientPaymentDetails = (contact_id, data, pageLimit = 10, pagination_token = '', pagination_direction = 'forward') => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.getClientPaymentDetails}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&contact_id=${contact_id}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`;
  return performRequestSecond(apiEndPoints.methodType.POST, url, data);
};

export const getInvoice = (invoice_sk) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.getInvoice}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&invoice_sk=${invoice_sk}`;
  return performRequestSecond(apiEndPoints.methodType.GET, url);
};

export const getClientInvoiceList = (contact_id, data, pageLimit = 10, pagination_token = '', pagination_direction = 'forward') => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.listClientInvoices}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&contact_id=${contact_id}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`;
  return performRequestSecond(apiEndPoints.methodType.POST, url, data);
};

export const getClientBillingSummary = (contact_id) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.clientBillingSummary}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&contact_id=${contact_id}`;
  return performRequestSecond(apiEndPoints.methodType.GET, url);
};

export const getSubscriptionPlan = (case_sk) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.getSubscriptionPlan}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&case_sk=${case_sk}`;
  return performRequestSecond(apiEndPoints.methodType.GET, url);
};

export const deleteInvoice = (invoice_sk, is_lead = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.deleteInvoice}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&invoice_sk=${invoice_sk}${
    is_lead ? `&is_lead=${is_lead}` : ''
  }`;
  return performRequestSecond(apiEndPoints.methodType.DELETE, url);
};

export const updateInvoice = (data, is_lead = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.updateInvoice}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${is_lead ? `&is_lead=${is_lead}` : ''}`;
  return performRequestSecond(apiEndPoints.methodType.PUT, url, data);
};

export const listTrustAccount = ({ filter, limit = 100, pagination_direction = 'forward', pagination_token = '' }) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.listTrustAccount}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${limit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`;
  return performRequestSecond(apiEndPoints.methodType.POST, url, filter);
};

export const sendInvoiceReport = (params) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.sendInvoice}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&${params}`;
  return performRequestThird(apiEndPoints.methodType.POST, url, {});
};

export const getTrustBalanceInfo = (case_sk, contact_id) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.getTrustBalanceInfo}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&case_sk=${case_sk}&contact_id=${contact_id}`;
  return performRequestThird(apiEndPoints.methodType.GET, url);
};

export const listModuleType = () => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.listModuleType}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestThird(apiEndPoints.methodType.GET, url);
};

export const validateModuleMapping = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.validateModuleType}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestThird(apiEndPoints.methodType.POST, url, data);
};
