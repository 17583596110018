import { useState } from 'react';
import { listContacts } from 'services/Contact/contactServices';

export function useContacts(isLead = false, contactType = '') {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const searchAsync = async (e) => {
    try {
      setLoading(true);
      const response = await listContacts({ contactType, keyWord: e?.query, pageLimit: 20, excludeFromLoading: true, in_lead: isLead });
      setResults(response?.data?.contacts);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  return { contactList: results, searchAsync, loading };
}
