import React, { useContext } from 'react';

import LabelName from 'components/UI/LabelName/LabelName';

import { UserDetailsContext } from 'context/userDetailsContext';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import constants from 'constants/index';

function GoogleAndOutlookPreview({ eventDetails }) {
  const userContext = useContext(UserDetailsContext);

  let event_type = eventDetails?.extendedProps?.res_type;
  let calendar_url = eventDetails?.extendedProps?.calendar_link;
  let meet_url = eventDetails?.extendedProps?.meet_link;

  function navigateTo(e, url) {
    e.preventDefault();
    window.open(url, '_blank');
  }

  return (
    <form>
      {/* form heading */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="modal-heading F-size16 text-bold">{event_type === 'google' ? 'Google Meet' : 'Outlook Event'}</div>
      </div>
      {/* title */}
      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-md-4 col-12 ">
          <LabelName required={false} htmlFor="title">
            Event Title
          </LabelName>
        </div>
        <div className="col-md-8 col-12 input-style">{eventDetails?.title}</div>
      </div>
      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-md-4 col-12">
          <LabelName required={false} htmlFor="title">
            Date
          </LabelName>
        </div>
        <div className="col-md-8 col-12 input-style">
          {handleDateTimeOffset(userContext.userDetails.timezone, eventDetails?.start, constants.month_date_year_format)}
        </div>
      </div>
      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-md-4 col-12">
          <LabelName required={false} htmlFor="title">
            Time
          </LabelName>
        </div>
        <div className="col-md-8 col-12 d-flex gap-3 align-items-center">
          <div className="w-100 input-style">
            {handleDateTimeOffset(userContext.userDetails.timezone, eventDetails?.start, constants.hour_minute_12_format)}
          </div>
          <div className="text-bold">-</div>
          <div className="w-100 input-style">
            {handleDateTimeOffset(userContext.userDetails.timezone, eventDetails?.end, constants.hour_minute_12_format)}
          </div>
        </div>
      </div>
      {event_type === 'google' && meet_url ? (
        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-md-4 col-12">
            <LabelName required={false} htmlFor="title">
              Meet link
            </LabelName>
          </div>
          <div className="col-md-8 col-12 d-flex gap-3 align-content-center">
            <button className="btn btn-primary" onClick={(e) => navigateTo(e, meet_url)}>
              Join With Google Meet
            </button>
          </div>
        </div>
      ) : (
        <>
          {meet_url && (
            <div className="d-flex align-items-center py-2 flex-wrap">
              <div className="col-md-4 col-12">
                <LabelName required={false} htmlFor="title">
                  Meet link
                </LabelName>
              </div>
              <div className="col-md-8 col-12 d-flex gap-3 align-content-center">
                <button className="btn btn-primary" onClick={(e) => navigateTo(e, meet_url)}>
                  Join With Skype
                </button>
              </div>
            </div>
          )}
        </>
      )}
      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-md-4 col-12">
          <LabelName required={false} htmlFor="title">
            {/* Calendar link */}
          </LabelName>
        </div>
        <div className="col-md-8 col-12 d-flex gap-3 align-content-center">
          <a href="http://" target="_blank" rel="noopener noreferrer" onClick={(e) => navigateTo(e, calendar_url)}>
            More Details
          </a>
        </div>
      </div>
    </form>
  );
}

export default GoogleAndOutlookPreview;
