import { Switch, useRouteMatch, Route } from 'react-router-dom';

import LeadList from './LeadList/LeadList';
import LeadDataHandler from './Context/LeadContext';
import LeadDetails from './LeadDetails/LeadDetails';

function LeadRouter() {
  let { path } = useRouteMatch();
  return (
    <LeadDataHandler>
      <Switch>
        <Route exact path={path} component={LeadList} />
        <Route path={`${path}/:id`} component={LeadDetails} />
      </Switch>
    </LeadDataHandler>
  );
}

export default LeadRouter;
