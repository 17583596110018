import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import StageHeader from './StageHeader';
import { ItemList } from './ItemList';

export const Column = React.memo(function Column({
  column,
  index,
  handleAddStage,
  handleManageColumn,
  isClosed,
  boardData,
  cardAction,
  disableDrag,
}) {
  return (
    <Draggable draggableId={column.id} index={index} isDragDisabled={isClosed || disableDrag}>
      {(provided) => (
        <div className="column" {...provided.draggableProps} ref={provided.innerRef}>
          <StageHeader data={column} {...{ handleManageColumn, handleAddStage }} {...provided.dragHandleProps} />
          <ItemList column={column} index={index} isClosed={isClosed} boardData={boardData} cardAction={cardAction} />
        </div>
      )}
    </Draggable>
  );
});
