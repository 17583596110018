import React from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';

export default function SubscriptionNoPermission() {
  const history = useHistory();

  const goToSubscription = () => history.push('/settings/subscription');
  const goBack = () => history.goBack();

  return (
    <div className="d-flex justify-content-center align-item-center mt-5 pt-5">
      <Card className="input-shadow" style={{ width: 650, textAlign: 'center' }}>
        <i className="fa fa-lock h3"></i>
        <h4 className="mt-2">You do not have permission to view this content.</h4>
        <p>Please upgrade your plan to use this feature</p>
        <Button className="ms-2 bg-btn p-button-secondary outline mt-3 mt-md-0" onClick={goBack}>
          Back
        </Button>
        <Button className="ms-2 p-button p-button-secondary" onClick={goToSubscription}>
          Upgrade
        </Button>
      </Card>
    </div>
  );
}
