import React from 'react';
import { Controller } from 'react-hook-form';

import LabelName from 'components/UI/LabelName/LabelName';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

function EventDescription({ control }) {
  return (
    <div className="d-flex mt-2 py-2 flex-wrap">
      <div className="col-md-4 col-12">
        <LabelName required={false} htmlFor="attendees">
          Description
        </LabelName>
      </div>
      <div className="col-md-8 col-12">
        <Controller
          name="description"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextSnippetWrapper
              type="textarea"
              id={field.name}
              name={field.name}
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              className="input-shadow w-100"
            />
          )}
        />
      </div>
    </div>
  );
}

export default EventDescription;
