import { useEffect, useRef } from 'react';

export default function useResposnsiveHandler(isGlobalMessagesScreen) {
  const secondDivRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const secondDiv = secondDivRef.current;
      const content = contentRef.current;
      if (!secondDiv || !content) return;

      const secondDivRect = secondDiv.getBoundingClientRect();
      // Check if the second div is about to scroll out of view
      if (secondDivRect.top <= 10 && secondDivRect.bottom > window.innerHeight) {
        content.style.position = 'fixed';
        content.style.top = '10px';
        content.style.width = secondDiv?.offsetWidth + 'px';
      } else if (secondDivRect.top > 10) {
        content.style.position = 'unset';
        content.style.top = 'unset';
        content.style.width = 'unset';
      }
    };
    const handleResize = () => {
      const secondDiv = secondDivRef.current;
      const content = contentRef.current;
      if (!secondDiv || !content) return;

      // Update the content width on resize
      if (content.style.position === 'fixed') {
        content.style.width = `${secondDiv.offsetWidth}px`;
      }
    };

    const element = isGlobalMessagesScreen ? document.getElementById('root') : window;
    element.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    return () => {
      element.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { secondDivRef, contentRef };
}
