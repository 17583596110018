import React from 'react';
import { Controller } from 'react-hook-form';

import { InputText } from 'primereact/inputtext';
import LabelName from 'components/UI/LabelName/LabelName';

function EventVideoConferenceUrl({ control }) {
  return (
    <div className="d-flex align-items-center py-2 flex-wrap">
      <div className="col-md-4 col-12">
        <LabelName required={false} htmlFor="url">
          Add Video Conferencing
        </LabelName>
      </div>
      <div className="col-md-8 col-12">
        {
          <Controller
            name="conference_url"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <InputText id={field.name} {...field} className="input-shadow w-100 border-0 p-2" placeholder="Meeting URL" />
            )}
          />
        }
      </div>
    </div>
  );
}

export default EventVideoConferenceUrl;
