import React from 'react';
import { useCalendarContext } from 'modules/calendar/Context/CalendarContext';

function CalendarTotalCount() {
  const { isGlobal } = useCalendarContext();

  return (
    <div className="mb-3">
      {isGlobal && <h1 className="title gray-800">Calendar</h1>}
      {/* <span className="font-italic"> Total Events : {totalCount} </span> */}
    </div>
  );
}

export default CalendarTotalCount;
