import { performRequest, performRequestThird } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';
import { getTenantDetails } from 'utils/utils';

export const listMedicalExpenses = (filters, caseId, keyword, pageLimit = 10, pagination_token = '', pagination_direction = 'forward') => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.listMedicalExpenses}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&case_id=${caseId}&keyword=${keyword}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`;
  return performRequestThird(apiEndPoints.methodType.POST, url, filters);
};

export const listAllMedicalProvider = (contactType, pageLimit = 10, pagination_token = '', pagination_direction = 'forward') => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.contact.list}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&contact_type=${contactType}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`;
  return performRequest(apiEndPoints.methodType.GET, url);
};

export const getExpense = (caseId, pk, sk) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.getMedicalExpenses}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&case_id=${caseId}&medical_expense_pk=${pk}&medical_expense_sk=${sk}`;
  return performRequestThird(apiEndPoints.methodType.GET, url);
};

export const createExpense = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.createMedicalExpense}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestThird(apiEndPoints.methodType.POST, url, data);
};

export const updateExpense = (data, is_lead = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.updateMedicalExpense}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestThird(apiEndPoints.methodType.PUT, url, data);
};

export const listCaseExpenses = (
  filters,
  caseId,
  keyword = '',
  pageLimit = 10,
  pagination_token = '',
  pagination_direction = 'forward'
) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.listCaseExpenses}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&case_id=${caseId}&keyword=${keyword}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`;
  return performRequestThird(apiEndPoints.methodType.POST, url, filters);
};

export const deleteMedicalExpense = (pk, sk) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.deleteMedicalExpense}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&medical_expense_pk=${pk}&medical_expense_sk=${sk}`;
  return performRequestThird(apiEndPoints.methodType.DELETE, url);
};

//case
export const createCaseExpense = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.createCaseexpense}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestThird(apiEndPoints.methodType.POST, url, data);
};

export const getCaseExpense = (caseId, pk, sk) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.getCaseExpenses}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&case_id=${caseId}&case_expense_pk=${pk}&case_expense_sk=${sk}`;
  return performRequestThird(apiEndPoints.methodType.GET, url);
};

export const updateCaseExpense = (data, is_lead = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.updateCaseExpense}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestThird(apiEndPoints.methodType.PUT, url, data);
};

export const deleteCaseExpense = (pk, sk) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.deleteCaseExpense}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&case_expense_pk=${pk}&case_expense_sk=${sk}`;
  return performRequestThird(apiEndPoints.methodType.DELETE, url);
};

export const getSettleMentDetails = (caseId) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.getSettlementExpenses}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&case_id=${caseId}`;
  return performRequestThird(apiEndPoints.methodType.GET, url);
};

export const updateSettleMent = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.updateSettlementExpenses}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestThird(apiEndPoints.methodType.PUT, url, data);
};
