import constants from "constants/index";

const initialState = {
    loading:false,
    userList:[],
    error:null,
    next:null
}

export const userListReducer =(state=initialState,action={})=>{

    switch(action.type){
       case constants.USER_LIST_STARTED:
           return {
               ...state,
               loading:true,
            next:""
           }
        case constants.USER_LIST__SUCCESS:
            let data = action.data
            if(action.loadMore){
                data = [...state.userList,...action.data]
            }
            return{
                ...state,
                loading:false,
                userList:data,
                next:action.next
            }
        case constants.USER_LIST__FAILURE:
            return{
                ...state,
                loading:false,
                error:action.error
            }

        default:
            return state
    }
}