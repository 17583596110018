import { format } from 'date-fns';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import React from 'react';

const EverGreenStandardRetainerBillingDetails = (props) => {
  const { caseDetails, setCaseDetails } = props || {};
  return (
    <>
      <div className="row mt-4 ">
        <div className="col-12 col-md-4">Retainer Amount</div>
        <div className="col-12 col-md-8">
          <InputText
            className="input-shadow w-50 border-0 p-2"
            value={caseDetails?.billing_preferences?.retainer_amount}
            onChange={(e) => {
              if (caseDetails?.billing_preferences) {
                caseDetails.billing_preferences.retainer_amount = e.target.value;
                setCaseDetails({ ...caseDetails });
              }
            }}
            disabled
          />
        </div>
      </div>
      <div className="row mt-4 ">
        <div className="col-12 col-md-4">Retainer Date</div>
        <div className="col-12 col-md-8">
          <Calendar
            className="input-shadow w-50 border-0 p-2"
            onChange={(e) => {
              let retainer_date = e.target.value;
              if (caseDetails?.billing_preferences && retainer_date) {
                caseDetails.billing_preferences.retainer_date = format(retainer_date, 'MM/dd/yyyy');
              }
            }}
            value={caseDetails?.billing_preferences?.retainer_date ? new Date(caseDetails?.billing_preferences?.retainer_date) : ''}
          />
        </div>
      </div>
    </>
  );
};

export default EverGreenStandardRetainerBillingDetails;
