import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import GlobalLoader from 'components/GlobalLoader/GlobalLoader';
import MessageCreateModal from './CreateMessageModal/MessageCreateModal';
import SecureMessageHeader from './Components/SecureMessageHeader';
import MessageListPage from './MessageListPage/MessageListPage';
import { markNotification } from 'services/notification/notificationService';
import { notificationAction } from 'redux/actions/notificationAction';

const SecureMessage = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const notifications = useSelector((state) => state?.notificationList?.notifications?.notifications);

  const [show, setShow] = useState(Boolean(location?.state?.createMessage));

  useEffect(() => {
    if (JSON.parse(window?.localStorage?.getItem('stateData'))?.secure_msg) {
      // setSelectedMessage(JSON.parse(window?.localStorage?.getItem('stateData'))?.secure_msg);
      window.localStorage.removeItem('stateData');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, window.localStorage]);

  useEffect(() => {
    markNotificationRead();
  }, [notifications]);

  const markNotificationRead = () => {
    const unReadNoti = notifications?.filter((v) => v?.is_read === false && v?.notification_type === 'secure_message');
    if (unReadNoti?.length > 0) {
      markNotification(
        true,
        unReadNoti?.map((v) => v.sk)
      )
        .then(() => dispatch(notificationAction('', 1000, '')))
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <GlobalLoader />
      <div className="message-wrap px-2">
        <SecureMessageHeader setShow={setShow} />
        <MessageListPage />
        {show && <MessageCreateModal show={show} setShow={setShow} />}
      </div>
    </>
  );
};

export default SecureMessage;
