import React from 'react';
import FileDownload from '../../ListView/Templates/ActionsTemplate/FileDownload';

const VideoViewer = (props) => {
  const { item, onLoad } = props;

  return (
    <>
      <FileDownload
        rowData={props?.item}
        isFromFilePreviewModal={true}
        filePreviewModalIconClassName={'icon-save video-download p-2 pointer float-end h2 mb-0'}
      />

      <video src={item?.url} controls style={{ height: '100%', width: '100%' }} className="mx-auto" onLoadStart={onLoad} autoPlay />
    </>
  );
};

export default VideoViewer;
