import React, { useEffect, useState } from "react";

const TempStorageContext = React.createContext();

const TempStorageProvider = ({ children }) => {
  const [tempData, setTempData] = useState();

  useEffect(() => {
  },[]);


  return (
    <TempStorageContext.Provider
      value={[
        tempData,
        setTempData,
      ]}
    >
      {children}
    </TempStorageContext.Provider>
  );
};

const TempStorageConsumer = TempStorageContext.Consumer;

export { TempStorageContext, TempStorageProvider, TempStorageConsumer };
