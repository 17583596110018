import React from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

import { caseNoteCallLogFilterOptions } from 'constants/dropdownOptions';
import { useUserDetailsContext } from 'context/userDetailsContext';
import constants from 'constants/index';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

export default function Filter({ isCaseClosed, isLeadClosed, isLeadSpecific, setShow, filterOnChange, filter }) {
  const { getPermission } = useUserDetailsContext();

  return (
    <div className="row mb-4">
      <div className="col-sm-12 d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex align-items-center filter-wrap p-2 filter-wraper">
          <div className="d-flex align-items-center autowidth mb-3 mb-lg-0">
            <div className="autowidth">
              <span className="p-input-icon-left input-shadow autowidth">
                <i className="icon-search F-size16 cursor-pointer" />
                <TextSnippetWrapper
                  type="text"
                  placeholder="Search"
                  value={filter?.keyword}
                  onChange={filterOnChange}
                  className="input-search"
                  name="keyword"
                />
              </span>
            </div>
          </div>

          <div className="note-filter ms-lg-4 autowidth">
            <span className="text-bold black-600 me-2">Filters: </span>
            <Dropdown
              value={filter?.note_type}
              options={caseNoteCallLogFilterOptions}
              onChange={filterOnChange}
              name="note_type"
              placeholder="By Status"
              className="mx-1"
            />
          </div>
        </div>
        {getPermission('cases') === constants.permissions.WRITE && (
          <Button
            label="Case Note/Call Log"
            className="p-button p-button-primary ml-2 mt-3 mt-lg-0"
            onClick={(e) => setShow(true)}
            disabled={isCaseClosed || (isLeadClosed && isLeadSpecific)}
            icon="pi pi-plus"
          />
        )}
      </div>
    </div>
  );
}
