import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import CustomHeader from './CustomHeader';
import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';

import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import { createFile } from 'services/fileManager/fileManagerServices';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

function CreateFolderModal() {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const { modals, fileManagerFilters, isCaseFileManager, isLeadFileManager, caseDetails, handleGetFiles, pageLimit } =
    useFileManagerContext();
  const { showCreateFolderModal, setShowCreateFolderModal } = modals;
  const { addToast } = useToast();

  function createFolder(data) {
    data.case_id = caseDetails?.case_id;
    data.type = 'folder';
    data.folder_id = fileManagerFilters.selectedFolder || '';
    data.display_name = data.folderName;
    data.is_lead = isLeadFileManager;

    let obj = {
      data: data,
      is_lead: isLeadFileManager,
    };

    createFile(obj)
      .then(() => {
        handleGetFiles(pageLimit);

        setShowCreateFolderModal(false);
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.FOLDER_CREATED);
      })
      .catch((error) => {
        console.log(error);
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.ERROR, toastConstant.message.FOLDER_CREATE_FAILED);
      });
  }

  const footer = (
    <div className="footer-btn">
      <Button label="Cancel" onClick={() => setShowCreateFolderModal(false)} className="p-button p-button-secondary outline" />
      <Button label="Create" onClick={handleSubmit(createFolder)} className="p-button p-button-secondary me-0" />
    </div>
  );

  return (
    <Dialog
      className="rounded-radius FM-popup new-task-popup"
      header={<CustomHeader title="" onHide={() => setShowCreateFolderModal(false)} />}
      footer={footer}
      visible={showCreateFolderModal}
      style={{ width: '40vw' }}
      modal
      onHide={() => setShowCreateFolderModal(false)}
      closable={false}
    >
      <div className="modal-heading F-size16 text-bold mb-4">Create Folder</div>
      <Controller
        name="folderName"
        control={control}
        defaultValue={''}
        rules={{ required: true }}
        render={({ field }) => (
          <TextSnippetWrapper
            type="text"
            {...field}
            onChange={(e) => {
              field.onChange(e);
            }}
            placeholder="Folder Name"
            className="input-shadow w-100 mt-2"
          />
        )}
      />
      {errors?.folderName && <InputErrorMessage>Required</InputErrorMessage>}
    </Dialog>
  );
}

export default CreateFolderModal;
