import { actionTypes } from "constants/types";
let initialState = {
  isCaseMatterLoading: false,
  caseMatterList: "",
  caseMatterError: "",
};

export default function CaseMatterReducer(state = initialState, action={}) {
  switch (action.type) {
    case actionTypes.GET_CASE_MATTER_REQUEST:
      return {
        ...state,
        isCaseMatterLoading:true,
        caseMatterList: "",
        caseMatterError: "",
      };
    case actionTypes.GET_CASE_MATTER_SUCCESS:
      return {
        ...state,
        caseMatterList: action.payload,
        caseMatterError: "",
        isCaseMatterLoading:false
      };
    case actionTypes.GET_CASE_MATTER_ERROR:
      return {
        ...state,
        caseMatterList: "",
        caseMatterError: action.payload,
        isCaseMatterLoading:false
      };

    default:
      return state;
  }
}
