import React, { useState } from 'react';

import Heading from './Heading/Heading';
import Filters from './Filters/Filters';
import SearchFiles from './SearchFiles/SearchFiles';
import Menu from './Menu/Menu';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';

function FileManagerHeader() {
  const { isGlobalFileManager } = useFileManagerContext();
  const [showInfo, setShowInfo] = useState(true);

  return (
    <div className="">
      {isGlobalFileManager && <Heading />}
      <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 col-sm-12 FM-filter">
        <div className="filter-wrap d-flex align-items-center pe-2 ps-lg-2 ps-2 py-2 flex-wrap">
          <SearchFiles />
          <Filters />
        </div>
        <Menu />
      </div>
      {isGlobalFileManager && showInfo && (
        <div className="d-flex flex-wrap mt-2 align-items-center files-note">
          <i className="pi pi-info-circle me-1"></i>
          By default, only global files are displayed. You can search to view both global and case-specific files
          <i className="pi pi-times cursor-pointer ms-3 me-1" onClick={() => setShowInfo(false)}></i>
        </div>
      )}
    </div>
  );
}

export default FileManagerHeader;
