import React from 'react';

const TemporaryFundHeader = (props) => {
  const { modalType } = props;
  return (
    <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
      <div className="modal-heading F-size16 text-bold">{modalType} Temporary Fund</div>
    </div>
  );
};

export default TemporaryFundHeader;
