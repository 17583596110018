import React from 'react';

function Heading() {
  return (
    <div className="d-flex  align-items-center flex-wrap">
      <h1 className="title mb-0">File Manager</h1>
    </div>
  );
}

export default Heading;
