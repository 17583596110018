export function adjustColor(color, amount) {
  //this function is used to adjust the color into darken or llighen the color
  //amount===negative value is for darken
  //amount===positive value is for lighten

  return (
    "#" +
    color
      ?.replace(/^#/, "")
      ?.replace(/../g, (color) =>
        (
          "0" +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount))?.toString(16)
        )?.substr(-2)
      )
  );
}

export function shadeColor(color, percent) {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  R = Math.round(R);
  G = Math.round(G);
  B = Math.round(B);

  var RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}

export const adjustColorToLightOrDark = (color) => {
  let textColor, brightness, r, g, b, hsp;
  let lightColors = ["#ffffff", "#eeeeee"];
  let darkColors = ["#333333", "#000000", "#585454"];

  const lightOrDark = (color) => {
    // Check the format of the color, HEX or RGB?
    if (color?.match(/^rgb/)) {
      // If HEX --> store the red, green, blue values in separate variables
      color = color?.match(
        /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
      );

      r = color[1];
      g = color[2];
      b = color[3];
    } else {
      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      color = +(
        "0x" + color?.slice(1)?.replace(color.length < 5 && /./g, "$&$&")
      );

      r = color >> 16;
      g = (color >> 8) & 255;
      b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math?.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {
      return "light";
    } else {
      return "dark";
    }
  };

  brightness = lightOrDark(color);

  if (brightness === "dark") {
    textColor = lightColors[0];
  } else {
    textColor = darkColors[0];
  }
  return textColor;
};
