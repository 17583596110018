import React from 'react';

const EnableTimeEntriesToggleSwitch = (props) => {
  const { showTimeEntry, setShowTimeEntry, setTimeEntriesError } = props || {};
  return (
    <>
      <div className="d-flex align-items-center mb-2">
        <span className="caption-bold mb-0 pe-4">Time Entries</span>
        <div>
          <label className="switch">
            <input
              type="checkbox"
              checked={showTimeEntry}
              onChange={(e) => {
                setShowTimeEntry(e.target.checked);
                setTimeEntriesError({});
              }}
            />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
    </>
  );
};

export default EnableTimeEntriesToggleSwitch;
