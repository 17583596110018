import React from 'react';
import moment from 'moment';

import { adjustColor, adjustColorToLightOrDark } from 'utils/colorUtils';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import constants from 'constants/index';
import { useCalendarContext } from 'modules/calendar/Context/CalendarContext';

function EventContent({ arg, timezone }) {
  const { setShowEventPreviewModal, setSelectedEvent, calendarSettings } = useCalendarContext();

  let startDate = arg?.event?.start;
  let endDate = arg?.event?.end;

  let hideEvent = false;
  let fallbackIcon = '';
  let respType = arg?.event?._def?.extendedProps?.res_type;

  let eventBgColor = '';

  let textColor = '';
  let borderColor = '';
  let iconBgColor = '';

  if (respType === 'event') {
    fallbackIcon = 'icon-callBack';
  } else if (respType === 'DOB') {
    fallbackIcon = 'fas fa-birthday-cake';
  } else if (respType === 'case') {
    fallbackIcon = 'icon-calendar';
  } else if (respType === 'task') {
    fallbackIcon = 'icon-calendar';
  } else {
    fallbackIcon = 'icon-calendar';
  }

  let icon = arg?.event?.extendedProps?.icon ? arg?.event?.extendedProps?.icon : fallbackIcon;

  let calendarColor = arg?.event?._def?.extendedProps?.created_by_details?.calendar_color;
  calendarColor = calendarColor ? calendarColor : '';
  if (calendarColor) {
    borderColor = calendarColor;
    iconBgColor = calendarColor;

    eventBgColor = adjustColor(calendarColor, 90); //positive value for lighten the color and negative value for darken the color
    textColor = adjustColorToLightOrDark(eventBgColor);
  } else {
    borderColor = '#2655b9';
    textColor = '#2655b9';
    eventBgColor = '#f5f5fa';
    iconBgColor = '#2655b9';
  }

  let leftBorder = `4px solid ${borderColor}`;
  if (startDate !== 'Invalid Date' && endDate !== 'Invalid Date') {
    startDate = handleDateTimeOffset(timezone, arg?.event?.startStr, constants.hour_minute_12_format);
    endDate = handleDateTimeOffset(timezone, arg?.event?.endStr, constants.hour_minute_12_format);

    let beforeTime = moment('09:00:00', 'hh:mm A');
    let afterTime = moment('17:00:00', 'hh:mm A');
    let _startDate = moment(startDate, 'hh:mm A');
    if (calendarSettings?.show_business_times_only && !_startDate.isBetween(beforeTime, afterTime) && respType === 'event') {
      hideEvent = true;
      arg?.event?.setProp && arg?.event?.setProp('display', 'none');
    } else if (arg?.event?.display !== 'auto') {
      arg?.event?.setProp && arg?.event?.setProp('display', 'auto');
    }
  }

  function onEventClick() {
    let eventSelected;
    if (arg?.event !== undefined) {
      eventSelected = arg.event.toJSON();
    } else {
      eventSelected = {
        extendedProps: arg,
        title: arg?.title,
        start: arg?.meeting_start_time ? arg?.meeting_start_time : arg?.start,
        end: arg?.meeting_end_time ? arg?.meeting_end_time : arg?.end,
      };
    }

    setSelectedEvent(eventSelected);
    setShowEventPreviewModal(true);
  }

  return (
    <>
      {!hideEvent && (
        <div
          className="badge-events d-flex"
          style={{
            background: eventBgColor,
            borderLeft: leftBorder,
            color: textColor,
          }}
          onClick={onEventClick}
        >
          <div
            className="badge-event-icon me-2"
            style={{
              backgroundColor: respType === 'DOB' || respType === 'case' ? '#2655B9' : iconBgColor,
            }}
          >
            <i className={icon} style={{ color: textColor }}></i>
          </div>

          <div className="badge-event-details" title={`${startDate} - ${endDate}`}>
            <div className="time" title={`${startDate} - ${endDate}`}>
              <span style={{ display: respType !== 'event' ? 'none' : 'inline' }}>
                {startDate}-{endDate}
              </span>
            </div>
            <div className="title" title={arg?.event?.title}>
              <span className="text-break title">{arg?.event?.title}</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EventContent;
