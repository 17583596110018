import React from 'react';

import GlobalLoader from 'components/GlobalLoader/GlobalLoader';
import CalendarContainer from './CalendarContainer';

import { CalendarContextProvider } from './Context/CalendarContext';

function Calendar(props) {
  return (
    <CalendarContextProvider {...props}>
      <GlobalLoader />
      <CalendarContainer />
    </CalendarContextProvider>
  );
}

export default Calendar;
