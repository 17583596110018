// Example of pascal case : "ThisIsPascalCase"

export const convertToPascal = (str) => {
  let newStr = '';
  if (str && typeof str !== 'string') {
    newStr = str.replace(/\w\S*/g, function (w) {
      return w[0].toUpperCase() + w.slice(1).toLowerCase();
    });
  }

  return newStr;
};

//Capitalizing first letter of each word or string
export const capitalizeString = (str) => {
  if (typeof str !== 'string' || str.length === 0) {
    return str; // Return input as-is if it's not a string or an empty string
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};

// Example of title case : "This Is Title Case"
export const convertToTitleCase = (params) => {
  let { str = '', separator = null, joinSeparator } = params || {};
  let formattedStrArr = [];

  if (!joinSeparator) {
    joinSeparator = separator;
  }

  if (str?.trim()?.length > 0 && typeof str === 'string') {
    let splittedStingArr = str.split(separator);

    if (splittedStingArr?.length > 0) {
      splittedStingArr?.forEach((item, idx) => {
        let capitalizedSting = capitalizeString(item);
        formattedStrArr?.push(capitalizedSting);
      });
    }
  }

  return formattedStrArr?.join(joinSeparator);
};
