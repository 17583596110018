import React from 'react';
import constants from 'constants/index';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import { useUserDetailsContext } from 'context/userDetailsContext';
import { handleRedirection } from 'utils/utils';
import { useHistory } from 'react-router-dom';

export default function CaseItem({ data }) {
  const userContext = useUserDetailsContext();
  const history = useHistory();

  // const isUpcomingEventAvailable = data?.upcoming_event && Object.keys(data?.upcoming_event)?.length > 0;

  // const generateTeamMembersName = (teamMemberArr) => {
  //   try {
  //     if (!teamMemberArr?.length) {
  //       return 'N/A';
  //     }
  //     let names = teamMemberArr?.map((val, key) => (
  //       <>
  //         <span
  //           className="redirection-link"
  //           onClick={() => {
  //             let route = val?.firm_user_id ? `/settings/team-management/${val?.firm_user_id}` : '/settings/team-management';
  //             handleRedirection(history, route);
  //           }}
  //         >
  //           {generateFullName(val)}
  //         </span>
  //         {teamMemberArr?.length > 1 && key < teamMemberArr?.length - 1 ? ', ' : ''}
  //       </>
  //     ));

  //     return names;
  //   } catch (error) {
  //     return 'N/A';
  //   }
  // };

  return (
    <div className="border shadow-small p-3 my-2 msg-dtls">
      <div className="my-2 text-break">
        <span className="text-medium">Opened:</span>
        <span className="ms-1">
          {data?.important_dates?.case_created
            ? handleDateTimeOffset(userContext.userDetails.timezone, data.important_dates.case_created, constants.month_date_year_format)
            : 'N/A'}
        </span>
      </div>
      <div className="my-2 text-break">
        <span className="text-medium">Practice Area:</span>
        <span className="ms-1">{data?.case_description?.case_practice_area || 'N/A'}</span>
      </div>
      <div
        className="my-2 text-break "
        onClick={() => {
          const route = data?.case_id ? `/cases/${data?.case_id}` : '/cases';
          handleRedirection(history, route);
        }}
      >
        <span className="text-medium">Case:</span>
        <span className="text-primary-dark redirection-link-no-text-transform ms-1">{data?.case_description?.case_name ?? 'N/A'}</span>
      </div>

      {/* <div className="my-2 text-break">
        <span className="text-medium">Lead Attorney:</span>
        <span
          className={data?.attorney_lead_details?.length ? 'redirection-link ms-1' : 'ms-1'}
          onClick={() => {
            if (data?.attorney_lead_details?.length) {
              const leadAttorneyFirmUserId = data?.attorney_lead_details[0]?.firm_user_id;
              const route = leadAttorneyFirmUserId ? `/settings/team-management/${leadAttorneyFirmUserId}` : '/settings/team-management';

              handleRedirection(history, route);
            }
          }}
        >
          {data?.attorney_lead_details?.length ? getLeadAttorneyName(data?.attorney_lead_details) : 'N/A'}
        </span>
      </div> */}

      {/* <div className="my-2 text-break">
        <span className="text-medium">Case Stage:</span>
        <span className="ms-1">{data?.case_description?.case_stage?.stage_name ?? 'N/A'}</span>
      </div> */}

      {/* <div className="my-2 text-break">
        <span className="text-medium">Upcoming Event:</span>{' '}
        <span
          className={isUpcomingEventAvailable ? 'redirection-link' : 'ms-1'}
          onClick={() => {
            if (isUpcomingEventAvailable) {
              const route = '/calendar';
              const stateToPass = { viewEvent: data?.upcoming_event?.event_data };
              handleRedirection(history, route, stateToPass);
            }
          }}
        >
          {isUpcomingEventAvailable
            ? data?.upcoming_event?.name
              ? data?.upcoming_event?.name
              : '' +
                ` (${handleDateTimeOffset(
                  userContext.userDetails.timezone,
                  data?.upcoming_event?.date,
                  constants.month_date_year_time_12_format
                )})`
            : 'N/A'}
        </span>
      </div> */}

      <div className="my-2 text-break">
        <span className="text-medium">SOL:</span>
        <span className="ms-1">
          {data?.important_dates?.statute_of_limitations
            ? handleDateTimeOffset(
                userContext.userDetails.timezone,
                data.important_dates.statute_of_limitations,
                constants.month_date_year_format
              )
            : 'N/A'}
        </span>
      </div>

      <div className="my-2 text-break">
        <span className="text-medium">Case Status:</span>
        <span className="ms-1">{data?.status}</span>
      </div>

      {/* <div className="my-2 text-break">
        <span className="text-medium">Team Members:</span>
         <span>{getTeamMemberName(data?.attorney_details)}</span>
        <span className="ms-1">{generateTeamMembersName(data?.attorney_details)}</span>
      </div> */}
    </div>
  );
}
