import { actionTypes } from "constants/types";
let initialState = {
  data: [],
  isNewTimeEntryAdded: false,
};

export const TimeEntryUpdateReducer = (state = initialState, action={}) => {
  if (action.type === actionTypes.NEW_TIME_ENTRY_ADDED) {
    return {
      ...state,
      data: action.payload,
      isNewTimeEntryAdded: true,
    };
  } else {
    return state;
  }
  // switch (action.type) {
  //   case actionTypes.NEW_TIME_ENTRY_ADDED:
  //     return {
  //       ...state,
  //       data: action.payload,
  //       isNewTimeEntryAdded: true,
  //     };
  //   default:
  //     return state;
  // }
};
