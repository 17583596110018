import constants from "constants/index";

const initialState = {
    loading:false,
    contactDetails:{},
    error:null,
    caseList:[],
    image_url:''
}

export const contactDetailsReducer =(state=initialState,action={})=>{

    switch(action.type){
       case constants.CONTACT_DETAILS_STARTED:
           return {
               ...state,
               contactDetails:{},
               caseList:[],
               loading:true
           }
        case constants.CONTACT_DETAILS_SUCCESS:
            return{
                ...state,
                loading:false,
                contactDetails:action.data
            }
        case constants.CONTACT_DETAILS_FAILURE:
            return{
                ...state,
                loading:false,
                error:action.error
            }

        case constants.SAVE_CONTACT_IMAGE_STARTED:
           return {
               ...state,
               image_url:action.payload
           }
        // case constants.SAVE_CONTACT_IMAGE_SUCCESS:
        //     return {
        //         ...state,
        //         image_url:action.payload
        //     }

        default:
            return state
    }
}