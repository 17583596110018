export const assignToItemTemplate = (v) => {
  if (v?.status === 'ARCHIVED' && !v.contact_id) {
    return (
      <>
        {v?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
        {`${v?.assignee_name} (Inactive)`}
      </>
    );
  }
  if (v?.status === 'ACTIVE' && v.is_confirmed === false && v?.is_create_client_portal) {
    return (
      <>
        {v?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
        {`${v?.assignee_name} (Unconfirmed)`}
      </>
    );
  }
  if ((v?.access_level === 'client' || v.contact_id) && v?.res_type !== 'user-group') {
    if (!v?.is_create_client_portal && v?.status === 'ACTIVE') {
      return (
        <>
          {v?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
          {`${v?.assignee_name} (Disabled Lawft Access)`}
        </>
      );
    }
    if (!v?.is_create_client_portal && (v?.status === 'ARCHIVED' || v?.is_confirmed)) {
      return (
        <>
          {v?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
          {`${v?.assignee_name} (Inactive)`}
        </>
      );
    }
    if (!Boolean(v?.email)) {
      return (
        <>
          {v?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
          {`${v?.assignee_name} (Email does not exist)`}
        </>
      );
    }
  }
  return (
    <>
      {v?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
      {`${v?.assignee_name}`}
    </>
  );
};

export const checkOptionDisabled = (v) => {
  if (v?.status === 'ARCHIVED') {
    return true;
  }
  if (v?.is_confirmed === false) {
    return true;
  }
  if ((Boolean(v?.access_level) === false || v?.access_level === 'client' || v.contact_id) && v?.res_type !== 'user-group') {
    return v.email === '' || !Boolean(v.email) || !v.is_create_client_portal ? true : false;
  }
  return false;
};

export const multiSelectTemplate = (v) => {
  if (v?.status === 'ARCHIVED' && !v.contact_id) {
    return (
      <>
        {v?.firm_user_id && <i class="fas fa-user-tie me-2"></i>}
        {v?.assignee_name}
      </>
    );
  }
  if ((v?.access_level === 'client' || v.contact_id) && v?.res_type !== 'user-group') {
    if (!Boolean(v?.is_create_client_portal)) {
      return (
        <>
          {v?.firm_user_id && <i class="fas fa-user-tie me-2"></i>}
          {v?.assignee_name}
        </>
      );
    }
    if (!Boolean(v?.email)) {
      return (
        <>
          {v?.firm_user_id && <i class="fas fa-user-tie me-2"></i>}
          {v?.assignee_name}
        </>
      );
    }
  }
  return (
    <>
      {v?.firm_user_id && <i class="fas fa-user-tie me-2"></i>}
      {v?.assignee_name}
    </>
  );
};
