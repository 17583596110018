import NotificationContent from 'modules/layout/components/NotificationContent';
import React, { useCallback, useEffect, useRef } from 'react';
import { VariableSizeList as List } from 'react-window';

const InfiniteScrollNotificationList = (props) => {
  const { notificationList, loadNotifications, isLoading, nextToken, markAsRead, currentToken, setCurrentToken, isClient } = props;
  const listRef = useRef(null);
  const rowHeights = useRef({});

  const isItemLoaded = (index) => index < notificationList?.length;

  function setRowHeight(index, size) {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  const Row = ({ index, style }) => {
    const rowRef = useRef();

    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight + 8);
      }
    }, [rowRef]); // eslint-disable-line

    if (!isItemLoaded(index)) {
      return null;
    }

    const notification = notificationList[index];
    return (
      <div style={{ ...style, borderBottom: 'none' }}>
        <div className={notification.is_read ? 'notification-read' : 'notification-unread'} key={index} ref={rowRef}>
          <div className="col-12 py-0 border-0 rounded-0">
            <NotificationContent data={notification} markAsRead={markAsRead} isClient={isClient} />
          </div>
        </div>
      </div>
    );
  };

  const loadMoreItems = () => {
    if (!isLoading && nextToken && currentToken !== nextToken) {
      setCurrentToken(nextToken);
      loadNotifications({ notificationType: '', limit: 10, nextToken });
    }
  };

  const renderLoader = () => {
    return (
      <div className="overlay d-flex p-1 justify-content-center">
        <i className="pi pi-spin pi-spinner" style={{ fontSize: '2em' }}></i>
      </div>
    );
  };

  const handleScroll = useCallback(
    ({ scrollDirection, scrollOffset }) => {
      if (scrollDirection === 'forward' && !isLoading) {
        const { scrollHeight, clientHeight } = listRef?.current?._outerRef || {};
        const threshold = scrollHeight - clientHeight * 1.2; // Adjust the threshold as needed
        if (scrollOffset > threshold) {
          loadMoreItems();
        }
      }
    },
    [loadMoreItems, isLoading]
  );

  return (
    <div>
      {isLoading && renderLoader()}
      <List
        ref={listRef}
        height={500} // Height of the list
        itemCount={notificationList?.length} // Plus 1 for the loading indicator
        itemSize={(index) => rowHeights?.current?.[index] || 80} // Height of each item
        onScroll={handleScroll}
        // overscanCount={5}
        overscanCount={10}
      >
        {Row}
      </List>
    </div>
  );
};

export default InfiniteScrollNotificationList;
