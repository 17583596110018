import React, { useState } from 'react';

import { AutoComplete } from 'primereact/autocomplete';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';

import { AddContact } from 'modules/cases/addContactModal';
import ImageComponent from 'shared/ImageComponent';

import { generateFullName } from 'utils/generateFullNameUtils';
import { Controller } from 'react-hook-form';
import { PI_PROVIDERS_TYPE } from 'constants/index';
import { useLeadContext } from 'modules/lead/Context/LeadContext';
import { useRelationships } from 'modules/lead/hooks/useRelationships';
import { getNestedProperty, handleRedirection } from 'utils/utils';
import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';
import { handleSettingSkAndPkToLocalStorage } from 'utils/localStorageUtils';
import { useHistory } from 'react-router-dom';

export default function Relationship(props) {
  const { control, errors, watch } = props;
  const { businessRole, relationRole } = useLeadContext();
  const { fields, append, removeItem, remove, results, loading, searchAsync } = useRelationships(control, watch);
  const [showModal, setshowModal] = useState(false);
  const { history } = useHistory();

  const itemTemplate = (e) => generateFullName(e);

  const RequiredValidation = ({ field, message = 'Field Required' }) => {
    return getNestedProperty(errors, field) ? (
      <InputErrorMessage>{getNestedProperty(errors, field)?.message || message}</InputErrorMessage>
    ) : null;
  };

  return (
    <div className="col-12 col-md-6 search-relation">
      <h5 className="caption-bold">RELATIONSHIPS</h5>
      <div className="">
        <AutoComplete
          placeholder="Search Existing Contacts"
          field="first_name"
          className="w-100 bg-white input-shadow mb-3 p-3 p-inputtext"
          multiple
          value={fields}
          suggestions={results}
          completeMethod={searchAsync}
          loading={loading}
          itemTemplate={itemTemplate}
          selectedItemTemplate={itemTemplate}
          onSelect={(e) => append(e.value)}
          onUnselect={(e) => removeItem(e.value)}
        />
      </div>
      <div className="shadow-middle bg-white lead-data-box p-3">
        <div className="row mt-2">
          <div className="row">
            <div className="col-sm-4">Name</div>
            <div className="col-sm-4">
              Role<span className="danger-text"> *</span>
            </div>
            <div className="col-sm-4">Enable Lawft Access</div>
          </div>
          <hr></hr>
          {fields?.map((relation, index) => {
            const { contact_id, sk, pk } = relation;
            let route = contact_id ? `/contacts/${contact_id}` : '/contacts';

            return (
              <div className="row mt-2 align-items-center" key={index}>
                <div
                  className="col-sm-4 d-flex align-items-center"
                  onClick={() => {
                    handleRedirection(history, route);
                    handleSettingSkAndPkToLocalStorage(sk, pk);
                  }}
                >
                  <ImageComponent
                    filePath={relation?.profile_image}
                    fileName={relation?.contact_type !== 'person' ? relation?.title : relation?.first_name}
                    fileSize="small.jpg"
                  />
                  <div className="ms-1 redirection-link">{generateFullName(relation)}</div>
                </div>
                <div className="col-sm-4">
                  {relation?.contact_type === 'business' || PI_PROVIDERS_TYPE.includes(relation?.contact_type) ? (
                    <Controller
                      name={`relations.${index}.role`}
                      control={control}
                      rules={{ required: 'Role is required' }}
                      render={({ field }) => (
                        <Dropdown
                          id={field.name}
                          onChange={(e) => field.onChange(e.value)}
                          value={field.value?.toLowerCase()}
                          options={businessRole}
                          optionLabel="name"
                          optionValue={(v) => v.name?.toLowerCase()}
                          placeholder="Select"
                        />
                      )}
                    />
                  ) : (
                    <Controller
                      name={`relations.${index}.role`}
                      control={control}
                      rules={{ required: 'Role is required' }}
                      render={({ field }) => (
                        <Dropdown
                          id={field.name}
                          onChange={(e) => field.onChange(e.value)}
                          value={field.value?.toLowerCase()}
                          options={relationRole}
                          optionLabel="relation_role"
                          optionValue={(v) => v.relation_role?.toLowerCase()}
                          placeholder="Select"
                        />
                      )}
                    />
                  )}
                  <RequiredValidation field={`relations.${index}.role`} />
                </div>
                <div className="col-sm-4 d-flex align-items-center justify-content-between">
                  <Controller
                    name={`relations.${index}.is_create_client_portal`}
                    control={control}
                    render={({ field }) => (
                      <InputSwitch
                        className="input-shadow input-swich"
                        inputId={field.name}
                        onChange={(e) => relation?.email && field.onChange(e.value)}
                        checked={field.value}
                        tooltip={relation?.email ? '' : 'Add Email To Enable Lawft Access'}
                        tooltipOptions={{ position: 'bottom', className: 'case-tooltip' }}
                      />
                    )}
                  />
                  <i className="icon-delete icon-red cursor-pointer" onClick={() => remove(index)}></i>
                </div>
              </div>
            );
          })}

          <div className="d-flex align-items-center">
            <div className="col-sm-6 d-flex addfield mt-2" onClick={() => setshowModal(true)}>
              <i className="icon-add"></i>
              <span>Add Relationship</span>
            </div>
          </div>

          {showModal && <AddContact show setShow={() => setshowModal(false)} handleSubmitData={(v) => append(v)} isFromAddLead={true} />}
        </div>
      </div>
    </div>
  );
}
