import { useEffect, useRef } from 'react';

const useScrollToDivOnLoad = () => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return ref;
};

export default useScrollToDivOnLoad;
