import { performRequest, performRequestSecond } from "common/apiHandler";
import { default as apiEndPoints } from "common/apiEndPoints";
import { getTenantDetails } from "utils/utils";

export const addRelation = (
  contact_id,
  data,
  is_lead = false,
  timeEntryData = ""
) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.POST,
    `${
      apiEndPoints.addRelations
    }?tenant_id=${tenantId}&tenant_sk=${tenantSk}&contact_id=${contact_id}${
      is_lead ? `&is_lead=${is_lead}` : ""
    }`,
    data,
    timeEntryData
  );
};

export const deleteRelation = (pk, sk) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.DELETE,
    `${apiEndPoints.deleteRelation}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&pk=${pk}&sk=${sk}`
  );
};

export const updateRelation = (data, is_lead = false, timeEntryData = "") => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.POST,
    `${
      apiEndPoints.updateRelations
    }?tenant_id=${tenantId}&tenant_sk=${tenantSk}${
      is_lead ? `&is_lead=${is_lead}` : ""
    }`,
    data,
    timeEntryData
  );
};

export const deleteCaseRelation = (
  case_id,
  contact_id,
  lead_id,
  is_lead = false,
  timeEntryData = ""
) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestSecond(
    apiEndPoints.methodType.DELETE,
    `${
      apiEndPoints.deleteCaseRelation
    }?tenant_id=${tenantId}&tenant_sk=${tenantSk}&contact_id=${contact_id}${
      case_id ? `&case_id=${case_id}` : ""
    }${lead_id ? `&lead_id=${lead_id}` : ""}${
      is_lead ? `&is_lead=${is_lead}` : ""
    }`,
    {},
    timeEntryData
  );
};
