/* React component that renders a table using the PrimeReact library. The table
displays tasks with various columns such as task name, priority, assigned users, move to stage, and
due date. The table also supports selection of multiple tasks. The component receives props such as
priority, taskStage, tasks, selectedTasks, and setSelectedTasks to customize the table's behavior
and data. */
import React from 'react';
import { Avatar } from 'primereact/avatar';
import { AvatarGroup } from 'primereact/avatargroup';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import constants from 'constants/index';
import ImageComponent from 'shared/ImageComponent';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import { useUserDetailsContext } from 'context/userDetailsContext';
import Cookies from 'universal-cookie';
const cookie = new Cookies();

const tenantId = cookie.get('tenantId');

const subTaskBodyTemplate = (data, e) => {
  return (
    <>
      <span className="p-column-title text-break">Sub Task</span>
      <div className="d-inline-table text-break">
        <span className="text-bold ellipsis-1" title={data?.task_name}>
          {data?.task_name}
        </span>
        <div className="text-hint black-500">
          Subtasks ({data?.sub_task?.filter((item) => item.sub_task_status === true).length} done of {data?.sub_task?.length})
        </div>
      </div>
    </>
  );
};

const assignedBodyTemplate = (data) => {
  return (
    <>
      <span className="p-column-title text-break">Assigned</span>
      <span className="d-inline-table">
        {
          <AvatarGroup className="pe-3">
            {data?.assign_to?.map((user, index) => {
              if (index < 4) {
                return (
                  <>
                    <ImageComponent
                      className="customClassName me-1"
                      filePath={
                        user?.contact_id || user?.cognito_username
                          ? `${tenantId}/${user?.contact_id ? user?.contact_id : user?.cognito_username}/profile_image`
                          : null
                      }
                      fileName={user.assignee_name}
                      style={{
                        backgroundColor: '#2196F3',
                        color: '#ffffff',
                      }}
                    />
                  </>
                );
              }
            })}
            {data?.assign_to?.length > 4 && (
              <Avatar
                label={`+${data?.assign_to?.length - 4}`}
                className="me-1"
                style={{
                  backgroundColor: '#9c27b0',
                  color: '#ffffff',
                }}
                shape="circle"
              />
            )}
          </AvatarGroup>
        }
      </span>
    </>
  );
};

export default function Table(props) {
  const { priority, taskStage, tasks, selectedTasks, setSelectedTasks } = props;

  const userContext = useUserDetailsContext();

  const priorityBodyTemplate = (data) => {
    let cssClasss = '';
    if (priority === 'High') {
      cssClasss = 'da-tag priority-tags';
    } else if (priority === 'Medium') {
      cssClasss = 'm-tag priority-tags';
    } else if (priority === 'Low') {
      cssClasss = 's-tag priority-tags';
    }

    return (
      <>
        <span className="p-column-title text-break">Priority</span>
        {selectedTasks?.filter((item) => item['task_id'] === data['task_id']).length ? (
          <div className={`${cssClasss} d-flex justify-content-center d-inline-table text-break`}>
            {selectedTasks?.filter((item) => item['task_id'] === data['task_id']).length ? priority : ''}
          </div>
        ) : (
          ' '
        )}
      </>
    );
  };

  const moveToBodyTemplate = (data) => {
    let formattedStageName = '';
    if (taskStage === 'to_do') {
      formattedStageName = 'To Do';
    } else if (taskStage === 'in_progress') {
      formattedStageName = 'In Progress';
    } else if (taskStage === 'done') {
      formattedStageName = 'Done';
    } else {
      formattedStageName = taskStage;
    }
    return <>{selectedTasks?.filter((item) => item['task_id'] === data['task_id']).length ? formattedStageName : ''}</>;
  };

  const dueDateBodyTemplate = (data) => {
    return (
      <>
        <span className="p-column-title text-break">Due Date</span>
        <span className="text-break">
          {handleDateTimeOffset(userContext?.userDetails?.timezone, data.due_date, constants.month_date_year_format)}
        </span>
      </>
    );
  };

  return (
    <DataTable
      selection={selectedTasks}
      onSelectionChange={(e) => setSelectedTasks(e.value)}
      value={tasks}
      paginator
      paginatorTemplate="RowsPerPageDropdown PrevPageLink NextPageLink "
      currentPageReportTemplate="Item Per Page"
      rowsPerPageOptions={[10, 20, 50, 100]}
      rows={10}
      className="p-datatable-responsive"
    >
      <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
      <Column header="Task" sortable field="task_name" headerStyle={{ width: 200 }} body={subTaskBodyTemplate} />
      <Column header="Priority" body={priorityBodyTemplate} />
      <Column header="Assigned" body={assignedBodyTemplate} />
      <Column header="Move To" body={moveToBodyTemplate} />
      <Column header="Due Date" sortable body={dueDateBodyTemplate} field="due_date" />
    </DataTable>
  );
}
