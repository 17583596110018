import React from 'react';

const MarkAllAsBillableToggleSwitch = (props) => {
  const { timeEntires, setTimeEntries, setTimeEntriesError } = props || {};
  return (
    <>
      <div className="d-flex align-items-center mb-2">
        <span className="caption-bold mb-0 pe-4">Mark all as Billable</span>
        <label className="switch">
          <input
            type="checkbox"
            name="non_billable"
            onChange={(e) => {
              let billable = true;

              if (!e.target.checked) {
                billable = false;
                setTimeEntriesError({});
              }

              setTimeEntries(
                timeEntires.map((ele) => ({
                  ...ele,
                  billable,
                }))
              );
            }}
          />{' '}
          <span className="slider round"></span>
        </label>
      </div>
    </>
  );
};

export default MarkAllAsBillableToggleSwitch;
