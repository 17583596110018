import { actionTypes } from "constants/types";
let initialState = {
    isLoading:false,
    calendarSettings:[],
    error:""
}

export default function CalendarReducer(state = initialState, action={}) {
    switch(action.type){
       case actionTypes.GET_CALENDAR_SETTINGS_STARTED:
           return{
               ...state,
               isLoading:true,
               calendarSettings:{}
           }
        case actionTypes.GET_CALENDAR_SETTINGS_SUCCESS:
            return{
                ...state,
                isLoading:false,
                calendarSettings:action.data,
                error:""
            }
        case actionTypes.GET_CALENDAR_SETTINGS_FAILURE:
            return{
                ...state,
                isLoading:false,
                calendarSettings:{},
                error:action.error
            }
       default:
           return state;
    }
}
