import { getPracticeAreas } from 'services/CaseServices';
import { actionTypes } from 'constants/types';
import Cookies from 'universal-cookie';
const cookie = new Cookies();

export const getPracticeAreaAction = (pageLimit = 10, pagination_token = '', pagination_direction = 'forward') => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_PRACTICE_AREA_REQUEST });
    return getPracticeAreas(tenantId, tenantSk, pageLimit, pagination_token, pagination_direction)
      .then((response) =>
        dispatch({
          type: actionTypes.GET_PRACTICE_AREA_SUCCESS,
          payload: response.data,
        })
      )
      .catch((error) =>
        dispatch({
          type: actionTypes.GET_PRACTICE_AREA_ERROR,
          payload: error.response,
        })
      );
  };
};
