import { useState, useRef, useEffect } from 'react';

function useDivProperties() {
  const divRef = useRef(null);
  const [divProperties, setDivProperties] = useState({
    offsetHeight: 0,
    offsetWidth: 0,
    scrollHeight: 0,
    scrollWidth: 0,
    clientHeight: 0,
    clientWidth: 0,
  });

  const updateDivProperties = () => {
    if (divRef.current) {
      const { offsetHeight, offsetWidth, scrollHeight, scrollWidth, clientHeight, clientWidth } = divRef.current;
      setDivProperties({ offsetHeight, offsetWidth, scrollHeight, scrollWidth, clientHeight, clientWidth });
    }
  };

  useEffect(() => {
    // Update properties on mount
    updateDivProperties();

    // Add resize listener to update properties on window resize
    window.addEventListener('resize', updateDivProperties);

    // Cleanup the listener on unmount
    return () => {
      window.removeEventListener('resize', updateDivProperties);
    };
  }, []);

  return [divRef, divProperties];
}

export default useDivProperties;
