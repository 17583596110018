import { useEffect, useState } from 'react';
import { getOfficeLocations } from 'services/Settings';

export const useOfficeLocation = () => {
  const [officeLocationList, setOfficeLocationList] = useState(null);
  const [modifiedOfficeLocationList, setModifiedOfficeLocationList] = useState(null);

  const onLoadOfficeLocation = () => {
    getOfficeLocations().then((response) => {
      setOfficeLocationList(response?.data);
    });
  };

  useEffect(() => {
    if (officeLocationList?.length > 0) {
      const modifiedData = officeLocationList?.map((item) => {
        return item?.is_primary_office === 'true'
          ? {
              ...item,
              office_nameLabel: `${item.office_name} (Primary Office)`,
            }
          : {
              ...item,
              office_nameLabel: item.office_name,
            };
      });
      setModifiedOfficeLocationList(modifiedData);
    }
  }, [officeLocationList]);

  useEffect(() => {
    onLoadOfficeLocation();
  }, []);

  return { officeLocationList, modifiedOfficeLocationList };
};
