import React from 'react';

import MessageListTable from './Components/MessageListTable';
import FilterComponent from './Components/FilterComponent';
import { useSecureMessageContext } from '../Context/SecureMessageContext';

function MessageListPage() {
  const { caseMessages } = useSecureMessageContext();

  return (
    <>
      <FilterComponent />

      {caseMessages ? (
        <MessageListTable caseMessages={caseMessages} />
      ) : (
        <div className="d-flex border-bottom mb-2">
          <div className="">No Messages Found</div>
        </div>
      )}
    </>
  );
}

export default MessageListPage;
