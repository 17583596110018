import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Dialog } from 'primereact/dialog';

import { ModalHeader } from 'shared/ModalHeader';
import { useAccounts } from 'hooks/useAccounts';
import TemporaryFundHeader from './facets/TemporaryFundHeader';
import TemporaryFundCaseName from './facets/TemporaryFundCaseName';
import TemporaryFundTrustAccount from './facets/TemporaryFundTrustAccount';
import TemporaryFundContact from './facets/TemporaryFundContact';
import TemporaryFundAmount from './facets/TemporaryFundAmount';
import TemporaryFundDate from './facets/TemporaryFundCreateDate';
import TemporaryFundDescription from './TemporaryFundDescription';
import TemporaryFundFormButton from './TemporaryFundFormButton';
import { generateFullName } from 'utils/generateFullNameUtils';
import { temporaryFundDefaulftFormValues } from '../../configs/temporaryFundConfigs';

const WithdrawOrDepositTemporaryFund = (props) => {
  const { onHide, caseDetails, temporaryFundModalOptions, depositOrWithdrawTemporaryFundAmount, updateTemporaryFundTransactions } = props;

  const caseName = caseDetails?.case_description?.case_name || '';
  const defaultTrustAccountDetails = caseDetails?.default_trust_account?.length ? caseDetails?.default_trust_account?.[0] : null;
  const modalType = temporaryFundModalOptions?.entryType === 'db' ? 'Withdraw' : 'Deposit';
  const shouldCallListAccountApi = defaultTrustAccountDetails?.account_name ? false : true;

  const isUpdateScreen = temporaryFundModalOptions?.isUpdate;

  const { accountList: trustAccountsList = [] } = useAccounts({ accountType: 'trust', shouldCallListAccountApi });

  const [toggleAddNewContact, setToggleAddNewContact] = useState(false);
  const [caseContactsList, setCaseContactsList] = useState([]);

  // react-hook form validation
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: temporaryFundDefaulftFormValues });

  const handleHideModal = () => {
    onHide();
    reset();
  };

  const onSubmit = (formValues) => {
    try {
      let palyloadData = {};

      const contactDetails =
        temporaryFundModalOptions?.entryType === 'cr' ? { fullName: formValues?.fromOrToContact } : formValues?.fromOrToContact;

      palyloadData['case_id'] = caseDetails?.case_id;
      palyloadData['case_name'] = caseDetails?.case_description?.case_name;
      palyloadData['entry_type'] = temporaryFundModalOptions?.entryType;
      palyloadData['amount'] = formValues?.amount?.toString();
      palyloadData['description'] = formValues?.description;
      palyloadData['record_created_date'] = formValues?.recordCreateDate;
      palyloadData['account_name'] = defaultTrustAccountDetails?.account_name || formValues?.trustAccount?.account_name;
      palyloadData['account_id'] = defaultTrustAccountDetails?.account_id || formValues?.trustAccount?.account_id;
      palyloadData['contact_details'] = contactDetails;
      palyloadData['deposit_into'] = '';

      const apiFunctionParams = {
        data: palyloadData,
        hideModal: handleHideModal,
      };

      if (!isUpdateScreen) {
        depositOrWithdrawTemporaryFundAmount(apiFunctionParams);
      } else {
        apiFunctionParams['data']['pk'] = temporaryFundModalOptions?.selectedRowData?.pk;
        apiFunctionParams['data']['sk'] = temporaryFundModalOptions?.selectedRowData?.sk;
        updateTemporaryFundTransactions(apiFunctionParams);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getCaseContactsList = () => {
    let allCaseContacts = [...caseDetails?.client_details, ...caseDetails?.relations];
    let formattedCaseContacts = allCaseContacts?.map((contact) => {
      return {
        ...contact,
        fullName: generateFullName(contact),
      };
    });

    setCaseContactsList(formattedCaseContacts);
  };

  useEffect(() => {
    getCaseContactsList();
  }, [caseDetails]);

  // update temporay fund data
  useEffect(() => {
    if (isUpdateScreen) {
      const { entry_type, account_name, account_id, contact_details, amount, record_created_date, description } =
        temporaryFundModalOptions?.selectedRowData;
      /** --- Setting multiple form values at a time --- **/
      reset((formValues) => {
        return {
          ...formValues,
          trustAccount: {
            account_type: 'trust',
            account_name,
            account_id,
          },
          fromOrToContact: entry_type === 'cr' ? contact_details?.fullName : { ...contact_details },
          recordCreateDate: new Date(record_created_date),
          amount,
          description,
        };
      });
    }
  }, [temporaryFundModalOptions]);

  return (
    <Dialog
      visible
      closable={false}
      onHide={onHide}
      header={ModalHeader}
      className="create-msg-popup" //className should be checked and changed
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          {/* Modal heading */}
          <TemporaryFundHeader modalType={modalType} />

          {/* Case name */}
          <TemporaryFundCaseName caseName={caseName} />

          {/* Trust account */}
          <TemporaryFundTrustAccount
            defaultTrustAccountDetails={defaultTrustAccountDetails}
            trustAccountsList={trustAccountsList}
            control={control}
            errors={errors}
          />

          {/* To/from */}
          <TemporaryFundContact
            control={control}
            errors={errors}
            temporaryFundModalOptions={temporaryFundModalOptions}
            toggleAddNewContact={toggleAddNewContact}
            setToggleAddNewContact={setToggleAddNewContact}
            caseContactsList={caseContactsList}
          />

          {/* Amount */}
          <TemporaryFundAmount control={control} errors={errors} />

          {/* Date */}
          <TemporaryFundDate control={control} errors={errors} />

          {/* Description */}
          <TemporaryFundDescription control={control} errors={errors} />
        </div>

        <hr></hr>

        {/* Button section */}
        <TemporaryFundFormButton handleHideModal={handleHideModal} modalType={modalType} isUpdateScreen={isUpdateScreen} />
      </form>
    </Dialog>
  );
};

export default WithdrawOrDepositTemporaryFund;
