import React from 'react';

const TemporaryFundCaseName = (props) => {
  const { caseName } = props;
  return (
    <div className="d-flex align-items-top py-2 flex-wrap">
      <div className="col-12 col-sm-4 pt-2">Case</div>
      <div className="col-12 col-sm-8 pt-2">{caseName}</div>
    </div>
  );
};

export default TemporaryFundCaseName;
