import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Controller, useForm } from 'react-hook-form';

export default function Form({ numberOptions, submit, fetchPhoneNumbers, loading }) {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    control,
  } = useForm();
  const areaCode = watch('areaCode');

  useEffect(() => {
    if (areaCode?.length === 3) {
      fetchPhoneNumbers && fetchPhoneNumbers(areaCode);
    }
  }, [areaCode]);

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="row">
        <div className="col-lg-4" />
        <div className="col-1">
          <div className="p-fluid">
            <div className="p-field mt-3">
              <InputText
                defaultValue=""
                className="border shadow-middle"
                value={areaCode}
                placeholder="Area Code"
                {...register('areaCode', { required: 'Required', minLength: 3 })}
              />
              {errors?.areaCode && (
                <small className="p-error p-d-block">
                  {errors.areaCode?.type === 'minLength' ? 'Invalid Code' : errors?.areaCode?.message}
                </small>
              )}
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="p-fluid">
            <div className="p-field mt-3">
              <Controller
                name="selectedNumber"
                control={control}
                rules={{ required: 'Required' }}
                defaultValue=""
                render={({ field }) => (
                  <Dropdown
                    options={numberOptions}
                    placeholder="Select a phone"
                    className="border"
                    filter
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    dropdownIcon={loading ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-down'}
                  />
                )}
              />
              {errors?.selectedNumber && <small className="p-error p-d-block">Phone number is required</small>}
            </div>
          </div>
          <Button type="button" label="Select" className="mt-3 p-button-secondary" onClick={handleSubmit(submit)} />
        </div>
        <div className="col-4" />
      </div>
    </form>
  );
}
