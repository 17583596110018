import React, { useEffect } from 'react';

import { Controller } from 'react-hook-form';

import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';
import LabelName from 'components/UI/LabelName/LabelName';

import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import CaseListDropDown from 'components/CaseListDropDown/CaseListDropDown';

function CaseName({ control, notLinkedToCaseWatcher, errors, setValue }) {
  const { versionFileUpload, selectedItem, fileManagerFilters, isClientFileManager } = useFileManagerContext();

  useEffect(() => {
    if (fileManagerFilters?.case) {
      let caseItem = fileManagerFilters?.case || {};
      setValue('case', caseItem);
    }
  }, [fileManagerFilters?.case]);

  useEffect(() => {
    if (versionFileUpload) {
      setValue('case', { id: versionFileUpload?.case_id, label: versionFileUpload?.case_name });
    }
  }, []);

  return (
    <div className="case-search-wrapper d-flex py-2 flex-wrap file-manager-case-wrap">
      <div className="col-md-3 col-12">
        <LabelName required={!notLinkedToCaseWatcher} htmlFor="case" className="mt-2">
          Case/Lead
        </LabelName>
      </div>
      <div className="col-md-9 col-12">
        <Controller
          name="case"
          control={control}
          rules={{
            required: !notLinkedToCaseWatcher ? true : false,
            validate: (value) => {
              return !notLinkedToCaseWatcher ? typeof value === 'object' : true;
            },
          }}
          render={({ field }) => (
            <CaseListDropDown
              isClient={isClientFileManager}
              value={field?.value}
              onChange={(e) => {
                if (field?.value !== e) {
                  field.onChange(e);
                }
              }}
              name="case"
              placeholder="Case/Lead Name"
              noOptionsMessage="No Cases/Leads Found"
              isClearable
              disabled={notLinkedToCaseWatcher || (versionFileUpload && selectedItem.case_id !== 'undefined') || fileManagerFilters.case}
            />
          )}
        />
        {errors?.case?.type === 'required' && <InputErrorMessage>Please select case/lead</InputErrorMessage>}
        {errors?.case?.type === 'validate' && <InputErrorMessage>Please select valid case/lead</InputErrorMessage>}
      </div>
    </div>
  );
}

export default CaseName;
