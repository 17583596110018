import React from 'react';
import { Button } from 'primereact/button';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';

function CreateFolderButton() {
  const { modals, isClosed } = useFileManagerContext();
  return (
    <Button
      label="Create Folder"
      className="p-button p-button-primary ms-3"
      onClick={() => modals.setShowCreateFolderModal(true)}
      disabled={isClosed}
    />
  );
}

export default CreateFolderButton;
