import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { Controller, useForm } from 'react-hook-form';

import { loadIcon } from 'utils/utils';
import constants from 'constants';
import InfiniteScrollList from './InfiniteScrollList';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

const getFileType = (fileData) => {
  try {
    let file_type = fileData?.type?.toString();
    file_type = constants.upload_doc_types[file_type];
    return file_type;
  } catch (error) {
    return false;
  }
};

export default function MessageBox(props) {
  const {
    isClosed,
    isNewThread,
    createNewThread,
    uploadindStatus,
    listRef,
    nextToken,
    caseLeadList,
    messages,
    loading,
    fetchMessageHistory,
    file,
    removeFile,
    sendMessage,
    selectedThread,
    newThreadActive,
    navigateToLatest,
    fileOnChange,
    isClientOptIn,
  } = props;

  const hiddenFileInput = React.useRef(null);
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
    trigger,
  } = useForm({ shouldValidate: true });

  const handleFileUploadClick = () => hiddenFileInput?.current?.click();

  const submit = async (data) =>
    sendMessage(data).then(() => {
      reset();
      handleClear();
    });

  const validate = (message) => Boolean(file || message?.trim());

  const handleDrop = (e) => {
    e.preventDefault();
    fileOnChange(e);
  };

  // Trigger validation on filechange
  useEffect(() => {
    trigger();
  }, [file]);

  useEffect(() => {
    reset({});
  }, [selectedThread]);

  const handleClear = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = '';
    }
    removeFile();
  };

  return (
    <div className="col-sm-8 col-12">
      <InfiniteScrollList {...{ nextToken, listRef, selectedThread, messages, loading, fetchMessageHistory }} />
      <div className="mb-2">
        {isClientOptIn === false && (
          <div className="flex-column mb-3 d-flex justify-content-center align-items-center">
            <div className="mt-4 F-size14 pb-2">You can't send a message. The client has not opted to receive a Text Message.</div>
          </div>
        )}
        {selectedThread && (
          <div className="flex-column mb-3 d-flex justify-content-center align-items-center">
            {selectedThread?.is_active_user === false && (
              <div className="mt-4 F-size14 pb-2">You can't send message. Contact is Inactive!</div>
            )}
            {selectedThread?.removed_phone_number === true && (
              <div className="mt-4 F-size14 pb-2">You can't send message. Contact phone number is removed</div>
            )}
            {!selectedThread?.is_active && Boolean(selectedThread?.removed_phone_number) === false && (
              <div className="mt-3 F-size14 pb-2">Changed their phone number to a new number ({selectedThread?.latest_phone_number})</div>
            )}
            {caseLeadList?.length === 0 && (
              <div className="mt-5 F-size14 pb-2 text-center">
                Message cannot be sent to this thread, since this contact is not been assigned to any case or Lead.
              </div>
            )}
            {!newThreadActive && !isNewThread && Boolean(selectedThread?.removed_phone_number) === false ? (
              <Button
                className="p-button-secondary"
                type="button"
                label="Create A New Thread"
                disabled={isClosed}
                onClick={() => createNewThread(selectedThread?.latest_phone_number)}
              />
            ) : !selectedThread.is_active && Boolean(selectedThread?.removed_phone_number) === false ? (
              <Button
                className="p-button-secondary"
                type="button"
                label="Go To Thread"
                onClick={() => navigateToLatest(selectedThread.contact_id)}
              />
            ) : null}
          </div>
        )}
        {selectedThread?.is_active &&
          caseLeadList?.length > 0 &&
          !isClosed &&
          selectedThread?.is_active_user !== false &&
          Boolean(selectedThread?.removed_phone_number) === false &&
          isClientOptIn && (
            <div className="col-sm-12">
              <form onSubmit={handleSubmit(submit)}>
                <div className="text-message-input-wrapper">
                  <div onDrop={handleDrop} style={{ position: 'relative' }}>
                    <Controller
                      name="message"
                      rules={{ validate }}
                      render={({ field }) => (
                        <TextSnippetWrapper
                          type="textarea"
                          rows={4}
                          cols={30}
                          autoResize
                          className="border shadow-small w-100 text-message-input"
                          placeholder="Send a Message..."
                          {...field}
                        />
                      )}
                      control={control}
                      defaultValue={''}
                    />

                    <div className="d-flex align-items-center justify-content-end">
                      <i className="pi pi-upload pointer me-3" onClick={handleFileUploadClick} />
                      <input type="file" onChange={fileOnChange} style={{ display: 'none' }} ref={hiddenFileInput} />
                      <Button
                        className="p-button-secondary"
                        type="button"
                        disabled={!Boolean(isValid) || !selectedThread?.is_active || uploadindStatus}
                        label="Send"
                        loading={uploadindStatus}
                        onClick={handleSubmit(submit)}
                      />
                    </div>
                  </div>
                </div>

                <div className="mb-3 mt-2">
                  {file && (
                    <div className="d-flex align-items-center ms-2">
                      <i className="fas fa-paperclip me-2"></i>
                      {getFileType(file) && loadIcon(getFileType(file))}
                      <span className="d-flex">
                        <p className="text-decoration-underline mb-0 text-muted">{file.name}</p> {uploadindStatus && '- Uploading...'}
                      </span>
                      {!Boolean(uploadindStatus) && (
                        <div className="d-flex ms-2">
                          <i onClick={handleClear} className="icon-delete ms-2 pointer icon-red" />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </form>
            </div>
          )}
      </div>
    </div>
  );
}
