import { updateFile, deleteBulkFile, moveBulkFile } from 'services/fileManager/fileManagerServices';

export default function useUpdateFiles() {
  const fileBulkDelete = (data, operation, is_lead) => {
    return deleteBulkFile(data, operation, is_lead);
  };

  const fileBulkMove = (data, operation, is_lead) => {
    return moveBulkFile(data, operation, is_lead);
  };

  return { updateFiles: updateFile, fileBulkDelete, fileBulkMove };
}
