export const outlookConfig = {
  appId: process.env.REACT_APP_MICROSOFT_APP_ID,
  // redirectUri: process.env.REACT_APP_URL,
  redirectUri: window?.location?.origin,
  scopes: ["user.read", "mailboxsettings.read", "calendars.readwrite"],
};

export const googleConfig = {
  clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  scopes: `https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar`,
  discoveryScopes: [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ],
  scriptSrc: "https://apis.google.com/js/client.js",
};
