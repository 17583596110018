export const priorityDropDownOptionTemplate = (data) => {
  let actualData = data || {};
  let iconClassName = '';
  if (actualData?.label === 'High') {
    iconClassName = 'icon-High-filled';
  } else if (actualData?.label === 'Medium') {
    iconClassName = 'icon-Medium-filled';
  } else if (actualData?.label === 'Low') {
    iconClassName = 'icon-Low-filled';
  } else if (actualData?.label === 'No Priority') {
    iconClassName = 'icon-Error';
  }

  return (
    <div>
      <i className={`${iconClassName}`}></i>
      <span className="ms-1">{data?.label}</span>
    </div>
  );
};

export const priorityDropDownValueTemplate = (data, item) => {
  let iconClassName = '';
  let actualData = data ?? {};

  if (actualData?.label === 'High') {
    iconClassName = 'icon-High-filled';
  } else if (actualData?.label === 'Medium') {
    iconClassName = 'icon-Medium-filled';
  } else if (actualData?.label === 'Low') {
    iconClassName = 'icon-Low-filled';
  } else if (actualData?.label === 'No Priority') {
    iconClassName = 'icon-Error no-priority';
  }
  return (
    <div>
      <i className={`${iconClassName}`}></i>
      {!data?.label && item?.placeholder}
    </div>
  );
};

export const priorityTemplate = (option, item) => {
  let iconClassName = '';
  if (option?.label === 'High') {
    iconClassName = 'icon-High-filled';
  } else if (option?.label === 'Medium') {
    iconClassName = 'icon-Medium-filled';
  } else if (option?.label === 'Low') {
    iconClassName = 'icon-Low-filled';
  } else if (option?.label === 'No Priority') {
    iconClassName = 'icon-Error no-priority';
  }
  return (
    <>
      <i className={`${iconClassName} pe-1`}></i>
      {option?.label || item?.placeholder}
    </>
  );
};
