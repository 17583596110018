import { actionTypes } from "constants/types";
const initialState = {
  officeLocationList: [],
  isLoading: false,
  causeError: "",
};
export default function OfficeLocationReducer(state = initialState, action={}) {
  switch (action.type) {
    case actionTypes.GET_OFFICE_LOCATIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_OFFICE_LOCATIONS_SUCCESS:
      return {
        ...state,
        officeLocationList: action.payload,
        isLoading: false,
        causeError: "",
      };
    case actionTypes.GET_OFFICE_LOCATIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        causeError: action.payload,
      };
    default:
      return state;
  }
}
