import React from 'react';

function CustomHeader({ onHide, title }) {
  return (
    <div>
      <div className="d-flex justify-content-between">
        <div>
          <i className="icon-window-filled pointer F-size14" onClick={onHide}></i>
          {/* <i className="icon-window-filled pointer F-size14 icon-minimize"></i> */}
        </div>
        <h5 className="sub-title mb-0">{title}</h5>
        <div></div>
      </div>
    </div>
  );
}

export default CustomHeader;
