import { actionTypes } from "constants/types";
const initialState={
    leadStageList:[],
    isLoading:false,
    causeError:"",
  
}
export default function LeadStageReducer(state = initialState, action={}){
    switch(action.type){
        case actionTypes.GET_LEAD_STAGE_REQUEST:
            return {
                ...state,
                isLoading:true, 
            }
            case actionTypes.GET_LEAD_STAGE_SUCCESS:
                return {
                    ...state,
                    leadStageList:action.payload,
                    isLoading:false,
                    causeError:""
                }
            case actionTypes.GET_LEAD_STAGE_ERROR:
                return {
                    ...state,
                    isLoading:false,
                    causeError:action.payload
                }
        default:
            return state;
    }
}