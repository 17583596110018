import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';

const useAxiosLoader = () => {
  const [counter, setCounter] = useState(0);
  const inc = useCallback(() => setCounter((counter) => counter + 1), []);
  const dec = useCallback(() => setCounter((counter) => counter - 1), []);

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        if (!config.excludeFromLoading) {
          inc();
        }
        return config;
      },
      (error) => {
        dec();
        return Promise.reject(error);
      }
    );

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        if (!response.config.excludeFromLoading) {
          dec();
        }
        return response;
      },
      (error) => {
        dec();
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [inc, dec]);

  return [counter > 0];
};

export default useAxiosLoader;
