import { Switch, useRouteMatch, Route } from 'react-router-dom';
import { SecureMessageContextProvider } from './Context/SecureMessageContext';
import MessageDetails from './MessageDetailsPage/MessageDetails';
import MessagesGlobalScreen from './MessagesGlobalScreen';

function MessageRouter(props) {
  let { path } = useRouteMatch();
  return (
    <SecureMessageContextProvider {...props} isGlobalMessagesScreen>
      <Switch>
        <Route exact path={path} component={MessagesGlobalScreen} />
        <Route path={`${path}/:message_id`} component={MessageDetails} />
      </Switch>
    </SecureMessageContextProvider>
  );
}

export default MessageRouter;
