import { actionTypes } from "constants/types";

let initialState = {
    payment_records:[],
    isLoading:false,
    error:""
}

export default function PaymentHistoryReducer(state = initialState, action={}){

    switch(action.type){
        case actionTypes.GET_PAYMENT_RECORD_LIST_STARTED:
            return{
                ...state,
                isLoading:true,
            }
        case actionTypes.GET_PAYMENT_RECORD_LIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                payment_records:action.data
            }
        case actionTypes.GET_PAYMENT_RECORD_LIST_FAILED:
            return{
                ...state,
                isLoading:false,
                error:action.error
            }

        default:
            return state;
    }

}