import React from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { adjustmentItems, adjustmentTypes, appliedToItems } from 'modules/billing/CreateInvoice/configs/create_invoice_configs';
import { fixToTwoDecimal, formatNumbersToCurrencyString } from 'utils/utils';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

const Adjustments = (params) => {
  //TODO:REMOVE direct state mutations - must fix
  //TODO:create body and editor templates
  const {
    isFlatFee,
    adjustments,
    addAdjustment,
    adjustmentError,
    setAdjustments,
    setAdjustmentError,
    isChanged,
    setIsChanged,
    deleteAdjustment,
    adjustmentTotal,
    discount,
    flatFeeTotal,
    rateWithTax,
    expenseTotal,
    subTotalAmount,
  } = params || {};
  return (
    <div className="mt-4">
      <div className="col-12 caption-bold mb-3">Adjustments</div>
      <div className="shadow-small">
        <div className="row mt-3">
          <div className="col-12 datatable-responsive">
            <DataTable
              className="p-datatable-responsive"
              value={adjustments}
              emptyMessage={() => <div onClick={addAdjustment}>No records found</div>}
            >
              <Column
                header="Item"
                body={(rowData, props) => {
                  return (
                    <>
                      <span className="p-column-title text-break">Item</span>
                      <span className="text-break">{rowData.adjustment_item ? rowData.adjustment_item : <i>Please Enter</i>}</span>
                      <br></br>
                      {adjustmentError && adjustmentError[`${props.rowIndex}_item`] && (
                        <span className="text-danger">{adjustmentError[`${props.rowIndex}_item`]}</span>
                      )}
                    </>
                  );
                }}
                editor={(props) => {
                  return (
                    <div>
                      <Dropdown
                        options={adjustmentItems}
                        value={{
                          adjustment_item: props?.rowData?.adjustment_item,
                          adjustment_item_id: props?.rowData?.adjustment_item_id,
                        }}
                        optionLabel="adjustment_item"
                        onChange={(e) => {
                          let index = props.rowIndex;
                          adjustments[index].adjustment_item = e.target.value?.adjustment_item;
                          adjustments[index].adjustment_item_id = e.target.value?.adjustment_item_id;
                          setAdjustments([...adjustments]);
                          adjustmentError[`${props.rowIndex}_item`] = '';
                          setAdjustmentError({
                            ...adjustmentError,
                          });
                          isChanged.adjustments = true;
                          setIsChanged({ ...isChanged });
                        }}
                        filter
                      />
                      {adjustmentError && adjustmentError[`${props.rowIndex}_item`] && (
                        <span className="text-danger">{adjustmentError[`${props.rowIndex}_item`]}</span>
                      )}
                    </div>
                  );
                }}
              />
              <Column
                header="Applied To"
                body={(rowData, props) => {
                  return (
                    <>
                      <span className="p-column-title text-break">Applied To</span>
                      <span className="text-break">{rowData.adjustment_to ? rowData.adjustment_to : <i>Please Enter</i>}</span>
                      <br></br>
                      {adjustmentError && adjustmentError[`${props.rowIndex}_applied_to`] && (
                        <span className="text-danger">{adjustmentError[`${props.rowIndex}_applied_to`]}</span>
                      )}
                    </>
                  );
                }}
                editor={(props) => {
                  return (
                    <div>
                      <Dropdown
                        options={appliedToItems?.filter((v) => !Boolean(isFlatFee && v.adjustment_to_id === 1))}
                        value={{
                          adjustment_to: props?.rowData?.adjustment_to,
                          adjustment_to_id: props?.rowData?.adjustment_to_id,
                        }}
                        optionLabel="adjustment_to"
                        onChange={(e) => {
                          let index = props.rowIndex;
                          adjustments[index].adjustment_to = e.target.value?.adjustment_to;
                          adjustments[index].adjustment_to_id = e.target.value?.adjustment_to_id;
                          switch (e.target.value?.adjustment_to) {
                            case 'Flat Fees':
                              adjustments[index].basic_amount = flatFeeTotal;
                              break;
                            case 'Time Entries':
                              adjustments[index].basic_amount = rateWithTax;
                              break;
                            case 'Expenses':
                              adjustments[index].basic_amount = expenseTotal;
                              break;
                            case 'Sub Total':
                              adjustments[index].basic_amount = subTotalAmount;
                              break;
                            default:
                              break;
                          }

                          setAdjustments([...adjustments]);
                          adjustmentError[`${props.rowIndex}_applied_to`] = '';
                          setAdjustmentError({
                            ...adjustmentError,
                          });
                          isChanged.adjustments = true;
                          setIsChanged({ ...isChanged });
                        }}
                        filter
                      />
                      {adjustmentError && adjustmentError[`${props.rowIndex}_applied_to`] && (
                        <span className="text-danger">{adjustmentError[`${props.rowIndex}_applied_to`]}</span>
                      )}
                    </div>
                  );
                }}
              />
              <Column
                header="Type"
                body={(rowData, props) => {
                  return (
                    <>
                      <span className="p-column-title text-break">Type</span>
                      <span className="text-break">{rowData.adjustment_type ? rowData.adjustment_type : <i>Please Enter</i>}</span>
                      <br></br>
                      {adjustmentError && adjustmentError[`${props.rowIndex}_type`] && (
                        <span className="text-danger">{adjustmentError[`${props.rowIndex}_type`]}</span>
                      )}
                    </>
                  );
                }}
                editor={(props) => {
                  return (
                    <div>
                      <Dropdown
                        options={adjustmentTypes}
                        value={{
                          adjustment_type_id: props?.rowData?.adjustment_type_id,
                          adjustment_type: props?.rowData?.adjustment_type,
                        }}
                        optionLabel="adjustment_type"
                        onChange={(e) => {
                          let index = props.rowIndex;
                          adjustments[index].adjustment_type = e.target.value?.adjustment_type;
                          adjustments[index].adjustment_type_id = e.target.value?.adjustment_type_id;
                          if (e.target.value?.adjustment_type_id === 2) {
                            adjustments[index].percentage = 0;
                            switch (adjustments[index].adjustment_to) {
                              case 'Flat Fees':
                                adjustments[index].amount = flatFeeTotal;
                                break;
                              case 'Time Entries':
                                adjustments[index].amount = rateWithTax;
                                break;
                              case 'Expenses':
                                adjustments[index].amount = expenseTotal;
                                break;
                              case 'Sub Total':
                                adjustments[index].amount = subTotalAmount;
                                break;
                              default:
                                break;
                            }
                          }
                          setAdjustments([...adjustments]);
                          adjustmentError[`${props.rowIndex}_type`] = '';
                          setAdjustmentError({
                            ...adjustmentError,
                          });
                          isChanged.adjustments = true;
                          setIsChanged({ ...isChanged });
                        }}
                        filter
                      />
                      {adjustmentError && adjustmentError[`${props.rowIndex}_type`] && (
                        <span className="text-danger">{adjustmentError[`${props.rowIndex}_type`]}</span>
                      )}
                    </div>
                  );
                }}
              />
              <Column
                header="Notes"
                field="notes"
                body={(rowData) => {
                  return (
                    <>
                      <span className="p-column-title text-break">Notes</span>
                      <span className="text-break">{rowData.notes ? rowData.notes : <i>Please Enter</i>}</span>
                    </>
                  );
                }}
                editor={(props) => {
                  return (
                    <TextSnippetWrapper
                      type="text"
                      value={props?.rowData?.notes}
                      onChange={(e) => {
                        let index = props.rowIndex;
                        adjustments[index].notes = e.target.value;
                        setAdjustments([...adjustments]);
                        isChanged.adjustments = true;
                        setIsChanged({ ...isChanged });
                      }}
                      className="input-shadow w-100 border-0 p-2"
                    />
                  );
                }}
              />
              <Column
                header="Basis"
                field="basic_amount"
                body={(rowData) => {
                  return (
                    <>
                      <span className="p-column-title text-break">Basis</span>
                      <span className="text-break">{formatNumbersToCurrencyString(rowData.basic_amount)}</span>
                    </>
                  );
                }}
              />
              <Column
                header="Percentage"
                field="percentage"
                body={(rowData, props) => {
                  return (
                    <>
                      <span className="p-column-title text-break">Percentage</span>
                      <span className="text-break">
                        {rowData.percentage ? (
                          rowData.percentage
                        ) : (
                          <i>{adjustments[props.rowIndex]?.adjustment_type_id === 2 ? 'Disabled' : 'Please Enter'}</i>
                        )}
                      </span>
                      <br></br>
                      {adjustmentError && adjustmentError[`${props.rowIndex}_percentage`] && (
                        <span className="text-danger">{adjustmentError[`${props.rowIndex}_percentage`]}</span>
                      )}
                    </>
                  );
                }}
                editor={(props) => {
                  return (
                    <div>
                      <InputText
                        value={props?.rowData?.percentage}
                        onChange={(e) => {
                          let index = props.rowIndex;
                          adjustments[index].percentage = fixToTwoDecimal(e.target.value);
                          setAdjustments([...adjustments]);
                          adjustmentError[`${props.rowIndex}_percentage`] = '';
                          setAdjustmentError({
                            ...adjustmentError,
                          });
                          isChanged.adjustments = true;
                          setIsChanged({ ...isChanged });
                        }}
                        type="number"
                        className="input-shadow w-100 border-0 p-2"
                        min={0}
                        keyfilter={'pnum'}
                        placeholder={adjustments[props.rowIndex]?.adjustment_type_id === 2 ? 'Disabled' : ''}
                        disabled={adjustments[props.rowIndex]?.adjustment_type_id === 2}
                      />
                      {adjustmentError && adjustmentError[`${props.rowIndex}_percentage`] && (
                        <span className="text-danger">{adjustmentError[`${props.rowIndex}_percentage`]}</span>
                      )}
                    </div>
                  );
                }}
              />
              <Column
                header="Amount"
                field="amount"
                body={(rowData, props) => {
                  return (
                    <>
                      <span className="p-column-title text-break">Amount</span>
                      <span className="text-break">{formatNumbersToCurrencyString(rowData.amount)}</span>
                      <br></br>
                      {adjustmentError && adjustmentError[`${props.rowIndex}_amount`] && (
                        <span className="text-danger">{adjustmentError[`${props.rowIndex}_amount`]}</span>
                      )}
                    </>
                  );
                }}
                editor={(props) => {
                  return (
                    <div>
                      <InputText
                        value={props?.rowData?.amount}
                        onChange={(e) => {
                          let index = props.rowIndex;
                          adjustments[index].amount = fixToTwoDecimal(e.target.value);
                          setAdjustments([...adjustments]);
                          adjustmentError[`${props.rowIndex}_amount`] = '';
                          setAdjustmentError({
                            ...adjustmentError,
                          });
                          isChanged.adjustments = true;
                          setIsChanged({ ...isChanged });
                        }}
                        type="number"
                        className="input-shadow w-100 border-0 p-2"
                        min={0}
                        max={adjustments[props.rowIndex]?.basic_amount}
                        keyfilter={'pnum'}
                        disabled={adjustments[props.rowIndex]?.adjustment_type_id === 1}
                      />
                      {adjustmentError && adjustmentError[`${props.rowIndex}_amount`] && (
                        <span className="text-danger">{adjustmentError[`${props.rowIndex}_amount`]}</span>
                      )}
                    </div>
                  );
                }}
              />
              <Column
                body={(rowData, props) => {
                  return (
                    <>
                      <span className="p-column-title text-break">Actions</span>
                      <i
                        className="icon-delete icon-red ms-2 mt-2"
                        onClick={(e) => {
                          deleteAdjustment(props);
                        }}
                      />
                    </>
                  );
                }}
              />
            </DataTable>
          </div>
        </div>
        <div className="d-flex mt-2 text-bold px-2">
          <div className="col col-md-8">Total</div>
          <div className="col col-md-2">Addition {formatNumbersToCurrencyString(adjustmentTotal)}</div>
          <div className="col col-md-2">Discount {formatNumbersToCurrencyString(discount)}</div>
        </div>
        <div className="d-flex mt-2">
          <div className="col-12 p-2">
            <span role="button" className="addfield" onClick={addAdjustment}>
              + Add Adjustment
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Adjustments;
