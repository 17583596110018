import React, { useState } from 'react';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { confirmDialog } from 'primereact/confirmdialog';

import useTextSnippets from '../hooks/useTextSnippets';
import SettingsLayout from '../SettingsLayout/SettingsLayout';
import { tableCell } from 'modules/reports/components/TableCell/TableCell';
import GlobalLoader from 'components/GlobalLoader/GlobalLoader';
import CreateSnippetModal from './CreateSnippetModal';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';

function TextSnippets() {
  const { snippets_list, paginatorTemplate, pageSize, deleteSnippet, createSnippet, updateSnippet } = useTextSnippets({});
  const { addToast } = useToast();

  const [selectedItem, setSelectedItem] = useState(null);
  const [addNewSnippet, setAddNewSnippet] = useState(false);

  const handledeleteSnippet = (item) => {
    confirmDialog({
      message: 'Are you sure you want to delete this snippet?',
      header: 'Delete Snippet',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        deleteSnippet(item?.pk, item?.sk)
          .then((res) => {
            addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.TEXTSNIPPET_DELETE_SUCCESS);
          })
          .catch((err) => {
            addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.TEXTSNIPPET_DELETE_FAILED);
          });
      },
      reject: () => {},
    });
  };

  const actionBody = (rowData) => {
    return (
      <React.Fragment>
        <div>
          <span className="p-column-title text-break">Action</span>
          <span role="button" onClick={() => setSelectedItem(rowData)}>
            <i className="icon-edit" onClick={(e) => {}} id="edit">
              <Tooltip target={'.icon-edit'} position={'bottom'} className="case-tooltip mb-3" showDelay={500}>
                Edit
              </Tooltip>
            </i>
          </span>

          <span role="button">
            <i className="icon-delete ms-2" onClick={() => handledeleteSnippet(rowData)} id="delete">
              <Tooltip target={'.icon-delete'} position={'bottom'} className="case-tooltip mb-3" showDelay={500}>
                Delete
              </Tooltip>
            </i>
          </span>
        </div>
      </React.Fragment>
    );
  };

  const onHide = () => {
    setSelectedItem(null);
    setAddNewSnippet(false);
  };

  const onSubmit = (formData) => {
    if (selectedItem) {
      updateSnippet(formData)
        .then((res) => {
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.TEXTSNIPPET_UPDATE_SUCCESS);
          onHide();
        })
        .catch((err) => {
          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.TEXTSNIPPET_UPDATE_FAILED);
          console.log(err);
        });
    } else {
      createSnippet(formData)
        .then((res) => {
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.TEXTSNIPPET_CREATE_SUCCESS);
          onHide();
        })
        .catch((err) => {
          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.TEXTSNIPPET_CREATE_FAILED);
          console.log(err);
        });
    }
  };

  return (
    <>
      <GlobalLoader />
      <div className="container-fluid px-0">
        <GlobalLoader />
        <div className="row">
          <div className="col-12 d-flex justify-content-between mb-3">
            <h1 className="title gray-800 mb-0">Text Snippets</h1>
            <Button
              label="Add Snippet"
              icon="pi pi-plus"
              className="p-button-raised p-button-rounded p-button-primary"
              onClick={() => setAddNewSnippet(true)}
            />
          </div>
        </div>
        <div className="col-12 team-table datatable-responsive">
          <DataTable
            value={snippets_list}
            responsiveLayout="scroll"
            className="bg-white shadow-middle p-3 p-datatable-responsive"
            emptyMessage="No Data Found."
            paginatorTemplate={paginatorTemplate}
            rows={pageSize}
            sortField=""
            stripedRows
            paginator
            resizableColumns
          >
            <Column header="Snippet" sortable sortField="attorney_details" body={tableCell} field="text_snippet_keyword" />
            <Column header="Expand Text" sortable sortField="leadAttorney.attorney_name" body={tableCell} field="text_snippet_expansion" />
            <Column body={actionBody} />
          </DataTable>
        </div>
        {(Boolean(selectedItem) || addNewSnippet) && <CreateSnippetModal selectedItem={selectedItem} onHide={onHide} onSubmit={onSubmit} />}
      </div>
    </>
  );
}

export const TextSnippetsWrapper = () => {
  return (
    <SettingsLayout>
      <TextSnippets />
    </SettingsLayout>
  );
};
