import { actionTypes } from "constants/types";
let initialState = {
  isLoading: "",
  caseTagList: "",
  caseTagError: "",
};

export default function CaseTagReducer(state = initialState, action={}) {
  switch (action.type) {
    case actionTypes.GET_CASE_TAG_REQUEST:
      return {
        ...state,
        caseTagList: "",
        caseTagError: "",
      };
    case actionTypes.GET_CASE_TAG_SUCCESS:
      return {
        ...state,
        caseTagList: action.payload,
        caseTagError: "",
      };
    case actionTypes.GET_CASE_TAG_ERROR:
      return {
        ...state,
        caseTagList: "",
        caseTagError: action.payload,
      };

    default:
      return state;
  }
}
