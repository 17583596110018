import Cookies from 'universal-cookie';
const cookie = new Cookies();

export const getTenantIdFromLS = () => {
  return cookie.get('tenantId') ?? null;
};

export const getTenantSkFromLS = () => {
  return cookie.get('tenantSk') || null;
};

export const getUserInfoFromLS = () => {
  return JSON.parse(localStorage.getItem('userInfo'));
};

export const setLocalStorageItem = (key, value) => {
  localStorage.setItem(key, value);
};

export const removeLocalStorageItem = (key) => {
  localStorage.removeItem(key);
};

export const getLocalStorageItem = (key) => {
  return localStorage.getItem(key);
};

export const getLocalStorageParseItem = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

export const clearLocalStorageItems = (itemsList = []) => {
  if (itemsList?.length > 0) {
    itemsList.forEach((item) => {
      if (item) {
        removeLocalStorageItem(item);
      }
    });
  }
};

export const setLocalStorageItems = (itemsList = []) => {
  if (itemsList?.length > 0) {
    itemsList.forEach((item) => {
      if (item) {
        setLocalStorageItem(item?.key, item?.value);
      }
    });
  }
};

export const handleSettingSkAndPkToLocalStorage = (sk, pk) => {
  const localStorageItemsArray = [
    { key: 'sk', value: sk },
    { key: 'pk', value: pk },
  ];
  setLocalStorageItems(localStorageItemsArray);
};
