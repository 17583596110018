export const temporaryFundDefaulftFormValues = {
  trustAccount: '',
  fromOrToContact: '',
  amount: '',
  recordCreateDate: '',
  description: '',
};

export const formValidationOptions = {
  trustAccount: {
    name: 'trustAccount',
    rules: { required: 'Account name is Required' },
  },
  fromOrToContact: {
    name: 'fromOrToContact',
    rules: { required: 'Contact name is Required' },
  },
  amount: {
    name: 'amount',
    rules: { required: 'Amount is Required' },
  },
  recordCreateDate: {
    name: 'recordCreateDate',
    rules: { required: 'Date is Required' },
  },
  description: {
    name: 'description',
  },
};
