import { dollarFormat } from 'utils/utils';

export const tableCell = (row, ...rest) => {
  const { header, field, currency } = rest?.[0];
  return (
    <>
      <span className="p-column-title text-break">{header}</span>
      <span>{currency ? dollarFormat(row?.[field]) : row?.[field]}</span>
    </>
  );
};
