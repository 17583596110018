import { actionTypes } from "constants/types";
let initialState = {
  isLoading: false,
  practiceAreaList:"",
  practiceAreaError: "",
};

export default function PracticeAreaReducer(state = initialState, action={}) {
  switch (action.type) {
    case actionTypes.GET_PRACTICE_AREA_REQUEST:
      return {
        ...state,
     
        isLoading:true
      };
    case actionTypes.GET_PRACTICE_AREA_SUCCESS:
      return {
        ...state,
        practiceAreaList: action.payload,
        
        isLoading:false
      };
    case actionTypes.GET_PRACTICE_AREA_ERROR:
      return {
        ...state,
        isLoading:false,
        practiceAreaError: action.payload,
      };

   
    default:
      return state;
  }
}
