import React from 'react';

import LeadItem from './LeadItem';
import CaseItem from './CaseItem';

export default function CaseLeadContainer({ caseLeadList }) {
  return (
    <>
      {Array.isArray(caseLeadList) &&
        caseLeadList.map((data, index) =>
          data?.is_lead ? <LeadItem {...{ data }} key={index} /> : <CaseItem {...{ data }} key={index} />
        )}
    </>
  );
}
