import { performRequestThird } from 'common/apiHandler';
import apiEndPoints from 'common/apiEndPoints';

export const listUserTenants = () => {
  let method = apiEndPoints?.methodType?.GET;
  let url = apiEndPoints?.listUserTenants;
  return performRequestThird(method, url);
};

export const signupMultiTenantEmail = (data = {}) => {
  let method = apiEndPoints?.methodType?.POST;
  let url = apiEndPoints?.signupMultiTenantEmail;
  let timeEntryData = {};
  let isLogin = false;
  return performRequestThird(method, url, data, timeEntryData, isLogin);
};
