import { actionTypes } from "constants/types";

let initialState = {
  isLoading: false,
  eventList: [],
  error: "",
};

export default function EventListReducer(state = initialState, action={}) {
  switch (action.type) {
    case actionTypes.GET_EVENT_LIST_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_EVENT_LIST_SUCCESS:
      return {
        ...state,
        eventList: [...action.data],
        isLoading: false,
      };
    case actionTypes.GET_EVENT_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
}
