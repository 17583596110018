import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { viewLeadDetailsModified } from 'services/lead/leadServices';

export function useLeadDetails() {
  const [leadDetails, setLeadDetails] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      loadLeadDetails();
    }
  }, [id]);

  const loadLeadDetails = (_id) => {
    viewLeadDetailsModified({ sk: id || _id })
      .then((response) => {
        let dat = response.data?.leads;
        dat.client_details = dat.main_client;
        dat.team_member = dat.lead_assign_to;
        dat.relations = [...(dat?.contact || []), ...(dat?.relations || [])];
        setLeadDetails(dat);
      })
      .catch((error) => console.log(error));
  };

  const clearData = () => setLeadDetails(null);

  return {
    leadDetails,
    loadLeadDetails,
    clearData,
  };
}
