import { useState } from 'react';
import { clearClientNotifications, clientNotificationCount } from 'services/notification/notificationService';

export default function useClientNoification() {
  const [notificationCounts, setNotificationCounts] = useState(null);

  const getNotificationCounts = (contact_id) => {
    clientNotificationCount(contact_id)
      .then((res) => {
        setNotificationCounts(res.data || {});
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const readClientNotifications = (module, contact_id) => {
    clearClientNotifications(module, contact_id)
      .then(() => {
        setTimeout(() => {
          getNotificationCounts(contact_id);
        }, 5000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return { notificationCounts, getNotificationCounts, readClientNotifications };
}
