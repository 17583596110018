import { useEffect, useState } from 'react';
import { getCustomFields } from 'services/Settings';
import { totalCountEvent } from 'services/generalServices';

export const useCaseDetailsCustomField = () => {
  const [customFields, setCustomFields] = useState([]);
  const [caseCustomFieldList, setCaseCustomFieldList] = useState([]);
  const [contactCustomFieldList, setContactCustomFieldList] = useState([]);
  const [companyCustomFieldList, setCompanyCustomFieldList] = useState([]);
  const loadCustomFields = async () => {
    try {
      const response = await totalCountEvent('custom-field');
      const res = await getCustomFields('', response.data);
      setCustomFields(res.data?.['custom-field']);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    loadCustomFields();
  }, []);

  useEffect(() => {
    if (customFields.length > 0) {
      setCaseCustomFieldList(customFields.filter((v) => v.module === 'case'));
      setContactCustomFieldList(customFields.filter((v) => v.module === 'contact'));
      setCompanyCustomFieldList(customFields.filter((v) => v.module === 'companies'));
    }
  }, [customFields]);
  return { caseCustomFieldList, contactCustomFieldList, companyCustomFieldList };
};
