import { actionTypes } from "constants/types";
let initialState = {
  isLoading: "",
  caseNoteList: "",
  caseNoteError: "",
};

export default function CaseNoteReducer(state = initialState, action={}) {
  switch (action.type) {
    case actionTypes.GET_CASE_NOTE_REQUEST:
      return {
        ...state,
        caseNoteList: "",
        caseNoteError: "",
        isLoading: true,
      };
    case actionTypes.GET_CASE_NOTE_SUCCESS:
      return {
        ...state,
        caseNoteList: action.payload,
        caseNoteError: "",
        isLoading: false,
      };
    case actionTypes.GET_CASE_NOTE_ERROR:
      return {
        ...state,
        caseNoteList: "",
        caseNoteError: action.payload,
        isLoading: false,
      };

    default:
      return state;
  }
}
