import { getTimeZoneOffsetAsString } from "./timezone";
export const formatTimeAndDate = (
  date,
  returnDateOnly = false,
  returnTimeOnly = false
) => {
  //usa date format “mm-dd-yyyy”
  //incoming format 2022-03-12T10:00:03+05:30
  // console.log();
  // let formattedDateAndTime = "";
  let formattedDate;
  let formattedTime;
  let dateAndTimeArray = date?.toString()?.split("T") ?? null;
  if (dateAndTimeArray?.length !== 0) {
    try {
      let dateArray = dateAndTimeArray[0].split("-");
      let timeArray = dateAndTimeArray[1].split("+");
      formattedDate = dateArray[1] + "/" + dateArray[2] + "/" + dateArray[0];
      formattedTime = timeArray[0];
      let splittedTime = formattedTime.toString().split(":");
      if (splittedTime.length === 3) {
        formattedTime = splittedTime[0] + ":" + splittedTime[1];
      }

      // formattedDateAndTime = formattedDate + " " + formattedTime;
    } catch (err) {
      return "Date can not be formatted";
    }
  }
  if (returnDateOnly) {
    return formattedDate;
  } else if (returnTimeOnly) {
    return formattedTime;
  }

  // return formattedDateAndTime;
  return (
    <div>
      <div>{formattedDate}</div>
      <div>{formattedTime}</div>
    </div>
  );
};

export const convert24HrToAmPm = (time) => {
  //input 18:00:03 or 03:45:25.065347
  try {
    let timeStringArray = time.toString().split(":");
    let hour = +timeStringArray[0];
    let minute = +timeStringArray[1];
    let formattedMinutes = minute < 10 ? `0${minute}` : minute;
    if (hour >= 12) {
      hour = hour % 12 || 12;

      return hour < 10
        ? `0${hour}:${formattedMinutes} PM`
        : `${hour}:${formattedMinutes} PM`;
    } else {
      return hour < 10
        ? `0${hour}:${formattedMinutes} AM`
        : `${hour}:${minute} AM`;
    }
  } catch (err) {
    return "Convertion Failed";
  }
};

export const getTimeStampEmbeddedDate = (
  date = new Date(),
  getStartingTime = false,
  returnUtcTime = false
) => {
  //input =>date object (Sat Apr 30 2022 00:00:00 GMT+0530 (India Standard Time))

  //usa date format “mm-dd-yyyy”
  //output 2022-03-14T01:53:28.638509+00:00
  if (!returnUtcTime) {
    let dd = String(date.getDate()).padStart(2, "0");
    let mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = date.getFullYear();
    let hours = String(date.getHours()).padStart(2, "0");
    let minutes = String(date.getMinutes()).padStart(2, "0");
    let seconds = String(date.getSeconds()).padStart(2, "0");
    let result;
    if (getStartingTime) {
      result =
        yyyy +
        "-" +
        mm +
        "-" +
        dd +
        "T" +
        "01" +
        ":" +
        "00" +
        ":" +
        "00" +
        getTimeZoneOffsetAsString();
    } else {
      result =
        yyyy +
        "-" +
        mm +
        "-" +
        dd +
        "T" +
        hours +
        ":" +
        minutes +
        ":" +
        seconds +
        getTimeZoneOffsetAsString();
    }

    return result;
  } else if (returnUtcTime) {
    let dd = String(date.getUTCDate()).padStart(2, "0");
    let mm = String(date.getUTCMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = date.getUTCFullYear();
    let hours = String(date.getUTCHours()).padStart(2, "0");
    let minutes = String(date.getUTCMinutes()).padStart(2, "0");
    let seconds = String(date.getUTCSeconds()).padStart(2, "0");
    let result;
    if (getStartingTime) {
      result =
        yyyy +
        "-" +
        mm +
        "-" +
        dd +
        "T" +
        "01" +
        ":" +
        "00" +
        ":" +
        "00" +
        "+00:00";
    } else {
      result =
        yyyy +
        "-" +
        mm +
        "-" +
        dd +
        "T" +
        hours +
        ":" +
        minutes +
        ":" +
        seconds +
        "+00:00";
      return result;
    }
  }
};
