import React, { useContext, useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

import { handlePageNumberInPagination } from 'common/pagination';
import { UserDetailsContext } from 'context/userDetailsContext';
import { getCaseNote } from 'services/CaseNoteServices';
import { paginationDropdownOptions } from 'utils/utils';

export function useCaseNotes(_filter, _pageSize = 10, isLeadSpecific, isFromCaseSpecificModule) {
  const userContext = useContext(UserDetailsContext);

  const [data, setData] = useState({});
  const [isForward, setIsForward] = useState(true);
  const [pageSize, setpageSize] = useState(getPageLimit() ?? _pageSize);
  const next_Token = data?.next_token;
  const transition_Token = data?.transition_token;
  const caseNoteList = data?.['case-note'] || [];
  useEffect(() => {
    setpageSize(getPageLimit());
  }, [userContext]);

  const hasNext = next_Token === null && isForward;
  const hasPrevious = transition_Token === null || (transition_Token && next_Token === null && !isForward);

  function getPageLimit() {
    if (isLeadSpecific && userContext) {
      return userContext?.firmDetails?.pagination_preference?.leads?.note;
    } else if (isFromCaseSpecificModule && userContext) {
      return userContext?.firmDetails?.pagination_preference?.case?.note;
    }
  }

  const fetchNext = () => {
    setIsForward(true);
    loadCaseNoteList({
      limit: pageSize,
      pagination_token: !isForward ? transition_Token : next_Token,
      pagination_direction: 'forward',
    });
  };

  const fetchprev = () => {
    setIsForward(false);
    loadCaseNoteList({
      limit: pageSize,
      pagination_token: isForward ? transition_Token : next_Token,
      pagination_direction: 'reverse',
    });
  };

  const onPageChange = (e) => {
    setpageSize(e.value);
    handlePageNumberInPagination(userContext, isLeadSpecific ? 'leads' : 'case', '', 'note', e.value);
    loadCaseNoteList({
      limit: e.value,
      pagination_token: '',
      pagination_direction: 'forward',
    });
    setIsForward(true);
  };

  const loadCaseNoteList = ({ limit = pageSize, pagination_token = '', pagination_direction = 'forward', filter = _filter }) => {
    localStorage.setItem('pagination_token_notes', pagination_token);
    localStorage.setItem('pagination_direction_notes', pagination_direction);
    return getCaseNote(
      filter?.case_id,
      filter?.note_type,
      filter?.keyword,
      filter?.is_assignee,
      limit,
      pagination_token,
      pagination_direction
    )
      .then((response) => setData(response?.data))
      .catch((error) => console.log(error));
  };

  const paginatorTemplate = {
    layout: 'RowsPerPageDropdown PrevPageLink NextPageLink',
    RowsPerPageDropdown: () => {
      return (
        <React.Fragment>
          <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
            Item Per Page
          </span>
          <Dropdown value={pageSize} options={paginationDropdownOptions} onChange={onPageChange} />
        </React.Fragment>
      );
    },
    NextPageLink: ({ iconClassName }) => {
      return (
        <Button onClick={fetchNext} disabled={hasNext} className={'p-paginator-next p-paginator-element p-link'} icon={iconClassName} />
      );
    },
    PrevPageLink: ({ iconClassName }) => {
      return (
        <Button onClick={fetchprev} disabled={hasPrevious} className={'p-paginator-next p-paginator-element p-link'} icon={iconClassName} />
      );
    },
  };

  return { caseNoteList, hasNext, hasPrevious, pageSize, paginatorTemplate, fetchNext, fetchprev, onPageChange, loadCaseNoteList };
}
