import { performRequest, performRequestSecond, performRequestThird } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';
import { FILEUPLOAD_S3_LEVEL, IMGUPLOAD_S3, SetS3Config } from 'config/amplify_config';
import { Storage } from 'aws-amplify';
import Cookies from 'universal-cookie';
const cookie = new Cookies();

const getTenantDetails = () => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return [tenantId, tenantSk];
};
export const createCustomField = (data) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  //as an object {}
  return performRequest(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.createCustomField}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};

export const getCustomFields = (module = '', pageLimit = 10, pagination_token = '', pagination_direction = '') => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.getCustomFieldList}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${pageLimit}&module_name=${module}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`
  );
};

export const deleteCustomField = (pk, sk) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  let skNew = sk.replace('#', '%23');
  let skModified = skNew.replace('#', '%23');

  let pkNew = pk.replace('#', '%23');
  return performRequest(
    apiEndPoints.methodType.DELETE,
    `${apiEndPoints.deleteCustomField}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&pk=${pkNew}&sk=${skModified}`
  );
};

// export /update-position
export const updateCustomFieldOrder = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.PUT,
    `${apiEndPoints.updateCustomFieldOrder}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};

export const editCustomField = (data) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return performRequest(apiEndPoints.methodType.PUT, `${apiEndPoints.updateCustomField}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`, data);
};

export const createCaseMatter = (data) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return performRequest(apiEndPoints.methodType.POST, `${apiEndPoints.createCaseMatter}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`, data);
};
export const updateCaseMatter = (data) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return performRequest(apiEndPoints.methodType.PUT, `${apiEndPoints.updateCaseMatter}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`, data);
};
export const deleteCaseMatter = (pk, sk) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  let skNew = sk.replace('#', '%23');
  let skModified = skNew.replace('#', '%23');
  let pkNew = pk.replace('#', '%23');
  return performRequest(
    apiEndPoints.methodType.DELETE,
    `${apiEndPoints.deleteCaseMatter}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&pk=${pkNew}&sk=${skModified}`
  );
};
export const getCaseMatterList = () => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return performRequest(apiEndPoints.methodType.GET, `${apiEndPoints.getCaseMatter}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`);
};

export const createPracticeArea = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.createPracticeArea}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};
export const updatePracticeArea = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.PUT,
    `${apiEndPoints.updatePracticeArea}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};
export const deletePracticeArea = (pk, sk) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let skNew = sk.replace('#', '%23');
  let skModified = skNew.replace('#', '%23');

  let pkNew = pk.replace('#', '%23');
  return performRequest(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.deletePracticeArea}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&pk=${pkNew}&sk=${skModified}`
  );
};

export const createCaseStage = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(apiEndPoints.methodType.POST, `${apiEndPoints.createCaseStage}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`, data);
};
export const updateCaseStage = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(apiEndPoints.methodType.PUT, `${apiEndPoints.updateCaseStage}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`, data);
};
export const deleteCaseStage = (pk, sk) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let skNew = sk.replace('#', '%23');
  let skModified = skNew.replace('#', '%23');
  let pkNew = pk.replace('#', '%23');
  return performRequest(
    apiEndPoints.methodType.DELETE,
    `${apiEndPoints.deleteCaseStage}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&pk=${pkNew}&sk=${skModified}`
  );
};
export const updateCaseStagePosition = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.updateCaseStagePosition}?tenant_id=${tenantId}&tenant_sk=${tenantSk}
    `,
    data
  );
};
export const listLeadStages = (pageLimit, pagination_token, pagination_direction, lead_stage_status = '') => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.listLeadStages}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}&lead_stage_status=${lead_stage_status}`
  );
};

export const createLeadStages = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(apiEndPoints.methodType.POST, `${apiEndPoints.createLeadStage}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`, data);
};

export const updateLeadStage = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(apiEndPoints.methodType.PUT, `${apiEndPoints.updateLeadStage}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`, data);
};

export const deleteLeadStage = (pk, sk, lead_stage_status = '') => {
  const [tenantId, tenantSk] = getTenantDetails();
  let skNew = sk.replace('#', '%23');
  let skModified = skNew.replace('#', '%23');

  let pkNew = pk.replace('#', '%23');
  return performRequest(
    apiEndPoints.methodType.DELETE,
    `${apiEndPoints.deleteLeadStage}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&pk=${pkNew}&sk=${skModified}&lead_stage_status=${lead_stage_status}`
  );
};

export const getOfficeLocations = () => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(apiEndPoints.methodType.GET, `${apiEndPoints.getOfficeLocations}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`);
};

export const createOfficeLocation = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.createOfficeLocation}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};

export const createCaseCourt = (data) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return performRequest(apiEndPoints.methodType.POST, `${apiEndPoints.createCaseCourt}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`, data);
};

export const updateOfficeLocation = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.PUT,
    `${apiEndPoints.updateOfficeLocation}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};
export const deleteOfficeLocation = (pk, sk) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let skNew = sk.replace('#', '%23');
  let skModified = skNew.replace('#', '%23');

  let pkNew = pk.replace('#', '%23');
  return performRequest(
    apiEndPoints.methodType.DELETE,
    `${apiEndPoints.deleteOfficeLocation}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&pk=${pkNew}&sk=${skModified}`
  );
};

export const createContactGroups = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.createContactGroup}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};

export const createUserGroup = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(apiEndPoints.methodType.POST, `${apiEndPoints.createUserGroup}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`, data);
};

export const listUserGroups = () => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(apiEndPoints.methodType.GET, `${apiEndPoints.listUserGroups}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`);
};

export const listSettingsUserGroups = (pageLimit, pagination_token = '', pagination_direction = '', status = 'ACTIVE') => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.listUserGroups}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}&status=${status}`
  );
};

export const deleteUserGroup = (status, data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    // apiEndPoints.methodType.DELETE,
    apiEndPoints.methodType.POST,
    // `${apiEndPoints.deleteUserGroup}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&user_group_sk=${user_group_sk}&status=${status}`
    `${apiEndPoints.deleteUserGroup}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&status=${status}`,
    data
  );
};

export const updateUserGroup = (user_group_sk, edit_name, data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.PUT,
    `${apiEndPoints.updateUserGroups}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&user_group_sk=${user_group_sk}&edit_name=${edit_name}`,
    data
  );
};

export const createUserRole = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(apiEndPoints.methodType.POST, `${apiEndPoints.createUserRole}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`, data);
};

export const listUserRoles = () => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(apiEndPoints.methodType.GET, `${apiEndPoints.listUserRoles}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`);
};

export const listSettingsUserRoles = (pageLimit, pagination_token = '', pagination_direction = '') => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.listUserRoles}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`
  );
};

export const getRoleDetails = (user_role_sk) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.userRoleDetails}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&user_role_sk=${user_role_sk}`
  );
};

export const updateRoleDetails = (user_role_sk, data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.PUT,
    `${apiEndPoints.updateRoleDetails}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&user_role_sk=${user_role_sk}`,
    data
  );
};

export const deleteUserRole = (user_role_sk) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.DELETE,
    `${apiEndPoints.deleteUserRole}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&user_role_sk=${user_role_sk}`
  );
};

export const deactivateFirmAccount = (status) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestSecond(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.deactivateFirmAccount}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&status=${status}`
  );
};

export const updateFirmName = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.PUT,
    `${apiEndPoints.updateFirmName}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};

export const createSetting = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestSecond(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.createSettings}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};
export const updateSetting = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestSecond(
    apiEndPoints.methodType.PUT,
    `${apiEndPoints.updateSettings}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};

export const getSetting = () => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestSecond(apiEndPoints.methodType.GET, `${apiEndPoints.getSettings}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`);
};

export const resetInvitation = (data = {}) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.resendInvitation}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};

export const getS3Image = async (data) => {
  let url = data.split('/');
  url.pop();
  url.push('small.jpg');
  SetS3Config(IMGUPLOAD_S3, FILEUPLOAD_S3_LEVEL);
  // let user = await Auth.currentAuthenticatedUser();
  // let userId = null;
  try {
    //userId = user.attributes.sub;
    // return await Storage.get(`${url.join("/")}`);
    const newData = await Storage.get(`${url.join('/')}`);
    return newData;
  } catch (err) {}
};

export const getPresignedURLForProfileImg = (file_path = '', file_size = '') => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.getPresignedURL}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&file_path=${file_path}&file_size=${file_size}&is_profile_image=true`;
  return performRequestSecond(apiEndPoints.methodType.GET, url);
};

export const getPresignedURLForUserProfileImg = (tenantId, tenant_sk, file_path, file_size) => {
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  let url = `${apiEndPoints.getPresignedURL}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&file_path=${file_path}&file_size=${file_size}&is_profile_image=true`;
  return performRequestSecond(apiEndPoints.methodType.GET, url);
};

export const listRelationRoles = (pageLimit, pagination_token = '', pagination_direction = '') => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.listRelationRoles}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`
  );
};

export const createRelationRoles = (data = {}) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.createRelationRoles}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};
export const updateRelationRoles = (data = {}) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.PATCH,
    `${apiEndPoints.updateRelationRoles}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};
export const deleteRelationRoles = (pk, sk) => {
  let tenantId = cookie.get('tenantId');
  let tenantSk = cookie.get('tenantSk');
  tenantSk = tenantSk ? tenantSk.replace('#', '%23') : '';
  pk = pk ? pk.replace('#', '%23') : '';
  sk = sk ? sk.replaceAll('#', '%23') : '';
  let url = `${apiEndPoints.deleteRelationRoles}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&pk=${pk}&sk=${sk}`;
  return performRequestThird(apiEndPoints.methodType.DELETE, url);
};

export const listLeadSource = (pageLimit = 10, pagination_token = '', pagination_direction = 'forward') => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.listLeadSource}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`
  );
};

export const createLeadSource = (data = {}) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.createLeadSource}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};
export const updateLeadSource = (data = {}) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.PATCH,
    `${apiEndPoints.updateLeadSource}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};
export const deleteLeadSource = (pk, sk) => {
  let tenantId = cookie.get('tenantId');
  let tenantSk = cookie.get('tenantSk');
  tenantSk = tenantSk ? tenantSk.replace('#', '%23') : '';
  pk = pk ? pk.replace('#', '%23') : '';
  sk = sk ? sk.replaceAll('#', '%23') : '';
  let url = `${apiEndPoints.deleteLeadSource}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&pk=${pk}&sk=${sk}`;
  return performRequestThird(apiEndPoints.methodType.DELETE, url);
};

//settings module  -> job title `
export const createJobTitle = (data) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return performRequestThird(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.createJobTitle}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};

export const getJobTitle = (pageLimit = 10, pagination_token = '', pagination_direction = 'forward', next = '') => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';

  return performRequestThird(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.getJobTitle}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${next ? `&next=${next}` : ''}${
      pageLimit ? `&limit=${pageLimit}` : ''
    }&pagination_token=${pagination_token}&pagination_direction=${pagination_direction} `
  );
};

export const deleteJobTitle = (sk) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';

  return performRequestThird(
    apiEndPoints.methodType.DELETE,
    `${apiEndPoints.deleteJobTitle}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&job_title_sk=${sk}`
  );
};

export const updateJobTitle = (data, sk) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return performRequestThird(
    apiEndPoints.methodType.PATCH,
    `${apiEndPoints.updateJobTitle}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&job_title_sk=${sk}`,
    data
  );
};

//settings module -> global tags

export const createGlobalTags = (data) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return performRequestThird(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.createGlobalTags}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};

export const listGlobalTags = (pageLimit, pagination_token = '', pagination_direction = '') => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return performRequestThird(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.listGlobalTags}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`
  );
};

export const deleteGlobalTags = (pk, sk) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';

  return performRequestThird(
    apiEndPoints.methodType.DELETE,
    `${apiEndPoints.deleteGlobalTags}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&pk=${pk}&sk=${sk}`
  );
};

export const updateGlobalTags = (data, sk) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return performRequestThird(
    apiEndPoints.methodType.PATCH,
    `${apiEndPoints.updateGlobalTags}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&global_tag_sk=${sk}`,

    data
  );
};

//settings-->Business Role

export const createBusinessRole = (data) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return performRequestThird(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.createBusinessRole}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};

export const listBusinessRole = (pageLimit, pagination_token = '', pagination_direction = 'forward') => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';

  return performRequestThird(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.listBusinessRole}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`
  );
};

export const deleteBusinessRole = (pk, sk) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';

  return performRequestThird(
    apiEndPoints.methodType.DELETE,
    `${apiEndPoints.deleteBusinessRole}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&pk=${pk}&sk=${sk}`
  );
};

export const updateBusinessRole = (data, sk) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return performRequestThird(
    apiEndPoints.methodType.PATCH,
    `${apiEndPoints.updateBusinessRole}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&business_role_sk=${sk}`,
    data
  );
};

export const updateOutcome = (outcome_reason_pk, outcome_reason_sk, data) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return performRequestThird(
    apiEndPoints.methodType.PATCH,
    `${apiEndPoints.updateOutcome}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&outcome_reason_pk=${outcome_reason_pk}&outcome_reason_sk=${outcome_reason_sk}`,
    data
  );
};

export const deleteOutcome = (outcome_reason_pk, outcome_reason_sk) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return performRequestThird(
    apiEndPoints.methodType.DELETE,
    `${apiEndPoints.deleteOutcome}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&pk=${outcome_reason_pk}&sk=${outcome_reason_sk}`
  );
};

export const deleteClosureReason = (pk, sk) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return performRequestThird(
    apiEndPoints.methodType.DELETE,
    `${apiEndPoints.deleteClosureReason}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&pk=${pk}&sk=${sk}`
  );
};

export const updateReason = (closure_reason_pk, closure_reason_sk, data) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return performRequestThird(
    apiEndPoints.methodType.PATCH,
    `${apiEndPoints.updateClosureReason}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&closure_reason_pk=${closure_reason_pk}&closure_reason_sk=${closure_reason_sk}`,
    data
  );
};

/** =============================================================  Workflow Automation Related API services starts ==================================================== **/

export const getAutomationRules = (next, practice_area, automation_status, pageLimit, pagination_token, pagination_direction) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestSecond(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.listAutomationRules}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${next ? `&next=${next}` : ''}${
      pageLimit ? `&limit=${pageLimit}` : ''
    }${practice_area ? `&practice_area=${practice_area}` : ''}${
      automation_status ? `&automation_status=${automation_status.replace(/%20/g, ' ').trim()}` : ''
    }&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`
  );
};

export const createAutomationRule = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestSecond(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.createAutomationRules}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};

//Automation Error-Log Related API Services
export const listAutomationErrorLogs = (sk = '', data = {}, page_limit = 10, pagination_token = '', pagination_direction = 'forward') => {
  const [tenantId, tenantSk] = getTenantDetails();

  return performRequestThird(
    apiEndPoints.methodType.POST,
    `${apiEndPoints?.listAutomationErrorLogs}?tenant_id=${tenantId ? tenantId : ''}&tenant_sk=${tenantSk ? tenantSk : ''}&sk=${
      sk ? sk : ''
    }&limit=${page_limit ? page_limit : ''}&pagination_token=${pagination_token ? pagination_token : ''}&pagination_direction=${
      pagination_direction ? pagination_direction : ''
    }`,
    data
  );
};

/** =============================================================  Workflow Automation Related API services ends ==================================================== **/
// venue crud operation
export const getVenueList = (page_limit = 10, pagination_token = '', pagination_direction = 'forward') => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return performRequestThird(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.getVenue}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${page_limit ? page_limit : 10}&pagination_token=${
      pagination_token ? pagination_token : ''
    }&pagination_direction=${pagination_direction ? pagination_direction : ''}`
  );
};

export const createVenue = (data) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  //as an object {}
  return performRequestThird(apiEndPoints.methodType.POST, `${apiEndPoints.createVenue}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`, data);
};

export const updateVenue = (data = {}) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(apiEndPoints.methodType.PUT, `${apiEndPoints.updateVenue}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`, data);
};

//ReviewRequestUrls Cred Operations

export const listSettingsReviewRequestUrls = (
  config_type = '',
  pageLimit,
  pagination_token = '',
  pagination_direction = '',
  is_dropdown = false
) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.listReviewRequestUrls}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&config_type=${config_type}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}&is_dropdown=${is_dropdown}`
  );
};

export const createReviewRequestUrl = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.createReviewRequestUrl}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};

export const updateReviewRequestUrl = (config_type = '', data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.PUT,
    `${apiEndPoints.updateReviewRequestUrl}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&config_type=${config_type}`,
    data
  );
};

export const deleteReviewRequestUrl = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.DELETE,
    `${apiEndPoints.deleteReviewRequestUrl}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};

export const listRequestReviewMessage = (config_type = '') => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.listReviewRequestUrls}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&config_type=${config_type}`
  );
};

export const sendReviewRequest = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.sentReviewRequest}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};
