import React, { useContext } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import constants from 'constants/index';
import { UserDetailsContext } from 'context/userDetailsContext';

export const Notification = (props) => {
  const userContext = useContext(UserDetailsContext);

  const renderFooter = () => {
    return (
      <div>
        <Button label="Cancel" onClick={() => props.onHide()} className="p-button-text" />
      </div>
    );
  };
  return (
    <Dialog header="Notification" visible={props.visible} style={{ width: '40vw' }} footer={renderFooter()} onHide={() => props.onHide()}>
      {props?.notificationData && (
        <>
          {/* {props.notificationData.notification_type && (
            <div className="row mt-2">
              <div className="col-12 col-md-4">Notification Type</div>
              <div className="col-12 col-md-8">
                {props.notificationData.notification_type}
              </div>
            </div>
          )} */}

          {props?.notificationData?.body && (
            <>
              {props.notificationData.body.event_name && (
                <div className="row mt-2">
                  <div className="col-12 col-md-4">Event Name</div>
                  <div className="col-12 col-md-8">{props.notificationData.body.event_name}</div>
                </div>
              )}
              {props?.notificationData?.body?.event_time && (
                <div className="row mt-2">
                  <div className="col-12 col-md-4">Event Start Time</div>
                  <div className="col-12 col-md-8">
                    {handleDateTimeOffset(
                      userContext.userDetails.timezone,
                      props.notificationData.body.event_time,
                      constants.month_date_year_time_seconds_12_format
                    )}
                  </div>
                </div>
              )}
              {props?.notificationData?.body?.event_end_time && (
                <div className="row mt-2">
                  <div className="col-12 col-md-4">Event End Time</div>
                  <div className="col-12 col-md-8">
                    {handleDateTimeOffset(
                      userContext.userDetails.timezone,
                      props.notificationData.body.event_end_time,
                      constants.month_date_year_time_seconds_12_format
                    )}
                  </div>
                </div>
              )}
              {props?.notificationData?.body?.case_name && (
                <div className="row mt-2">
                  <div className="col-12 col-md-4">Case Name</div>
                  <div className="col-12 col-md-8">{props.notificationData.body.case_name}</div>
                </div>
              )}
              {props?.notificationData?.body?.organizer_name && (
                <div className="row mt-2">
                  <div className="col-12 col-md-4">Organizer Name</div>
                  <div className="col-12 col-md-8">{props.notificationData.body.organizer_name}</div>
                </div>
              )}
              {props?.notificationData?.body?.message && (
                <div className="row mt-2">
                  <div className="col-12 col-md-4">Message</div>
                  <div className="col-12 col-md-8">{props.notificationData.body.message}</div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </Dialog>
  );
};
