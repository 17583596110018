import { actionTypes } from "constants/types";
let initialState = {
    showAddLeadPopUp: false,
};

export default function ShowAddLeadPopUpReducer(state = initialState, action={}) {
    switch (action.type) {
      case actionTypes.SHOW_ADD_LEAD:
        return {
          ...state,
  
          showAddLeadPopUp: true,
        };
      case actionTypes.HIDE_ADD_LEAD:
        return {
          ...state,
  
          showAddLeadPopUp: false,
        };
  
      default:
        return state;
    }
  }