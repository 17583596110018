import { actionTypes } from "constants/types";

let initialState = {
    payment_requests:[],
    isLoading:false,
    error:""
}

export default function PaymentRequestReducer(state = initialState, action={}){

    switch(action.type){
        case actionTypes.GET_PAYMENT_REQUEST_LIST_STARTED:
            return{
                ...state,
                isLoading:true,
            }
        case actionTypes.GET_PAYMENT_REQUEST_LIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                payment_requests:action.data
            }
        case actionTypes.GET_PAYMENT_REQUEST_LIST_FAILED:
            return{
                ...state,
                isLoading:false,
                error:action.error
            }

        default:
            return state;
    }

}