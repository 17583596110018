import { axiosInstance } from 'common/apiHandler';

export const lisGoogleEvents = async (obj) => {
  let { start_date, end_date, accessToken, calendarId = 'primary' } = obj;

  let timeMin = '';
  let timeMax = '';

  if (start_date) {
    timeMin = new Date(start_date).toISOString();
  }
  if (end_date) {
    timeMax = new Date(end_date).toISOString();
  }

  try {
    let options = {
      method: 'GET',
      url: `https://www.googleapis.com/calendar/v3/calendars/${encodeURIComponent(
        calendarId
      )}/events?singleEvents=true&&timeMin=${timeMin}&&timeMax=${timeMax}`,

      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
      data: {},
    };

    let response = await axiosInstance(options);
    if (response.data) {
      let events = response?.data?.items;

      return formatGoogleEvents(events);
    }
  } catch (err) {
    console.log('err', err);
    if (err?.status === 401 || (err?.status === 403 && err?.status === 'PERMISSION_DENIED')) {
      window?.localStorage?.removeItem('google_access_token');
    }
    throw err;
  }
};

export const getCalendarList = ({ accessToken }) => {
  return axiosInstance.get('https://www.googleapis.com/calendar/v3/users/me/calendarList', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const formatGoogleEvents = (list) => {
  return list?.map((item, id) => {
    let eventObj = {};
    if (item?.status && item?.status !== 'cancelled') {
      eventObj = {
        id: item?.htmlLink?.split('=').reverse()[0],
        title: item?.summary ?? 'No title',
        start: item?.start?.dateTime || item?.start?.date,
        end: item?.end?.dateTime || item?.end?.date,
        meeting_start_time: item?.start?.dateTime || item?.start?.date,
        meeting_end_time: item?.end?.dateTime || item?.end?.date,
        in_app_event: item?.extendedProperties ? (item.extendedProperties?.private?.LawftEvent ? true : false) : false,
        res_type: 'google',
        calendar_link: item?.htmlLink,
        meet_link: item?.hangoutLink,
      };
    }

    return eventObj;
  });
};
