import React, { createRef, useMemo } from 'react';

import GridItem from './GridItem/GridItem';
import Tags from './Tags';
import { Button } from 'primereact/button';

import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import useFileDownload from 'modules/file_manager/hooks/useFileDownload';

function GridView() {
  const { files, modals, selectedRows, isGlobalFileManager, isClientFileManager } = useFileManagerContext();
  const { setShowBulkDeleteModal, setShowBulkMoveModal, setShowShareFileModal } = modals;
  const { downloadFile } = useFileDownload();

  const itemsRef = useMemo(
    () =>
      Array(files && files.length ? files.length : 0)
        .fill()
        .map(() => createRef()),
    [files]
  );
  const containsFolders = selectedRows.some((item) => item.entry_type === 'folder');
  async function downloadFiles() {
    try {
      await Promise.all(
        selectedRows.map(async (file) => {
          await downloadFile(file);
        })
      );
      console.log('All files downloaded successfully');
    } catch (error) {
      console.error('Error downloading files:', error);
    }
  }
  return (
    <>
      {selectedRows.length > 0 && (
        <div className="flex flex-wrap align-items-end justify-content-end gap-2 d-flex">
          {!isGlobalFileManager && !isClientFileManager && (
            <Button
              // variant="outline-secondary"
              className="p-button  p-button-primary outline ms-2 bg-"
              onClick={(e) => {
                setShowBulkDeleteModal(true);
              }}
            >
              Delete
            </Button>
          )}

          {!isGlobalFileManager && !isClientFileManager && (
            <Button
              className="p-button  p-button-primary outline ms-2"
              onClick={(e) => {
                setShowBulkMoveModal(true);
              }}
            >
              Move
            </Button>
          )}
          {!isGlobalFileManager && !isClientFileManager && (
            <Button
              className="p-button  p-button-primary outline ms-2"
              onClick={() => {
                setShowBulkMoveModal(true);
              }}
            >
              Copy
            </Button>
          )}
          {!containsFolders && !isGlobalFileManager && !isClientFileManager && (
            <Button label="Share" className="p-button  p-button-primary outline ms-2" onClick={() => setShowShareFileModal(true)} />
          )}
          {!containsFolders && <Button label="Download" className="p-button  p-button-primary outline ms-2" onClick={downloadFiles} />}
        </div>
      )}

      {files.length > 0 ? (
        <div style={fileManagerStyles}>
          {files.map((item, index) => (
            <div key={index} style={containerStyles}>
              <GridItem item={item} />
              {!isClientFileManager ? <Tags item={item} itemsRef={itemsRef} index={index} /> : null}
            </div>
          ))}
        </div>
      ) : (
        <div className="d-flex justify-content-center">No Data Found</div>
      )}
    </>
  );
}

export default GridView;

const fileManagerStyles = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
  gap: '32px',
  marginTop: '16px',
};

const containerStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
};
