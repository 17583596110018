import React from 'react';
import { Route, Switch } from 'react-router-dom';
import withSidebar from './components/SideBar/WithSidebar';
import Calendar from 'modules/calendar/Calendar';
import Billing from 'modules/cases/Billing';
import ActivityLog from 'modules/dashboard/components/ActivityLog/ActivityLog';
import Notes from 'modules/cases/Note/Notes';
import Tasks from 'modules/Tasks/Tasks';
// import Information from 'modules/cases/Information';
import TextMessages from 'modules/messages/TextMessages/TextMessages';
import Overview from './components/Overview/Overview';

import { useLeadDetails } from 'modules/lead/hooks/useLeadDetails';
import NewFileManager from 'modules/file_manager/NewFileManager';
import { useUserDetailsContext } from 'context/userDetailsContext';
import MessageRouterLead from 'modules/messages/SecureMessages/MessageRouterLead';
import { Information } from 'modules/cases/Information/Information';
import Contacts from 'modules/cases/CaseDetails/Contacts/Contacts';
import NoteDetails from 'modules/cases/NoteDetails';

function LeadDetails({ match }) {
  const { leadDetails, loadLeadDetails } = useLeadDetails();
  const { checkSubPermission } = useUserDetailsContext();

  return (
    <>
      {Boolean(leadDetails) ? (
        <Switch>
          <Route
            exact
            path={`${match.path}`}
            render={(props) => <Overview {...props} leadDetails={leadDetails} loadLeadDetails={loadLeadDetails} />}
          />
          <Route
            path={`${match.path}/info`}
            render={(props) => (
              <Information
                {...props}
                caseDetails={leadDetails}
                handleGetCaseAction={loadLeadDetails}
                fetchLeadDetails={loadLeadDetails}
                isLeadSpecific={true}
              />
            )}
          />
          <Route
            path={`${match.path}/files`}
            render={(props) => (
              <NewFileManager caseDetails={leadDetails} isLeadFileManager={true} isClosed={leadDetails?.is_lead && !leadDetails?.is_open} />
            )}
          />
          <Route
            path={`${match.path}/contacts`}
            render={(props) => (
              <Contacts
                {...props}
                caseDetails={leadDetails}
                isLeadSpecific={true}
                getDetails={loadLeadDetails}
                isLeadClosed={leadDetails?.is_lead && !leadDetails?.is_open}
              />
            )}
          />
          <Route
            path={`${match.path}/calendar`}
            render={(props) => (
              <Calendar
                {...props}
                caseDetails={leadDetails}
                isFromCaseSpecificModule={false}
                isFromLeadSpecificModule={true}
                isClosed={!leadDetails.is_open}
              />
            )}
          />
          {checkSubPermission('task_management') && (
            <Route
              path={`${match.path}/task`}
              render={(props) => (
                <Tasks
                  {...props}
                  caseDetails={leadDetails}
                  isFromLead={true}
                  isLeadClosed={leadDetails?.is_lead && !leadDetails?.is_open}
                />
              )}
            />
          )}
          <Route
            path={`${match.path}/messages`}
            render={(props) => (
              <MessageRouterLead
                {...props}
                caseDetails={leadDetails}
                isFromCaseSpecificModule={false}
                isLeadSpecific={true}
                isLeadClosed={leadDetails.is_lead && !leadDetails.is_open}
              />
            )}
          />
          {checkSubPermission('texting') && (
            <Route
              path={`${match.path}/text-message`}
              render={(props) => <TextMessages {...props} leadDetails={leadDetails} isFromLead={true} />}
            />
          )}
          <Route
            path={`${match.path}/note`}
            render={(props) => (
              <Notes
                {...props}
                caseDetails={leadDetails}
                isFromCaseSpecificModule
                isLeadSpecific
                isLeadClosed={leadDetails.is_lead && !leadDetails.is_open}
              />
            )}
          />
          <Route
            path={`${match.path}/notes/:id`}
            render={(props) => (
              <NoteDetails
                {...props}
                caseDetails={leadDetails}
                isFromCaseSpecificModule
                isLeadSpecific
                isLeadClosed={leadDetails.is_lead && !leadDetails.is_open}
              />
            )}
          />
          <Route
            path={`${match.path}/billing`}
            render={(props) => (
              <Billing
                {...props}
                ActivityLog
                caseDetails={leadDetails}
                isLeadSpecific
                isLeadClosed={leadDetails.is_lead && !leadDetails.is_open}
                getCaseDetails={loadLeadDetails}
              />
            )}
          />
          <Route
            path={`${match.path}/activity`}
            render={(props) => <ActivityLog {...props} caseDetails={leadDetails} isCaseSpecific={true} isLead={true} />}
          />
        </Switch>
      ) : (
        <h2 className="title gray-800 mb-0 case-title">Loading</h2>
      )}
    </>
  );
}

export default withSidebar(LeadDetails);
