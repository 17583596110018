import React from 'react';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { frequencyOfPayments } from '../../configs/create_invoice_configs';
import { Button } from 'primereact/button';

const PaymentPlan = (props) => {
  const {
    paymentPlan,
    setPaymentPlan,
    paymentDates,
    setPaymentDates,
    setPaymentPlanError,
    isChanged,
    setIsChanged,
    paymentPlanError,
    totalAmount,
    setRate,
    resetFormErrors,
    formErrors,
    numberOfPayments,
    setNumberOfPayments,
    selectedFrequency,
    setSelectedFrequency,
    paymentStartDate,
    setPaymentStartDate,
    firstPaymentAmount,
    setFirstPaymentAmount,
    generatePlan,
  } = props || {};

  return (
    <div className="mt-3" id="payment-plan">
      <div className="row ">
        <div className="col-12 d-flex align-items-center mb-2">
          <span className="caption-bold mb-0 pe-4">Payment Plan</span>
          <div>
            <label className="switch">
              <input
                type="checkbox"
                checked={paymentPlan}
                onChange={(e) => {
                  setPaymentPlan(e.target.checked);
                }}
              />{' '}
              <span className="slider round"></span>
            </label>
          </div>
        </div>
      </div>
      <div className="d-flex mt-2 shadow-small p-2">
        <div className="col-12 col-md-6 time-entries px-3">
          {paymentPlan && paymentDates && paymentDates.length > 0 && (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Due Date</th>
                  <th>Amount</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {paymentDates.map((data, id) => {
                  return (
                    <tr className="" key={id}>
                      <td>
                        <Calendar
                          className="input-shadow"
                          value={typeof data.date === 'string' ? new Date(data.date) : data.date}
                          onChange={(e) => {
                            paymentDates[id].date = e.value;
                            setPaymentDates([...paymentDates]);
                            paymentPlanError[`${id}_date`] = '';
                            setPaymentPlanError({
                              ...paymentPlanError,
                            });
                            isChanged.payment_plan = true;
                            setIsChanged({ ...isChanged });
                          }}
                        />
                        {paymentPlanError && paymentPlanError[`${id}_date`] && (
                          <span className="text-danger">{paymentPlanError[`${id}_date`]}</span>
                        )}
                      </td>
                      <td>
                        <InputNumber
                          inputId="currency-us"
                          className="input-shadow"
                          value={data.amount}
                          //type="number"
                          onValueChange={(e) => {
                            paymentDates[id].amount = e.target.value;
                            setPaymentDates([...paymentDates]);
                            paymentPlanError[`${id}_amount`] = '';
                            paymentPlanError['total'] = '';
                            setPaymentPlanError({
                              ...paymentPlanError,
                            });
                            isChanged.payment_plan = true;
                            setIsChanged({ ...isChanged });
                          }}
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                        />
                        {paymentPlanError && paymentPlanError[`${id}_amount`] && (
                          <span className="text-danger">{paymentPlanError[`${id}_amount`]}</span>
                        )}
                      </td>
                      <td className="align-middle">
                        <i
                          className="icon-delete"
                          onClick={(e) => {
                            paymentDates.splice(id, 1);
                            setPaymentDates([...paymentDates]);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {paymentPlan && (
            <span
              role="button"
              onClick={() => {
                paymentDates.push({ date: '', amount: '' });
                setPaymentDates([...paymentDates]);
              }}
              className="addfield"
            >
              + Add Date
            </span>
          )}
        </div>
        <div className="col-12 col-md-6 px-3 mt-4">
          {paymentPlan && (
            <>
              <div className="d-flex align-items-center py-2">
                <div className="col-sm-4">
                  <div>
                    Fee
                    <span className="text-danger">*</span>
                  </div>
                </div>
                <div className="col-sm-8">
                  <InputNumber
                    inputId="currency-us"
                    value={totalAmount}
                    onChange={(e) => {
                      setRate(e.value);
                      resetFormErrors('rate');
                      isChanged.payment_plan = true;
                      setIsChanged({ ...isChanged });
                    }}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    className="input-shadow w-50"
                  />
                  {formErrors && formErrors.rate && <span className="text-danger">{formErrors.rate}</span>}
                </div>
              </div>
              <div className="d-flex align-items-center py-2">
                <div className="col-sm-4">
                  <div>
                    Number of Payments
                    <span className="text-danger">*</span>
                  </div>
                </div>
                <div className="col-sm-8">
                  <InputTextarea
                    value={numberOfPayments}
                    onChange={(e) => {
                      setNumberOfPayments(e.target.value);
                      resetFormErrors('payment_number');
                      isChanged.payment_plan = true;
                      setIsChanged({ ...isChanged });
                    }}
                    className="input-shadow w-50"
                    placeholder="Number of Payments"
                    type="number"
                  />
                  {formErrors && formErrors.payment_number && <span className="text-danger">{formErrors.payment_number}</span>}
                </div>
              </div>

              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-md-4 col-12">
                  <div>
                    Frequency of Payments
                    <span className="text-danger">*</span>
                  </div>
                </div>
                <div className="col-md-8 col-12">
                  <Dropdown
                    options={frequencyOfPayments}
                    value={selectedFrequency}
                    onChange={(e) => {
                      setSelectedFrequency(e.target.value);
                      resetFormErrors('payment_frequency');
                      isChanged.payment_plan = true;
                      setIsChanged({ ...isChanged });
                    }}
                    placeholder="Please select"
                    className="w-50"
                    filter
                  />
                  {formErrors && formErrors.payment_frequency && <span className="text-danger">{formErrors.payment_frequency}</span>}
                </div>
              </div>
              <div className="d-flex align-items-center py-2">
                <div className="col-sm-4">
                  Payment Starting Date
                  <span className="text-danger">*</span>
                </div>
                <div className="col-sm-8 d-flex align-items-center">
                  <Calendar
                    className="input-shadow w-50"
                    value={paymentStartDate}
                    onChange={(e) => {
                      setPaymentStartDate(e.value);
                      paymentPlanError.start_date = '';
                      setPaymentPlanError({
                        ...paymentPlanError,
                      });
                      isChanged.payment_plan = true;
                      setIsChanged({ ...isChanged });
                    }}
                  />
                  {paymentPlanError && paymentPlanError['start_date'] && (
                    <span className="text-danger">{paymentPlanError[`start_date`]}</span>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center py-2">
                <div className="col-sm-4">
                  First Payment Amount
                  <span className="text-danger">*</span>
                </div>
                <div className="col-sm-8 d-flex align-items-center">
                  <InputNumber
                    inputId="currency-us"
                    className="input-shadow flex-1"
                    value={firstPaymentAmount}
                    //type="number"
                    placeholder="Amount"
                    min={0}
                    //keyfilter={"pnum"}
                    onValueChange={(e) => {
                      //setFirstPaymentAmount(fixToTwoDecimal(num));
                      setFirstPaymentAmount(e.value);
                      paymentPlanError['first_amount'] = '';
                      setPaymentPlanError({ ...paymentPlanError });
                      isChanged.payment_plan = true;
                      setIsChanged({ ...isChanged });
                    }}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                  />

                  {paymentPlanError && paymentPlanError['first_amount'] && (
                    <span className="text-danger">{paymentPlanError[`first_amount`]}</span>
                  )}
                  <Button className="p-button-secondary ms-2" label="Generate Plan" onClick={generatePlan}></Button>
                </div>
              </div>
              {paymentPlanError['total'] && <span className="danger-text ms-3">{paymentPlanError['total']}</span>}
              {paymentPlanError['payment_plan'] && <span className="danger-text ms-3">{paymentPlanError['payment_plan']}</span>}
              {}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentPlan;
