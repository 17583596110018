import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button } from 'primereact/button';

import { toastConstant } from 'constants/toastmessage';
import { useToast } from 'context/ToastContext';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import { createLeadReferral } from 'services/leadServices';

export default function AddReferral(props) {
  const { onSuccess, onDismiss } = props;
  const { handleSubmit, control } = useForm();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);

  const _onDismiss = () => typeof onDismiss === 'function' && onDismiss();

  const handleAddReferral = ({ name }) => {
    if (name) {
      setLoading(true);
      let data = {};
      data = {
        referred_from: name,
      };
      createLeadReferral(true, data)
        .then((response) => {
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.REFERRAL_CREATED);
          setLoading(false);
          typeof onSuccess === 'function' && onSuccess(name);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            addToast(false, toastConstant.toasterType.WARN, toastConstant.api.FAILED, error.response.data);
          }
          setLoading(false);
        });
    } else {
      addToast(false, toastConstant.toasterType.INFO, toastConstant.api.FAILED, toastConstant.message.REFERRAL_NAME_VALIDATION);
    }
  };

  return (
    <div className="p-inputgroup align-items-center">
      <Controller
        name="name"
        control={control}
        render={({ field }) => <TextSnippetWrapper type="text" id={field.name} {...field} className="input-shadow w-100" />}
      />
      <Button
        icon="pi pi-check"
        className="p-button-secondary mx-2"
        type="button"
        onClick={handleSubmit(handleAddReferral)}
        loading={loading}
      />
      <Button icon="pi pi-times" className="p-button-secondary outline" type="button" onClick={_onDismiss} disabled={loading} />
    </div>
  );
}
