import React from 'react';
import { Menu } from 'primereact/menu';

import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import usePrintFile from 'modules/file_manager/hooks/usePrintFile';
import useFileDownload from 'modules/file_manager/hooks/useFileDownload';
import { useUserDetailsContext } from 'context/userDetailsContext';
import { Tooltip } from 'primereact/tooltip';

const docTypeVal = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

function MenuIcon({ item, index, itemsRef }) {
  const {
    deletePermission,
    handleBreadCrumbs,
    getFiles,
    currentView,
    setSelectedItem,
    modals,
    onFilterChange,
    setVersionFileUpload,
    setPreviewList,
    files,
    setSelectedPreviewItem,
    pageLimit,
    isGlobalFileManager,
    caseDetails,
    isClientFileManager,
    setSelectedRows,
  } = useFileManagerContext();

  const {
    setShowRenameModal,
    setMoveItemModal,
    setShowDeleteModal,
    setShowPreview,
    setShowFileUploadModal,
    setShowShareFileModal,
    setShowSignatureModal,
  } = modals;
  const { checkSubPermission } = useUserDetailsContext();

  const { pdfTronPrintFile } = usePrintFile();
  const { downloadFile } = useFileDownload();

  const getItemsOption = (rowData) => {
    const printFileFormats = ['image/png', 'application/pdf', docTypeVal];
    let printObj = {};

    let uploadNewVersionObj = {
      label: 'Upload New Version',
      command: (e) => {
        setShowFileUploadModal(true);
        setSelectedItem(rowData);
        setVersionFileUpload(rowData);
      },
      show: true,
    };
    let shareFileObj = {
      label: 'Share',
      command: (e) => {
        setShowShareFileModal(true);
        setSelectedRows([rowData]);
      },
      show: true,
    };
    let downloadObj = {
      label: 'Download',

      command: () => {
        downloadFile(rowData);
      },
      show: true,
    };

    printObj = {
      label: 'Print',

      command: () => {
        pdfTronPrintFile(rowData);
      },
      show: Boolean(rowData?.file_type && printFileFormats?.includes(rowData?.file_type?.toLowerCase())),
    };

    let movObj = {
      label: 'Move',
      command: (e) => {
        setMoveItemModal(true);
        setSelectedItem(rowData);
      },
      show: true,
    };
    const withSignature = [
      {
        label: 'Open',
        command: () => {
          selectFile(rowData);
        },
        show: true,
      },
      {
        label: 'Rename',
        command: (e) => {
          setShowRenameModal(true);
          setSelectedItem(rowData);
        },
        show: true,
      },
      {
        label: 'Delete',

        command: (e) => {
          setShowDeleteModal(true);
          setSelectedItem(rowData);
        },
        show: true,
      },
      {
        label: 'Request Signature',
        command: (e) => {
          setSelectedItem(rowData);
          setShowSignatureModal(true);
        },
        show: true,
      },
    ];

    const withOutSignature = [
      {
        label: 'Open',
        command: () => {
          selectFile(rowData);
        },
        show: true,
      },
      {
        label: 'Rename',
        command: (e) => {
          setShowRenameModal(true);
          setSelectedItem(rowData);
        },
        show: true,
      },
      {
        label: 'Delete',

        command: () => {
          setShowDeleteModal(true);
          setSelectedItem(rowData);
        },
        show: true,
      },
    ];
    if (rowData?.entry_type !== 'folder') {
      withSignature?.push(uploadNewVersionObj);
      withOutSignature?.push(uploadNewVersionObj);

      if (rowData.case_id && rowData.case_id !== 'undefined') {
        withSignature?.push(shareFileObj);
        withOutSignature?.push(shareFileObj);
      }

      if (currentView === 'grid') {
        const gridActions = [printObj, downloadObj];
        withSignature?.push(...gridActions);
        withOutSignature?.push(...gridActions);
      }
    }
    if (!isGlobalFileManager) {
      withSignature?.push(movObj);
      withOutSignature?.push(movObj);
    }
    if (rowData?.file_type?.toLowerCase() === 'docx') {
      let edit_menu = {
        label: 'Edit',
        // command: (e) => {
        //   localStorage.setItem('file_url', selectedItem.file_path);
        //   localStorage.setItem('pk', selectedItem.pk);
        //   localStorage.setItem('sk', selectedItem.sk);
        //   localStorage.setItem('mode', 'edit');
        //   let disp_name_arr = selectedItem.display_name.split('.');
        //   disp_name_arr.splice(-1);
        //   localStorage.setItem('file_name', disp_name_arr.join('.'));

        //   let pathname = '/templates/builder';
        //   let state = {
        //     type: 'blank',
        //     save_as: 'file',
        //     case_id: rowData.case_id || null,
        //   };
        //   setIsLoading(false);
        //   if (!openLinkInBlank(pathname, state)) {
        //     history.push({
        //       pathname,
        //       state,
        //     });
        //   }
        // },
      };
      withSignature.splice(1, 0, edit_menu);
      withOutSignature.splice(1, 0, edit_menu);
    }

    try {
      if (
        rowData &&
        rowData.file_type &&
        (rowData.file_type === 'application/pdf' || rowData.file_type === docTypeVal) &&
        checkSubPermission('e_signature')
      ) {
        return withSignature;
      } else {
        return withOutSignature;
      }
    } catch (err) {
      return withSignature;
    }
  };

  const viewItems = [
    {
      label: 'Open',
      command: () => {
        selectFile(item);
      },
    },
  ];

  const selectFile = (e) => {
    if (e.entry_type === 'folder') {
      handleBreadCrumbs(e);
      let obj = {
        case_id: isGlobalFileManager || isClientFileManager ? '' : caseDetails.case_id,
        selectedFolder: e.object_id,
        selectedType: '',
        tag: '',
        query: '',
        folder_id: '',
      };
      getFiles(obj, currentView, pageLimit);
      let data = {
        target: {
          name: 'selectedFolder',
        },
        value: e.object_id,
      };
      onFilterChange(data);
    } else {
      setShowPreview(true);
      setPreviewList(files);
      setSelectedPreviewItem(e);
    }
  };

  return (
    <>
      {/* <Menu model={deletePermission ? getItemsOption(item) : viewItems} popup id="popup_menu" ref={itemsRef[index]} /> */}

      <Menu
        model={deletePermission ? getItemsOption(item)?.filter((v) => v.show) : viewItems}
        popup
        id="popup_menu"
        ref={itemsRef[index]}
      />
      <i
        className={`icon-more d-inline-block rotate-90 me-3 pointer text-primary-main ${
          caseDetails?.case_closed || (caseDetails?.is_lead && !caseDetails?.is_open) ? 'disabled-icon' : ''
        }`}
        aria-hidden="true"
        onClick={(event) => itemsRef[index]?.current?.toggle(event)}
        aria-controls="popup_menu"
        aria-haspopup
      ></i>
      <Tooltip content="More" position="top" target=".icon-more" showDelay={500} />
    </>
  );
}

export default MenuIcon;
