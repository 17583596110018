import React from 'react';
import { formatNumbersToCurrencyString } from 'utils/utils';
import {
  ADDITION,
  AMOUNT_EXCEEDS_TRUST_FUNDS_AVAILABLE,
  BILLED_HOUR_SUB_TOTAL,
  DISCOUNT,
  EXPENSE_SUB_TOTAL,
  FLAT_FEE_SUB_TOTAL,
  FORWARD_SUB_TOTAL,
  INVOICE_TOTAL,
  SETTLEMENT_AMOUNT,
  TOTAL_AMOUNT,
  TOTAL_BILLS_AND_EXPENSES,
  TOTAL_LEGAL_FEE,
  TOTAL_TO_CLIENT,
} from '../../constants/createInvoiceConstants';

const InvoiceTotal = (props) => {
  const {
    flatFeeTotal,
    rateWithTax,
    adjustmentTotal,
    expenseTotal,
    forwardedSubtotal,
    discount,
    trustAccountData,
    totalAmount,
    settlementAmount,
    billsAndExpenseTotal,
    isContigencyFee,
    attorneyFeeAmount,
    isPersonalInjuryPracticeArea,
  } = props || {};

  const renderTemplate = (params) => {
    const { header, valueToBeFormatted } = params || {};
    <div className="d-flex align-items-center py-2">
      <div className="col-5">{`${header} :`}</div>
      <div className="col-7 d-flex justify-content-end text-bold">{formatNumbersToCurrencyString(valueToBeFormatted)}</div>
    </div>;
  };

  return (
    <div className="mt-3 col-12 col-sm-4">
      <div className="col-12 caption-bold">{INVOICE_TOTAL}</div>
      <div className="shadow-small p-3 align-self-start">
        {flatFeeTotal > 0 && renderTemplate({ header: FLAT_FEE_SUB_TOTAL, valueToBeFormatted: flatFeeTotal })}

        {rateWithTax > 0 && renderTemplate({ header: BILLED_HOUR_SUB_TOTAL, valueToBeFormatted: rateWithTax })}

        {expenseTotal > 0 && renderTemplate({ header: EXPENSE_SUB_TOTAL, valueToBeFormatted: expenseTotal })}

        {adjustmentTotal > 0 && renderTemplate({ header: ADDITION, valueToBeFormatted: adjustmentTotal })}

        {forwardedSubtotal > 0 && renderTemplate({ header: FORWARD_SUB_TOTAL, valueToBeFormatted: forwardedSubtotal })}

        {discount > 0 && renderTemplate({ header: DISCOUNT, valueToBeFormatted: discount })}

        {isContigencyFee && isPersonalInjuryPracticeArea && (
          <>
            {[
              { header: SETTLEMENT_AMOUNT, valueToBeFormatted: settlementAmount },
              { header: TOTAL_BILLS_AND_EXPENSES, valueToBeFormatted: billsAndExpenseTotal },
              { header: TOTAL_LEGAL_FEE, valueToBeFormatted: attorneyFeeAmount },
            ]?.map((item, idx) => {
              return renderTemplate(item);
            })}
          </>
        )}

        <div className="d-flex align-items-center py-2">
          <div className="col-md-5 text-bold">
            {isContigencyFee && isPersonalInjuryPracticeArea ? `${TOTAL_TO_CLIENT} :` : `${TOTAL_AMOUNT} :`}
          </div>
          <div className="col-md-7 d-flex justify-content-end text-bold">{formatNumbersToCurrencyString(totalAmount)}</div>
        </div>
        {Boolean(trustAccountData && Number(totalAmount) > Number(trustAccountData?.amount_available)) && (
          <span className="text-danger">{AMOUNT_EXCEEDS_TRUST_FUNDS_AVAILABLE}</span>
        )}
      </div>
      {/* TODO:FORM ERROR */}
      {/* {formErrors && formErrors.total_amount && (
        <div className="col-12 d-flex justify-content-center mt-3">
          <span className="text-danger">{formErrors.total_amount}</span>{' '}
        </div>
      )} */}
    </div>
  );
};

export default InvoiceTotal;
