import React from 'react';
import { Tag } from 'primereact/tag';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';

function ThreadHeading({ messageDetails, handleMarkAsUnRead, isFromClientPortal }) {
  const history = useHistory();

  return (
    <div className="d-flex mb-4 align-items-center">
      <i className="icon-back icon-box me-3 pointer" onClick={history?.goBack} />
      <div className="d-flex w-100">
        <h1 className="title gray-800 mb-0 ml-1">Thread : {messageDetails?.subject}</h1>
        {messageDetails?.is_new ? (
          <span style={{ marginLeft: '0.5rem' }}>
            <Tag className="p-mr-2 tag-case" value="New" rounded />
          </span>
        ) : null}
      </div>
      {isFromClientPortal && (
        <div className="d-flex w-100 justify-content-end">
          <Button
            label={'Mark as Unread'}
            className={'btn-outline p-button-secondary outline py-1 px-3 me-2'}
            type="button"
            style={{ overflow: 'inherit' }}
            onClick={(e) => {
              handleMarkAsUnRead(e, messageDetails, true);
            }}
          />
        </div>
      )}
    </div>
  );
}

export default ThreadHeading;
