import { createContext, useContext, useState, Fragment, useRef, useCallback } from 'react';
import useDownloadProgress from 'hooks/useDownloadProgress';
import FloatingProgressBar from 'components/FloatingProgressBar/FloatingProgressBar';
import useNotifications from 'hooks/useNotifications';
import useClientNoification from 'hooks/useClientNoification';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import useMultipartUpload from 'hooks/useMultipartUpload';
import UploadWindow from 'components/UploadWindow/UploadWindow';

export const NavBarContext = createContext();

export const useNavbarContext = () => {
  return useContext(NavBarContext);
};

export const NavBarContextProvider = ({ children }) => {
  const [uploadWindowMinimized, setUploadWindowMinimized] = useState(false);
  const { downloads, downloadFile, remove } = useDownloadProgress();
  const notificationData = useNotifications();
  const { getNotificationCounts, notificationCounts, readClientNotifications } = useClientNoification();
  const { startUpload, uploadingFiles, pauseUpload, resumeUpload, cancelAllUploads } = useMultipartUpload();
  const confirmDialogRef = useRef(null);

  const showConfirmDialog = useCallback((content) => {
    return new Promise((resolve, reject) => {
      if (confirmDialogRef.current) {
        confirmDialogRef.current.open(content);
        const handleConfirm = (data) => {
          resolve(data);
        };
        const handleCancel = () => {
          reject(false);
        };
        confirmDialogRef.current.open(content, handleConfirm, handleCancel);
      }
    });
  }, []);

  const toggleUploadWindow = () => {
    setUploadWindowMinimized(!uploadWindowMinimized);
  };

  const value = {
    notificationCounts,
    getNotificationCounts,
    readClientNotifications,
    downloadFile,
    notificationData,
    showConfirmDialog,
    startUpload,
    uploadingFiles,
    pauseUpload,
    resumeUpload,
  };

  return (
    <NavBarContext.Provider value={value}>
      {children}
      <ConfirmDialog ref={confirmDialogRef} />
      {downloads.map((download, index) => (
        <Fragment key={download.id + index}>
          {download.showProgressBar && (
            <div
              draggable
              style={{
                position: 'fixed',
                bottom: `${20 + index * 100}px`,
                right: '20px',
                zIndex: '9999',
              }}
            >
              <FloatingProgressBar {...download} remove={remove} />
            </div>
          )}
        </Fragment>
      ))}
      {Object.keys(uploadingFiles).length > 0 && (
        <UploadWindow
          files={uploadingFiles}
          onPause={pauseUpload}
          onResume={resumeUpload}
          onCancel={cancelAllUploads}
          onMinimize={toggleUploadWindow}
          minimized={uploadWindowMinimized}
        />
      )}
    </NavBarContext.Provider>
  );
};
