import { useEffect, useState } from 'react';
import { listContacts } from 'services/ContactService';
const { totalCountEvent } = require('services/generalServices');

export function useCourtLocationList() {
  const [courtList, setCourtList] = useState(null);

  const loadCourtContacts = () => {
    totalCountEvent('contact')
      .then((response) => {
        listContacts('location', 'ACTIVE', '', true, '', response.data, '', 'forward')
          .then((response) => {
            if (response?.data?.contacts?.length > 0) {
              setCourtList(response.data.contacts);
            }
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    loadCourtContacts();
  }, []);

  return { courtList, loadCourtContacts };
}
