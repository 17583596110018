import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { getClientUserList } from 'services/CaseServices';
import { sendPaymentRequestMail } from 'services/billingServices';
import { toastConstant } from 'constants/toastmessage';
import { useToast } from 'context/ToastContext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import useSpinner from 'hooks/useSpinner';
import { getInvoice } from 'services/billingServices';
import { ModalHeader } from 'shared/ModalHeader';

export const SendNotificationMail = (props) => {
  const [billingContact, setBillingContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedContact, setSelectedContact] = useState();
  const { addToast } = useToast();
  const [spinner, showSpinner, hideSpinner] = useSpinner(true);

  const onSubmit = () => {
    if (props.selectedRequest) {
      setLoading(true);
      let data = {
        contact_id: selectedContact?.contact_id,
        contact_email: selectedContact?.email,
      };
      sendPaymentRequestMail(
        props.selectedRequest.sk,
        data,
        props.invoiceView,
        props.isLeadSpecific ? props?.isLeadSpecific : props?.selectedRequest?.is_lead
      )
        .then(() => {
          addToast(
            false,
            toastConstant.toasterType.SUCCESS,
            toastConstant.api.SUCCESS,
            toastConstant.message.NOTIFY_PAYMENT_REQUEST_SUCCESS
          );
          setLoading(false);
          props.onHide();
        })
        .catch((err) => {
          let msg = toastConstant.message.NOTIFY_PAYMENT_REQUEST_FAILED;
          if (err?.response?.data) {
            msg = err?.response?.data;
          }
          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, msg);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (props.selectedRequest) {
      showSpinner();
      // console.log("props.selectedRequest",props.selectedRequest);
      let billing_contact_id = props.selectedRequest?.billing_contact_id;
      setSelectedContact();
      if (props.invoiceView) {
        getInvoice(props.selectedRequest.sk).then((response) => {
          // console.log("response",response);
          let case_id = response?.data?.invoice?.case_id;
          billing_contact_id = response?.data?.invoice?.contact_id;
          getClientUserList(case_id, true)
            .then((response) => {
              let user_list = [];
              // console.log("response", response);
              if (response?.data && response.data.length > 0) {
                response.data.forEach((index) => {
                  let name = index.first_name ? index.first_name : '';
                  if (index.last_name) name = name + ' ' + index.last_name;
                  let obj = {
                    contact_id: index.contact_id,
                    name: name,
                    email: index.email,
                  };
                  if (billing_contact_id === index.contact_id) {
                    setSelectedContact({ ...obj });
                  }

                  user_list.push(obj);
                });
              }
              setBillingContacts([...user_list]);
              hideSpinner();
            })
            .catch((err) => {
              hideSpinner();
            });
        });
      } else {
        getClientUserList(props.selectedRequest?.case_id, true)
          .then((response) => {
            let user_list = [];
            // console.log("response", response);
            if (response?.data && response.data.length > 0) {
              response.data.forEach((index) => {
                let name = index.first_name ? index.first_name : '';
                if (index.last_name) name = name + ' ' + index.last_name;
                let obj = {
                  contact_id: index.contact_id,
                  name: name,
                  email: index.email,
                };
                if (billing_contact_id === index.contact_id) {
                  setSelectedContact({ ...obj });
                }

                user_list.push(obj);
              });
            }
            setBillingContacts([...user_list]);
            hideSpinner();
          })
          .catch((err) => {
            hideSpinner();
          });
      }
    }
  }, [props.selectedRequest]);

  return (
    <Dialog
      visible={props.show}
      onHide={props.onHide}
      //header="Send Payment Request Reminder"
      header={ModalHeader}
      footer={<></>}
      style={{ width: '700px' }}
      className="new-task-popup"
    >
      {spinner}
      <div className="">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="modal-heading F-size16 text-bold">Send Payment Request Reminder</div>
        </div>
        <div className="row mt-3">
          <div className="col-12">Are you are sure you want to send a reminder?</div>
        </div>
        <div className="row mt-3 align-items-center">
          <div className="col-12 col-sm-4 p-medium">Contact Name</div>
          <div className="col-12 col-sm-8">
            <Dropdown
              options={billingContact}
              optionLabel="name"
              value={selectedContact}
              onChange={(e) => {
                setSelectedContact(e.value);
              }}
              filter
              className="w-100"
              showClear={selectedContact === '' ? false : true}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="footer-btn">
            <Button className="p-button-secondary outline" label="Cancel" onClick={props.onHide}></Button>
            <Button className="p-button-secondary ms-2" label={'Send'} onClick={onSubmit} loading={loading}></Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
