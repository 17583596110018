import { performRequest } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';
import { Storage, Auth } from 'aws-amplify';
import { FILEUPLOAD_S3, FILEUPLOAD_S3_LEVEL, SetS3Config } from 'config/amplify_config';
import Cookies from 'universal-cookie';
const cookie = new Cookies();

export const addEvent = (data, case_id = '', is_lead = false, timeEntryData = '') => {
  let tenantId = cookie.get('tenantId');
  let tenantSk = cookie.get('tenantSk');
  tenantSk = tenantSk ? tenantSk.replace('#', '%23') : '';

  let url = `${apiEndPoints.calendar.createEvent}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&case_id=${case_id}${
    is_lead ? `&is_lead=${is_lead}` : ''
  }`;
  return performRequest(apiEndPoints.methodType.POST, url, data, timeEntryData);
};

export const listEvents = (
  event_type,
  attendee,
  category,
  location,
  event_month,
  event_year,
  upcoming_events,
  data,
  case_id = '',
  limit = 1000,
  next = ''
) => {
  let tenantId = cookie.get('tenantId');
  let tenantSk = cookie.get('tenantSk');
  tenantSk = tenantSk ? tenantSk.replace('#', '%23') : '';
  let url = `${
    apiEndPoints.calendar.listEvents
  }?tenant_id=${tenantId}&tenant_sk=${tenantSk}&event_type=${event_type}&attendee${attendee}&category_filter=${
    category ? category : ''
  }&location=${location}&limit=${limit}&next=${next}&event_month=${event_month}&event_year=${event_year}&upcoming_events=${upcoming_events}&case_id=${
    case_id ? case_id : ''
  }`;
  return performRequest(apiEndPoints.methodType.POST, url, data);
};

export const deleteInAppEvent = (pk, is_lead, timeEntryData = '') => {
  let tenantId = cookie.get('tenantId');
  let tenantSk = cookie.get('tenantSk');
  tenantSk = tenantSk ? tenantSk.replace('#', '%23') : '';
  pk = pk ? pk.replaceAll('#', '%23') : '';
  let url = `${apiEndPoints.calendar.deleteEvent}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&pk=${pk}${
    is_lead ? `&is_lead=${is_lead}` : ''
  }`;
  return performRequest(apiEndPoints.methodType.DELETE, url, {}, timeEntryData);
};

export const downloadAttachments = async (fileInfo) => {
  let url = fileInfo.file_path.split('/');
  url.shift();
  let _url = url.join('/');
  SetS3Config(FILEUPLOAD_S3, FILEUPLOAD_S3_LEVEL);
  try {
    return Storage.get(_url, { download: true, useAccelerateEndpoint: true });
  } catch (err) {
    console.log(err);
  }
};

export const updateInAppEvent = (reminder_updated, send_notification, is_lead, data, case_id = '', timeEntryData = '') => {
  let tenantId = cookie.get('tenantId');
  let tenantSk = cookie.get('tenantSk');
  tenantSk = tenantSk ? tenantSk.replace('#', '%23') : '';
  let url = `${
    apiEndPoints.calendar.updateEvent
  }?tenant_id=${tenantId}&tenant_sk=${tenantSk}&case_id=${case_id}&is_reminder_updated=${reminder_updated}&send_update_notification=${send_notification}${
    is_lead ? `&is_lead=${is_lead}` : ''
  }`;
  return performRequest(apiEndPoints.methodType.PUT, url, data, timeEntryData);
};

export const createEventType = (data) => {
  let tenantId = cookie.get('tenantId');
  let tenantSk = cookie.get('tenantSk');
  tenantSk = tenantSk ? tenantSk.replace('#', '%23') : '';
  let url = `${apiEndPoints.calendar.createEventType}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequest(apiEndPoints.methodType.POST, url, data);
};

export const listEventTypes = (pageLimit = 10, pagination_token = '', pagination_direction = 'forward') => {
  let tenantId = cookie.get('tenantId');
  let tenantSk = cookie.get('tenantSk');
  tenantSk = tenantSk ? tenantSk.replace('#', '%23') : '';
  let url = `${apiEndPoints.calendar.listEventTypes}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`;
  return performRequest(apiEndPoints.methodType.GET, url);
};

export const updateEventType = (data) => {
  let tenantId = cookie.get('tenantId');
  let tenantSk = cookie.get('tenantSk');
  tenantSk = tenantSk ? tenantSk.replace('#', '%23') : '';
  let url = `${apiEndPoints.calendar.updateEventType}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequest(apiEndPoints.methodType.PUT, url, data);
};

export const deleteEventType = (pk, sk) => {
  let tenantId = cookie.get('tenantId');
  let tenantSk = cookie.get('tenantSk');
  tenantSk = tenantSk ? tenantSk.replace('#', '%23') : '';
  pk = pk ? pk.replace('#', '%23') : '';
  sk = sk ? sk.replaceAll('#', '%23') : '';
  let url = `${apiEndPoints.calendar.deleteEventType}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&pk=${pk}&sk=${sk}`;
  return performRequest(apiEndPoints.methodType.DELETE, url);
};

export const deleteFile = async (fileInfo) => {
  let url = fileInfo.file_path.split('/');
  url.shift();
  // console.log("url",url.join("/"))
  SetS3Config(FILEUPLOAD_S3, FILEUPLOAD_S3_LEVEL);
  let user = await Auth.currentAuthenticatedUser();
  let userId = null;
  try {
    userId = user.attributes.sub;
    return Storage.remove(`${url.join('/')}`);
  } catch (err) {
    /*  console.log(err) */
  }
};

export const listUsers = (caseId, is_person, all_contacts = false, contact_id = false, is_team_member_list = false) => {
  let tenantId = cookie.get('tenantId');
  let tenantSk = cookie.get('tenantSk');
  tenantSk = tenantSk ? tenantSk.replaceAll('#', '%23') : '';
  let url = `${apiEndPoints.calendar.userList}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${caseId ? `&case_id=${caseId}` : ''}${
    all_contacts ? `&all_contacts=${all_contacts}` : ''
  }${is_person ? `&is_person=${is_person}` : ''}${is_team_member_list ? `&is_team_member_list=${is_team_member_list}` : ''}`;
  if (contact_id) url = url + '&contact_id=' + contact_id;

  return performRequest(apiEndPoints.methodType.GET, url);
};

export const updateCalendarSettings = (data) => {
  let tenantId = cookie.get('tenantId');
  let tenantSk = cookie.get('tenantSk');
  tenantSk = tenantSk ? tenantSk.replaceAll('#', '%23') : '';
  let url = `${apiEndPoints.calendar.updateSettings}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequest(apiEndPoints.methodType.PUT, url, data);
};

export const getCalendarSettings = () => {
  let tenantId = cookie.get('tenantId');
  let tenantSk = cookie.get('tenantSk');
  tenantSk = tenantSk ? tenantSk.replaceAll('#', '%23') : '';
  let url = `${apiEndPoints.calendar.getSettings}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequest(apiEndPoints.methodType.GET, url);
};
