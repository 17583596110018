import React, { useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { Checkbox } from 'primereact/checkbox';

import { uuidv4 } from 'common/uuidGenerator';
import { useTaskContext } from 'modules/Tasks/Context/TaskContext';
import { classNames } from 'primereact/utils';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

export default function NestedSubTask({ control, register }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sub_task',
  });
  const { isClosed } = useTaskContext();

  const [editingIndexes, setEditingIndexes] = useState([]);

  const toggleEdit = (index) => {
    if (editingIndexes.includes(index)) {
      setEditingIndexes(editingIndexes.filter((item) => item !== index));
    } else {
      setEditingIndexes([...editingIndexes, index]);
    }
  };

  const handleAppend = () => {
    append({ sub_task_description: '', sub_task_status: false, sk: uuidv4() });
    setEditingIndexes([...editingIndexes, fields.length]); // Make the newly appended field editable
  };

  const handleRemove = (index) => {
    remove(index);
    setEditingIndexes((prevIndexes) => prevIndexes.filter((item) => item !== index).map((item) => (item > index ? item - 1 : item)));
  };

  return (
    <>
      {fields.map((item, index) => {
        const isEditing = editingIndexes.includes(index);

        return (
          <div className="d-flex align-items-center" key={item.id}>
            <Controller
              name={`sub_task[${index}].sub_task_status`}
              control={control}
              defaultValue={false}
              render={({ field, fieldState }) => (
                <Checkbox
                  inputId={field.name}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e.checked)}
                  checked={field.value}
                  className={classNames({ 'p-invalid': fieldState.invalid, 'pe-none opacity-50': isClosed })}
                  disabled={isClosed}
                />
              )}
            />
            {!isClosed && isEditing ? (
              <TextSnippetWrapper
                type="text"
                className="ms-2 mt-2 mb-2 w-100 input-shadow"
                defaultValue={item.sub_task_description}
                {...register(`sub_task[${index}].sub_task_description`)}
                disabled={isClosed}
              />
            ) : (
              <label className="ms-2 mt-2 mb-2" onClick={() => toggleEdit(index)}>
                {item.sub_task_description}
              </label>
            )}
            <div className={`ms-3 ${isClosed ? 'pe-none opacity-50' : ''}`}>
              <i className="icon-delete icon-red pointer" onClick={() => handleRemove(index)} />
            </div>
          </div>
        );
      })}
      <div className={`mt-2 edit-field pointer addfield  ${isClosed ? 'disabled-icon' : ''}`} onClick={handleAppend}>
        <i className="icon-add F-size14 me-1"></i> Add Sub Task
      </div>
    </>
  );
}
