import React, { createContext, useEffect, useState, useContext } from 'react';
import Cookies from 'universal-cookie';
import moment from 'moment';
import constants from 'constants/index';
import { toastConstant } from 'constants/toastmessage';
import { getUserDetails } from 'services/index';
import { getUserProfileDetails } from 'services/profileServices';
import { SpinnerComponent } from 'shared/SpinnerComponent';
import { useToast } from './ToastContext';
import { listUserTenants } from 'services/authentication/authServices';
import { listCasesForUser } from 'services/CaseServices';

const cookie = new Cookies();

const UserDetailsContext = createContext(null);

export const useUserDetailsContext = () => useContext(UserDetailsContext);

const currentBrowserTimeZone = moment.tz.guess();

const UserDetailsProvider = (props) => {
  const { addToast } = useToast() || {};

  const [userDetails, setUserDetails] = useState();

  const [access_level, setAccessLevel] = useState();
  const [tenantName, setTenantName] = useState();
  const [permissions, setPermissions] = useState({
    dashboard: '',
    cases: '',
    files: '',
    templates: '',
    messages: '',
    contacts: '',
    calendar: '',
    billing: '',
    leads: '',
    reports: '',
    automation: '',
    tasks: '',
    settings: {
      visible_practice_area: [],
      add_new_users: false,
      edit_permissions: false,
      delete_items: false,
      edit_custom_fields: false,
      manage_firm_settings: false,
    },
  });
  const [firmDetails, setFirmDetails] = useState([]);
  const [textMessageStatus, setTextMessageStatus] = useState('');
  const [firmPhoneNumber, setFirmPhoneNumber] = useState('');
  const [profileData, setProfileData] = useState('');
  const [selectedPlanDetails, setSelectedPlanDetails] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [subscriptionAccess, setSubscriptionAccess] = useState({});
  const [loading, setLoading] = useState(true);
  const [clientCaseList, setClientCaseList] = useState([]);
  const [clientSelectedCase, setClientSelectedCase] = useState();

  const loadUserTenantsDetails = async () => {
    const tenantDetailsResponse = await listUserTenants();
    const userTenantsList = tenantDetailsResponse?.data;
    if (userTenantsList?.length) {
      const stringifiedUserTenantsList = JSON.stringify(userTenantsList);
      localStorage.setItem('userTenantsList', stringifiedUserTenantsList);
      return userTenantsList;
    }
  };

  const loadUserDetails = async (tenant_Id, excludeFromLoading = false) => {
    let tenantId = tenant_Id || cookie.get('tenantId');
    try {
      let response = await getUserDetails(tenantId, excludeFromLoading);

      if (response?.data) {
        setFirmDetails(response?.data);
        cookie.set('userId', response?.data?.current_user, { path: '/', domain: process.env.REACT_APP_DOMAIN });
        const { current_user, firm_details = [] } = response?.data || {};
        let selectedFirmDetails;

        if (firm_details?.length) {
          selectedFirmDetails = firm_details?.find((firm) => firm?.tenant_id === tenantId);
        }

        //section 1
        if (selectedFirmDetails) {
          const {
            tenant_id,
            sk,
            company,
            access_level,
            user_permissions,
            subscription_details,
            text_messaging_status,
            firm_phone_number,
            customer_id,
          } = selectedFirmDetails || {};

          //============== Subscription Related==========//
          if (
            access_level &&
            access_level !== 'firm-admin' &&
            subscription_details?.message &&
            subscription_details?.message === constants?.NON_FIRM_ADMIN_SUBSCRIPTION_CANCEL_MESSAGE
          ) {
            localStorage.clear();
            clearSubscriptionValue();
            addToast(
              false,
              toastConstant?.toasterType?.ERROR,
              toastConstant?.api?.FAILED,
              constants.NON_FIRM_ADMIN_SUBSCRIPTION_CANCEL_MESSAGE
            );
            return true;
          }

          //============== Subscription Related ==========//

          cookie.set('tenantId', tenant_id, { path: '/', domain: process.env.REACT_APP_DOMAIN });
          cookie.set('tenantSk', sk, { path: '/', domain: process.env.REACT_APP_DOMAIN });
          cookie.set('tenantName', company, { path: '/', domain: process.env.REACT_APP_DOMAIN });
          cookie.set('userId', current_user, { path: '/', domain: process.env.REACT_APP_DOMAIN });
          cookie.set('access_level', access_level, { path: '/', domain: process.env.REACT_APP_DOMAIN });

          //Inorder to check if automatic time tracker is enabled or not
          if (selectedFirmDetails?.automatic_time_tracking_active) {
            cookie.remove('stopAutomaticTimer');
          } else {
            cookie.set('stopAutomaticTimer', !selectedFirmDetails?.automatic_time_tracking_active, {
              path: '/',
              domain: process.env.REACT_APP_DOMAIN,
            });
          }
          cookie.set('idle_screen_tracking', selectedFirmDetails?.idle_screen_tracking, {
            path: '/',
            domain: process.env.REACT_APP_DOMAIN,
          });

          setUserDetails({
            ...selectedFirmDetails,
            text_snippets_map: response?.data?.text_snippets_map,
            timezone: selectedFirmDetails?.timezone || currentBrowserTimeZone,
          });
          setTenantName(company);
          setAccessLevel(access_level);
          setTextMessageStatus(text_messaging_status);
          setFirmPhoneNumber(firm_phone_number);
          setSelectedPlanDetails(subscription_details);
          setCustomerId(customer_id);
          if (user_permissions) setPermissions(user_permissions);
          if (subscription_details?.access_modules) setSubscriptionAccess(subscription_details?.access_modules);
        } else {
          //TODO:CHECK LOGIC
          let selected_tenant = cookie.get('selected_tenant');
          if (selected_tenant) {
            let firmDetails = firm_details?.filter((index) => index.tenant_id === selected_tenant);
            selectTenant(firmDetails);
          }
        }

        return response;
      }

      setLoading(false);
    } catch (err) {
      console.log('err', err);
      setLoading(false);
      localStorage.clear();
      if (err) {
        const { message } = err || {};
        if (
          message === 'Refresh Token has expired' ||
          message === 'Invalid Refresh Token' ||
          message === 'Refresh Token has been revoked' ||
          err === 'No current user'
        ) {
          cookie.remove('token');
          window.location.reload();
        }
      }
    }
  };

  const getPermission = (module) => {
    return permissions[module];
  };
  const getFirmPermission = (key) => {
    let accessLevel = cookie.get('access_level') || null;

    let permission = '';
    let settings = permissions?.settings;
    permission = settings ? settings[key] : '';

    if (accessLevel !== 'client' && key === 'manage_firm_settings') {
      return true;
    }

    return permission;
  };

  const selectTenant = (data) => {
    cookie.set('tenantId', data.tenant_id, { path: '/', domain: process.env.REACT_APP_DOMAIN });
    cookie.set('tenantSk', data.sk, { path: '/', domain: process.env.REACT_APP_DOMAIN });
    cookie.set('tenantName', data.company, { path: '/', domain: process.env.REACT_APP_DOMAIN });
    cookie.set('subdomain', data.subdomain, { path: '/', domain: process.env.REACT_APP_DOMAIN });

    setTenantName(data.company);

    cookie.set('access_level', data.access_level, { path: '/', domain: process.env.REACT_APP_DOMAIN });

    setAccessLevel(data.access_level);
    if (data.user_permissions) setPermissions(data.user_permissions);
    if (data?.subscription_details?.access_modules) setSubscriptionAccess(data?.subscription_details?.access_modules);
    setUserDetails({ ...data, timezone: data?.timezone || currentBrowserTimeZone });
  };

  const fetchUserDetails = () => {
    getUserProfileDetails()
      .then((response) => {
        if (response && response.data) {
          setProfileData({ ...response.data });
        } else {
          setProfileData('');
        }
      })
      .catch(() => {
        setProfileData('');
      })
      .finally(() => setLoading(false));
  };

  const clearSubscriptionValue = () => {
    setSelectedPlanDetails('');
    setCustomerId('');
  };

  const checkSubPermission = (name) => {
    return Boolean(subscriptionAccess?.[name]);
  };

  const loadCases = (id) => {
    listCasesForUser('contact', id, '')
      .then((response) => {
        if (response && response.data && response.data.length) {
          let dat = response.data.map((val) => {
            if (val && val.case_description && val.case_description.case_name) {
              val.case_name = val.case_description.case_name;
            } else {
              val.case_name = 'TBD';
            }
            return val;
          });
          setClientCaseList([...dat]);
          setClientSelectedCase(dat?.[0]);
          // handleCaseChange({ value: dat[0] });
        } else {
          setClientCaseList([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        setClientCaseList([]);
      });
  };

  useEffect(() => {
    let token = cookie.get('token');
    if (!userDetails && token) {
      const fetchUser = async () => {
        let tenantId = cookie.get('tenantId');
        if (tenantId) await loadUserDetails(tenantId);
        fetchUserDetails();
      };

      fetchUser();
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (checkSubPermission('automatic_time_tracking') === false) {
      cookie.set('stopAutomaticTimer', false, { path: '/', domain: process.env.REACT_APP_DOMAIN });
    }
  }, [subscriptionAccess]);

  useEffect(() => {
    if (userDetails && userDetails.contact_id && userDetails?.access_level === 'client') {
      loadCases(userDetails?.contact_id);
    }
  }, [userDetails]);

  if (loading) {
    return <SpinnerComponent />;
  }

  return (
    <UserDetailsContext.Provider
      value={{
        userDetails,
        loadUserTenantsDetails,
        loadUserDetails,
        access_level,
        tenantName,
        permissions,
        getPermission,
        getFirmPermission,
        firmDetails,
        selectTenant,
        textMessageStatus,
        firmPhoneNumber,
        profileData,
        setProfileData,
        fetchUserDetails,
        selectedPlanDetails,
        customerIdVal: customerId,
        clearSubscriptionValue,
        subscriptionAccess,
        checkSubPermission,
        clientCaseList,
        clientSelectedCase,
        setClientSelectedCase,
      }}
    >
      {props.children}
    </UserDetailsContext.Provider>
  );
};

const UserDetailsConsumer = UserDetailsContext?.Consumer;

export { UserDetailsContext, UserDetailsProvider, UserDetailsConsumer };
