import React, { useContext } from 'react';

import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import CaseListDropDown from 'components/CaseListDropDown/CaseListDropDown';
import { UserDetailsContext } from 'context/userDetailsContext';

function CaseFilter() {
  const { fileManagerFilters, onFilterChange, isClientFileManager, setBreadCrumbs } = useFileManagerContext();
  const userContext = useContext(UserDetailsContext);

  function clearBreadcrumbs() {
    setBreadCrumbs([]);
  }

  return (
    <div className="me-2 pb-2 py-md-2 filter-wraper">
      {isClientFileManager && userContext?.clientCaseList.length > 1 && (
        <CaseListDropDown
          isClient={isClientFileManager}
          value={fileManagerFilters?.case}
          onChange={(e) => {
            if (fileManagerFilters?.case !== e) {
              onFilterChange({ target: { name: 'case', value: e }, value: e }, clearBreadcrumbs);
            }
          }}
          name="case"
          isClearable
          selectFirstOption
        />
      )}
      {!isClientFileManager && (
        <CaseListDropDown
          isClient={isClientFileManager}
          placeholder="By Case/Lead"
          value={fileManagerFilters?.case}
          onChange={(e) => {
            if (fileManagerFilters?.case !== e) {
              onFilterChange({ target: { name: 'case', value: e }, value: e }, clearBreadcrumbs);
            }
          }}
          noOptionsMessage="No Cases/Leads Found"
          name="case"
          isClearable
        />
      )}
    </div>
  );
}

export default CaseFilter;
