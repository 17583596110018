import React, { useRef } from 'react';
import { useToggle } from 'hooks/useToggle';
import { Controller, useForm } from 'react-hook-form';
import { useTaskContext } from 'modules/Tasks/Context/TaskContext';
import { Menu } from 'primereact/menu';
import { classNames } from 'primereact/utils';
import { confirmDialog } from 'primereact/confirmdialog';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

const StageHeader = (props) => {
  const { data, handleManageColumn, handleAddStage, ...rest } = props;
  const opanel = useRef();

  const { handleSubmit, control, reset, watch } = useForm();
  const [showLaneNameInputField, setShowLaneNameInputField] = useToggle(false);
  const { updateStageData, deleteStage, setSelectedStageId, setAddTaskToggle, isClosed } = useTaskContext();
  const stageName = watch('laneName');

  const onUpdateStage = ({ laneName }) => {
    updateStageData(data?.title, { task_stage_name: laneName });
    setShowLaneNameInputField();
  };

  const closeNameChange = () => {
    setShowLaneNameInputField(false);
    reset({ laneName: data?.title });
  };

  const handleDuplicateStages = () => {
    opanel?.current?.hide();
    handleAddStage({ laneName: data?.title + ' Duplicate' });
  };

  const manageColumnOnClick = () => {
    opanel?.current?.hide();
    handleManageColumn(data?.id);
  };

  const addTask = () => {
    setSelectedStageId(data?.id);
    setAddTaskToggle(true);
  };

  const isDefaultColumns = data?.id === 'to_do' || data?.id === 'in_progress' || data?.id === 'done';

  const menuItems = [
    { label: 'Manage Column', command: manageColumnOnClick },
    { label: 'Rename', command: setShowLaneNameInputField, disabled: isDefaultColumns },
    { label: 'Duplicate', command: handleDuplicateStages },
    {
      label: 'Delete',
      command: () => {
        confirmDialog({
          message: data?.items?.length
            ? 'This stage contains tasks. Deleting the stage will also delete the associated tasks. Are you sure you want to proceed with the deletion?'
            : 'Do you want to delete this stage?',
          header: 'Delete Confirmation',
          icon: 'pi pi-info-circle',
          acceptClassName: 'p-button-danger',
          accept: () => deleteStage(data?.id),
          style: { maxWidth: 350 },
        });
      },
      disabled: isDefaultColumns,
    },
  ];

  const validateName = !(stageName?.length > 0 && stageName !== data?.title);

  return (
    <div {...rest}>
      <div className="d-flex align-items-center m-1">
        {showLaneNameInputField ? (
          <div className="d-flex col-7">
            <Controller
              name="laneName"
              control={control}
              defaultValue={data?.title || ''}
              rules={{ required: true, pattern: { value: /^(?!^\d+$).*/i } }}
              render={({ field }) => <TextSnippetWrapper required id={field.name} type="text" className="w-100 input-shadow" {...field} />}
            />
            <div
              className={classNames({ 'd-flex justify-content-center align-items-center ms-1 me-1': true, 'disabled-icon': validateName })}
            >
              <i className="pi pi-check cursor-pointer" onClick={handleSubmit(onUpdateStage)} />
            </div>
            <div className="d-flex justify-content-center align-items-center ms-1">
              <i className="pi pi-times cursor-pointer" onClick={closeNameChange} />
            </div>
          </div>
        ) : (
          <div className="col-7 F-size16 task-heading">{data.title}</div>
        )}

        <div className="col-5 d-flex align-items-center justify-content-end">
          <div className="pe-2">
            {!isClosed && (
              <div className="d-flex align-items-center justify-content-center p-2 btn-add-task pointer" onClick={addTask}>
                <i className="icon-add" />
              </div>
            )}
          </div>
          <div className="pe-2">
            <div className="d-flex align-items-center justify-content-center p-2 task-number-header">{data?.items?.length ?? 0}</div>
          </div>
          {!isClosed && (
            <div className="pointer">
              <div
                className="d-flex border border-1 rounded  justify-content-center p-2 task-lane-header-options pointer"
                onClick={(e) => opanel?.current?.toggle(e)}
              >
                <i className="pi pi-ellipsis-v" />
              </div>
            </div>
          )}
        </div>
      </div>
      <Menu model={menuItems} popup ref={opanel} id="popup_menu" />
    </div>
  );
};

export default React.memo(StageHeader);
