import { useEffect, useState } from 'react';
import { getCaseStage } from 'services/case/caseServices';
import { totalCountEvent } from 'services/generalServices';

export function useCaseStageList() {
  const [List, setList] = useState([]);
  const [caseStageOptions, setCaseStageOptions] = useState(null);

  const loadStageList = (practice_area = false) => {
    totalCountEvent('case-stage').then((response) => {
      getCaseStage({ practice_area, pageLimit: response?.data ?? 10 })
        .then((response) => setList(response?.data?.case_stage))
        .catch((error) => console.log(error));
    });
  };

  useEffect(() => {
    loadStageList();
  }, []);

  useEffect(() => {
    if (List && List.length) {
      let data = [];
      List.forEach((val) => {
        data.push({
          label: val.stage_name,
          value: val.stage_name,
        });
      });
      setCaseStageOptions(data);
    } else {
      setCaseStageOptions([]);
    }
  }, [List]);

  return { caseStageList: List, loadStageList, caseStageOptions };
}
