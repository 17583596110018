import { performRequestThird } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';
import { getTenantDetails } from 'utils/utils';

export const createSnippetService = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.createSnippet}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestThird(apiEndPoints.methodType.POST, url, data);
};

export const updateSnippetService = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.updateSnippet}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestThird(apiEndPoints.methodType.PUT, url, data);
};

export const listSnippet = (pagination_token, pagination_direction = 'forward', limit = 10) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints?.getSnippet}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${limit}&pagination_token=${
    pagination_token || ''
  }&pagination_direction=${pagination_direction}`;
  return performRequestThird(apiEndPoints?.methodType?.GET, url);
};

export const deleteSnippetService = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.deleteSnippet}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestThird(apiEndPoints.methodType.DELETE, url, data);
};
