import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import FeeAndPayments from 'modules/lead/AddLead/components/FeePayment/FeePayment';
import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';
import { getNestedProperty } from 'utils/utils';
import { ModalHeader } from 'shared/ModalHeader';
import useConvertCaseForm from 'modules/lead/hooks/useConvertCaseForm';
import Contacts from './Contacts';
import TeamMember from './TeamMember';
import { useToast } from 'context/ToastContext';
import { updateTeamMember } from 'services/teamManagement.service';
import { toastConstant } from 'constants/toastmessage';
import GlobalLoader from 'components/GlobalLoader/GlobalLoader';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
export const ConvertCaseDialogue = ({ onHide, leadDetails, getDetails, formState }) => {
  const { paymentStructures, control, formProps, loading, updateLeadData } = useConvertCaseForm({ onHide, leadDetails });

  const [selectedList, setSelectedList] = useState([]);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const [primaryContact, setPrimaryContact] = useState();
  const [billingContact, setBillingContact] = useState();
  const { addToast } = useToast();
  const { handleSubmit } = useForm();

  const RequiredValidation = ({ field, message = 'Field Required' }) => {
    return getNestedProperty(formProps?.formState?.errors, field) ? (
      <InputErrorMessage>{getNestedProperty(formProps?.formState?.errors, field)?.message || message}</InputErrorMessage>
    ) : null;
  };

  useEffect(() => {
    setSelectedTeamMembers(
      selectedList
        .filter((user) => user.is_selected)
        .map(({ pk, sk, firm_user_id, ...rest }) => {
          if (firm_user_id === leadDetails?.lead_assign_to[0]?.firm_user_id) {
            // If the firm ID matches, include pk and sk
            return {
              firm_user_id,
              pk: leadDetails?.lead_assign_to[0]?.pk,
              sk: leadDetails?.lead_assign_to[0]?.sk,
              case_id: leadDetails.case_id,
              ...rest,
              ...leadDetails?.lead_assign_to[0],
            };
          } else {
            // If the firm ID does not match, exclude pk and sk
            return { ...rest, firm_user_id, case_id: leadDetails.case_id };
          }
        })
    );
  }, [selectedList, leadDetails?.case_id]);

  const updateTeamMembers = async (selectedMembers) => {
    return updateTeamMember({ team_member: selectedMembers })
      .then((response) => {
        typeof getDetails === 'function' && getDetails();
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.TEAM_MEMBER_CASE_ADD_SUCCESS);
      })
      .catch(() => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.TEAM_MEMBER_CASE_ADD_FAILED);
      });
  };

  const handleConvertLead = handleSubmit(async () => {
    if (primaryContact && selectedTeamMembers.length > 0) {
      try {
        await updateTeamMembers(selectedTeamMembers.map((member, index) => ({ ...member, key: index })));
      } catch (error) {
        console.log(error);
        return;
      }
    }

    const convertedLeadData = typeCheck();
    formProps.handleSubmit((formValues) => {
      const data = { ...formValues, ...convertedLeadData };
      updateLeadData(data);
    })();
  });

  const typeCheck = () => {
    let convertedLeadData = {};

    if (primaryContact?.res_type === 'case_contact') {
      const updatedCaseContact = {
        ...primaryContact,
        is_primary_contact: true,
        is_billing_contact: primaryContact?.is_billing_contact === true ? true : false,
      };
      convertedLeadData = {
        ...convertedLeadData,
        case_contact: updatedCaseContact,
      };
    } else if (primaryContact?.res_type === 'case_relation') {
      const updatedCaseRelation = {
        ...primaryContact,
        is_primary_contact: true,
        is_billing_contact: primaryContact?.is_billing_contact === true ? true : false,
      };
      convertedLeadData = {
        ...convertedLeadData,
        case_relation: [updatedCaseRelation],
      };
    }

    const caseContacts = billingContact?.filter((contact) => contact.res_type === 'case_contact');
    const caseRelation = billingContact?.filter((contact) => contact.res_type === 'case_relation');

    if (caseContacts?.length > 0) {
      const caseContactData = {
        ...caseContacts[0],
        is_billing_contact: true,
        is_primary_contact: primaryContact?.contact_id === caseContacts[0]?.contact_id,
      };
      convertedLeadData = {
        ...convertedLeadData,
        case_contact: caseContactData,
      };
    }

    if (caseRelation?.length > 0) {
      const updatedCaseRelations = caseRelation.map((contact) => ({
        ...contact,
        is_billing_contact: true,
        is_primary_contact: primaryContact?.contact_id === contact?.contact_id,
      }));
      convertedLeadData = {
        ...convertedLeadData,
        case_relation: updatedCaseRelations,
      };
    }

    return convertedLeadData;
  };

  return (
    <Dialog style={{ width: '60vw' }} visible onHide={onHide} className="new-task-popup convertocase" header={ModalHeader} footer={<></>}>
      <GlobalLoader />
      <div className="row">
        <div className="col-sm-12">
          <div className="modal-heading F-size16 text-bold mb-3">Convert to Case</div>
        </div>
      </div>
      <div className="row py-2">
        <div className="col-12 p-medium mt-2">
          Case Name
          <span className="danger-text"> *</span>
        </div>
        <div className="col-12 mt-2">
          <Controller
            name="case_description.case_name"
            control={control}
            rules={{ required: 'Case Name is Required' }}
            render={({ field }) => (
              <TextSnippetWrapper type="text" placeholder="Type in" id={field.name} {...field} autoFocus className="input-shadow w-100" />
            )}
          />
          <RequiredValidation field="case_description.case_name" errors={formState?.errors} />
        </div>
      </div>
      <div className="row align-items-start py-2">
        <div className="col-12 p-medium">
          Fee and Payment
          <span className="danger-text"> *</span>
        </div>
        <div className="col-12 p-medium mt-2">
          <FeeAndPayments isFromConvertToCase paymentStructures={paymentStructures} control={control} hideCustomRate {...formProps} />
        </div>
      </div>
      <div className="row align-items-start py-2">
        <div className="col-12 p-medium">
          Contacts
          <span className="danger-text"> *</span>
        </div>
        <div className="col-12 p-medium mt-2">
          <Contacts
            control={control}
            {...formProps}
            leadDetails={leadDetails}
            primaryContact={primaryContact}
            setPrimaryContact={setPrimaryContact}
            billingContact={billingContact}
            setBillingContact={setBillingContact}
          />
        </div>
      </div>
      <div className="row align-items-start py-2">
        <div className="col-12 p-medium">
          Team Members
          <span className="danger-text"> *</span>
        </div>
        <div className="col-12 p-medium mt-2">
          <TeamMember control={control} {...formProps} selectedList={selectedList} setSelectedList={setSelectedList} />
        </div>
      </div>
      <div className="row mt-2">
        <div className="footer-btn">
          <Button label="Cancel" onClick={onHide} className="p-button-secondary outline mx-2" />
          <Button
            label="Convert"
            loading={loading}
            onClick={() => {
              handleConvertLead();
            }}
            className="me-2 p-button-secondary"
          />
        </div>
      </div>
    </Dialog>
  );
};
