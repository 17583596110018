import { actionTypes } from "constants/types";
const initialState = {
    notifications:[],
    isLoading:false,
    error:""
}

export default function NotificationReducer (state = initialState,action={}){
    switch(action.type){
        case actionTypes.GET_NOTIFICATIONS_STARTED:
            return {
                ...state,
                isLoading:true,
                notifications:[]
            }
        case actionTypes.GET_NOTIFICATIONS_SUCCESS:
            return{
                ...state,
                isLoading:false,
                notifications:action.data
            }
        case actionTypes.GET_NOTIFICATIONS_FAILURE:
            return{
                ...state,
                isLoading:false,
                error:action.error
            }

         default:
            return state
    }
}
