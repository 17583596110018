import React from 'react';
import { Dropdown } from 'primereact/dropdown';

import { FileTypes } from 'common/dropdownOptions';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';

function FileTypeFilter() {
  const { fileManagerFilters, onFilterChange } = useFileManagerContext();

  return (
    <div className="me-2 filter-wraper">
      <Dropdown
        options={FileTypes}
        value={fileManagerFilters.fileType}
        placeholder="By File Type"
        className="file-manager-select col-sm-12"
        onChange={(e) => {
          onFilterChange(e);
        }}
        name="fileType"
      />
    </div>
  );
}

export default FileTypeFilter;
