import { useRef } from 'react';

const useIsInitialRender = () => {
  const isInitialRender = useRef(true);

  if (isInitialRender.current) {
    isInitialRender.current = false;
    return true;
  }
  return false;
};

export default useIsInitialRender;
