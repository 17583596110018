import { useEffect, useState } from 'react';
import { getCaseMatter } from 'services/CaseServices';
import { totalCountEvent } from 'services/generalServices';
import Cookies from 'universal-cookie';
const cookie = new Cookies();

export const useCaseMatter = () => {
  const [caseMatterList, setCaseMatterList] = useState(null);
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';

  const loadCaseMatter = () => {
    totalCountEvent('case-matter')
      .then((response) => {
        getCaseMatter(tenantId, tenantSk, response.data, '', 'forward')
          .then((res) => setCaseMatterList(res.data?.['case-matter'] || []))
          .catch((error) => console.error(error));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    loadCaseMatter();
  }, []);

  return { loadCaseMatter, caseMatterList };
};
