import React from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';

import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';

function Directories() {
  const {
    currentView,
    fileManagerFilters,
    getFiles,
    breadCrumbs,
    setBreadCrumbs,
    filterBreadCrumbs,
    onFilterChange,
    pageLimit,
    isGlobalFileManager,
    caseDetails,
    isClientFileManager,
    query,
  } = useFileManagerContext();

  const home = {
    icon: 'pi pi-home',
    command: (e) => {
      let obj = {
        case_id: isGlobalFileManager || isClientFileManager ? fileManagerFilters.case : caseDetails.case_id,
        selectedFolder: fileManagerFilters.selectedFolder,
        selectedType: fileManagerFilters.fileType,
        tag: fileManagerFilters.tag,
        query: query,
        folder_id: 'home',
      };
      getFiles(obj, currentView, pageLimit);
      let data = {
        target: {
          name: 'selectedFolder',
        },
        value: e.object_id,
      };
      onFilterChange(data, clearBreadcrumbs);
    },
  };

  function clearBreadcrumbs() {
    setBreadCrumbs([]);
  }

  return (
    <div className="file-path-containter d-flex align-items-center py-2 flex-wrap">
      <div className="col-md-3 col-12">
        <label className="p-medium">Directory Path</label>
      </div>
      <div className="col-md-9 col-12">
        <BreadCrumb
          model={breadCrumbs?.map((item) => {
            item.command = (e) => {
              let obj = {
                case_id: isGlobalFileManager || isClientFileManager ? fileManagerFilters.case : caseDetails.case_id,
                selectedFolder: e.item.value,
                selectedType: fileManagerFilters.fileType,
                tag: fileManagerFilters.tag,
                query: query,
                folder_id: e.item.value,
              };
              getFiles(obj, currentView, pageLimit);
              filterBreadCrumbs(e.item.value);
            };
            return item;
          })}
          home={home}
        />
      </div>
    </div>
  );
}

export default Directories;
