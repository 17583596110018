import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { Storage, Auth } from 'aws-amplify';
import { FILEUPLOAD_S3, FILEUPLOAD_S3_LEVEL, SetS3Config } from 'config/amplify_config';
import { useToast } from 'context/ToastContext';
import PdfViewerComponent from 'modules/file_manager/PdfViewerComponent';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { getSignatureStatus } from 'services/fileManager/fileManagerServices';
import { toTitleCase } from 'utils/utils';
import { toastConstant } from 'constants/toastmessage';
import CustomHeader from './../CustomHeader';
import { useHistory } from 'react-router-dom';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { InputSwitch } from 'primereact/inputswitch';
import { Steps } from 'primereact/steps';
import useSpinner from 'hooks/useSpinner';
import useFileDownload from 'modules/file_manager/hooks/useFileDownload';

// status codes
// 0 = mail not send
// 1 = mail send
// 2 = file viewed
// 3 = file signed

function SignatureModal() {
  let interval = useRef();
  const { modals, selectedItem, caseDetails, isCaseFileManager, isLeadFileManager, handleGetFiles, pageLimit } = useFileManagerContext();
  const [spinner, showSpinner, hideSpinner] = useSpinner(true);

  const { showEsignStatusModal, setEsignStatusModal } = modals;
  const [isLoading, setIsLoading] = useState(false);
  const [signData, setSignData] = useState({});
  const { downloadCertFile } = useFileDownload();

  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    if (isLoading) {
      showSpinner();
    } else {
      hideSpinner();
    }
  }, [isLoading]);
  //   useEffect(() => {
  //     if (selectedItem?.file_name) {
  //       let name = selectedItem?.file_name.split('.');
  //       name.pop();
  //       name = name.join('.');
  //       setFileName(name);
  //       setValue("file_name", name)
  //     }
  //   }, [selectedItem]);

  //   useEffect(() => {
  //     if (selectedItem?.case_id) {
  //     }
  //   }, [selectedItem]);
  const refreshSignStatus = async (obj) => {
    try {
      let response = await getSignatureStatus(obj, true);
      if (response?.data?.esignature_status?.toLowerCase() == 'signed') {
        clearInterval(interval?.current);
      }
      setSignData(response?.data);
    } catch (e) {
      clearInterval(interval?.current);
    }
  };

  useEffect(() => {
    console.log(selectedItem, 'SSSS');
    if (selectedItem?.pk) {
      let obj = {
        case_id: selectedItem.case_id,
        file_pk: selectedItem?.pk,
        file_sk: selectedItem?.sk,
        is_lead: isLeadFileManager,
      };

      //requesting foe signature
      getSignatureStatus(obj, false)
        .then((response) => {
          setIsLoading(false);
          console.log(response.data, 'DATAAAAA');
          setSignData(response.data);
          if (response.data?.esignature_status?.toLowerCase() !== 'signed') {
            interval.current = setInterval(() => {
              refreshSignStatus(obj);
            }, 4000);
          }

          //   handleGetFiles(pageLimit);
        })
        .catch((error) => {
          //   let msg = toastConstant.message.E_SIGNATURE_SEND_FAILED;
          //   if (error.response.data === 'Invalid Case') msg = 'File is not linked with a case';
          setIsLoading(false);
        });
    }

    return () => {
      if (interval?.current) {
        clearInterval(interval?.current);
      }
    };
  }, [selectedItem]);
  const downloadSignCertificate = () => {
    downloadCertFile(signData);
  };

  const footer = (
    <div>
      <Button label="Cancel" onClick={() => setEsignStatusModal(false)} className="p-button p-button-secondary outline mx-2" />
      {signData?.esignature_status?.toLowerCase() == 'signed' && (
        <Button label="Download Sign Certificate" onClick={downloadSignCertificate} className="p-button p-button-secondary" />
      )}
    </div>
  );

  const status = [
    {
      label: 'Sent',
      icon: 'pi pi-send',
    },
    {
      label: 'Viewed',
      icon: 'pi pi-eye',
    },
    {
      label: 'Signed',
      icon: 'pi pi-check',
    },
  ];

  const SignProgressComponent = (props) => {
    let { steps, activeIndex } = props;
    return (
      <div className="d-flex align-items-center">
        {steps.map((item, index) => {
          return (
            <>
              <div className="d-flex flex-column align-items-center">
                <div
                  className="rounded-circle  justify-content-center align-items-center d-flex"
                  style={{ width: 30, height: 30, background: activeIndex >= index + 1 ? '#517DDA' : 'lightgrey' }}
                >
                  <i className={`text-white ${item.icon}`}></i>
                </div>
                <span style={{ fontSize: 9 }}>{item.label}</span>
              </div>
              {index + 1 != steps.length && (
                <div className="d-flex flex-column">
                  <div style={{ width: 80, height: 35, background: 'transparent' }} className={'d-flex  align-items-center'}>
                    <div
                      style={{ width: '100%', height: 5, background: activeIndex > index + 1 ? '#517DDA' : 'lightgrey', marginTop: 5 }}
                    />
                  </div>
                  <small style={{ visibility: 'hidden', fontSize: 9 }} className="">
                    {item.label}
                  </small>
                </div>
              )}
            </>
          );
        })}
      </div>
    );
  };
  return (
    <Dialog
      className="common-popup-style"
      header={<CustomHeader onHide={() => setEsignStatusModal(false)} title="E-sign Status" />}
      footer={footer}
      visible={showEsignStatusModal}
      style={{ width: '50vw' }}
      modal
      onHide={() => setEsignStatusModal(false)}
      closable={false}
    >
      <div className="w-100 h-100">
        <div className="d-flex flex-wrap h-100">
          <div className="pe-3 my-3 col-12 d-flex flex-column">
            <span className="text-bold black-600 ps-lg-0 pe-2  my-1">{signData?.esignature_request?.request_name}</span>

            <div className="d-flex  my-1">
              <div className="col-3">
                <span className=" black-600 ps-lg-0 pe-2">Sent By:</span>
              </div>
              <div className="col-7">
                <span className=" black-600 ps-lg-0 pe-2">{signData?.esignature_request?.send_by}</span>
              </div>
            </div>
            <div className="d-flex  my-1">
              <div className="col-3">
                <span className="black-600 ps-lg-0 pe-2">Sent On:</span>
              </div>
              <div className="col-7">
                <span className=" black-600 ps-lg-0 pe-2">{signData?.esignature_request?.send_on}</span>
              </div>
            </div>

            <span className="text-bold black-600 ps-lg-0 pe-2  mt-3">Recipients</span>

            {signData?.esignature_actions?.map((item, index) => {
              return (
                <div className="border border-1 d-flex rounded-1 mt-3 mb-1 p-2 d-flex align-items-center">
                  <div className="col-1 d-flex justify-content-center align-items-center ">
                    <span className="text-bold black-600 ps-lg-0 pe-2  my-1">{index + 1}</span>
                  </div>
                  <div className="col-4 ps-3 border-start ">
                    <span className=" black-600 ps-lg-0 pe-2">{item?.name}</span> <br />
                    <span className=" black-600 ps-lg-0 pe-2">{item?.email}</span>
                  </div>
                  <div className="col-7 d-flex justify-content-center align-items-center">
                    <SignProgressComponent steps={status} activeIndex={item?.status} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default SignatureModal;
