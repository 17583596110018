import React from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';

export default function SuggestedTime({ handleSelectTime, eventFreeTime, op }) {
  return (
    <OverlayPanel ref={op}>
      <div className="suggested-times-container">
        {eventFreeTime?.map((dateGroup, index) => (
          <div className="date-group" key={index}>
            <div className="date-header">{dateGroup?.date}</div>
            <div className="time-slots">
              {dateGroup.times.map((val, timeIndex) => (
                <div className="time-slot" key={timeIndex} onClick={() => handleSelectTime(val?.datetime)}>
                  {val?.time}
                </div>
              ))}
            </div>
          </div>
        ))}
        {eventFreeTime?.length === 0 && <div>No time slots available</div>}
      </div>
    </OverlayPanel>
  );
}
