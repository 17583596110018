import React, { useEffect, useState } from 'react';

import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

import CustomHeader from './CustomHeader';

import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import { getFolders } from 'services/fileManager/fileManagerServices';
import { Controller, useForm } from 'react-hook-form';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import useUpdateFiles from 'modules/file_manager/hooks/useUpdateFiles';
import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';

function MoveItemModal() {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const { addToast } = useToast();
  const { fileBulkMove } = useUpdateFiles();

  const {
    modals,
    isGlobalFileManager,
    fileManagerFilters,
    isLeadFileManager,
    caseDetails,
    handleGetFiles,
    pageLimit,
    selectedRows,
    setSelectedRows,
    breadCrumbs,
    isClientFileManager,
  } = useFileManagerContext();
  const { showBulkMoveModal, setShowBulkMoveModal, isCopy } = modals;
  const [folderList, setFolderList] = useState([]);

  useEffect(() => {
    getFoldersHandler();
  }, []);

  const getFoldersHandler = () => {
    let params = '';
    // if (selectedItem.entry_type === 'folder') {
    //   params = `folder_id=${selectedItem.object_id}&case_id=${isGlobalFileManager ? fileManagerFilters.case_id : caseDetails.case_id}`;
    // } else {
    // }
    params = `case_id=${isGlobalFileManager || isClientFileManager ? fileManagerFilters?.case : caseDetails?.case_id}`;

    getFolders(params)
      .then((response) => {
        let items = response.data;
        let folders_in_selected = selectedRows.filter((item) => item.entry_type === 'folder').map((item) => item.object_id);
        items = items.filter((item) => !folders_in_selected.includes(item.value));
        const dropdownList = items.filter((item) => Object.keys(item).length > 1);
        // option to move to root folder
        breadCrumbs?.length !== 0 && dropdownList?.push({ label: 'Root directory', value: 'root' });
        setFolderList(dropdownList);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const moveFileHandler = (data) => {
    if (data.folder === fileManagerFilters.selectedFolder) {
      addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.ERROR, toastConstant.message.FILE_MOVE_ERROR);
      return;
    }

    let file_ids = selectedRows.map((item) => {
      return {
        pk: item.pk,
        sk: item.sk,
      };
    });
    let payload = {
      file_ids,
      case_id: selectedRows[0].case_id !== 'undefined' ? selectedRows[0].case_id : '',
      folder_id: data.folder === 'root' ? '' : data.folder,
    };

    if (isCopy) {
      payload.action = 'copy';
    }

    fileBulkMove(payload, 'move', isLeadFileManager)
      .then(() => {
        handleGetFiles(pageLimit);
        setShowBulkMoveModal(false);
        setSelectedRows([]);
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.FILE_MOVED_SUCCESS);
      })
      .catch((error) => {
        console.log(error);
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.ERROR, toastConstant.message.FILE_MOVE_FAILED);
      });
  };

  const moveFooter = (
    <div className="footer-btn">
      <Button label="Cancel" onClick={() => setShowBulkMoveModal(false)} className="p-button p-button-secondary outline mx-2" />
      <Button label={isCopy ? 'Copy' : 'Move'} onClick={handleSubmit(moveFileHandler)} className="p-button p-button-secondary" />
    </div>
  );

  return (
    <Dialog
      className="new-task-popup"
      header={<CustomHeader onHide={() => setShowBulkMoveModal(false)} />}
      footer={moveFooter}
      visible={showBulkMoveModal}
      style={{ width: '40vw' }}
      modal
      onHide={() => setShowBulkMoveModal(false)}
      closable={false}
    >
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="modal-heading F-size16 text-bold">{isCopy ? 'Copy' : 'Move'} Files</div>
      </div>
      <div className="P-medium">
        {isCopy ? 'Copy' : 'Move'} selected {selectedRows.length} files to
      </div>
      <Controller
        name="folder"
        control={control}
        defaultValue={[]}
        rules={{ required: true }}
        render={({ field }) => (
          <Dropdown
            className="input-shadow mb-2 mt-2 w-100 F-size14"
            placeholder={'Target Folder'}
            onChange={(e) => {
              field.onChange(e.value);
            }}
            id={field.name}
            value={field.value}
            options={folderList}
            isSearchable
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
        )}
      />
      {errors?.folder && <InputErrorMessage>Select folder</InputErrorMessage>}
    </Dialog>
  );
}

export default MoveItemModal;
