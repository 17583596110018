import React, { useRef } from 'react';
import { TransformWrapper, TransformComponent, useControls } from 'react-zoom-pan-pinch';
import PrintFile from '../../ListView/Templates/ActionsTemplate/PrintFile';
import FileDownload from '../../ListView/Templates/ActionsTemplate/FileDownload';

const ImageViewer = (props) => {
  const { imageUrl, title, onLoad, item } = props;
  const imageRef = useRef();

  const Controls = () => {
    const { zoomIn, zoomOut } = useControls();
    return (
      <div className="zoom-controls">
        <i className="icon-zoom-out p-2 pointer float-end h2 mb-0" title="Zoom Out" onClick={() => zoomOut()} />
        <i className="icon-zoom-in p-2 pointer float-end h2 mb-0" title="Zoom In" onClick={() => zoomIn()} />

        <FileDownload
          rowData={item}
          isFromFilePreviewModal={true}
          filePreviewModalIconClassName={'icon-save p-2 pointer float-end h2 mb-0'}
        />
        <PrintFile
          rowData={item}
          isFromFilePreviewModal={true}
          filePreviewModalIconClassName={'icon-Print p-2 pointer float-end h2 mb-0'}
          imageRef={imageRef}
        />
      </div>
    );
  };

  return (
    <>
      <TransformWrapper minScale={0.3}>
        <Controls />
        <TransformComponent>
          <div className="image-comp">
            <img ref={imageRef} src={imageUrl} alt={title} controls onLoad={typeof onLoad === 'function' && onLoad} />
          </div>
        </TransformComponent>
      </TransformWrapper>
    </>
  );
};

export default ImageViewer;
