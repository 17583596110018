import React from 'react';
import { Card } from 'primereact/card';

import Conversations from './Conversations';
import InputContainer from './InputContainer';
import Sidebar from 'modules/messages/SecureMessages/Sidebar/Sidebar';
import useResposnsiveHandler from 'modules/messages/SecureMessages/hooks/useResposnsiveHandler';

function MessageContainer({ messageDetails, showSideBar, isGlobalMessagesScreen }) {
  const { secondDivRef, contentRef } = useResposnsiveHandler(isGlobalMessagesScreen);

  return (
    <div className="inner-conversation">
      <div className="row mb-3">
        <div className={showSideBar ? 'col-md-8 col-sm-12' : 'col-md-12'}>
          <Card className="shadow-middle">
            <Conversations messageDetails={messageDetails} />
            <InputContainer messageDetails={messageDetails} />
          </Card>
        </div>
        {messageDetails && showSideBar && (
          <div className="col-md-4 col-sm-12 p-0" ref={secondDivRef}>
            <div className="input-shadow bg-white p-2" ref={contentRef} style={{ height: '97vh' }}>
              <Sidebar messageDetails={messageDetails} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MessageContainer;
