import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';

import { fontAwesome } from 'constants/icon';
import { createEventType } from 'services/Calender/calendarServices';

import { useCalendarContext } from 'modules/calendar/Context/CalendarContext';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

function AddEventTypeForm({ setAddEventType, setValue }) {
  const { loadTypes } = useCalendarContext();

  const { addToast } = useToast();

  const {
    control,
    resetField,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const options = Object.keys(fontAwesome).flatMap((category) =>
    fontAwesome[category].map((icon) => ({
      label: icon,
      value: icon,
    }))
  );

  function handleAddEventType(data) {
    createEventType(data)
      .then(async (res) => {
        setAddEventType(false);
        resetField('event_type_icon');
        resetField('event_type');
        await loadTypes();
        setValue('event_type', data);
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.EVENT_TYPE_CREATE_SUCCESS);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        addToast(false, toastConstant.toasterType.INFO, toastConstant.api.FAILED, toastConstant.message.EVENT_TYPE_CREATE_FAILURE);
      });
  }

  const valueTemplate = (option) => {
    return (
      <span className="mx-2">
        <i className={option.value} style={{ fontSize: '25px' }} />
      </span>
    );
  };

  const eventTypeValueTemplate = (item, props) => {
    if (item) {
      return (
        <span className="mx-2">
          <i className={item.value} style={{ fontSize: '25px' }} />
        </span>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  return (
    <div className="d-flex w-100">
      <div className="d-flex gap-2 mb-2 w-100">
        <div>
          <Controller
            name="event_type_icon"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field }) => (
              <Dropdown
                id={field.name}
                name={field.name}
                value={field.value}
                panelClassName="add-event-icon-picker"
                options={options}
                onChange={(e) => field.onChange(e.value)}
                placeholder="Select an icon"
                itemTemplate={valueTemplate}
                valueTemplate={eventTypeValueTemplate}
                showClear={field.value ? true : false}
                filter
              />
            )}
          />
          {errors?.event_type_icon && <InputErrorMessage>Select event icon</InputErrorMessage>}
        </div>
        <div className="w-100">
          <Controller
            name="event_type"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field }) => (
              <TextSnippetWrapper
                type="text"
                id={field.name}
                {...field}
                className="input-shadow w-100 border-0"
                placeholder="Event type name"
              />
            )}
          />
          {errors?.event_type && <InputErrorMessage>Enter Event type name</InputErrorMessage>}
        </div>
      </div>

      <div className="d-flex justify-content-end mt-1">
        <Button
          icon="pi pi-times"
          className="p-button-secondary outline ms-2"
          type="button"
          onClick={() => {
            setAddEventType(false);
            resetField('event_type_icon');
            resetField('event_type');
          }}
        />
        <Button icon="pi pi-check" className="p-button-secondary ms-2" type="button" onClick={handleSubmit(handleAddEventType)} />
      </div>
    </div>
  );
}

export default AddEventTypeForm;
