import { actionTypes } from "constants/types";
let initialState = {
  isCaseDetailsLoading: "",
  caseDetails: "",
  caseDetailsError: "",
};

export default function CaseDetailsReducer(state = initialState, action={}) {
  switch (action.type) {
    case actionTypes.GET_CASE_DETAILS_REQUEST:
      return {
        ...state,
        caseDetails: "",
        caseError: "",
        isCaseDetailsLoading: true,
      };

    case actionTypes.GET_CASE_DETAILS_SUCCESS:
      return {
        ...state,
        caseDetails: action.payload,
        caseDetailsError: "",
        isCaseDetailsLoading: false,
      };

    case actionTypes.GET_CASE_DETAILS_ERROR:
      return {
        ...state,
        caseDetails: "",
        caseDetailsError: action.payload,
        isCaseDetailsLoading: false,
      };

    case actionTypes.CLEAR_CASE_DETAILS:
      return {
        ...state,
        caseCreateSuccess: "",
      };

    default:
      return state;
  }
}
