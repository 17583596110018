import { useContext, useEffect, useState } from 'react';
import moment from 'moment';

import { UserDetailsContext } from 'context/userDetailsContext';
import { handlePageNumberInPagination } from 'common/pagination';
import {
  listTemporaryFunds,
  getTemporaryFundBalance,
  updateTemporaryFundTransaction,
  depositOrWithdrawTemporaryFund,
} from 'services/Billing/temporaryFunds/temporaryFundServices';
import { convertToTimezoneFormat } from 'utils/utility_functions/timezone';

export function useTemporaryFundList({ account_id, case_id, _pageSize = 10, _filter = {}, getCaseDetails, isLeadSpecific }) {
  const userContext = useContext(UserDetailsContext);
  const [data, setData] = useState([]);
  const [isForward, setIsForward] = useState(true);
  const [pageSize, setpageSize] = useState(userContext?.firmDetails?.pagination_preference?.case?.temp_fund_list || _pageSize);
  const [temporaryFundBalance, setTemporaryFundBalance] = useState(0);

  const next_Token = data?.next_token;
  const transition_Token = data?.transition_token;
  const temporaryFundsList = data?.['temporary_funds'] || [];

  const hasNext = next_Token === null && isForward;
  const hasPrevious = transition_Token === null || (transition_Token && next_Token === null && !isForward);

  useEffect(() => {
    setpageSize(userContext?.firmDetails?.pagination_preference?.case?.temp_fund_list || _pageSize);
  }, [userContext?.firmDetails]); // eslint-disable-line

  const fetchNext = () => {
    setIsForward(true);
    loadTempFundList({
      limit: pageSize,
      pagination_token: !isForward ? transition_Token : next_Token,
      pagination_direction: 'forward',
    });
  };

  const fetchprev = () => {
    setIsForward(false);
    loadTempFundList({
      limit: pageSize,
      pagination_token: isForward ? transition_Token : next_Token,
      pagination_direction: 'reverse',
    });
  };

  const onPageChange = (e) => {
    handlePageNumberInPagination(userContext, 'case', '', 'temp_fund_list', e?.value);
    setpageSize(e?.value);
    loadTempFundList({
      limit: e?.value,
      pagination_token: '',
      pagination_direction: 'forward',
    });
    setIsForward(false);
  };

  const pickExtractedDate = (dateArray) => {
    return {
      transaction_date_lower: dateArray?.[0]
        ? convertToTimezoneFormat(moment(dateArray[0]).startOf('day'), userContext?.userDetails.timezone)
        : '',
      transaction_date_upper: dateArray?.[0]
        ? convertToTimezoneFormat(moment(dateArray[1] ? dateArray[1] : dateArray[0]).endOf('day'), userContext?.userDetails.timezone)
        : '',
    };
  };

  const loadTempFundList = ({ limit = pageSize, pagination_token, pagination_direction, filter = _filter }) => {
    let filterParam = { ...filter };
    if (filterParam?.dateFilter?.[0]) {
      filterParam = {
        ...filterParam,
        ...pickExtractedDate(filterParam?.dateFilter),
      };
      delete filterParam?.dateFilter;
    }
    listTemporaryFunds({ limit, pagination_token, pagination_direction, filter: filterParam })
      .then((response) => setData(response?.data))
      .catch((error) => console.log(error));
  };

  const loadTempAccountBalance = (trust_account_id, case_id) => {
    getTemporaryFundBalance(trust_account_id, case_id)
      .then((response) => {
        setTemporaryFundBalance(response?.data?.temporary_fund_balance ? parseFloat(response?.data?.temporary_fund_balance) : 0);
      })
      .catch((err) => {});
  };

  const depositOrWithdrawTemporaryFundAmount = (params) => {
    const { hideModal, data } = params;

    depositOrWithdrawTemporaryFund({ data, is_lead: isLeadSpecific ? true : false })
      .then((res) => {
        hideModal();
        loadTempFundList({});
        !account_id && typeof getCaseDetails === 'function' && getCaseDetails();
        loadTempAccountBalance(account_id, case_id);
      })
      .catch((err) => {
        console.log('Error:depositOrWithdrawTemporaryFund', err);
      });
  };

  const updateTemporaryFundTransactions = (params) => {
    const { hideModal, data } = params;
    updateTemporaryFundTransaction({ data, is_lead: isLeadSpecific ? true : false })
      .then((res) => {
        hideModal();
        loadTempFundList({});
        loadTempAccountBalance(account_id, case_id);
      })
      .catch((err) => {
        console.log('Error:updateTemporaryFundTransaction', err);
      });
  };

  return {
    temporaryFundBalance,
    pageSize,
    temporaryFundsList,
    hasNext,
    hasPrevious,
    fetchNext,
    fetchprev,
    onPageChange,
    loadTempFundList,
    loadTempAccountBalance,
    updateTemporaryFundTransactions,
    depositOrWithdrawTemporaryFundAmount,
  };
}
