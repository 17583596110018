import React from "react";
import logo from "../../src/assets/images/loading-logo.png";
import logoText from "../../src/assets/images/loading-logo-text.png";

export const SpinnerComponent = () => {
  return (
    <>
      <div className="spinner-container">
        <div id="loader">
          <div id="shadow"></div>
          <div id="box">
            <img className="logo-lawft" alt="logo" src={logo} />
          </div>
          <div className="logo">
            <img className="logo-lawft-text" alt="lawft" src={logoText} />
          </div>
        </div>
      </div>
    </>
  );
};
