import { performRequest, performRequestThird } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';
import { getTenantDetails } from 'utils/utils';

export const createCaseMessage = (tenantId, tenantSk, data, is_lead = false) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.createCaseMessage}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${is_lead ? `&is_lead=${is_lead}` : ''}`,
    data
  );
};

export const getCaseMessages = (
  tenantId,
  tenantSk,
  case_id = false,
  author_id = [],
  message_date = false,
  keyword = false,
  is_global = false,
  is_read = '',
  to_limit = false,
  pageLimit = 10,
  pagination_token = '',
  pagination_direction = 'forward'
) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.listMessages}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${case_id ? `&case_id=${case_id}` : ''}${
      message_date ? `&message_date=${message_date}` : ''
    }${keyword ? `&keyword=${keyword}` : ''}${is_global ? `&is_global=${is_global}` : ''}&is_read=${is_read}${
      to_limit ? `&to_limit=${to_limit}` : ''
    }&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`,
    {
      author_id: author_id,
    }
  );
};

export const viewCaseMessageDetails = (
  tenantId,
  tenantSk,
  pk,
  sk,
  pageLimit = 10,
  pagination_token = '',
  pagination_direction = 'forward'
) => {
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.viewMessageThreadDetails}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&sk=${sk}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`
  );
};

export const createCaseMessageReply = (tenantId, tenantSk, message_thread_id, data, is_lead = false) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.createMessageReply}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&message_thread_id=${message_thread_id}${
      is_lead ? `&is_lead=${is_lead}` : ''
    }`,
    data
  );
};

export const updateMessageThread = (tenantId, tenantSk, data, is_lead = false, timeEntryData = '') => {
  return performRequest(
    apiEndPoints.methodType.PUT,
    `${apiEndPoints.updateMessageThread}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${is_lead ? `&is_lead=${is_lead}` : ''}`,
    data,
    timeEntryData
  );
};

// Messasge module secure message  - edit case in message list

export const updateMessageCase = (pk = '', data = {}) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.PATCH,
    `${apiEndPoints.chageMessageCase}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${pk ? `&pk=${pk}` : ''}`,
    data
  );
};
// Messasge module secure message  - Invite users
export const sendInviteToUser = (sk = '', is_lead = false, data = {}) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.PATCH,
    `${apiEndPoints.sendInviteToUser}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${sk ? `&sk=${sk}` : ''}${
      is_lead ? `&is_lead=${is_lead}` : ''
    }`,
    data
  );
};
