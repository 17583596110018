import React from 'react';

import CaseFilter from './CaseFilter';
import FileTypeFilter from './FileTypeFilter';
import TagFilter from './TagFilter';
import ResetFilters from './ResetFilters';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import FileStatusFilter from './FileStatusFilter';

function Filters() {
  const { isGlobalFileManager, isClientFileManager } = useFileManagerContext();
  return (
    <>
      <span className="text-bold black-600 ps-lg-0 pe-2">Filters :</span>
      {isGlobalFileManager || isClientFileManager ? <CaseFilter /> : null}
      <FileTypeFilter />
      <TagFilter />
      <FileStatusFilter />
      <ResetFilters />
    </>
  );
}

export default Filters;
