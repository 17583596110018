import { getCustomFields,deleteCustomField ,editCustomField} from "services/Settings";
import { actionTypes } from "constants/types"
export const getCustomFieldList=(module="",pageLimit=10,pagination_token="",pagination_direction="forward")=>{
    return (dispatch)=>{
        dispatch({type:actionTypes.GET_CUSTOM_FIELD_LIST});
        return getCustomFields(module,pageLimit,pagination_token,pagination_direction).then((response)=>{
            dispatch({
                type:actionTypes.GET_CUSTOM_FIELD_LIST_SUCCESS,
                payload:response.data
            })
        }).catch((error)=>{
            dispatch({type:actionTypes.GET_CUSTOM_FIELD_LIST_ERROR,
                payload: error.response,})
        })
    }
}

export const deleteCustomFieldAction=(pk,sk)=>{
    return (dispatch)=>{
        dispatch({type:actionTypes.DELETE_CUSTOM_FIELD});
        return deleteCustomField(pk,sk).then((response)=>{
            dispatch({type:actionTypes.DELETE_CUSTOM_FIELD_SUCCESS
           })
        }).catch((error)=>{
            dispatch({type:actionTypes.DELETE_CUSTOM_FIELD_ERROR,
                payload: error.response,})
        })
    } 
}

export const updateCustomField=(data)=>{
    return (dispatch)=>{
        dispatch({type:actionTypes.UPDATE_CUSTOM_FIELD});
        return editCustomField(data).then((response)=>{
            dispatch({type:actionTypes.UPDATE_CUSTOM_FIELD_SUCCESS
            })
        }).catch((error)=>{
            dispatch({type:actionTypes.UPDATE_CUSTOM_FIELD_ERROR,
                payload: error.response,})
        })
    }
}