import { useLoaderDispatch } from 'context/LoaderContext';
import { presignedURLGet } from '../services';
import printJS from 'print-js';

export default function usePrintFile() {
  const setLoader = useLoaderDispatch(true);
  let PSPDFKit, instance;

  const pdfTronPrintFile = async (file) => {
    setLoader(true);
    let extension = file?.file_path?.split('.')?.slice(-1)[0]?.toLowerCase();
    let response = await presignedURLGet(file.file_path, file.version_id);
    if (extension === 'pdf') {
      fetch(response?.data?.file_url)
        .then((res) => res.blob())
        .then((blob) => {
          printJS(URL.createObjectURL(blob));
          setLoader(false);
        });
    } else {
      PSPDFKit = await import('pspdfkit');
      PSPDFKit.convertToPDF({
        document: response?.data?.file_url,
        licenseKey: process.env.REACT_APP_PSPDFKIT_KEY,
        // baseUrl: `${process.env.REACT_APP_URL_2}/${process.env.PUBLIC_URL}/`,
        baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}/`,
      }).then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/pdf' });
        printJS(URL.createObjectURL(blob));
        setLoader(false);
      });
    }
  };

  return { pdfTronPrintFile };
}
