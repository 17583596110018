import { generateFullName } from 'utils/generateFullNameUtils';

export const formatCreateNote = (data) => {
  let formData = {
    case_id: data.case_id,
    case_name: data.case_name,
    note_type: data.note_type,
    description: data.description,
    title: data.title,
    resolution_status: data.resolution_status,
    firm_user: data?.assign_to
      ?.filter((v) => !v?.group_id)
      .map((v) => ({
        // team_id: v?.contact_id,
        team_id: v?.client_cognito_username,
        firm_user_id: v?.firm_user_id,
        team_member_name: generateFullName(v),
        is_confirmed: v?.is_confirmed,
      })),
  };
  if (data?.note_type === 'case_note') {
    return {
      ...formData,
      groups: data.assign_to
        ?.filter((v) => v?.group_id)
        .map((v) => ({
          group_id: v?.group_id,
          group_name: v?.group_name,
          sk: v?.sk,
        })),
    };
  } else {
    formData = {
      ...formData,
      call_type: data?.call_type,
      call_date: data?.call_date,
      phone_number: data?.phone,
    };
    if (data?.call_type === 'incoming') {
      return {
        ...formData,
        call_for: data.call_for.map((v) => ({
          team_id: v?.contact_id,
          team_member_name: generateFullName(v),
        })),
        caller_name: {
          client_id: data?.caller_name.contact_id,
          client_name: generateFullName(data?.caller_name),
        },
      };
    } else if (data?.call_type === 'outgoing') {
      return {
        ...formData,
        person_called: {
          client_id: data?.person_called.contact_id,
          client_name: generateFullName(data?.person_called),
        },
      };
    }
  }
};
