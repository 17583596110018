import React, { useContext } from 'react';

import { classNames } from 'primereact/utils';

import { UserDetailsContext } from 'context/userDetailsContext';
import { toCapitalized } from 'utils/utils';
import { Tooltip } from 'primereact/tooltip';
import { GroupUserTooltip } from 'components/UI/GroupUserTooltip/GroupUserTooltip';

function ConversationBetween({ messageDetails, redirectToPage }) {
  const userContext = useContext(UserDetailsContext);

  const handleModuleRedirectionForCurrentUser = (e) => {
    // let currentUser = messageDetails?.message_to?.filter((data) => data?.recipient_id === userContext?.firmDetails?.current_user);//this condition referring to "client_cognito_username"
    // if (currentUser[0].client_cognito_username && currentUser[0].access_level !== 'client') {
    //   redirectToPage(e, `/settings/team-management/${currentUser[0]?.client_cognito_username}`);
    // }

    let currentUser = messageDetails?.message_to?.filter((data) => data?.firm_user_id === userContext?.firmDetails?.firm_user_id);
    let route = '/';

    if (currentUser?.length && currentUser[0]?.firm_user_id && currentUser[0]?.access_level !== 'client') {
      route = `/settings/team-management/${currentUser[0]?.firm_user_id}`;
    }
    redirectToPage(e, route);
  };

  const handleModuleRedirection = (e, data, type) => {
    let route = '/';

    if (type === 'group') {
      route = '/settings/groups';
    } else {
      if (data?.access_level === 'client') {
        route = data?.contact_id ? `/contacts/${data?.contact_id}` : '/contacts';
      } else if (data?.access_level !== 'client') {
        route = data?.firm_user_id ? `/settings/team-management/${data?.firm_user_id}` : '/settings/team-management';
      }
    }

    redirectToPage(e, route);
  };

  return (
    <div className="row mb-4">
      <div className="col-sm-12">
        <span className="F-size14">Conversation Between:</span>
        {messageDetails?.message_to?.filter((data) => data?.recipient_id === userContext.firmDetails.current_user) && (
          <span
            style={{ marginLeft: '0.5rem' }}
            className=" F-size14 text-primary-dark text-decoration-none case-details-link pointer"
            onClick={(e) => handleModuleRedirectionForCurrentUser(e)}
          >
            You
          </span>
        )}
        {messageDetails?.groups?.map((msg, index) => (
          <span
            key={index}
            className={classNames({
              'F-size14 text-primary-dark text-decoration-none case-details-link pointer': true,
              'disabled-element': msg?.group_status === 'Deleted',
              ['name-' + index]: true,
            })}
            onClick={(e) => msg?.group_status !== 'Deleted' && handleModuleRedirection(e, msg, 'group')}
          >
            {/* {` ,  ${toCapitalized(msg?.group_name)} (Group${msg?.group_status ? ' - Deleted' : ''})`} */}
            {` ,  ${toCapitalized(msg?.group_name)} (Group${
              msg?.group_status === 'ARCHIVED' ? ' - Archived' : msg?.group_status === 'Deleted' ? ' - Deleted' : ''
            })`}
            <Tooltip target={`.name-${index}`} position="top">
              {GroupUserTooltip(msg, messageDetails?.message_to)}
            </Tooltip>
          </span>
        ))}

        {messageDetails?.message_to
          ?.filter((data) => data?.recipient_id !== userContext.firmDetails.current_user && data.is_individual_user)
          .map((msg, index) => (
            <span
              key={index}
              className=" F-size14 text-primary-dark text-decoration-none case-details-link pointer class-tooltip"
              onClick={(e) => handleModuleRedirection(e, msg, 'user')}
            >
              {/* {` ,  ${toCapitalized(msg?.recipient_name)} (${msg?.access_level ? toCapitalized(msg?.access_level) : 'Client'})`} */}
              <>
                {' , '}
                {msg?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
                {toCapitalized(msg?.recipient_name)} ({msg?.access_level ? toCapitalized(msg?.access_level) : 'Client'})
              </>

              {msg.status === 'ARCHIVED' && (
                <Tooltip
                  position="top"
                  target=".class-tooltip"
                  content={msg?.status?.toLowerCase()?.charAt(0)?.toUpperCase() + msg?.status?.slice(1)?.toLowerCase()}
                />
              )}
            </span>
          ))}
      </div>
    </div>
  );
}

export default ConversationBetween;
