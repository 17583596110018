import React from 'react';

const HeaderCheckbox = ({ handleSelectAllCheckboxClick, selectAll, selectedRows, files, caseDetails }) => {
  return (
    <input
      type="checkbox"
      onChange={handleSelectAllCheckboxClick}
      checked={selectAll || selectedRows?.length === files?.length}
      disabled={caseDetails?.case_closed || (caseDetails?.is_lead && !caseDetails?.is_open)}
    />
  );
};

export default HeaderCheckbox;
