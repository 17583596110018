import React from 'react';
import { Button } from 'primereact/button';

import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';

function UploadFileButton() {
  const { modals, isClosed, setSelectedItem, setVersionFileUpload } = useFileManagerContext();

  return (
    <span className="disabled-button mr-2">
      <Button
        label="Upload"
        className="p-button  p-button-primary outline ms-2"
        // onClick={(e) => {
        //   setSelectedItem("");
        //   getCaseObject(caseId);
        //   if (!isAtHomeFolder && (!caseId || caseId === "undefined")) {
        //     setNotLinkedToCase(true);
        //   } else {
        //     setNotLinkedToCase(false);
        //   }
        //   setVisible(true);
        // }}
        onClick={() => {
          setSelectedItem();
          setVersionFileUpload(false);

          modals.setShowFileUploadModal(true);
        }}
        disabled={isClosed}
      />
    </span>
  );
}

export default UploadFileButton;
