import { useState } from 'react';
import axios from 'axios';
import { uuidv4 } from 'common/uuidGenerator';

const useDownloadProgress = () => {
  const [downloads, setDownloads] = useState([]);

  const downloadFile = (url, fileName) => {
    const id = uuidv4();
    const download = {
      id,
      progress: 0,
      showProgressBar: true,
      fileName,
      error: null,
    };

    setDownloads((prevDownloads) => [...prevDownloads, download]);

    axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
      excludeFromLoading: true,
      onDownloadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader('content-length') ||
            progressEvent.target.getResponseHeader('x-decompressed-content-length');
        if (totalLength) {
          const progress = Math.round((progressEvent.loaded * 100) / totalLength);

          setDownloads((prevDownloads) => prevDownloads.map((download) => (download.id === id ? { ...download, progress } : download)));
        }
      },
    })
      .then((response) => {
        const mediaType = response?.headers['content-type'];
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data], { type: mediaType }));

        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName || 'no-name');
        document.body.appendChild(link);
        link.click();

        setDownloads((prevDownloads) => prevDownloads.filter((download) => download.id !== id));
      })
      .catch((error) => {
        console.error('Error downloading file:', error);

        setDownloads((prevDownloads) =>
          prevDownloads.map((download) => (download.id === id ? { ...download, showProgressBar: true, error } : download))
        );
      });
  };

  const remove = (id) => {
    setDownloads((prevDownloads) => prevDownloads.filter((download) => download.id !== id));
  };

  return { downloads, downloadFile, remove };
};

export default useDownloadProgress;
