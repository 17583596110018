import { useMemo, useState } from 'react';
import { toastConstant } from 'constants/toastmessage';
import { useToast } from 'context/ToastContext';
import { updateCaseContact } from 'services/CaseServices';
import { createContact, updateContactDetails } from 'services/contact';
import { addRelation, deleteCaseRelation, updateRelation } from 'services/relationServices';
import { getCustomFields } from 'services/Settings';
import { totalCountEvent } from 'services/generalServices';

export function useCaseContact({ caseDetails, getDetails, isLeadSpecific }) {
  const { addToast } = useToast();
  const [customFields, setCustomFields] = useState([]);

  const clientDetails = useMemo(() => {
    if (caseDetails?.client_details?.length === 1) {
      return caseDetails?.client_details[0];
    } else {
      return caseDetails?.client_details?.find((data) => data?.is_primary_contact === true);
    }
  }, [caseDetails]);

  const updateContact = async (data, oldContactId, isContactUpdate = true) => {
    // Time entry related code
    updateCaseContact(caseDetails.case_id, oldContactId, data, isContactUpdate, isLeadSpecific)
      .then(() => {
        typeof getDetails === 'function' && getDetails();
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.CASE_UPDATED_CREATED);
      })
      .catch(() => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.CASE_UPDATED_FAILED);
      });
  };

  const changeClient = (_selectedContact, oldContactId) => {
    let selectedContact = { ..._selectedContact };
    selectedContact.is_billing_contact = false;
    selectedContact.is_primary_contact = true;
    return updateContact({ client: [selectedContact] }, oldContactId, (isLeadSpecific = false), false);
  };

  const handleUpdateContact = (data) => {
    let _data = JSON.parse(JSON.stringify(data));
    delete _data['is_billing_contact'];
    delete _data['is_primary_contact'];
    updateContactDetails(_data, isLeadSpecific)
      .then(() => {
        if (
          data?.is_billing_contact !== clientDetails?.is_billing_contact ||
          data?.is_primary_contact !== clientDetails?.is_primary_contact
        ) {
          updateContact(data, clientDetails?.contact_id);
        } else {
          typeof getDetails === 'function' && getDetails();
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.CONTACT_UPDATE_SUCCESS);
        }
      })
      .catch(() => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.CONTACT_UPDATE_FAILED);
      });
  };

  const updateContactHandler = (_data, isLead) => {
    updateContactDetails(_data, isLead)
      .then(() => {
        typeof getDetails === 'function' && getDetails();
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.CONTACT_UPDATE_SUCCESS);
      })
      .catch(() => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.CONTACT_UPDATE_FAILED);
      });
  };

  const updateRelations = (sendData) => {
    updateRelation(sendData, isLeadSpecific)
      .then(() => {
        typeof getDetails === 'function' && getDetails();
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.UPDATE_RELATION_SUCCESS);
      })
      .catch(() => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.UPDATE_RELATION_FAILURE);
      });
  };

  const deleteRelationMember = async (contact_id) => {
    return deleteCaseRelation(caseDetails?.case_id, contact_id, '', isLeadSpecific, false)
      .then(() => {
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.DELETE_RELATION_SUCCESS);
        typeof getDetails === 'function' && getDetails();
      })
      .catch(() => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.DELETE_RELATION_FAILURE);
      });
  };

  const addContactRelation = async (isNew, _data) => {
    let data = {
      ..._data,
      case_id: caseDetails?.case_id,
    };
    if (isNew) {
      try {
        const res = await createContact(data, isLeadSpecific);
        data = { ...data, ...res?.data };
      } catch (error) {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.RELATION_CASE_ADD_FAILED);
      }
    }
    return addRelation(clientDetails?.contact_id, { relations: [data] }, isLeadSpecific)
      .then(() => {
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.RELATION_CASE_ADD_SUCCESS);
        typeof getDetails === 'function' && getDetails();
      })
      .catch(() =>
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.RELATION_CASE_ADD_FAILED)
      );
  };

  const loadCustomFields = () => {
    totalCountEvent('custom-field')
      .then((c) => {
        getCustomFields('contact', c.data).then((r) => setCustomFields(r?.data?.['custom-field']));
      })
      .catch((err) => console.log(err));
  };

  return {
    customFields,
    loadCustomFields,
    updateContact,
    changeClient,
    handleUpdateContact,
    updateRelations,
    deleteRelationMember,
    addContactRelation,
    updateContactHandler,
  };
}
