/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'primereact/button';

import Summary from './component/Summary'; // component import start here
import CaseDetailsCustomFields from './component/CaseDetailsCustomFields';
import InformationDetails from './component/InformationDetails';
import ConflictCheck from './component/ConflictCheck';
import ImportantDates from './component/ImportantDates';
import GlobalLoader from 'components/GlobalLoader/GlobalLoader';

import { useCaseDetailsCustomField } from '../hooks/useCaseDetailsCustomField'; // hooks import start here
import { useCaseStageList } from 'hooks/useCaseStageList';
import { useCourtLocationList } from 'hooks/useCourtLocation';

import { UserDetailsContext } from 'context/userDetailsContext'; //context import start here
import { useToast } from 'context/ToastContext';

import AppConstants from 'constants/index'; // constants import start here
import { toastConstant } from 'constants/toastmessage';

import { totalCountEvent } from 'services/generalServices'; // service import start here
import { updateLeadDetails } from 'services/leadServices';

import { convertToTimezoneFormat } from 'utils/utility_functions/timezone'; // utils import start here
import { makeListUnique } from 'utils/utils';
import CompanionCases from './component/CompanionCases';
import useComponentLifecycle from 'hooks/useComponentLifecycle';
import { useCaseNotes } from '../hooks/useCaseNotes';
import NeedResolution from 'modules/lead/LeadDetails/components/Overview/components/NotesResolution/NeedResolution';

export const Information = (props) => {
  const { isLeadSpecific, caseDetails, fetchLeadDetails, updateCaseDetails } = props;

  const userDetails = useContext(UserDetailsContext); //  context and hook declarations
  const { caseStageList, loadStageList, caseStageOptions } = useCaseStageList();
  const { caseCustomFieldList } = useCaseDetailsCustomField();
  const { courtList, loadCourtContacts } = useCourtLocationList();
  const { addToast } = useToast();
  const { caseNoteList, loadCaseNoteList } = useCaseNotes();
  useComponentLifecycle('information-activity', props?.onMountUnMount);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const [caseReopenDate, setCaseReopenDate] = useState(''); //  state declarations
  const [isCaseClosed, setIsCaseClosed] = useState(false);
  const [caseClosedDate, setCaseClosedDate] = useState('');

  const fetchCaseStage = (practiceAreaVal = false) => {
    if (practiceAreaVal) {
      totalCountEvent('case-stage').then((response) => {
        loadStageList(practiceAreaVal, response.data);
      });
    }
  };

  const fetchCaseNotes = () => {
    loadCaseNoteList({ limit: 1000, pagination_token: '', pagination_direction: 'forward', filter: { case_id: caseDetails.case_id } });
  };

  // to handle submit case details

  const handleUpdateCaseDetails = (sendData) => {
    //---------------- setting up case info page  updated data into data fields --------------------

    let data = JSON.parse(JSON.stringify(caseDetails));
    let sendDataDescription = sendData?.case_description,
      sendDataDetails = sendData?.case_details;
    data.case_description = { ...(data?.case_description || {}), ...(sendDataDescription || {}) };
    data.case_details = { ...(data?.case_details || {}), ...(sendDataDetails || {}) };

    //------------------------- Start here ------- handling custom fields---------------------------

    let existingCustomField = sendData?.case_description?.custom_field ?? [];
    let remainingCustomFields = sendData?.unassigned_custom_field ?? [];

    let finalCustomfield = [...remainingCustomFields, ...existingCustomField];
    finalCustomfield = makeListUnique(finalCustomfield, 'custom_field_id');

    let assignedCustomField = [],
      unassignedCustomField = [];

    finalCustomfield.map((item) => {
      if ([0, null, undefined, '', 'undefined'].includes(item.value)) {
        unassignedCustomField = [...unassignedCustomField, item];
      } else {
        assignedCustomField = [...assignedCustomField, item];
      }
      return true;
    });
    setValue('case_description.custom_field', assignedCustomField);
    setValue('unassigned_custom_field', unassignedCustomField);
    data.case_description.custom_field = assignedCustomField;
    //------------------------ End here ------------------handling custom fields----------------------------

    //  data from lead details page

    if (isLeadSpecific) {
      data = {
        ...data,
        lead_details: {
          ...data?.lead_details,
          description: sendData?.description,
          tags: sendData?.tags,
          conflict_check: sendData?.lead_details?.conflict_check,
          conflict_contacts: sendData?.lead_details?.conflict_contacts,
          conflict_note: sendData?.lead_details?.conflict_note,
        },
      };
    }
    //  data from case details page
    else {
      data.case_description.case_stage =
        sendDataDescription?.case_stage && caseStageList?.find((val) => val.stage_name === sendDataDescription?.case_stage); //summary page
      data.case_description.case_name = sendDataDescription?.case_name ?? 'TBD';
      data.important_dates.statute_of_limitations = sendData?.important_dates?.statute_of_limitations
        ? convertToTimezoneFormat(sendData?.important_dates?.statute_of_limitations, userDetails?.userDetails?.timezone)
        : '';
      data.case_description.date_of_loss_event = sendData?.case_description?.date_of_loss_event
        ? convertToTimezoneFormat(sendData?.case_description?.date_of_loss_event, userDetails?.userDetails?.timezone)
        : undefined;
      data.case_details.description = sendData?.description;
      data.case_details.tags = sendData?.tags;
      data.case_details.is_create_client_portal = sendData?.is_create_client_portal; //detail page
      data.case_details.conflict_check = sendData?.lead_details?.conflict_check; // conflict check page
      data.case_details.conflict_note = sendData?.lead_details?.conflict_note;
      data.case_details.conflict_contacts = sendData?.lead_details?.conflict_contacts;
    }

    data.stage_change = caseDetails?.case_description?.case_stage?.sk !== data.case_description?.case_stage?.sk ? 'true' : 'false';
    data.case_practice_area_sk = sendData?.case_practice_area_sk || undefined;
    data.case_description.case_status = isCaseClosed ? 'Closed' : 'Open';
    setValue('case_description.custom_field_toggle', false);

    let { description, tags, is_create_client_portal, conflict_check, conflict_note, unassigned_custom_field, ...rest } = data; // to delete unwanted fields
    data = { ...rest };
    delete data.case_description.custom_field_toggle; // deleting unwanted fields

    if (!isLeadSpecific) {
      updateCaseDetails(data, false).then((response) => {});
    } else {
      updateLeadDetails(data.sk, data)
        .then((response) => {
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.LEAD_UPDATE_SUCCESS);
          fetchLeadDetails();
        })
        .catch((error) => {
          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.LEAD_UPDATE_FAILURE);
        });
    }
  };

  useEffect(() => {
    if (isLeadSpecific) {
      fetchCaseNotes();
    }
  }, []);

  // for inital load of case details page
  useEffect(() => {
    if (caseDetails?.pk && caseDetails?.sk) {
      setCaseClosedDate(caseDetails?.case_closed_date);
      setCaseReopenDate(caseDetails?.case_reopen_date);
      if (caseDetails?.is_open === false && caseDetails?.is_lead === true) {
        setIsCaseClosed(true);
      } else if (caseDetails?.status === 'INACTIVE') {
        setIsCaseClosed(true);
      } else if (caseDetails?.status === 'ACTIVE') {
        setIsCaseClosed(false);
      }
    }
    let caseDetailsField = caseDetails?.case_details;
    let caseDescription = caseDetails?.case_description;

    //custom Fields
    let existingCustomFieldList = caseDetails?.case_description?.custom_field
      ?.filter((data) => data.value && data.custom_field_id)
      ?.map((c) => {
        let item2 = caseCustomFieldList?.find((item) => item?.custom_field_id === c?.custom_field_id);
        if (item2) {
          c.position = item2?.position;
        }
        return c;
      })
      ?.sort((a, b) => a?.position - b?.position);

    let assignedCustomField = [],
      unassignedCustomField = [];

    if (Array.isArray(caseCustomFieldList) && caseCustomFieldList.length) {
      let custom_field_id = existingCustomFieldList?.map((item) => item.custom_field_id) || [];
      let remainingCustomFields = caseCustomFieldList?.filter((item) => !custom_field_id?.includes(item?.custom_field_id));

      let assignedLength = existingCustomFieldList?.length;
      let unassignedLength = remainingCustomFields?.length;

      let condition_1 = !assignedLength && unassignedLength; // no existing custom field
      let condition_2 = assignedLength && unassignedLength; // some + new custom field

      if (condition_1) {
        unassignedCustomField = remainingCustomFields;
      } else if (condition_2) {
        assignedCustomField = existingCustomFieldList;
        unassignedCustomField = remainingCustomFields;
      } else {
        assignedCustomField = existingCustomFieldList;
      }
    }

    if (caseDetails) {
      let existingCaseDetailData = {
        case_description: {
          case_name: caseDescription?.case_name,
          review_requested: caseDescription?.review_requested,
          review_received: caseDescription?.review_received,
          case_matter: caseDescription?.case_matter,
          office_location: caseDescription?.office_location,
          venue: caseDescription?.venue,
          case_practice_area: caseDescription?.case_practice_area,
          // from details page
          case_number: caseDescription?.case_number,
          case_stage: caseDescription?.case_stage?.stage_name,
          date_of_loss_event: caseDescription?.date_of_loss_event ? new Date(caseDescription?.date_of_loss_event) : undefined,
          custom_field: assignedCustomField,
        },
        //from imp dates
        important_dates: {
          ...(caseDetails?.important_dates || {}),
          statute_of_limitations: caseDetails?.important_dates?.statute_of_limitations
            ? new Date(caseDetails?.important_dates?.statute_of_limitations)
            : '',
        },
        unassigned_custom_field: unassignedCustomField,
      };
      if (isLeadSpecific) {
        // existing data from lead details page(summary and conflict page)
        let isLeadSpecificData = {
          description: caseDetails?.lead_details?.description,
          tags: caseDetails?.lead_details?.tags,
          lead_details: {
            conflict_check: caseDetails?.lead_details?.conflict_check,
            conflict_note: caseDetails?.lead_details?.conflict_note,
            conflict_contacts: caseDetails?.lead_details?.conflict_contacts,
          },
          case_description: {
            ...existingCaseDetailData.case_description,
            is_qualified: caseDescription?.is_qualified,
          },
        };
        reset({ ...existingCaseDetailData, ...isLeadSpecificData });
      }
      // existing data from case details page
      else {
        let caseSpecificData = {
          description: caseDetailsField?.description,
          tags: caseDetailsField?.tags,
          is_create_client_portal: caseDetailsField?.is_create_client_portal,
          lead_details: {
            conflict_check: caseDetailsField?.conflict_check,
            conflict_note: caseDetailsField?.conflict_note,
            conflict_contacts: caseDetailsField?.conflict_contacts,
          },
        };
        reset({ ...existingCaseDetailData, ...caseSpecificData });
      }
    }
  }, [caseDetails, caseCustomFieldList]);

  useEffect(() => {
    if (caseDetails) setValue('case_description.court', courtList?.find((val) => val.title === caseDetails?.case_description.court)?.title);
  }, [courtList, caseDetails]); // to set value for court location

  return (
    <>
      <GlobalLoader />
      <div className="text-end">
        {userDetails.getPermission('cases') === AppConstants.permissions.WRITE && (
          <Button
            label="Save"
            className="btn-outline p-button-secondary py-1 px-3 "
            type="button"
            onClick={handleSubmit(handleUpdateCaseDetails)}
            disabled={isCaseClosed || (caseDetails.is_lead && !caseDetails?.is_open)}
          />
        )}
      </div>
      <div className="row lead-summary">
        <div className="col-lg-7">
          <Summary
            control={control}
            userDetails={userDetails}
            caseDetails={caseDetails}
            isCaseClosed={isCaseClosed}
            watch={watch}
            setValue={setValue}
            fetchCaseStage={fetchCaseStage}
            caseStageList={caseStageList}
            courtList={courtList}
            loadCourtContacts={loadCourtContacts}
            isLeadSpecific={isLeadSpecific}
          />
          <CaseDetailsCustomFields
            control={control}
            userDetails={userDetails}
            isCaseClosed={isCaseClosed}
            watch={watch}
            setValue={setValue}
            isLeadSpecific={isLeadSpecific}
            caseCustomFieldList={caseCustomFieldList}
          />
        </div>
        <div className="col-lg-5">
          {isLeadSpecific && <NeedResolution caseNoteList={caseNoteList} fetchCaseNotes={fetchCaseNotes} leadDetails={caseDetails} />}
          <InformationDetails
            control={control}
            caseDetails={caseDetails}
            isCaseClosed={isCaseClosed}
            setValue={setValue}
            isLeadSpecific={isLeadSpecific}
            caseStageList={caseStageOptions}
            errors={errors}
          />
          <CompanionCases caseDetails={caseDetails} isCaseClosed={isCaseClosed} />
          <ConflictCheck {...{ control, userDetails, caseDetails, isCaseClosed, isLeadSpecific, watch, setValue }} />
          <ImportantDates {...{ control, userDetails, caseDetails, isCaseClosed, isLeadSpecific, caseReopenDate, caseClosedDate }} />
        </div>
      </div>
      <div className="footer-btn mb-3">
        {userDetails.getPermission('cases') === AppConstants.permissions.WRITE && (
          <Button
            label="Save"
            className="btn-outline p-button-secondary py-1 px-3 "
            type="button"
            onClick={handleSubmit(handleUpdateCaseDetails)}
            disabled={isCaseClosed || (caseDetails.is_lead && !caseDetails?.is_open)}
          />
        )}
      </div>
    </>
  );
};
