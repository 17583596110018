import React, { useEffect, useRef, useState } from 'react';

import { useForm, Controller } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Chips } from 'primereact/chips';

import CustomHeader from './CustomHeader';
import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';

import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import { useToast } from 'context/ToastContext';

import { saveTags } from 'services/fileManager/fileManagerServices';
import { toastConstant } from 'constants/toastmessage';

function AddTagModal() {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
    watch,
  } = useForm();
  const {
    modals,
    caseDetails,
    isGlobalFileManager,
    handleGetFiles,
    selectedItem,
    pageLimit,
    globalTags,
    getGlobalTags,
    getFileTags,
    fileManagerFilters,
    isLeadFileManager,
  } = useFileManagerContext();
  const { showAddTag, setShowAddTag, tagModalType } = modals;
  const { addToast } = useToast();
  const [showGlobalTags, setShowGlobalTags] = useState(false);

  const tagRef = useRef();

  useEffect(() => {
    getGlobalTags();
    if (tagModalType === 'edit') {
      setValue('tags', selectedItem.tags);
    }
  }, []);

  function submitTagHandler(data) {
    if (data.tags.length === 0 && !tagRef.current.inputRef.current.value) {
      setError('tags');
      return;
    }
    if (tagRef.current.inputRef.current.value) {
      data.tags.push(tagRef.current.inputRef.current.value);
    }
    let arr = [];
    arr = data.tags.map((val) => val.replace(/\s+/g, ''));
    changeTags(arr);
  }

  const changeTags = (tags) => {
    let sendData = {};
    sendData.pk = selectedItem.pk;
    sendData.sk = selectedItem.sk;
    sendData.tags = tags;
    let obj = {
      data: sendData,
      is_lead: isLeadFileManager ? true : false,
    };
    saveTags(obj)
      .then(() => {
        handleGetFiles(pageLimit);
        let case_id = isGlobalFileManager ? fileManagerFilters?.case?.id : caseDetails?.case_id;
        getFileTags(case_id);
        setShowAddTag(false);
        let msg = tagModalType === 'add' ? toastConstant.message.TAGS_ADDED_SUCCESS : toastConstant.message.TAGS_UPDATED_SUCCESS;
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, msg);
      })
      .catch((error) => {
        console.log(error);
        let msg = tagModalType === 'add' ? toastConstant.message.TAGS_ADDED_FAILED : toastConstant.message.TAGS_UPDATED_FAILED;
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, msg);
      });
  };

  return (
    <Dialog
      header={<CustomHeader onHide={() => setShowAddTag(false)} />}
      footer={
        <TagFooter
          setShowAddTag={setShowAddTag}
          handleSubmit={handleSubmit}
          submitTagHandler={submitTagHandler}
          tagModalType={tagModalType}
        />
      }
      visible={showAddTag}
      className="new-task-popup"
      modal
      onHide={() => {
        setShowAddTag(false);
      }}
      closable={false}
    >
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="modal-heading F-size16 text-bold">{tagModalType === 'add' ? 'Add Tag' : 'Update Tag'} </div>
      </div>
      <Controller
        name="tags"
        control={control}
        defaultValue={[]}
        render={({ field }) => (
          <Chips
            itemTemplate={chipTemplate}
            placeholder="Press enter to add a tag"
            id={field.name}
            value={field.value}
            ref={tagRef}
            onChange={(e) => {
              let arr = [];
              if (e?.value?.length) {
                arr = e.value.map((val) => val.replace(/\s+/g, ''));
              }
              field.onChange(arr || e.value);
            }}
            className="input-shadow border-0 w-100 taging update-tag add-tag-chips-input mb-0"
          />
        )}
      />
      {errors?.tags && <InputErrorMessage>Required</InputErrorMessage>}
      <div className="col-12">
        <div className="row mb-2">
          <div className="col-sm-12 addfield">
            <Button
              type="button"
              className="p-button button-text text-medium addfield ps-0"
              onClick={(e) => {
                e.preventDefault();
                setShowGlobalTags(!showGlobalTags);
              }}
            >
              <i className="icon-add F-size14 me-1"></i>Add Global Tags
            </Button>
          </div>
        </div>
        {showGlobalTags ? (
          globalTags?.length > 0 ? (
            <div className="row mb-3">
              <div className="col-sm-4"></div>
              <div className="col-sm-8 ">
                <div className="global-tag-wrapper input-shadow" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  {globalTags.map((element, index) => {
                    let hashReplacedTag = element.value.replace('#', '');
                    return (
                      <span
                        key={index}
                        className={`tag-highlight ${watch('tags')?.includes(hashReplacedTag) ? 'selected-global-tag' : ''}`}
                        onClick={(e) => {
                          let existingTags = watch('tags') ? watch('tags') : [];
                          existingTags.push(hashReplacedTag);
                          setValue('tags', [...new Set(existingTags)]);
                        }}
                      >
                        {element.value}
                      </span>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            <div className="row mb-2">
              <div className="col-12 small">
                <i className="fas fa-info-circle mx-2 mt-1"></i>
                <span className="text-hint">No global tags found.</span>{' '}
              </div>
            </div>
          )
        ) : null}
      </div>
    </Dialog>
  );
}

export default AddTagModal;

const TagFooter = ({ setShowAddTag, handleSubmit, submitTagHandler, tagModalType }) => (
  <div className="footer-btn">
    <Button
      label="Cancel"
      onClick={() => {
        setShowAddTag(false);
      }}
      className="p-button-secondary outline"
    />
    <Button label={tagModalType === 'add' ? 'Save' : 'Update'} onClick={handleSubmit(submitTagHandler)} className="p-button-secondary" />
  </div>
);

const chipTemplate = (e) => {
  if (e) {
    try {
      let formattedTagData = e?.replaceAll('#', '');
      return <div>{'#' + formattedTagData}</div>;
    } catch (error) {
      return <div>{e}</div>;
    }
  }
};
