import { actionTypes } from "constants/types";
let initialState = {
  isLoading: "",
  ContactList: "",
  ContactError: "",
};

export default function ContactReducer(state = initialState, action={}) {
  switch (action.type) {
    case actionTypes.GET_CONTACT_REQUEST:
      return {
        ...state,
        ContactList: "",
        ContactError: "",
      };
    case actionTypes.GET_CONTACT_SUCCESS:
      return {
        ...state,
        ContactList: action.payload,
        ContactError: "",
      };
    case actionTypes.GET_CONTACT_ERROR:
      return {
        ...state,
        ContactList: "",
        ContactError: action.payload,
      };
    
      case actionTypes.CLEAR_CONTACT_DATA:
        return {
          ...state,
          ContactList: "",
        };

    default:
      return state;
  }
}
