import { handlePageNumberInPagination } from 'common/pagination';
import { UserDetailsContext } from 'context/userDetailsContext';
import moment from 'moment';
import { useContext, useState, useEffect } from 'react';
import { getCaseList } from 'services/case/caseServices';
import { convertToTimezoneFormat } from 'utils/utility_functions/timezone';

export function useCaseList(_filter, _pageSize = 10) {
  const userContext = useContext(UserDetailsContext);

  const [data, setData] = useState([]);
  const [isForward, setIsForward] = useState(true);
  const [pageSize, setpageSize] = useState(userContext?.firmDetails?.pagination_preference?.case?.self ?? _pageSize);
  const next_Token = data?.next_token;
  const transition_Token = data?.transition_token;
  const caseList = data?.['case'] || [];
  useEffect(() => {
    setpageSize(userContext?.firmDetails?.pagination_preference?.case?.self);
  }, [userContext]);

  const hasNext = next_Token === null && isForward;
  const hasPrevious = transition_Token === null || (transition_Token && next_Token === null && !isForward);

  const pickExtractedDate = (dateArray) => {
    if (!dateArray) {
      return undefined;
    }
    return {
      from: dateArray?.[0] ? convertToTimezoneFormat(moment(dateArray[0]).startOf('day'), userContext?.userDetails.timezone) : '',
      to: dateArray?.[0]
        ? convertToTimezoneFormat(moment(dateArray[1] ? dateArray[1] : dateArray[0]).endOf('day'), userContext?.userDetails.timezone)
        : '',
    };
  };

  const fetchNext = () => {
    setIsForward(true);
    loadCaseList({
      limit: pageSize,
      pagination_token: !isForward ? transition_Token : next_Token,
      pagination_direction: 'forward',
    });
  };

  const fetchprev = () => {
    setIsForward(false);
    loadCaseList({
      limit: pageSize,
      pagination_token: isForward ? transition_Token : next_Token,
      pagination_direction: 'reverse',
    });
  };

  const onPageChange = (e) => {
    setpageSize(e.value);
    handlePageNumberInPagination(userContext, 'case', '', 'self', e.value);
    loadCaseList({
      limit: e.value,
      pagination_token: '',
      pagination_direction: 'forward',
    });
    setIsForward(true);
  };

  const loadCaseList = ({ limit = pageSize, pagination_token = '', pagination_direction = 'forward', filter = _filter }) => {
    getCaseList({
      data: {
        ...filter,
        statute_of_limitations: undefined,
        date_created: undefined,
        date_closed: undefined,
        last_updated: undefined,
        case_dates_filter: {
          statute_of_limitations: filter?.statute_of_limitations
            ? convertToTimezoneFormat(moment(filter?.statute_of_limitations), userContext?.userDetails.timezone)
            : '',
          date_created: pickExtractedDate(filter?.date_created),
          date_closed: pickExtractedDate(filter?.date_closed),
          last_updated: pickExtractedDate(filter?.last_updated),
        },
      },
      limit,
      pagination_token,
      pagination_direction,
      searchValue: filter?.keyword,
      caseMatter: filter?.case_matter,
    })
      .then((response) => setData(response?.data))
      .catch((error) => console.log(error));
  };

  return { caseList, hasNext, hasPrevious, pageSize, fetchNext, fetchprev, onPageChange, loadCaseList };
}
