import React from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';

import { useToast } from 'context/ToastContext';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import useUpdateFiles from 'modules/file_manager/hooks/useUpdateFiles';

import { toastConstant } from 'constants/toastmessage';

function DeleteConfirmationModal() {
  const { modals, isLeadFileManager, handleGetFiles, selectedItem, pageLimit } = useFileManagerContext();
  const { showDeleteModal, setShowDeleteModal } = modals;

  const { updateFiles } = useUpdateFiles();

  const { addToast } = useToast();

  const deleteFile = () => {
    let updated_file = {
      deleted: true,
      pk: selectedItem['pk'],
      sk: selectedItem['sk'],
      type: selectedItem['entry_type'],
    };

    let obj = {
      data: updated_file,
      is_lead: isLeadFileManager,
    };

    updateFiles(obj)
      .then(() => {
        handleGetFiles(pageLimit);

        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.FILE_DELETE_SUCCESS);
      })
      .catch((error) => {
        console.log(error);
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.ERROR, toastConstant.message.FILE_DELETE_FAILED);
      });
  };

  const CustomMessage = () => {
    return (
      <div>
        <div className="P-medium text-bold">
          {selectedItem.entry_type === 'folder' ? 'Folder Name' : 'File Name'} : {selectedItem.display_name}
        </div>
        <h6 className="mt-2">Are you sure you want to delete this {selectedItem.entry_type === 'folder' ? 'folder' : 'file'}?</h6>
      </div>
    );
  };

  return (
    <ConfirmDialog
      visible={showDeleteModal}
      onHide={() => setShowDeleteModal(false)}
      message={<CustomMessage />}
      header="Confirm Delete"
      rejectClassName="p-button-secondary outline"
      accept={deleteFile}
      closable={false}
      className="common-popup-style confirm-delete-popup"
      icon="pi pi-exclamation-triangle"
      reject={() => setShowDeleteModal(false)}
    />
  );
}

export default DeleteConfirmationModal;
