import { actionTypes } from "constants/types";
let initialState = {
  isCaseCourtLoading: false,
  caseCourtList: "",
  caseCourtError: "",
};

export default function CaseCourtReducer(state = initialState, action={}) {
  switch (action.type) {
    case actionTypes.GET_CASE_COURT_REQUEST:
      return {
        ...state,
        isCaseCourtLoading: true,
        caseCourtList: "",
        caseCourtError: "",
      };
    case actionTypes.GET_CASE_COURT_SUCCESS:
      return {
        ...state,
        caseCourtList: action.payload,
        caseCourtError: "",
        isCaseCourtLoading: false,
      };
    case actionTypes.GET_CASE_COURT_ERROR:
      return {
        ...state,
        caseCourtList: "",
        caseCourtError: action.payload,
        isCaseCourtLoading: false,
      };

    default:
      return state;
  }
}
