import React, { useRef, useLayoutEffect } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { VariableSizeList, areEqual } from 'react-window';
import TaskCard from './TaskCard';
import useWindowSize from 'hooks/useWindowSize';

export const ItemList = React.memo(function ItemList({ column, index, isClosed, boardData, cardAction }) {
  // There is an issue I have noticed with react-window that when reordered
  // react-window sets the scroll back to 0 but does not update the UI
  // I should raise an issue for this. As a work around I am resetting the scroll to 0 on any list that changes it's index
  const listRef = useRef();
  const rowHeights = useRef({});
  const toggledSubTask = useRef([]);
  const { height } = useWindowSize();

  useLayoutEffect(() => {
    const list = listRef.current;
    if (list) {
      list.scrollTo(0);
    }
  }, [index]);

  function setRowHeight(index, size) {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  function getRowHeight(index) {
    return rowHeights.current[index] + 16 || 250;
  }

  // Recommended react-window performance optimisation: memoize the row render function
  const Row = React.memo(function Row(props) {
    const { data: items, index, style } = props;
    const item = items[index];

    // We are rendering an extra item for the placeholder
    if (!item) {
      return null;
    }

    return (
      <Draggable draggableId={item?.task_id} index={index} key={item?.task_id} isDragDisabled={isClosed}>
        {(provided) => (
          <TaskCard
            isClosed={isClosed}
            provided={provided}
            item={item}
            style={style}
            index={index}
            setRowHeight={setRowHeight}
            toggledSubTask={toggledSubTask}
            boardData={boardData}
            cardAction={cardAction}
          />
        )}
      </Draggable>
    );
  }, areEqual);

  return (
    <Droppable
      droppableId={column.id}
      mode="virtual"
      renderClone={(provided, snapshot, rubric) => (
        <TaskCard
          provided={provided}
          isDragging={snapshot.isDragging}
          item={column.items[rubric.source.index]}
          index={index}
          setRowHeight={setRowHeight}
          toggledSubTask={toggledSubTask}
          isClosed={isClosed}
          cardAction={cardAction}
        />
      )}
    >
      {(provided, snapshot) => {
        // Add an extra item to our list to make space for a dragging item
        // Usually the DroppableProvided.placeholder does this, but that won't work in a virtual list
        const itemCount = snapshot.isUsingPlaceholder ? column.items.length + 1 : column.items.length;

        return (
          <VariableSizeList
            height={height - 255}
            itemCount={itemCount}
            itemSize={getRowHeight}
            width={375}
            outerRef={provided.innerRef}
            itemData={column.items}
            className="task-list trello-height"
            ref={listRef}
          >
            {Row}
          </VariableSizeList>
        );
      }}
    </Droppable>
  );
});
