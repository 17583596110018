import React from 'react';

const CheckboxTemplate = ({ handleSingleCheckboxClick, selectedRows, rowData, caseDetails }) => {
  return (
    <input
      type="checkbox"
      className="d-flex mt-1"
      onChange={(e) => handleSingleCheckboxClick(e, rowData)}
      checked={selectedRows?.includes(rowData)}
      disabled={caseDetails?.case_closed || (caseDetails?.is_lead && !caseDetails?.is_open)}
    />
  );
};

export default CheckboxTemplate;
