import React from 'react';
import { useHistory } from 'react-router-dom';

import { handleRedirection } from 'utils/utils';

export default function LeadItem({ data }) {
  const history = useHistory();
  // const userContext = useUserDetailsContext();
  // const leadAttorneyFirmUserId = data?.attorney_details?.length ? data?.attorney_details[0]?.firm_user_id : null;
  // const isUpcomingEventDataAvailable = data?.upcoming_event && Object.keys(data?.upcoming_event)?.length;

  const redirectToPage = (e, route) => {
    e.preventDefault();
    handleRedirection(history, route);
  };

  return (
    <div className="border shadow-small p-3 my-2 msg-dtls">
      <div className="my-2 text-break">
        <span className="text-medium">Practice area:</span> {data?.case_description?.case_practice_area || 'N/A'}
      </div>
      <div
        className="my-2 text-break"
        onClick={(e) => {
          const route = data?.case_id ? `/leads/${data?.case_id}` : '/leads';
          redirectToPage(e, route);
        }}
      >
        <span className="text-medium">Lead:</span>{' '}
        <span className="redirection-link-no-text-transform">{data?.case_description?.case_name ?? 'N/A'}</span>
      </div>
      <div className="my-2 text-break">
        <span className="fw-bold">Lead Status:</span> {data?.status ?? 'N/A'}
      </div>
      {/* <div className="my-2 text-break">
        <span className="fw-bold">Lead Stage:</span> {data?.lead_stage_name || 'N/A'}
      </div>
      <div className="my-2 text-break">
        <span className="text-medium">Upcoming Event:</span>{' '}
        <span
          className={classNames({ 'redirection-link': isUpcomingEventDataAvailable })}
          onClick={(e) => {
            if (isUpcomingEventDataAvailable) {
              const route = '/calendar';
              const stateToPass = { viewEvent: data?.upcoming_event?.event_data };
              handleRedirection(history, route, stateToPass);
            }
          }}
        >
          {data?.upcoming_event?.name
            ? data?.upcoming_event?.name
            : data?.upcoming_event?.date
            ? ` (${handleDateTimeOffset(
                userContext.userDetails.timezone,
                data?.upcoming_event?.date,
                constants.month_date_year_time_12_format
              )})`
            : 'N/A'}
        </span>
      </div>
      <div className="my-2 text-break">
        <span className="fw-bold">Lead Assigned To:</span> {getTeamMemberName(data?.attorney_details)}
      </div> */}
    </div>
  );
}
