import { performRequest } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';
import { getTenantDetails } from 'utils/utils';

export const getClientUserList = (obj) => {
  const [tenantId, tenantSk] = getTenantDetails();

  let {
    caseId,
    is_contact_list = false,
    is_team_member_list = false,
    all_contacts = false,
    contact_id = false,
    is_lead = false,
    is_mandatory_lawft_access = true,
    is_active = false,
    is_include_groups = false,
    active_groups = false,
  } = obj;
  const queryParams = [`tenant_id=${tenantId}`, `tenant_sk=${tenantSk}`, `is_mandatory_lawft_access=${is_mandatory_lawft_access}`];

  if (caseId) queryParams.push(`case_id=${caseId}`);
  if (is_contact_list) queryParams.push(`is_contact_list=${is_contact_list}`);
  if (is_team_member_list) queryParams.push(`is_team_member_list=${is_team_member_list}`);
  if (all_contacts) queryParams.push(`all_contacts=${all_contacts}`);
  if (contact_id) queryParams.push(`contact_id=${contact_id}`);
  if (is_lead) queryParams.push(`is_lead=${is_lead}`);
  if (is_active) queryParams.push(`is_active=${is_active}`);
  if (is_include_groups) queryParams.push(`is_include_groups=${is_include_groups}`);
  if (active_groups) queryParams.push(`active_groups=${active_groups}`);

  const url = `${apiEndPoints.calendar.userList}?${queryParams.join('&')}`;
  return performRequest(apiEndPoints.methodType.GET, url);
};

export const listUsers1 = (caseId, is_person, all_contacts = false, contact_id = false, is_team_member_list = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.calendar.userList}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${caseId ? `&case_id=${caseId}` : ''}${
    all_contacts ? `&all_contacts=${all_contacts}` : ''
  }${is_person ? `&is_person=${is_person}` : ''}${is_team_member_list ? `&is_team_member_list=${is_team_member_list}` : ''}`;
  if (contact_id) url = url + '&contact_id=' + contact_id;

  return performRequest(apiEndPoints.methodType.GET, url);
};

export const listUsers = (obj) => {
  const [tenantId, tenantSk] = getTenantDetails();

  let {
    status,
    limit = 10,
    pagination_token = '',
    pagination_direction = 'forward',
    keyWord = '',
    next = '',
    is_include_contacts = false,
    is_detailed = false,
    is_confirmed_user = false,
    is_include_groups = false,
    active_groups = false,
  } = obj;
  let url = `${apiEndPoints.user.list}?tenant_id=${tenantId}&status=${status}&keyword=${keyWord}&next=${next}&limit=${limit}&tenant_sk=${tenantSk}&is_include_contacts=${is_include_contacts}&is_detailed=${is_detailed}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}&is_confirmed_user=${is_confirmed_user}&is_include_groups=${is_include_groups}&active_groups=${active_groups}`;
  return performRequest(apiEndPoints.methodType.GET, url);
};
