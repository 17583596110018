/* This code is a React component called `ManageColumn`. It is responsible for rendering a dialog box
that allows the user to manage the columns of a task board. */
import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

import { formatStageName } from 'utils/utils';
import { manageColumnData } from 'services/TaskServices';

import PriorityDropdown from 'components/UI/PriorityDropdown/PriorityDropdown';
import { ModalHeader } from 'shared/ModalHeader';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import Table from './Table';

const ManageColumn = (props) => {
  const { selectedStage, loadTaskData, onHide, stages = [], tasks = [] } = props;

  const [dropDownSelection, setDropDownSelection] = useState({ taskStage: '', priority: '' });
  const [selectedTasks, setSelectedTasks] = useState([]);
  const { addToast } = useToast();

  let taskStageOptions = stages?.filter((v) => v !== selectedStage)?.map((item) => ({ label: formatStageName(item), value: item }));

  const onModalHide = () => {
    onHide();
    setDropDownSelection({ taskStage: '', priority: '' });
    setSelectedTasks([]);
  };

  const handleOnSubmit = () => {
    let dataToSent = {
      tasks: selectedTasks.map((item) => {
        return {
          task_stage: dropDownSelection?.taskStage ? dropDownSelection?.taskStage : item?.task_stage,
          priority: dropDownSelection?.priority ? dropDownSelection?.priority : item?.priority,
          sk: item?.sk,
          pk: item?.pk,
        };
      }),
    };
    if (dropDownSelection?.taskStage || dropDownSelection?.priority) {
      manageColumnData(dataToSent).then(() => {
        setSelectedTasks([]);
        setDropDownSelection({ taskStage: '', priority: '' });
        onHide();
        loadTaskData({});
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.TASK_UPDATED_SUCCESSFULLY);
      });
    }
  };

  const onChangeHadler = (e) => setDropDownSelection((v) => ({ ...v, [e.target.name]: e.target.value }));

  return (
    <Dialog
      className="new-task-popup mange-colum-modal datatable-responsive"
      header={ModalHeader}
      footer={<></>}
      visible={true}
      onHide={onModalHide}
    >
      <div className="d-flex justify-content-between mb-4">
        <div className="sub-title">Manage Column - {formatStageName(selectedStage)}</div>
      </div>
      <div className="d-flex mb-4">
        <div className="rounded me-3">
          <PriorityDropdown placeholder="Priority" name="priority" onChange={onChangeHadler} value={dropDownSelection?.priority} />
        </div>
        <div className="rounded">
          <Dropdown
            placeholder="Move To"
            name="taskStage"
            options={taskStageOptions}
            onChange={onChangeHadler}
            value={dropDownSelection?.taskStage}
          />
        </div>
      </div>
      <Table tasks={tasks} {...dropDownSelection} {...{ selectedTasks, setSelectedTasks }} />
      <div className="footer-btn">
        <Button onClick={onModalHide} className="bg-btn p-button-secondary outline me-1" label="Cancel" />
        <Button className="p-button-secondary" label="Save" onClick={handleOnSubmit} />
      </div>
    </Dialog>
  );
};

export default React.memo(ManageColumn);
