import { actionTypes } from "constants/types";
let initialState = {
  isCaseListLoading: false,
  caseList: "",
  caseListError: "",
};

export default function CaseListReducer(state = initialState, action={}) {
  switch (action.type) {
    case actionTypes.GET_CASE_LIST_REQUEST:
      return {
        ...state,
        isCaseListLoading: true,
        caseList: "",
        caseListError: "",
      };
    case actionTypes.GET_CASE_LIST_SUCCESS:
      return {
        ...state,
        isCaseListLoading: true,
        caseList: action.payload,
        caseListError: "",
      };
    case actionTypes.GET_CASE_LIST_ERROR:
      return {
        ...state,
        isCaseListLoading: true,
        caseList: "",
        caseListError: action.payload,
      };

    default:
      return state;
  }
}
