import { actionTypes } from "constants/types";
let initialState = {
    showAddTaskPopUp: false,
};

export default function ShowAddTaskPopUpReducer(state = initialState, action={}) {
    switch (action.type) {
      case actionTypes.SHOW_ADD_TASK:
        return {
          ...state,
  
          showAddTaskPopUp: true,
        };
      case actionTypes.HIDE_ADD_TASK:
        return {
          ...state,
  
          showAddTaskPopUp: false,
        };
  
      default:
        return state;
    }
  }