import React from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { tableCell } from '../helpers/dataTableCommonHelpers';

const SubscriptionBillingDetails = (props) => {
  const { caseDetails, formErrors, subscriptionItems } = props || {};

  return (
    <div className="mt-4">
      <div className="caption-bold mb-3">Subscription Details</div>
      <div className="col-12 datatable-responsive shadow-small p-2">
        <DataTable className="p-datatable-responsive" value={[subscriptionItems]}>
          <Column header="Type" field="frequency_of_payment" body={tableCell} />
          <Column header="Date" field="invoice_date" body={tableCell} />
          <Column header="Notes" field="description" body={tableCell} />
          <Column header="Due Date" field="due_date" body={tableCell} />
          <Column header="Actual Amount" field="rate" currency={true} body={tableCell} />
          {caseDetails?.billing_preferences?.amount_with_tax && (
            <Column header="Amount (with Tax)" field="amount_with_tax" currency={true} body={tableCell} />
          )}
        </DataTable>
      </div>
      {formErrors && formErrors.subscription && (
        <div className="col-12 d-flex justify-content-center mt-3">
          <span className="text-danger">{formErrors.subscription}</span>{' '}
        </div>
      )}
    </div>
  );
};

export default SubscriptionBillingDetails;
