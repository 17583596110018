import React from 'react';
import { Controller } from 'react-hook-form';

import { InputSwitch } from 'primereact/inputswitch';
import { RadioButton } from 'primereact/radiobutton';

import ImageComponent from 'shared/ImageComponent';
import { handleRedirection } from 'utils/utils';

function SelectedFirmUsers(props) {
  const { selectedAttendees, control, history } = props;
  return (
    <div className="d-flex align-items-center py-2">
      <div className="col-12">
        <div className="">
          <table className="table attendees-table">
            <thead>
              <tr>
                <th className="attendee-names">Firm Users</th>
                <th className="text-center">
                  <label className="text-muted">Required</label>
                </th>
                <th className="text-center">
                  <label className="text-muted">Optional</label>
                </th>
                <th className="text-center">
                  <label className="text-muted">Send Reminder</label>
                </th>
              </tr>
            </thead>
            {selectedAttendees?.map((item, id) => {
              return (
                <tbody key={id}>
                  <td className="attendee-names">
                    <div
                      className="d-flex redirection-link"
                      onClick={() => {
                        let url = '/settings/team-management';
                        if (item?.firm_user_id) url = `/settings/team-management/${item?.firm_user_id}`;
                        handleRedirection(history, url);
                      }}
                    >
                      <ImageComponent filePath={item?.profile_image} fileName={item.name} fileSize="small.jpg" />
                      <div className="mx-2 align-items-center d-flex">{item.name}</div>
                    </div>
                  </td>

                  <Controller
                    name={`firm_users.${id}.is_attendance_required`}
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <>
                        <td className="text-center">
                          <div>
                            <RadioButton
                              inputId="required"
                              name={field.name}
                              value="required"
                              checked={field.value === 'required'}
                              onChange={(e) => field.onChange(e.value)}
                            />
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="p-field-radiobutton">
                            <RadioButton
                              inputId="optional"
                              name="gender"
                              value="optional"
                              checked={field.value === 'optional'}
                              onChange={(e) => field.onChange(e.value)}
                            />
                          </div>
                        </td>
                      </>
                    )}
                  />

                  <Controller
                    name={`firm_users.${id}.send_reminder`}
                    control={control}
                    render={({ field }) => (
                      <td className="text-center">
                        <InputSwitch
                          id={field.name}
                          value={field.value}
                          checked={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          placeholder="Meeting URL"
                        />
                      </td>
                    )}
                  />
                </tbody>
              );
            })}
          </table>
        </div>
      </div>
    </div>
  );
}

export default SelectedFirmUsers;
