import React from 'react';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import { Tooltip } from 'primereact/tooltip';

function ResetFilters() {
  const { onResetFilter } = useFileManagerContext();

  return (
    <div className="p-2 filter-wraper">
      <div className="pointer ms-0 text-center">
        <i
          className="fas fa-sync-alt"
          onClick={() => {
            onResetFilter();
          }}
        ></i>
        <Tooltip content="Clear Filter" position="top" target=".fa-sync-alt" showDelay={500} />
      </div>
    </div>
  );
}

export default ResetFilters;
