import { performRequestSecond } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';
import { getTenantDetails } from 'utils/utils';

export const getUserProfileDetails = () => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.getUserProfile}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestSecond(apiEndPoints.methodType.GET, url);
};

export const updateUserProfileDetails = (data, excludeFromLoading = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.updateUserProfile}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestSecond(apiEndPoints.methodType.PATCH, url, data, '', true, excludeFromLoading);
};
