import React from 'react';
import { Controller } from 'react-hook-form';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';

import { ModalHeader } from 'shared/ModalHeader';
import { useCloseLead } from 'modules/lead/hooks/useCloseLead';
import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

export default function CloseLeadDialog({ onHide, leadDetails }) {
  const {
    handleSubmit,
    control,
    register,
    reasonList,
    showCustomReason,
    loading,
    saveLoading,
    addCloseLeadReason,
    errors,
    setShowCustomReason,
    showReferredTo,
    setShowReferredTo,
    showCustomReferral,
    setShowCustomReferral,
    referralsList,
  } = useCloseLead(leadDetails, onHide);

  const RequiredValidation = ({ field, message = 'Field Required' }) => {
    return errors[field] ? <InputErrorMessage>{errors[field]?.message || message}</InputErrorMessage> : null;
  };

  return (
    <Dialog visible onHide={onHide} className="new-task-popup" header={ModalHeader} footer={<></>}>
      <div className="d-flex justify-content-between align-items-center mb-3 w-100">
        <div className="modal-heading F-size16 text-bold">Close Lead Reason</div>
      </div>
      <div className="d-flex align-items-center flex-wrap py-2">
        <div className="col-lg-4 col-12 me-sm-3">
          Reason
          <span className="alert-star">*</span>
        </div>
        <div className="col-sm-7 col-11 d-flex flex-column">
          {!showCustomReason ? (
            <Controller
              name="old_reason"
              control={control}
              defaultValue={''}
              rules={{ required: true }}
              render={({ field }) => (
                <Dropdown
                  id={field.name}
                  onChange={(e) => {
                    if (e.value === 'Referred Out' || (field.value === 'Referred Out' && e.value !== 'Referred Out')) {
                      setShowReferredTo();
                    }
                    field.onChange(e.value);
                  }}
                  className="input-shadow w-100"
                  value={field.value}
                  options={reasonList}
                  optionLabel="closure_reason"
                  optionValue="closure_reason"
                  placeholder="Select a reason"
                  dropdownIcon={loading ? 'pi pi-spin pi-spinner' : undefined}
                />
              )}
            />
          ) : (
            <TextSnippetWrapper type="textarea" className="input-shadow w-100" {...register('new_reason', { required: true })} />
          )}
          <RequiredValidation field="old_reason" />
          <RequiredValidation field="new_reason" />
        </div>
        {!showCustomReason && (
          <span className="pointer" onClick={setShowCustomReason}>
            <i className="fas fa-plus-circle ms-2" />
          </span>
        )}
      </div>
      {showReferredTo && (
        <div className="d-flex align-items-center flex-wrap py-2">
          <div className="col-lg-4 col-12 me-sm-3">
            Referred To
            <span className="alert-star">*</span>
          </div>
          <div className="col-sm-7 col-11 d-flex flex-column">
            {!showCustomReferral ? (
              <Controller
                name="old_referred_to"
                control={control}
                defaultValue={''}
                rules={{ required: true }}
                render={({ field }) => (
                  <Dropdown
                    id={field.name}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    className="input-shadow w-100"
                    value={field.value}
                    options={referralsList.filter((item) => item.referral_entity !== '')}
                    optionLabel="referral_entity"
                    optionValue="referral_entity"
                    placeholder="Select Referred To"
                    dropdownIcon={loading ? 'pi pi-spin pi-spinner' : undefined}
                  />
                )}
              />
            ) : (
              <TextSnippetWrapper type="textarea" className="input-shadow w-100" {...register('referred_to', { required: true })} />
            )}
            <RequiredValidation field="old_referred_to" />
            <RequiredValidation field="referred_to" />
          </div>
          {!showCustomReferral && (
            <span className="pointer" onClick={setShowCustomReferral}>
              <i className="fas fa-plus-circle ms-2" />
            </span>
          )}
        </div>
      )}
      <div className="footer-btn">
        <Button className="p-button-secondary outline me-2" label="Cancel" onClick={onHide} disabled={saveLoading} />
        <Button className="p-button-secondary" label="Save" onClick={handleSubmit(addCloseLeadReason)} loading={saveLoading} />
      </div>
    </Dialog>
  );
}
