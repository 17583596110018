import { useEffect, useState } from 'react';
import { totalCountEvent } from 'services/generalServices';
import { listActivityTypes } from 'services/timerServices';

const useActivityTypes = () => {
  const [activityTypes, setActivityTypes] = useState();
  //   console.log('activityTypes', activityTypes);

  const loadActivityType = () => {
    totalCountEvent('activity_type').then((response) => {
      //   console.log('response_totalCount', response);
      listActivityTypes('', response?.data)
        .then((response) => {
          //   console.log('response_activity-types', response);

          if (response && response.data && response.data?.activity_types.length > 0) {
            response.data.activity_types.forEach((type, idx) => {
              type.activity_label = type.activity_type + ' - ' + type.activity_type_code;
            });

            setActivityTypes([...response.data.activity_types]);
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    });
  };

  useEffect(() => {
    loadActivityType();
  }, []);

  return { activityTypes };
};

export default useActivityTypes;
