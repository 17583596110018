import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';

import ImageComponent from 'shared/ImageComponent';
import { generateFullName } from 'utils/generateFullNameUtils';
import { getTenantSkFromLS, handleSettingSkAndPkToLocalStorage } from 'utils/localStorageUtils';
import { handleRedirection } from 'utils/utils';

const tenantId = getTenantSkFromLS();

export default function Header(props) {
  const { leadDetails, setConvertCaseToggle, setCloseCaseToggle, setReopenLeadToggle, sk, pk } = props;
  const mainClientContacId = leadDetails?.main_client?.length ? leadDetails?.main_client[0]?.contact_id : null;
  const history = useHistory();

  const onClickMainClient = (e) => {
    e.preventDefault();
    let route = mainClientContacId ? `/contacts/${mainClientContacId}` : '/contacts';
    handleRedirection(history, route);
    handleSettingSkAndPkToLocalStorage(sk, pk);
  };

  return (
    <div className="d-flex justify-content-between align-items-center mb-3 flex-wrap">
      <div className="d-flex justify-content-between flex-wrap">
        <div className="d-flex align-items-center">
          <div className="rounded d-flex align-items-center border p-2 pointer me-3" onClick={() => history.replace('/leads')}>
            <i className="pi pi-angle-left" />
          </div>
          <span
            onClick={(e) => {
              onClickMainClient(e);
            }}
            className="d-flex align-items-center cursor-pointer"
          >
            <ImageComponent
              filePath={`${tenantId}/${leadDetails?.main_client[0]?.contact_id}/profile_image`}
              fileName={leadDetails?.main_client[0]?.first_name?.charAt(0) ?? 'C'}
              fileSize="medium.jpg"
            />
            <div className="mx-1">
              <a href className="redirection-link">
                <h1 className="redirection-link caption-bold F-size14 mb-0">{generateFullName(leadDetails?.main_client[0])}</h1>
              </a>
            </div>
          </span>
        </div>
      </div>
      <div className="d-flex flex-wrap mt-sm-0 mt-3">
        <Button
          onClick={() => {
            if (!leadDetails?.is_open) {
              setReopenLeadToggle();
            } else {
              setCloseCaseToggle();
            }
          }}
          className="btn-secondary p-button-primary outline me-2 mb-2 mb-lg-0"
        >
          {!leadDetails?.is_open ? 'Reopen Lead' : 'Close Lead'}
        </Button>
        <Button
          label="Convert to Case"
          className="p-button p-button-primary me-2 mb-2 mb-lg-0"
          disabled={!leadDetails?.is_open}
          onClick={setConvertCaseToggle}
        />
      </div>
    </div>
  );
}
