import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { UserDetailsContext } from 'context/userDetailsContext';
import { useCalendarContext } from 'modules/calendar/Context/CalendarContext';
import { useToast } from 'context/ToastContext';

import { deleteEvent, getEvent } from 'services/Calender/calendarServices';

import constants from 'constants/index';
import { toastConstant } from 'constants/toastmessage';

import BirthdayAndSOLPreview from './BirthdayAndSOLPreview';
import EventAndTaskPreview from './EventAndTaskPreview';
import GoogleAndOutlookPreview from './GoogleAndOutlookPreview';
import { ModalHeader } from 'shared/ModalHeader';

function EventPreviewModal({ showEventPreviewModal }) {
  const {
    setShowEventPreviewModal,
    selectedEvent,
    setShowCreateEventModal,
    setEditEventModal,
    hidePreviewModal,
    isFromCaseSpecificModule,
    isFromLeadSpecificModule,
    caseDetails,
    eventDetails,
    setEventDetails,
    getEventList,
    isClosed,
  } = useCalendarContext();

  const [birthdaySOLModal, setBirthdaySOLModal] = useState(false);
  const [eventTaskModal, setEventTaskModal] = useState(false);
  const [googleOutlookModal, setGoogleOutlookModal] = useState(false);
  const location = useLocation();

  useEffect(() => {
    //Email Redirection logic
    const queryParams = new URLSearchParams(location?.search);
    const event_id = queryParams.get('id');
    if (selectedEvent || event_id) {
      const res_type = selectedEvent?.extendedProps?.res_type;

      if (res_type === 'user' || res_type === 'case') {
        setEventDetails(selectedEvent);
        setBirthdaySOLModal(true);
        setEventTaskModal(false);
        setGoogleOutlookModal(false);
      } else if (res_type === 'event' || res_type === 'task' || event_id) {
        getEventDetails(selectedEvent?.extendedProps?.sk || '', event_id);
        setEventTaskModal(true);
        setBirthdaySOLModal(false);
        setGoogleOutlookModal(false);
      } else if (res_type === 'google' || res_type === 'outlook') {
        setEventDetails(selectedEvent);
        setEventTaskModal(false);
        setBirthdaySOLModal(false);
        setGoogleOutlookModal(true);
      }
    }
  }, []); // eslint-disable-line

  const handleEdit = () => {
    setShowCreateEventModal(true);
    setEditEventModal(true);
    setShowEventPreviewModal(false);
  };

  function getEventDetails(sk, event_id) {
    getEvent(sk, event_id)
      .then((res) => {
        setEventDetails(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const userContext = useContext(UserDetailsContext);
  const { addToast } = useToast();

  const [cancelEvent, setCancelEvent] = useState(false);

  let cancelEventPermission =
    userContext.getPermission('cases') === constants.permissions.WRITE && userContext.getFirmPermission(constants.permissions.DELETE_ITEMS);

  let editEventPermission = userContext.getPermission('cases') === constants.permissions.WRITE;

  function cancelCurrentEvent() {
    let obj = {
      pk: eventDetails.pk,
      is_lead: isFromLeadSpecificModule ? isFromLeadSpecificModule : eventDetails?.is_lead,
    };
    deleteEvent(obj)
      .then((response) => {
        hidePreviewModal();
        getEventList();
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.EVENT_DELETE_SUCCESS);
        // todo: delete google and outlook event
      })
      .catch((err) => {
        console.log(err);
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.EVENT_DELETE_FAILURE);
      });
  }

  const customFooter = () => {
    return (
      <>
        {eventDetails?.res_type === 'event' ? (
          <div></div>
        ) : (
          <div>
            {/* footer buttons */}
            {eventDetails?.pk && eventDetails?.res_type === 'event' && eventDetails?.upcoming_event?.type !== 'SOL' && !isClosed && (
              <div className="footer-btn">
                {cancelEventPermission && (
                  <Button label="Cancel Event" className="btn p-button-secondary outline me-2" onClick={() => setCancelEvent(true)} />
                )}
                {editEventPermission && (
                  <Button
                    label="Edit Event"
                    type="button"
                    className="p-button-secondary"
                    onClick={() => {
                      setShowCreateEventModal(true);
                      setEditEventModal(true);
                      setShowEventPreviewModal(false);
                    }}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </>
    );
  };
  return (
    <>
      <ConfirmDialog
        className="confirm-delete-popup"
        header="Confirm Delete"
        rejectClassName="p-button-secondary outline"
        icon="pi pi-exclamation-triangle"
        visible={cancelEvent}
        message="Are you sure you want to delete this event?"
        accept={() => {
          cancelCurrentEvent();
        }}
        reject={() => {
          setCancelEvent(false);
        }}
        onHide={() => {
          setCancelEvent(false);
        }}
      />
      <Dialog
        visible={showEventPreviewModal}
        modal
        onHide={hidePreviewModal}
        className="new-task-popup media-popup z-5"
        resizable={false}
        header={ModalHeader}
        footer={customFooter}
      >
        {birthdaySOLModal && <BirthdayAndSOLPreview eventDetails={eventDetails} />}{' '}
        {eventTaskModal && (
          <EventAndTaskPreview
            eventDetails={eventDetails}
            setCancelEvent={setCancelEvent}
            handleEdit={handleEdit}
            cancelEventPermission={cancelEventPermission}
            editEventPermission={editEventPermission}
            isClosed={isClosed}
          />
        )}
        {googleOutlookModal && <GoogleAndOutlookPreview eventDetails={eventDetails} />}
      </Dialog>
    </>
  );
}

export default EventPreviewModal;
