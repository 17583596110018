import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { getCustomFieldListOptions } from 'utils/utils';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

export default function CustomFields({ control, field, fieldDisable, setValue, practiceArea }) {
  const { fields } = useFieldArray({
    control,
    name: field,
  });

  const handleCustomFieldNumberFormat = (e, val, field) => {
    if (e.target.value) {
      if ((val.type === 'Number' && /^[0-9-()./]*$/.test(e.target.value)) || val.type !== 'Number') {
        field.onChange(e.target.value);
      }
    } else {
      setValue(field.name, '');
    }
  };

  return (
    <React.Fragment>
      {fields.map((val, index) => (
        <React.Fragment key={index}>
          {val?.selectedPracticeAreas?.length === 0 ||
          !Boolean(val?.selectedPracticeAreas) ||
          val?.selectedPracticeAreas?.includes(practiceArea) ? (
            <div className="d-flex align-items-center py-2 flex-wrap">
              <div className="col-12 col-lg-4 p-medium">
                <div className="text-capitalize p-medium">{val.name}</div>
              </div>
              <div className="col-12 col-lg-8">
                <Controller
                  name={`${field}[${index}].value`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <>
                        {val.type === 'Text(short)' && (
                          <TextSnippetWrapper
                            type="text"
                            className="input-shadow w-100"
                            name={field.name}
                            value={field.value}
                            placeholder="Enter a Text"
                            onChange={(e) => {
                              field.onChange(e.value);
                            }}
                            {...field}
                            disabled={fieldDisable}
                          />
                        )}
                        {val.type === 'Text(long)' && (
                          <TextSnippetWrapper
                            type="textarea"
                            className="input-shadow w-100"
                            rows={5}
                            cols={30}
                            name={field.name}
                            placeholder="Enter a Text Area"
                            value={field.value}
                            onChange={(e) => field.onChange(e.target.value)}
                            disabled={fieldDisable}
                          />
                        )}
                        {val.type === 'Number' && (
                          <InputText
                            className="input-shadow w-100"
                            inputId="minmaxfraction"
                            placeholder="Enter a Number"
                            name={field.name}
                            value={field.value}
                            onChange={(e) => handleCustomFieldNumberFormat(e, val, field)}
                            disabled={fieldDisable}
                          />
                        )}
                        {val.type === 'Currency' && (
                          <InputNumber
                            inputId="currency-us"
                            name={field.name}
                            value={field.value}
                            onValueChange={(e) => field.onChange(e.value)}
                            mode="currency"
                            currency="USD"
                            locale="en-US"
                            className="w-100 input-shadow"
                            disabled={fieldDisable}
                            placeholder={'Enter a Currency'}
                          />
                        )}
                        {val.type === 'Date' && (
                          <Calendar
                            monthNavigator
                            yearNavigator
                            yearRange="1990:2050"
                            showButtonBar
                            placeholder="Enter a Date"
                            className="w-100 input-shadow"
                            name={field.name}
                            value={field.value ? new Date(field.value) : ''}
                            onChange={(e) => field.onChange(e.value)}
                            dateFormat={'mm/dd/yy'}
                            disabled={fieldDisable}
                          />
                        )}
                        {val.type === 'List' && (
                          <>
                            <Dropdown
                              className="w-100 input-shadow"
                              options={getCustomFieldListOptions(val.listOptions)}
                              name={field.name}
                              value={field.value}
                              onChange={(e) => field.onChange(e.value)}
                              filter
                              placeholder="Select a item from a list"
                              disabled={fieldDisable}
                            />
                          </>
                        )}
                        {val.type === 'Boolean' && (
                          <InputSwitch
                            className="input-swich"
                            checked={field.value}
                            name={field.name}
                            value={field.value}
                            onChange={(e) => field.onChange(e.value)}
                            disabled={fieldDisable}
                          />
                        )}
                      </>
                    );
                  }}
                />
              </div>
            </div>
          ) : null}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}
