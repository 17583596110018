import React from 'react';

export default function Loader() {
  return (
    <div className="chat-loader-container">
      <div className="overlay">
        <i className="pi pi-spin pi-spinner" style={{ fontSize: '2em' }}></i>
      </div>
    </div>
  );
}
