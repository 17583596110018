import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';

function CreateDocument() {
  const { modals, isClosed } = useFileManagerContext();

  return (
    <span className={'create-document-button me-2 pointer' + (isClosed ? ' opacity-25 pe-none' : '')}>
      <Tooltip content="Create Document" position="top" target=".create-document-button" showDelay={500} />
      <i className="icon-doc fa-lg" onClick={() => modals.setShowCreateDocumentModal(true)}></i>
    </span>
  );
}

export default CreateDocument;
