import React from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { fixToTwoDecimal, formatNumbersToCurrencyString } from 'utils/utils';
import { tableCell } from '../helpers/dataTableCommonHelpers';

const ContigencyFeeBillingDetails = (props) => {
  const {
    settlementAmount,
    setSettlementAmount,
    formErrors,
    setFormErrors,
    isChanged,
    setIsChanged,
    isPersonalInjuryPracticeArea,
    litigation,
    setLitigation,
    contingencyItems,
    settlementDetails,
    addExpense,
    billsAndExpenseTotal,
  } = props || {};

  return (
    <>
      <div className="row mt-4 ">
        <div className="col-12 col-md-4">Settlement Amount</div>
        <div className="col-12 col-md-8">
          <InputText
            value={settlementAmount}
            keyfilter="pnum"
            onChange={(e) => {
              setSettlementAmount(fixToTwoDecimal(e.target.value));
              formErrors.settlement_amount = ''; //TODO:Remove direct state updates
              setFormErrors({ ...formErrors });
              isChanged.contingency = true;
              setIsChanged({ ...isChanged });
            }}
            type="number"
            min={0}
            className="input-shadow w-50 border-0 p-2"
            mode="currency"
            currency="USD"
            locale="en-US"
          />
          {formErrors && formErrors.settlement_amount && <span className="text-danger">{formErrors.settlement_amount}</span>}
        </div>
      </div>
      {!isPersonalInjuryPracticeArea && (
        <>
          <div className="row mt-3 ">
            <div className="col-12 col-md-4">Resolve Stage</div>
            <div className="col-12 col-md-8">
              Pre Litigation
              <label className="switch">
                <input
                  type="checkbox"
                  checked={litigation}
                  name="non_billable"
                  onChange={(e) => {
                    setLitigation(e.target.checked);
                    isChanged.contingency = true;
                    setIsChanged({ ...isChanged });
                  }}
                />{' '}
                <span className="slider round"></span>
              </label>
              <span>Litigation</span>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 datatable-responsive">
              <DataTable value={contingencyItems} className="p-datatable-responsive">
                <Column header="Type" field="type" body={tableCell} />
                <Column header="Date" field="date" body={tableCell} />
                <Column header="Notes" field="notes" body={tableCell} />
                <Column header="Quantity" field="quantity" body={tableCell} />
                <Column
                  header="Rate"
                  field="rate"
                  body={(row) => {
                    return (
                      <>
                        <span className="p-column-title text-break">Rate</span>
                        <span className="text-break">{formatNumbersToCurrencyString(row?.rate)}</span>
                      </>
                    );
                  }}
                />
                <Column
                  header="Total"
                  field="total"
                  body={(row) => {
                    return (
                      <>
                        <span className="p-column-title text-break">Total</span>
                        <span className="text-break">{formatNumbersToCurrencyString(row?.total)}</span>
                      </>
                    );
                  }}
                />
              </DataTable>
            </div>
          </div>
        </>
      )}

      {isPersonalInjuryPracticeArea && (
        <>
          {/* case expenses */}
          <div className="mt-4">
            <div className="caption-bold mb-3">Case Expenses</div>
            <div className="shadow-small">
              <div className="row mt-3">
                <div className="col-12 datatable-responsive">
                  <DataTable
                    className="p-datatable-responsive"
                    value={settlementDetails[0]?.case_expense_per_vendor ?? []}
                    emptyMessage={() => <div onClick={addExpense}>No records found</div>}
                  >
                    <Column
                      header="Vendor"
                      body={(rowData, props) => {
                        return (
                          <>
                            <span className="p-column-title text-break">Vendor</span>
                            <span className="text-break">{rowData?.case_vendor?.vendor?.title ?? ''}</span>
                          </>
                        );
                      }}
                      footer={<div>Total</div>}
                    />
                    <Column
                      header="Amount"
                      body={(rowData, props) => {
                        return (
                          <>
                            <span className="p-column-title text-break">Amount</span>
                            <span className="text-break">{formatNumbersToCurrencyString(rowData?.case_vendor?.bill_amount) ?? ''}</span>
                          </>
                        );
                      }}
                      footer={formatNumbersToCurrencyString(settlementDetails[0]?.total_case_expense ?? 0)}
                    />
                  </DataTable>
                </div>
              </div>
            </div>
          </div>

          {/* medical bills */}
          <div className="mt-4">
            <div className="caption-bold mb-3">Medical Bills</div>
            <div className="shadow-small">
              <div className="row mt-3">
                <div className="col-12 datatable-responsive">
                  <DataTable
                    className="p-datatable-responsive"
                    value={settlementDetails[0]?.medical_expense_per_provider ?? []}
                    emptyMessage={() => <div onClick={addExpense}>No records found</div>}
                  >
                    <Column
                      header="Provider"
                      body={(rowData, props) => {
                        return (
                          <>
                            <span className="p-column-title text-break">Provider</span>
                            <span className="text-break">{rowData?.medical_provider?.provider?.title ?? ''}</span>
                          </>
                        );
                      }}
                      footer={<div>Total</div>}
                    />
                    <Column
                      header="Amount"
                      body={(rowData, props) => {
                        return (
                          <>
                            <span className="p-column-title text-break">Amount</span>
                            <span className="text-break">
                              {formatNumbersToCurrencyString(rowData?.medical_provider?.bill_amount) ?? ''}
                            </span>
                          </>
                        );
                      }}
                      footer={formatNumbersToCurrencyString(settlementDetails[0]?.total_bill ?? 0)}
                    />
                  </DataTable>
                </div>
              </div>
            </div>
          </div>

          {/* total bill and expenses */}
          <div className="mt-3 col-12 col-sm-4">
            {/* <div className="col-12 caption-bold">INVOICE TOTAL</div> */}
            <div className="shadow-small p-3 align-self-start">
              <div className="d-flex align-items-center py-2">
                <div className="col-5">Total Bills And Expenses:</div>
                <div className="col-7 d-flex justify-content-end text-bold">{formatNumbersToCurrencyString(billsAndExpenseTotal)}</div>
              </div>
            </div>
          </div>

          {/* settlement */}

          <div className="mt-4">
            <div className="caption-bold mb-3">Settlement</div>
            <div className="shadow-small">
              <div className="row mt-3">
                <div className="col-12 datatable-responsive">
                  <DataTable
                    className="p-datatable-responsive"
                    value={settlementDetails}
                    emptyMessage={() => <div onClick={addExpense}>No records found</div>}
                  >
                    <Column
                      header=" Medical Bill Balance"
                      body={(rowData, props) => {
                        return (
                          <>
                            <span className="p-column-title text-break">Medical Bill Balance</span>
                            <span className="text-break">
                              {rowData.balance_owed ? formatNumbersToCurrencyString(rowData.balance_owed) : ''}
                            </span>
                          </>
                        );
                      }}
                    />
                    <Column
                      header="Total Legal Fee"
                      body={(rowData, props) => {
                        return (
                          <>
                            <span className="p-column-title text-break">Total Legal Fee</span>
                            <span className="text-break">
                              {rowData.attorney_fee_amount ? formatNumbersToCurrencyString(rowData.attorney_fee_amount) : ''}
                            </span>
                          </>
                        );
                      }}
                    />
                    <Column
                      header="Total Case Expenses"
                      body={(rowData) => {
                        return (
                          <>
                            <span className="p-column-title text-break">Total Case Expenses</span>
                            <span className="text-break">
                              {rowData.total_case_expense ? formatNumbersToCurrencyString(rowData.total_case_expense) : ''}
                            </span>
                          </>
                        );
                      }}
                    />
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ContigencyFeeBillingDetails;
