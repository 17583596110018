import React from 'react';
import { ProgressBar } from 'primereact/progressbar';

const FloatingProgressBar = ({ progress, fileName, error, id, remove }) => {
  return (
    <div className="download-toast">
      <div style={{ width: '93%' }}>
        <h6 className="mb-0">Downloading</h6>
        <div className="d-flex align-items-center justify-content-between">
          <ProgressBar value={progress} style={{ height: 10, width: 145 }} displayValueTemplate={() => <></>} />
          <span>{progress} %</span>
        </div>
        {fileName && (
          <p className="mb-0" style={{ wordWrap: 'break-word' }}>
            {fileName}
          </p>
        )}
        {error && <p className="mb-0 danger-text">Download Failed</p>}
      </div>
      {error && <i className="pi pi-times ms-2 cursor-pointer" onClick={() => remove(id)}></i>}
    </div>
  );
};

export default FloatingProgressBar;
