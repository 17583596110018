import React from 'react';

import { Dropdown } from 'primereact/dropdown';
import { priorityList } from 'constants/dropdownOptions';
import { priorityDropDownOptionTemplate, priorityDropDownValueTemplate, priorityTemplate } from './PriorityDropdownTemplate';
import { MultiSelect } from 'primereact/multiselect';

export default function PriorityDropdown({ iconOnly = false, multiSelect, ...rest }) {
  const Component = multiSelect ? MultiSelect : Dropdown;
  return (
    <Component
      placeholder="By Priority"
      optionLabel="label"
      optionValue="value"
      options={priorityList}
      name="priority"
      valueTemplate={iconOnly ? priorityDropDownValueTemplate : priorityTemplate}
      itemTemplate={priorityDropDownOptionTemplate}
      {...rest}
    />
  );
}
