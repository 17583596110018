import { actionTypes } from "constants/types";
let initialState = {
    showAddEventPopUp: false,
};

export default function ShowAddEventPopUpReducer(state = initialState, action={}) {
    switch (action.type) {
      case actionTypes.SHOW_ADD_EVENT:
        return {
          ...state,
  
          showAddEventPopUp: true,
        };
      case actionTypes.HIDE_ADD_EVENT:
        return {
          ...state,
  
          showAddEventPopUp: false,
        };
  
      default:
        return state;
    }
  }