import { actionTypes } from "constants/types";
import { listPaymentRequest } from "services/billingServices";

export const listPaymentRequests = (filters,pageLimit=10,pagination_token="",pagination_direction="forward") => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_PAYMENT_REQUEST_LIST_STARTED });
    listPaymentRequest(filters,pageLimit,pagination_token,pagination_direction)
      .then((response) => {
        if (response && response.data) {
          dispatch({
            type: actionTypes.GET_PAYMENT_REQUEST_LIST_SUCCESS,
            data: response.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.GET_PAYMENT_REQUEST_LIST_FAILED,
          error: err,
        });
      });
  };
};
