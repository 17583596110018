import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button } from 'primereact/button';

import { toastConstant } from 'constants/toastmessage';
import { useToast } from 'context/ToastContext';
import { createLeadSource } from 'services/lead/leadServices';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

export default function AddLeadSource(props) {
  const { onSuccess, onDismiss } = props;

  const { handleSubmit, control } = useForm();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);

  const _onDismiss = () => typeof onDismiss === 'function' && onDismiss();

  const handleLeadSourceChange = ({ source }) => {
    if (source) {
      setLoading(true);
      createLeadSource({ source: source.trim() })
        .then((data) => {
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.BUSINESS_ROLE_CREATED);
          typeof onSuccess === 'function' && onSuccess(data.data);
          setLoading(false);
        })
        .catch((error) => {
          if (error?.response?.data) {
            addToast(false, toastConstant.toasterType.WARN, toastConstant.api.FAILED, error.response.data);
          }
          setLoading(false);
        });
    } else {
      addToast(false, toastConstant.toasterType.INFO, toastConstant.api.FAILED, toastConstant.message.CASE_COURT_NAME_VALIDATION);
    }
  };

  return (
    <div className="p-inputgroup align-items-center">
      <Controller
        name="source"
        control={control}
        render={({ field }) => <TextSnippetWrapper type="text" id={field.name} {...field} className="input-shadow w-100" />}
      />
      <Button
        icon="pi pi-check"
        className="p-button-secondary mx-2"
        type="button"
        onClick={handleSubmit(handleLeadSourceChange)}
        loading={loading}
      />
      <Button icon="pi pi-times" className="p-button-secondary outline" type="button" onClick={_onDismiss} disabled={loading} />
    </div>
  );
}
