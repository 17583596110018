import React from 'react';

import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { generateFullName } from 'utils/generateFullNameUtils';
import { useContacts } from 'hooks/useContacts';

const dropdownTemplate = (rowData) => generateFullName(rowData);

export default function DuplicateDetection({ value, onSelect, onDismiss }) {
  const { contactList, searchAsync } = useContacts(true);

  const isDisabled = typeof value === 'object';

  return (
    <div className="row mt-3">
      <div className="auto-width">
        <div className="text-bold pb-2">DUPLICATE DETECTION</div>
        <div className="pb-2">Enter first name or phone number to find possible duplicates in the system</div>

        <div className="d-flex align-items-center">
          <AutoComplete
            id="search_duplicate"
            name="search_duplicate"
            className="input-shadow input-width w-100"
            style={{ width: '100%!important' }}
            inputClassName="w-100"
            itemTemplate={dropdownTemplate}
            selectedItemTemplate={dropdownTemplate}
            value={value}
            suggestions={
              contactList
              //   .filter(
              //     (item) =>
              //         (clientArrValue?.value ? item?.contact_id !== clientArrValue?.value : true) &&
              //         !relations?.some((r) => r.contact_id === item.contact_id)
              // )
            }
            onChange={(e) => onSelect(e.value)}
            completeMethod={searchAsync}
            placeholder="Type in"
          />
          <Button className="ms-2 bg-btn p-button-primary outline" label="Dismiss" disabled={!isDisabled} onClick={onDismiss} />
        </div>
      </div>
    </div>
  );
}
