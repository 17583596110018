import { actionTypes } from "constants/types";

let initialState = {
    groups:[],
    isLoading:false,
    error:""
}

export default function UserGroupsReducer(state = initialState, action={}){

    switch(action.type){
        case actionTypes.GET_USER_GROUPS_STARTED:
            return{
                ...state,
                isLoading:true,
            }
        case actionTypes.GET_USER_GROUPS_SUCCESS:
            return{
                ...state,
                isLoading:false,
                groups:action.data
            }
        case actionTypes.GET_USER_GROUPS_FAILURE:
            return{
                ...state,
                isLoading:false,
                error:action.error
            }

        default:
            return state;
    }

}