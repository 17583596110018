import useFileDownload from 'modules/file_manager/hooks/useFileDownload';
import React from 'react';
import { Tooltip } from 'primereact/tooltip';

function FileDownload(props) {
  const { downloadFile } = useFileDownload();
  const { rowData, isFromFilePreviewModal, filePreviewModalIconClassName } = props;

  const iconClassName =
    isFromFilePreviewModal && filePreviewModalIconClassName ? filePreviewModalIconClassName : 'icon-save me-3 text-primary-main pointer';

  return (
    <>
      <i
        onClick={() => downloadFile(rowData)}
        className={iconClassName}
        aria-hidden="true"
        // title="Download"
      />
      <Tooltip content="Download" position="top" target=".icon-save" showDelay={500} />
    </>
  );
}

export default FileDownload;
