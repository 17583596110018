import React from 'react';
import { useFieldArray } from 'react-hook-form';

import { uuidv4 } from 'common/uuidGenerator';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

export default function NestedSubTask({ control, register }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sub_task',
  });

  return (
    <>
      {fields.map((item, index) => {
        return (
          <div className="d-flex align-items-center" key={item.id}>
            <TextSnippetWrapper
              type="text"
              className="mt-2 mb-2 w-100 input-shadow"
              defaultValue={item.sub_task_description}
              {...register(`sub_task[${index}].sub_task_description`)}
            />
            <div className="ms-3">
              <i className="icon-delete icon-red pointer" onClick={() => remove(index)} />
            </div>
          </div>
        );
      })}
      <div
        className="mt-2 edit-field pointer addfield"
        onClick={() => append({ sub_task_description: '', sub_task_status: false, sk: uuidv4() })}
      >
        <i className="icon-add F-size14 me-1"></i> Add Sub Task
      </div>
    </>
  );
}
