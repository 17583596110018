import { actionTypes } from "constants/types";
let initialState = {
  isCaseLoading: "",
  caseCreateSuccess: "",
  caseCreateError: "",
  caseList: "",
  caseError: "",
};

export default function CaseReducer(state = initialState, action={}) {
  switch (action.type) {
    case actionTypes.GET_CASE_REQUEST:
      return {
        ...state,
        caseList: "",
        caseError: "",
        isCaseLoading: true,
      };

    case actionTypes.GET_CASE_SUCCESS:
      return {
        ...state,
        caseList: action.payload,
        caseError: "",
        isCaseLoading: false,
      };

    case actionTypes.GET_CASE_ERROR:
      return {
        ...state,
        caseList: "",
        caseError: action.payload,
        isCaseLoading: false,
      };

    case actionTypes.CREATE_CASE_REQUEST:
      return {
        ...state,
        caseCreateSuccess: "",
        caseCreateError: "",
      };

    case actionTypes.CREATE_CASE_SUCCESS:
      return {
        ...state,
        caseCreateSuccess: action.payload,
        caseCreateError: "",
      };

    case actionTypes.CREATE_CASE_ERROR:
      return {
        ...state,
        caseCreateSuccess: "",
        caseCreateError: action.payload,
      };

    case actionTypes.CLEAR_CASE_CREATE:
      return {
        ...state,
        caseCreateSuccess: "",
      };

      case actionTypes.UPDATE_CASE_LIST:
        return {
          ...state,
          caseList: [...state.caseList, action.payload]
        };

    default:
      return state;
  }
}
