import React, { useState, useContext, createContext, useEffect } from 'react';
import { SpinnerComponent } from 'shared/SpinnerComponent';

const LoaderDispatchContext = createContext();

export const useLoaderDispatch = () => {
  const context = useContext(LoaderDispatchContext);
  return context;
};

export const LoaderContext = ({ children }) => {
  let [loader, setLoader] = useState(false);

  //workaround to access setLoader globally
  useEffect(() => {
    global.setLoader = setLoader;
  }, []);

  return (
    <LoaderDispatchContext.Provider value={setLoader}>
      {loader ? <SpinnerComponent /> : ''}
      {children}
    </LoaderDispatchContext.Provider>
  );
};
