import { actionTypes } from "constants/types";
const initialState={
    customFieldList:[],
    isLoading:false,
    causeError:"",
    deleteSuccess:false
}
export default function CustomFieldReducer(state = initialState, action={}){
    switch(action.type){
        case actionTypes.GET_CUSTOM_FIELD_LIST:
            return {
                ...state,
                isLoading:true,
                
            }
            case actionTypes.GET_CUSTOM_FIELD_LIST_SUCCESS:
                return {
                    ...state,
                    customFieldList:action.payload,
                    isLoading:false,
                    causeError:""
                }
            case actionTypes.GET_CUSTOM_FIELD_LIST_ERROR:
                return {
                    ...state,
            
                    isLoading:false,
                    causeError:action.payload
                }
        default:
            return state;
    }
}