import { useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { listContacts } from 'services/lead/leadServices';

export function useRelationships(control, watch) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'relations',
  });

  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const selectedContact = watch('contact.contact_id');
  const selectedClient = watch('client.contact_id');

  const searchAsync = async (e) => {
    try {
      setLoading(true);
      const response = await listContacts({ contactType: '', keyWord: e?.query, pageLimit: 20, excludeFromLoading: true, in_lead: true });
      setResults(response?.data?.contacts?.filter((c) => c?.contact_id !== (selectedContact || selectedClient)) || []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const removeItem = (item) => {
    const index = fields.findIndex((v) => v.contact_id === item.contact_id);
    if (index !== -1) {
      remove(index);
    }
  };

  return {
    fields,
    append,
    removeItem,
    remove,
    results,
    loading,
    searchAsync,
  };
}
