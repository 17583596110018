import { actionTypes } from "constants/types";
let initialState = {
  userFirstName: "",
  userLastName: "",
};

export default function userDetails(state = initialState, action={}) {
  switch (action.type) {
    case actionTypes.SET_USER_FIRST_NAME:
      return {
        ...state,
        firstName: action.payload,
      };
    case actionTypes.SET_USER_LAST_NAME:
      return {
        ...state,
        lastName: action.payload,
      };

    default:
      return state;
  }
}
