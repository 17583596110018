import React from 'react';

import fileImage from 'assets/images/file.png';
import docImage from 'assets/images/doc.png';
import videoImage from 'assets/images/video.png';
import textImage from 'assets/images/text.png';
import xlsImage from 'assets/images/xls.png';
import pdfImage from 'assets/images/pdf.png';

const ThumbnailComponent = ({ type, url, display_name }) => {
  let src, alt;

  if (type === 'image') {
    src = url;
    alt = display_name;
  } else if (type === 'video') {
    src = videoImage;
    alt = display_name;
  } else if (type === 'pdf') {
    src = pdfImage;
    alt = display_name;
  } else if (type === 'doc') {
    src = docImage;
    alt = display_name;
  } else if (type === 'xls') {
    src = xlsImage;
    alt = display_name;
  } else if (type === 'text') {
    src = textImage;
    alt = display_name;
  } else {
    src = fileImage;
    alt = display_name;
  }

  return (
    <div style={itemStyle}>
      <div style={imageContainerStyle}>
        <img src={src} alt={alt} style={imageStyle} />
      </div>
      <span style={spanStyle}>{display_name}</span>
    </div>
  );
};

export default ThumbnailComponent;

const itemStyle = {
  textAlign: 'center',
};

const imageContainerStyle = {
  position: 'relative',
  width: '100%',
  paddingTop: '100%',
  overflow: 'hidden',
  borderRadius: '16px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.4)',
};

const imageStyle = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  padding: '8px',
  borderRadius: '16px',
};

const spanStyle = {
  marginTop: '10px',
};
