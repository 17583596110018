import React, { useEffect } from 'react';

import GlobalLoader from 'components/GlobalLoader/GlobalLoader';
import Client from './Client';
import TeamMembers from './TeamMembers';
import RelatedContacts from './RelatedContacts';
import TeamMemberLeads from './TeamMemberLeads';

export default function Contacts(props) {
  const { caseDetails, isLeadSpecific, getDetails, isCaseClosed, isLeadClosed } = props;
  useEffect(() => {
    getDetails();
  }, []);

  return (
    <div className="d-flex case-contact flex-wrap flex-column mb-4">
      <GlobalLoader />
      <div className="col-12">
        <div className="row mb-3">
          <div className="col-lg-6 col-12">
            <Client {...{ caseDetails, getDetails, isCaseClosed, isLeadClosed, isLeadSpecific }} />
          </div>
          <div className="col-lg-6 mt-3 mt-lg-0">
            {isLeadSpecific ? (
              <TeamMemberLeads {...{ caseDetails, getDetails, isCaseClosed, isLeadClosed, isLeadSpecific }} />
            ) : (
              <TeamMembers {...{ caseDetails, getDetails, isCaseClosed, isLeadClosed, isLeadSpecific }} />
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <RelatedContacts {...{ caseDetails, isCaseClosed, getDetails, isLeadClosed, isLeadSpecific }} />
        </div>
      </div>
    </div>
  );
}
