import React, { useEffect, useState } from 'react';

import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

import { createSnippetService, deleteSnippetService, listSnippet, updateSnippetService } from 'services/settings/snippetsService';
import { useUserDetailsContext } from 'context/userDetailsContext';
import { paginationDropdownOptions } from 'utils/utils';
import { handlePageNumberInPagination } from 'common/pagination';

export default function useTextSnippets({ _pageSize = 10 }) {
  const userContext = useUserDetailsContext();

  const [snippets, setSnippets] = useState({});
  const [isForward, setIsForward] = useState(true);
  const [pageSize, setpageSize] = useState(userContext?.firmDetails?.pagination_preference?.settings?.snippets?.self || _pageSize);
  const next_Token = snippets?.next_token;
  const transition_Token = snippets?.transition_token;
  const list = snippets?.text_snippets || [];

  useEffect(() => {
    setpageSize(userContext?.firmDetails?.pagination_preference?.settings?.snippets?.self || _pageSize);
  }, [userContext]); // eslint-disable-line

  const hasNext = next_Token === null && isForward;
  const hasPrevious = transition_Token === null || (transition_Token && next_Token === null && !isForward);

  const paginatorTemplate = {
    layout: 'RowsPerPageDropdown PrevPageLink NextPageLink',
    RowsPerPageDropdown: () => {
      return (
        <React.Fragment>
          <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
            Item Per Page
          </span>
          <Dropdown value={pageSize} options={paginationDropdownOptions} onChange={onPageChange} />
        </React.Fragment>
      );
    },
    NextPageLink: ({ iconClassName }) => {
      return (
        <Button onClick={fetchNext} disabled={hasNext} className={'p-paginator-next p-paginator-element p-link'} icon={iconClassName} />
      );
    },
    PrevPageLink: ({ iconClassName }) => {
      return (
        <Button onClick={fetchprev} disabled={hasPrevious} className={'p-paginator-next p-paginator-element p-link'} icon={iconClassName} />
      );
    },
  };

  const fetchNext = () => {
    setIsForward(true);
    getSnippet({
      limit: pageSize,
      pagination_token: !isForward ? transition_Token : next_Token,
      pagination_direction: 'forward',
    });
  };

  const fetchprev = () => {
    setIsForward(false);
    getSnippet({
      limit: pageSize,
      pagination_token: isForward ? transition_Token : next_Token,
      pagination_direction: 'reverse',
    });
  };

  const onPageChange = (e) => {
    setpageSize(e.value);
    handlePageNumberInPagination(userContext, 'settings', 'snippets', 'self', e.value);
    getSnippet({
      limit: e.value,
      pagination_token: '',
      pagination_direction: 'forward',
    });
    setIsForward(true);
  };

  const createSnippet = (data) => {
    return createSnippetService(data).then(() => {
      userContext?.loadUserDetails();
      getSnippet({});
    });
  };

  const updateSnippet = (data) => {
    return updateSnippetService(data).then(() => {
      userContext?.loadUserDetails();
      getSnippet({});
    });
  };

  const deleteSnippet = (pk, sk) => {
    return deleteSnippetService({ pk, sk }).then(() => {
      userContext?.loadUserDetails();
      getSnippet({});
    });
  };

  const getSnippet = ({ limit = pageSize, pagination_token = '', pagination_direction = '' }) => {
    listSnippet(pagination_token, pagination_direction, limit)
      .then((res) => {
        setSnippets(res.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getSnippet({});
  }, []);

  return { snippets_list: list, paginatorTemplate, pageSize, createSnippet, updateSnippet, deleteSnippet };
}
