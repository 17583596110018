import constants from "constants/index";

const initialState = {
    loading:false,
    contactList:{contacts: []},
    error:null,
    next:null
}

export const contactReducer =(state=initialState,action={})=>{

    switch(action.type){
       case constants.CONTACT_LIST_STARTED:
           return {
               ...state,
               loading:true,
            next:""
           }
        case constants.CONTACT_LIST__SUCCESS:
            let data = action.data
            if(action.loadMore){
                data = [...state.contactList,...action.data]
            }
            return{
                ...state,
                loading:false,
                contactList:data,
                next:action.next
            }
        case constants.CONTACT_LIST__FAILURE:
            return{
                ...state,
                loading:false,
                error:action.error
            }

        default:
            return state
    }
}