import { useState, useEffect } from 'react';

const useComponentLifecycle = (moduleName, callback) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    if (callback && typeof callback === 'function') {
      callback(moduleName, 'mounted');
    }
    return () => {
      setMounted(false);
      if (callback && typeof callback === 'function') {
        callback(moduleName, 'unmounted');
      }
    };
  }, []);

  return mounted;
};

export default useComponentLifecycle;
