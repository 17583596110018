import Amplify, { Storage } from 'aws-amplify';

export const FILEUPLOAD_S3 = process.env.REACT_APP_BUCKET;
export const IMGUPLOAD_S3 = process.env.REACT_APP_IMG_BUCKET;
export const FILEUPLOAD_S3_LEVEL = 'public';

export const ConfigureAmplify = () => {
  Amplify.configure({
    Auth: {
      userPoolId: process.env.REACT_APP_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
      authenticationFlowType: process.env.REACT_APP_AUTH_FLOW,
      region: process.env.REACT_APP_REGION,
      identityPoolId: process.env.REACT_APP_IDP_ID,
      mandatorySignIn: false,
      cookieStorage: {
        domain: process.env.REACT_APP_DOMAIN,
        secure: !(process.env.REACT_APP_DOMAIN === 'localhost'),
        // domain: ".lawft.com",
        // secure: false,
        path: '/',
        expires: 365,
      },
    },
  });
};

export function SetS3Config(bucket, level) {
  Storage.configure({
    bucket: bucket,
    level: level,
    region: process.env.REACT_APP_REGION,
    identityPoolId: process.env.REACT_APP_IDP_ID,
    customPrefix: {
      public: 'public/',
      protected: 'protected/',
      private: 'private/',
    },
  });
}
