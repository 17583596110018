import React from 'react';

import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import constants from 'constants/index';

function UploadedTemplate({ rowData, userContext }) {
  let IsoFormattedDate = rowData?.time || rowData?.version_edited || '';

  return (
    <React.Fragment>
      <span className="p-column-title text-break">Date Uploaded</span>
      <div className="text-break d-inline-table">
        <small>
          {handleDateTimeOffset(userContext?.userDetails?.timezone, IsoFormattedDate, constants.month_date_year_time_12_format)}
        </small>
        <div className="text-hint text-capitalize r-me-0">
          {rowData && rowData.created_by && rowData.created_by['custom:first_name']
            ? `${rowData.created_by['custom:first_name']} ${rowData.created_by['custom:last_name']}`
            : ''}
        </div>
      </div>
    </React.Fragment>
  );
}

export default UploadedTemplate;
