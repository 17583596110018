import { formValidationOptions } from 'modules/billing/TemporaryFund/configs/temporaryFundConfigs';
import { showFormErrorMessage } from 'modules/billing/TemporaryFund/helpers/temporaryFundHelpers';
import { Calendar } from 'primereact/calendar';
import React from 'react';
import { Controller } from 'react-hook-form';

const TemporaryFundDate = (props) => {
  const { control, errors } = props;
  return (
    <div className="d-flex align-items-top py-2 flex-wrap">
      <div className="col-12 col-sm-4 pt-2">
        Date<span className="alert-star">*</span>
      </div>
      <div className="col-12 col-sm-8">
        <Controller
          control={control}
          name={formValidationOptions?.recordCreateDate?.name}
          rules={formValidationOptions?.recordCreateDate?.rules}
          render={({ field }) => (
            <Calendar
              className="w-100 input-shadow field-icon"
              dateFormat="mm/dd/yy"
              showIcon
              monthNavigator
              yearNavigator
              yearRange="2023:3000"
              minDate={new Date()}
              id={field.name}
              name={field.name}
              value={field.value}
              onChange={(e) => field.onChange(e.value)}
              placeholder="Select"
            />
          )}
        />
        {showFormErrorMessage(formValidationOptions?.recordCreateDate?.name, errors)}
      </div>
    </div>
  );
};

export default TemporaryFundDate;
