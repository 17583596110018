import { createCase, getCase, getCaseDetails, getConflictCheck } from 'services/CaseServices';
import { actionTypes } from 'constants/types';
import Cookies from 'universal-cookie';
const cookie = new Cookies();

export const createCaseAction = (data) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return (dispatch) => {
    dispatch({ type: actionTypes.CREATE_CASE_REQUEST });
    return createCase(data, tenantId, tenantSk)
      .then((response) => {
        dispatch({
          type: actionTypes.CREATE_CASE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: actionTypes.CREATE_CASE_ERROR,
          payload: error.response,
        })
      );
  };
};

export const getCaseAction = (
  data = {},
  status = '',
  tag = '',
  practice = '',
  caseMatter = '',
  searchValue = '',
  pageLimit = 10,
  pagination_token = '',
  pagination_direction = 'forward'
) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return async (dispatch) => {
    dispatch({ type: actionTypes.GET_CASE_REQUEST });
    try {
      const response = await getCase(
        tenantId,
        tenantSk,
        data,
        status,
        tag,
        practice,
        caseMatter,
        searchValue,
        pageLimit,
        pagination_token,
        pagination_direction,
        ''
      );
      return dispatch({
        type: actionTypes.GET_CASE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      return dispatch({
        type: actionTypes.GET_CASE_ERROR,
        payload: error.response,
      });
    }
  };
};

export const updateCaseAction = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_CASE_LIST,
      payload: data,
    });
  };
};

export const getCaseDetailsAction = (case_pk, case_sk, timeEntryData = '', case_id) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replaceAll('#', '%23') : '';
  let pk = case_pk ? case_pk.replaceAll('#', '%23') : '';
  let sk = case_sk ? case_sk.replaceAll('#', '%23') : '';
  return async (dispatch) => {
    // dispatch({ type: actionTypes.GET_CASE_DETAILS_REQUEST });
    try {
      const response = await getCaseDetails(tenantId, tenantSk, pk, sk, timeEntryData, case_id);
      dispatch({ type: actionTypes.GET_CASE_DETAILS_REQUEST });
      dispatch({
        type: actionTypes.GET_CASE_DETAILS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      return dispatch({
        type: actionTypes.GET_CASE_DETAILS_ERROR,
        payload: error.response,
      });
    }
  };
};

export const getConflictCheckAction = (id) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_CONFLICT_REQUEST });
    return getConflictCheck(tenantId, tenantSk, id)
      .then((response) =>
        dispatch({
          type: actionTypes.GET_CONFLICT_SUCCESS,
          payload: response.data,
        })
      )
      .catch((error) =>
        dispatch({
          type: actionTypes.GET_CONFLICT_ERROR,
          payload: error.response,
        })
      );
  };
};

export const clearStateValue = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CLEAR_CASE_CREATE });
  };
};
