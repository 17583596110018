import { actionTypes } from "constants/types";

let initialState = {
  payment_structures: [],
  isLoading: false,
  error: "",
  tax: "",
};

export default function ExpenseTypeListReducer(state = initialState, action={}) {
  switch (action.type) {
    case actionTypes.GET_PAYMENT_STRUCTURES_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_PAYMENT_STRUCTURES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        payment_structures: action.data,
      };
    case actionTypes.GET_PAYMENT_STRUCTURES_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    // case actionTypes.GET_TAX_STARTED:
    //     return{
    //         ...state,
    //         isLoading:true,
    //     }
    case actionTypes.GET_TAX_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tax: action.data,
      };
    // case actionTypes.GET_TAX_FAILED:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     error: action.error,
    //   };

    default:
      return state;
  }
}
