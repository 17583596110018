import { combineReducers } from "redux";
import { actionTypes } from "constants/types";
import { contactReducer } from "./contact.reducer";
import userDetails from "./userDetails";
import CaseTagReducer from "./CaseTagReducer";
import PracticeAreaReducer from "./PracticeAreaReducer";
import CaseStageReducer from "./CaseStageReducer";
import CaseReducer from "./CaseReducer";
import ContactReducer from "./ContactReducer";
import { contactDetailsReducer } from "./contactDetails.reducer";
import CaseMatterReducer from "./CaseMatterReducer";
import LeadStageReducer from "./LeadStageReducer";
import CustomFieldReducer from "./CustomFieldReducer";
import { userListReducer } from "./userList.reducer";
import { userDetailsReducer } from "./UserDetailsReducer";
import EventListReducer from "./EventListReducer";
import OfficeLocationReducer from "./OfficeLocationReducer";
import CaseNoteReducer from "./CaseNoteReducer";
import CaseCourtReducer from "./CaseCourtReducer";
import EventTypeListReducer from "./EventTypeListReducer";
import TaskReducer from "./TaskReducer";
import ClientUserDetails from "./ClientUserDetails";
import CalendarReducer from "./CalendarReducer";
import NotificationReducer from "./NotificationReducer";
import CaseDetailsReducer from "./CaseDetailsReducer";
import UserGroupsReducer from "./UserGroupsReducer";
import UserRolesReducer from "./UserRolesReducer";
import TaskStageReducer from "./TaskStageReducer";
import CaseListReducer from "./CaseListReducer";
import { TimeEntryUpdateReducer } from "./TimeEntryUpdateReducer";
import AccountListReducer from "./AccountListReducer";
import ExpenseTypeListReducer from "./ExpenseTypeListReducer";
import PaymentStructureReducer from "./ers/ExpenseTypeListReducer.js";
import WorkFlowAutomationReducer from "./WorkFlowAutomationReducer";
import PaymentRequestReducer from "./PaymentRequestReducer";
import AutomationRulesLocalReducer from "./WorkFlowAutomationLocalReducer";
import PaymentHistoryReducer from "./PaymentHistoryReducer";
import LeadDetailsPageScreenReducer from "./LeadDetailsPageScreenReducer";
import LeadLocalReducer from "./LeadLocalReducer";
import ShowAddCasePopUpReducer from "./ShowAddCasePopUpReducer";
import RelationListReducer from "./RelationListReducer";
import LeadSourceListReducer from "./LeadSourceListReducer";
import ShowAddLeadPopUpReducer from "./ShowAddLeadPopupReducer";
import ShowAddEventPopUpReducer from "./ShowAddEventPopupReducer";
import ShowAddTaskPopUpReducer from "./ShowAddTaskPopupReducer";
import ShowAddMessagePopUpReducer from "./ShowAddMessagePopupReducer";
import ShowAddTimeEntryPopUpReducer from "./ShowAddTimeEntryPopupReducer";
import JobTitleReducer from "./JobTitleReducer";
import GlobalTagReducer from "./GlobalTagReducer";
import BusinessRoleReducer from "./BusinessRoleReducer";
import ProfileDetailReducer from "./ProfileDetailReducer";
import TimerReducer from "./TimerReducer";


const appReducer = combineReducers({
  userData: userDetails,
  case: CaseReducer,
  caseTag: CaseTagReducer,
  practiceArea: PracticeAreaReducer,
  caseStage: CaseStageReducer,
  contact: contactReducer,
  contactDetails: contactDetailsReducer,
  contactList: ContactReducer,
  caseMatter: CaseMatterReducer,
  customField: CustomFieldReducer,
  userList: userListReducer,
  userDetails: userDetailsReducer,
  eventList: EventListReducer,
  caseNoteList: CaseNoteReducer,
  caseCourt: CaseCourtReducer,
  leadStage: LeadStageReducer,
  officeLocation: OfficeLocationReducer,
  eventTypeData: EventTypeListReducer,
  tasks: TaskReducer,
  clientUserDetails: ClientUserDetails,
  calendarSettings: CalendarReducer,
  notificationList: NotificationReducer,
  caseDetails: CaseDetailsReducer,
  userGroups: UserGroupsReducer,
  userRoles: UserRolesReducer,
  taskStages: TaskStageReducer,
  caseList: CaseListReducer,
  timeEntryUpdateNotification: TimeEntryUpdateReducer,
  accountsList: AccountListReducer,
  expenseTypeList: ExpenseTypeListReducer,
  paymentStructures: PaymentStructureReducer,
  automationRules: WorkFlowAutomationReducer,
  paymentRequests: PaymentRequestReducer,
  workflowautomationlocal: AutomationRulesLocalReducer,
  paymentHistory: PaymentHistoryReducer,
  leadDetailsPageScreen: LeadDetailsPageScreenReducer,
  addLeadFormData: LeadLocalReducer,
  showAddCasePopUp: ShowAddCasePopUpReducer,
  roleList: RelationListReducer,
  leadSourceList: LeadSourceListReducer,
  showAddLeadPopUp: ShowAddLeadPopUpReducer,
  showAddEventPopUp: ShowAddEventPopUpReducer,
  showAddTaskPopUp: ShowAddTaskPopUpReducer,
  showAddMessagePopUp: ShowAddMessagePopUpReducer,
  showAddTimeEntryPopUp: ShowAddTimeEntryPopUpReducer,
  jobTitle: JobTitleReducer,
  globalTag: GlobalTagReducer,
  businessRole: BusinessRoleReducer,
  profileDetail:ProfileDetailReducer,
  timerDetails:TimerReducer
});

const rootReducer = (state, action) => {
  if (action.type === actionTypes.USER_LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
