import React, { useState, useEffect, useCallback } from 'react';

import { useDebounce } from 'hooks/useDebounce';
import { formatStageName, reorderList, startCase } from 'utils/utils';
import { stageUpdate } from 'services/TaskServices';

import { useTaskContext } from 'modules/Tasks/Context/TaskContext';
import { Board } from './Board/Board';
import AddNewStage from './Board/AddNewStage';
import AddTask from '../AddTask/AddTask';
import ManageColumn from '../ManageColumn/ManageColumn';
import ViewTask from '../ViewTask/ViewTask';

export default function TaskBoard() {
  const {
    isFromCase,
    caseDetails,
    loadTaskList,
    selectedStageId,
    searchKeyWord,
    createStage,
    addTaskToggle,
    setAddTaskToggle,
    manageColumnToggle,
    setManageColumnToggle,
    updateStagePosition,
    selectedTask,
    taskData,
    loadTaskData,
    setSelectedStageId,
    isFromLead,
    isClosed,
    setSearchValue,
  } = useTaskContext();
  const debouncedsearchKeyWord = useDebounce(searchKeyWord, 300);

  const [selectedStage, setSelectedStage] = useState('');

  const [boardData, setBoardData] = useState({
    columns: {},
    columnOrder: [],
  });

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.type === 'column') {
      if (boardData.columnOrder.length - 1 === result.destination.index) {
        return;
      }
      // if the list is scrolled it looks like there is some strangeness going on
      // with react-window. It looks to be scrolling back to scroll: 0
      // I should log an issue with the project
      const columnOrder = reorderList(boardData.columnOrder, result.source.index, result.destination.index);
      setBoardData({
        ...boardData,
        columnOrder,
      });
      if (result.source.index !== result.destination.index) {
        updateStagePosition(columnOrder);
      }
      return;
    }

    // reordering in same list
    if (result.source.droppableId === result.destination.droppableId) {
      const column = boardData.columns[result.source.droppableId];
      const items = reorderList(column.items, result.source.index, result.destination.index);

      // updating column entry
      const newState = {
        ...boardData,
        columns: {
          ...boardData.columns,
          [column.id]: {
            ...column,
            items,
          },
        },
      };
      setBoardData(newState);
      return;
    }

    // moving between lists
    const sourceColumn = boardData.columns[result.source.droppableId];
    const destinationColumn = boardData.columns[result.destination.droppableId];
    const item = sourceColumn.items[result.source.index];

    //stage update API calls
    if (result.source.droppableId !== result.destination.droppableId) {
      stageUpdate(item.sk, result.destination.droppableId, isFromLead);
    }

    // 1. remove item from source column
    const newSourceColumn = {
      ...sourceColumn,
      items: [...sourceColumn.items],
    };
    newSourceColumn.items.splice(result.source.index, 1);

    // 2. insert into destination column
    const newDestinationColumn = {
      ...destinationColumn,
      items: [...destinationColumn.items],
    };
    // in line modification of items
    newDestinationColumn.items.splice(result.destination.index, 0, { ...(item || {}), task_stage: newDestinationColumn.id });

    const newState = {
      ...boardData,
      columns: {
        ...boardData.columns,
        [newSourceColumn.id]: newSourceColumn,
        [newDestinationColumn.id]: newDestinationColumn,
      },
    };

    setBoardData(newState);
  }

  useEffect(() => {
    if (taskData) {
      prepareData();
    }
  }, [taskData, debouncedsearchKeyWord]);

  const prepareData = () => {
    let laneData = {};
    if (taskData) {
      let keys = Object.keys(taskData);
      keys = [...keys.filter((column) => column !== 'done'), 'done'];
      keys.forEach((key) => {
        let stageData = taskData[key] ?? [];
        if (debouncedsearchKeyWord !== '') {
          stageData = stageData.filter((v) => {
            const title = v?.task_name?.toLowerCase() ?? '';
            return title.includes(debouncedsearchKeyWord?.toLowerCase());
          });
        }
        let data = {
          id: key,
          title: formatStageName(key),
          items: stageData,
        };
        laneData = {
          [key]: data,
          ...laneData,
        };
      });
      setBoardData({
        columns: laneData,
        columnOrder: keys,
      });
    }
  };

  const checkTaskStageExists = (stageName) => {
    return boardData?.columnOrder?.find((v) => startCase(v).toLocaleLowerCase() === startCase(stageName).toLocaleLowerCase());
  };

  const handleManageColumn = useCallback((stageId) => {
    setSelectedStage(stageId);
    setManageColumnToggle();
  }, []);

  const handleAddStage = (params) => {
    createStage({
      task_stage_name: params.laneName,
      position: boardData?.columnOrder?.length,
    });
    const updatedBoardColumnOrder = [...boardData.columnOrder];
    updatedBoardColumnOrder.splice(updatedBoardColumnOrder.length - 1, 0, params.laneName);
    updateStagePosition(updatedBoardColumnOrder);
  };

  const addTaskOnHide = () => {
    setAddTaskToggle();
    setSelectedStageId(null);
  };

  const cardAction = (action, task) => {
    if (action === 'move') {
      onDragEnd(task);
    }
  };

  return (
    <div className="task-wrap d-flex" style={{ overflow: 'auto' }}>
      {taskData && <Board {...{ boardData, onDragEnd, handleAddStage, handleManageColumn, isClosed, cardAction }} />}
      {!Boolean(isFromCase) && !isClosed && <AddNewStage {...{ handleAddStage, checkTaskStageExists }} />}

      {addTaskToggle && (
        <AddTask
          onHide={addTaskOnHide}
          selectedStageId={selectedStageId}
          loadTaskList={loadTaskList}
          isFromCase={isFromCase}
          caseDetails={caseDetails}
          isFromLead={isFromLead}
          setSelectedStageId={setSelectedStageId}
          setSearchValue={setSearchValue}
        />
      )}

      {manageColumnToggle && (
        <ManageColumn
          onHide={setManageColumnToggle}
          stages={boardData?.columnOrder}
          tasks={boardData?.columns?.[selectedStage]?.items}
          loadTaskData={loadTaskData}
          selectedStage={selectedStage}
        />
      )}

      {Boolean(selectedTask) && <ViewTask data={selectedTask} />}
    </div>
  );
}
