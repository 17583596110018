export const statusOptions = [
  {
    label: 'Created',
    value: 'Created',
  },
  {
    label: 'Sent',
    value: 'Sent',
  },
  {
    label: 'Viewed',
    value: 'Viewed',
  },
  {
    label: 'Paid',
    value: 'Paid',
  },
  {
    label: 'Late',
    value: 'Late',
  },
];

export const paymentTerms = [
  {
    label: 'Weekly',
    value: 'Weekly',
  },
  {
    label: 'Biweekly',
    value: 'Biweekly',
  },
  {
    label: 'Monthly',
    value: 'Monthly',
  },
  {
    label: 'Quarterly',
    value: 'Quarterly',
  },
  {
    label: 'Yearly',
    value: 'Yearly',
  },
];

export const adjustmentItems = [
  {
    adjustment_item: 'Discount',
    adjustment_item_id: 1,
  },
  {
    adjustment_item: 'Interest',
    adjustment_item_id: 2,
  },
  {
    adjustment_item: 'Tax',
    adjustment_item_id: 3,
  },
  {
    adjustment_item: 'Addition',
    adjustment_item_id: 4,
  },
];

export const appliedToItems = [
  {
    adjustment_to: 'Flat Fees',
    adjustment_to_id: 1,
  },
  {
    adjustment_to: 'Time Entries',
    adjustment_to_id: 2,
  },
  {
    adjustment_to: 'Expenses',
    adjustment_to_id: 3,
  },
  {
    adjustment_to: 'Sub Total',
    adjustment_to_id: 4,
  },
];

export const adjustmentTypes = [
  {
    adjustment_type: 'Percentage',
    adjustment_type_id: 1,
  },
  {
    adjustment_type: 'Amount',
    adjustment_type_id: 2,
  },
];

//this available in constants/dropdownoptions.js check it
export const reminderOptions = [
  { label: '30 minutes before', value: '30#minutes#' },
  { label: '1 day before', value: '1#days#' },
  { label: '3 days before', value: '3#days#' },
  { label: '7 days before', value: '1#weeks#' },
];

export const frequencyOfPayments = [
  {
    label: 'Weekly',
    value: 'weekly',
  },
  { label: 'Bi-weekly', value: 'biweekly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Quarterly', value: 'quarterly' },
  { label: 'Yearly', value: 'yearly' },
];

export const paymentStructure = {
  FLAT_FEE: 'Flat Fee',
  SUBSCRIPTION: 'Subscription',
  CONTINGENCY_FEE: 'Contingency Fee',
  EVERGREEN_RETAINER: 'Evergreen Retainer',
  STANDARD_RETAINER: 'Standard Retainer',
};
