import React, { useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';

import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import { generateFullName } from 'utils/generateFullNameUtils';
import constants from 'constants/index';

function Message({ message, index, setRowHeight, myself, userDetails }) {
  const rowRef = useRef({});

  useEffect(() => {
    if (rowRef.current) {
      setRowHeight(index, rowRef.current.clientHeight);
    }
  }, [rowRef]); // eslint-disable-line

  return (
    <div ref={rowRef} className={myself ? 'd-flex flex-wrap py-2 my-chat flex-column' : 'd-flex flex-wrap py-2 reply-chat flex-column'}>
      <div className="d-flex justify-content-start user-profile">
        <div className="user-dtls">
          <div className={myself ? 'F-size12 me-2 text-start' : 'F-size12 me-2'}>{!myself ? generateFullName(message?.name) : null}</div>
          <div className="F-size12"></div>
        </div>
      </div>

      <div className={myself ? 'd-flex justify-content-end reply-body' : 'd-flex justify-content-start reply-body'}>
        <div className="border shadow-small p-2 my-1 msg-box d-flex justify-content-between me-2">
          <div className="d-flex flex-column">
            <div className="d-flex">{message?.message ?? null}</div>
          </div>
        </div>
      </div>
      <p className={classNames({ 'F-size12': true, 'text-end': myself, 'mb-0': true })}>
        {handleDateTimeOffset(userDetails?.timezone, message?.time, constants.hour_minute_12_format)}{' '}
      </p>
    </div>
  );
}
export default React.memo(Message);
