import { actionTypes } from "constants/types";
let initialState = {
  globalData: [],
  actionData: [],
};

//do not delete
//global data template
// const globalDataFormat = [
//   {
//     card_type: "action",
//     actionType: { label: "Create task", code: "ACT_TASK_CREATE" },
//     actionBody: "",
//   },
//   { card_type: "delay", delayExpression: "" },
//   { card_type: "filter", body: "" },
// ];

export default function AutomationRulesLocalReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case actionTypes.RECORD_AUTOMATION_DATA:
      if (state.actionData[action.payload.index]) {
        //check for already existing action
        let tempData = [...state.actionData];
        tempData[action.payload.index] = action.payload.data;
        return {
          ...state,
          actionData: [...tempData],
        };
      }
      return {
        ...state,
        actionData: [...state.actionData, action.payload.data],
      };

    case actionTypes.RESET_AUTOMATION_DATA:
      return {
        ...state,
        globalData: [],
      };
    case actionTypes.SET_AUTOMATION_DATA:
      return {
        ...state,
        actionData: [...action.payload.data],
      };
    case actionTypes.ADD_NEW_CARD:
      if (action.payload === "filter") {
        return {
          ...state,
          globalData: [
            ...state.globalData,
            {
              card_type: action.payload,
              filterBody: {
                eq: {},
                ne: {},
                lte: {},
                gte: {},
                update: {},
                lt: {},
                gt: {},
                contains: {},
              },
            },
          ],
        };
      }
      return {
        ...state,
        globalData: [...state.globalData, { card_type: action.payload }],
      };
    case actionTypes.RECORD_ACTION_TYPE:
      let tempData = [...state.globalData];
      //global data is a list of objects
      //payload=[actionTypeObject,correspondingIndex]
      tempData[action.payload[1]].actionType = action.payload[0];

      //fix for back end data pre-poulate on type change in
      //update screen
      if (tempData[action.payload[1]]?.actionBody) {
        tempData[action.payload[1]].actionBody = "";
      }
      return {
        ...state,
        globalData: [...tempData],
      };
    case actionTypes.RECORD_DELAY_OR_ACTION_BODY:
      let tmepArray = [...state.globalData];
      if (tmepArray[action.payload[1]].card_type === "action") {
        tmepArray[action.payload[1]].actionBody = action.payload[0];
        tmepArray[action.payload[1]].isFormDataChanging = false;
      } else if (tmepArray[action.payload[1]].card_type === "delay") {
        tmepArray[action.payload[1]].delayExpression = action.payload[0];
        tmepArray[action.payload[1]].isFormDataChanging = false;
      } else if (tmepArray[action.payload[1]].card_type === "filter") {
        tmepArray[action.payload[1]].filterBody = action.payload[0];
        tmepArray[action.payload[1]].isFormDataChanging = false;
      }
      return {
        ...state,
        globalData: [...tmepArray],
      };

    case actionTypes.ACTION_PARAMETERS:
      let data = [...state.globalData];
      if (data[action.payload[1]].card_type === "action") {
        data[action.payload[1]].actionParameters = action.payload[0];
        data[action.payload[1]].isFormDataChanging = false;
      }
      return {
        ...state,
        globalData: [...data],
      };
    case actionTypes.RECORD_FILTER_BODY:
      //global data is a list of objects
      //payload=[filterBody,correspondingIndex]
      let filterTempArray = [...state.globalData];
      filterTempArray[action.payload[1]].filterBody = action.payload[0];
      filterTempArray[action.payload[1]].isFormDataChanging = false;

      return {
        ...state,
        globalData: [...filterTempArray],
      };

    case actionTypes.DELETE_CARD_AND_DATA_BODY:
      let tempArray2 = state.globalData.filter(
        (item, dataIndex) => dataIndex !== action.payload
      );
      return {
        ...state,
        globalData: [...tempArray2],
      };
    case actionTypes.SET_DATA_FROM_BACK_END:
      return {
        ...state,
        globalData: [...action.payload],
      };
    case actionTypes.FORM_DATA_IS_CHANGING:
      let tempArray3 = [...state.globalData];
      tempArray3[action.payload].isFormDataChanging = true;

      return {
        ...state,
        globalData: [...tempArray3],
      };
    case actionTypes.FOLDER_STRUCTURE:
      let folderTemp = [...state.globalData];
      folderTemp[action.payload[1]].actionBody = action.payload[0];
      folderTemp[action.payload[1]].isFormDataChanging = false;

      return {
        ...state,
        globalData: [...folderTemp],
      };
    default:
      return state;
  }
}
