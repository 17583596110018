export const AppConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  baseURL2: process.env.REACT_APP_API_URL_2,
  baseURL3:process.env.REACT_APP_API_URL_3,
  echoServerURL: "",
  env: process.env.REACT_APP_ENV,
  awsImageURL: "",
  appTitle: "Lawft",
  appVersion: "[Version 1]",
};
