import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import LabelName from 'components/UI/LabelName/LabelName';

import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import { UserDetailsContext } from 'context/userDetailsContext';

import constants from 'constants/index';
import { useCalendarContext } from 'modules/calendar/Context/CalendarContext';

function BirthdayAndSOLPreview({ eventDetails }) {
  const history = useHistory();

  const userContext = useContext(UserDetailsContext);
  const { isClientCalendar } = useCalendarContext;

  let event_type = eventDetails?.extendedProps?.res_type;

  function navigateToModule(item, module) {
    if (!isClientCalendar) {
      if (module === 'cases' && !item.is_lead) {
        let case_id = item?.extendedProps?.case_id;
        history.push(`/cases/${case_id}`);
      }
    }
  }

  return (
    <form>
      {/* form heading */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="modal-heading F-size16 text-bold">{event_type === 'user' ? 'Birthday' : 'SOL'}</div>
      </div>
      {/* title */}
      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-md-4 col-12 ">
          <LabelName required={false} htmlFor="title">
            Event Title
          </LabelName>
        </div>
        <div className="col-md-8 col-12 input-style">{eventDetails?.title}</div>
      </div>
      {event_type === 'case' && (
        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-md-4 col-12">
            <LabelName required={false} htmlFor="title">
              Case
            </LabelName>
          </div>

          <div className="col-md-8 col-12 react-tagsinput-tag mb-2 casename-container p-2  text-center F-size13 m-0">
            <span className="pointer" onClick={() => navigateToModule(eventDetails, 'cases')}>
              {eventDetails?.extendedProps?.case_description.case_name ?? 'No case selected'}
            </span>
          </div>
        </div>
      )}
      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-md-4 col-12">
          <LabelName required={false} htmlFor="title">
            Date
          </LabelName>
        </div>
        <div className="col-md-8 col-12 input-style">
          {handleDateTimeOffset(userContext.userDetails.timezone, eventDetails?.start, constants.month_date_year_format)}
        </div>
      </div>
    </form>
  );
}

export default BirthdayAndSOLPreview;
