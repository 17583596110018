import React, { useState, forwardRef, useImperativeHandle } from 'react';
import ReactDOM from 'react-dom';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { useForm, Controller } from 'react-hook-form';
import { ModalHeader } from 'shared/ModalHeader';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

const ConfirmDialog = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false);
  const [resolveConfirm, setResolveConfirm] = useState(null);
  const [rejectCancel, setRejectCancel] = useState(null);
  const [dynamicContent, setDynamicContent] = useState(null);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useImperativeHandle(ref, () => ({
    open(content, onConfirm, onCancel) {
      setDynamicContent(content);
      setVisible(true);
      setResolveConfirm(() => onConfirm);
      setRejectCancel(() => onCancel);
    },
  }));

  const onSubmit = (data) => {
    setVisible(false);
    if (resolveConfirm) {
      resolveConfirm(data);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    if (rejectCancel) {
      rejectCancel(false);
    }
  };

  const renderField = (fieldConfig) => {
    const validationRules = fieldConfig.required ? { required: `${fieldConfig.label} is required` } : {};

    switch (fieldConfig.type) {
      case 'input':
        return (
          <Controller
            name={fieldConfig.name}
            control={control}
            defaultValue=""
            rules={validationRules}
            render={({ field: controllerField }) => (
              <>
                <TextSnippetWrapper type="text" id={fieldConfig.name} {...controllerField} className="w-100 input-shadow" />
                {errors[fieldConfig.name] && <small className="p-error">{errors[fieldConfig.name].message}</small>}
              </>
            )}
          />
        );
      case 'dropdown':
        return (
          <Controller
            name={fieldConfig.name}
            control={control}
            defaultValue=""
            rules={validationRules}
            render={({ field: controllerField }) => (
              <>
                <Dropdown
                  className="w-100 input-shadow"
                  id={fieldConfig.name}
                  value={controllerField.value}
                  options={fieldConfig.options}
                  onChange={(e) => controllerField.onChange(e.value)}
                  placeholder={fieldConfig.placeholder}
                />
                {errors[fieldConfig.name] && <small className="p-error">{errors[fieldConfig.name].message}</small>}
              </>
            )}
          />
        );
      case 'multiselect':
        return (
          <Controller
            name={fieldConfig.name}
            control={control}
            defaultValue=""
            rules={validationRules}
            render={({ field: controllerField }) => (
              <>
                <MultiSelect
                  className="w-100 input-shadow"
                  id={fieldConfig.name}
                  value={controllerField.value}
                  options={fieldConfig.options}
                  onChange={(e) => controllerField.onChange(e.value)}
                  placeholder={fieldConfig.placeholder}
                />
                {errors[fieldConfig.name] && <small className="p-error">{errors[fieldConfig.name].message}</small>}
              </>
            )}
          />
        );
      default:
        return null;
    }
  };

  return ReactDOM.createPortal(
    <Dialog visible={visible} onHide={handleCancel} header={ModalHeader} className="new-task-popup" footer={<></>}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {dynamicContent && (
          <div>
            {dynamicContent.formFields.map((fieldConfig) => (
              <div key={fieldConfig.name} className="p-field mb-2">
                <label htmlFor={fieldConfig.name} className="p-medium">
                  {fieldConfig.label}
                </label>
                {renderField(fieldConfig)}
              </div>
            ))}
          </div>
        )}
        <div className="footer-btn">
          <Button label="Cancel" type="reset" icon="pi pi-times" onClick={handleCancel} className="ms-2 btn p-button-secondary outline" />
          <Button label="Select" icon="pi pi-check" type="submit" autoFocus className="p-button-secondary ms-2" />
        </div>
      </form>
    </Dialog>,
    document.body
  );
});

export default ConfirmDialog;
