import React, { createContext, useState } from 'react';
import { googleConfig } from 'config/calendar_config';

const GoogleContext = createContext(null);

const GoogleProvider = (props) => {
  const [googleUser, setGoogleUser] = useState();

  let googleAccessToken = window?.localStorage?.getItem('google_access_token');

  const handleCallbackResponse = (response) => {
    console.log('response-GoogleUser', response);
    if (response?.access_token) {
      setGoogleUser(response);
    } else {
      setGoogleUser();
    }
  };

  const initClient = async (showConsent = false) => {
    if (showConsent) {
      try {
        let client = await window.google.accounts.oauth2.initTokenClient({
          client_id: googleConfig.clientId,
          scope: googleConfig.scopes,
          callback: handleCallbackResponse,
          prompt: 'consent',
          access_type: 'offline',
          // prompt:"select_account"
          include_granted_scopes: true,
        });

        return await client?.requestAccessToken();
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  const googleSignIn = async (showConsent) => {
    return initClient(showConsent);
  };
  const googleSignOut = () => {
    return window?.google?.accounts.oauth2.revoke(googleAccessToken, () => {
      setGoogleUser();
      window?.localStorage?.removeItem('google_access_token');
    });
  };

  return (
    <GoogleContext.Provider
      value={{
        googleSignIn,
        googleSignOut,
        googleUser,
        setGoogleUser,
      }}
    >
      {props.children}
    </GoogleContext.Provider>
  );
};

const GoogleConsumer = GoogleContext.Consumer;

export { GoogleContext, GoogleProvider, GoogleConsumer };
