import { actionTypes } from "constants/types";
let initialState = {
    showAddTimeEntryPopUp: false,
};

export default function ShowAddTimeEntryPopUpReducer(state = initialState, action={}) {
    switch (action.type) {
      case actionTypes.SHOW_ADD_TIMEENTRY:
        return {
          ...state,
  
          showAddTimeEntryPopUp: true,
        };
      case actionTypes.HIDE_ADD_TIMEENTRY:
        return {
          ...state,
  
          showAddTimeEntryPopUp: false,
        };
  
      default:
        return state;
    }
  }