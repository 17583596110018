import { performRequest } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';
import { getTenantDetails } from 'utils/utils';

export const verifyUpdateSignedUser = (data, login) => {
  return performRequest(apiEndPoints.methodType.POST, apiEndPoints.verifyUser, data, '', login);
};

export const verifySubDomain = (domain, login) => {
  let url = `${apiEndPoints.verifySubDomain}?sub_domain=${domain?.trim()}`;
  return performRequest(apiEndPoints.methodType.GET, url, {}, '', login);
};

export const getUserDetails = (id, excludeFromLoading = false) => {
  const [tenantId] = getTenantDetails();
  const method = apiEndPoints?.methodType?.GET;
  const url = `${apiEndPoints?.userContext}?tenant_id=${id || tenantId || ''}`;

  return performRequest(method, url, '', '', true, excludeFromLoading);
};
