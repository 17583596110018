import { actionTypes } from "constants/types";
let initialState = {
    showAddMessagePopUp: false,
};

export default function ShowAddMessagePopUpReducer(state = initialState, action={}) {
    switch (action.type) {
      case actionTypes.SHOW_ADD_MESSAGE:
        return {
          ...state,
  
          showAddMessagePopUp: true,
        };
      case actionTypes.HIDE_ADD_MESSAGE:
        return {
          ...state,
  
          showAddMessagePopUp: false,
        };
  
      default:
        return state;
    }
  }