import React, { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Menu } from 'primereact/menu';
import { classNames } from 'primereact/utils';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import { useToggle } from 'hooks/useToggle';
import { useLeadContext } from 'modules/lead/Context/LeadContext';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

const StageHeader = (props) => {
  const { data, stage, ...rest } = props;
  const opanel = useRef();

  const { handleSubmit, control, reset, watch } = useForm();
  const [showLaneNameInputField, setShowLaneNameInputField] = useToggle(false);
  const { deleteStage, updateStageData } = useLeadContext();
  const stageName = watch('laneName');
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [archiveMessage, setArchiveMessage] = useState('');

  const onUpdateStage = ({ laneName }) => {
    updateStageData({ pk: stage?.pk, sk: stage?.sk, lead_stage_name: laneName });
    setShowLaneNameInputField();
  };

  const closeNameChange = () => {
    setShowLaneNameInputField(false);
    reset({ laneName: stage?.lead_stage_name });
  };

  const isDefaultColumns = (stage?.lead_stage_name).toLowerCase() === 'new' || (stage?.lead_stage_name).toLowerCase() === 'closed';

  // const menuItems = [
  //   { label: 'Rename', command: setShowLaneNameInputField, disabled: isDefaultColumns },
  //   {
  //     label: 'Delete',
  //     command: () => {
  //       confirmDialog({
  //         message: data?.items?.length
  //           ? 'This stage contains leads. Deleting the stage will also delete the associated leads. Are you sure you want to proceed with the deletion?'
  //           : 'Do you want to delete this stage?',
  //         header: 'Delete Confirmation',
  //         icon: 'pi pi-info-circle',
  //         acceptClassName: 'p-button-danger',
  //         accept: () => deleteStage(stage),
  //         style: { maxWidth: 350 },
  //       });
  //     },
  //     disabled: isDefaultColumns,
  //   },
  // ];

  const menuItems = [
    { label: 'Rename', command: setShowLaneNameInputField, disabled: isDefaultColumns },
    {
      label: 'Archive',
      command: () => {
        if (data?.items?.length) {
          // Case when there are active leads in the stage
          setArchiveMessage('This stage contains active leads. Please move the active leads to another stage before archiving');
        } else {
          // Case when there are no active leads in the stage
          setArchiveMessage('Do you want to archive this stage?');
        }
        setConfirmVisible(true);
      },
      disabled: isDefaultColumns,
    },
  ];

  const validateName = !(stageName?.length > 0 && stageName !== stage?.lead_stage_name);

  const footerContent = (
    <>
      {/* Show Cancel button only if there are active leads */}
      {data?.items?.length ? (
        <button className="p-button p-component p-button-secondary outline" onClick={() => setConfirmVisible(false)}>
          Cancel
        </button>
      ) : null}

      {/* Show Yes button only if there are no active leads */}
      {!data?.items?.length && (
        <>
          <button className="p-button p-component p-button-secondary outline" onClick={() => setConfirmVisible(false)}>
            No
          </button>
          <button
            className="p-button p-component p-button-text"
            onClick={() => {
              deleteStage(stage); // Archive the stage if no items present
              setConfirmVisible(false);
            }}
          >
            Yes
          </button>
        </>
      )}
    </>
  );

  return (
    <div {...rest}>
      <div className="d-flex align-items-center m-1">
        {showLaneNameInputField ? (
          <div className="d-flex col-7">
            <Controller
              name="laneName"
              control={control}
              defaultValue={stage?.lead_stage_name || ''}
              rules={{ required: true, pattern: { value: /^(?!^\d+$).*/i } }}
              render={({ field }) => <TextSnippetWrapper type="text" required id={field.name} className="w-100 input-shadow" {...field} />}
            />
            <div
              className={classNames({ 'd-flex justify-content-center align-items-center ms-1 me-1': true, 'disabled-icon': validateName })}
            >
              <i className="pi pi-check cursor-pointer" onClick={handleSubmit(onUpdateStage)} />
            </div>
            <div className="d-flex justify-content-center align-items-center ms-1">
              <i className="pi pi-times cursor-pointer" onClick={closeNameChange} />
            </div>
          </div>
        ) : (
          <div className="col-7 F-size16 task-heading">{stage?.lead_stage_name}</div>
        )}

        <div className="col-5 d-flex align-items-center justify-content-end">
          <div className="pe-2">
            <div className="d-flex align-items-center justify-content-center p-2 task-number-header">{data?.items?.length ?? 0}</div>
          </div>
          <div className="pointer">
            <div
              className="d-flex border border-1 rounded  justify-content-center p-2 task-lane-header-options pointer"
              onClick={(e) => opanel?.current?.toggle(e)}
            >
              <i className="pi pi-ellipsis-v" />
            </div>
          </div>
        </div>
      </div>
      <Menu model={menuItems} popup ref={opanel} id="popup_menu" />
      <ConfirmDialog
        visible={confirmVisible}
        onHide={() => setConfirmVisible(false)}
        message={archiveMessage}
        header="Archive Confirmation"
        icon="pi pi-info-circle"
        footer={footerContent} // Set custom footer
        style={{ maxWidth: 350 }}
      />
    </div>
  );
};

export default React.memo(StageHeader);
