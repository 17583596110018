import { actionTypes } from "constants/types";

let initialState = {
  isLoading: false,
  leadSourceList: [],
  error: "",
};

export default function LeadSourceListReducer(state = initialState, action={}) {
  switch (action.type) {
    case actionTypes.GET_LEAD_SOURCE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_LEAD_SOURCE_SUCCESS:
      return {
        ...state,
        leadSourceList: action.payload,
        isLoading: false,
      };
    case actionTypes.GET_LEAD_SOURCE_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
