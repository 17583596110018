import { actionTypes } from "constants/types";
const initialState = {
  clientUserDetails: [],
  isLoading: true,
  causeError: "",
};
export default function ClientUserDetails(state = initialState, action={}) {
  switch (action.type) {
    case actionTypes.GET_CLIENT_USER_DETAILS_REQUEST:
      return {
        ...state,
        clientUserDetails: [],
        isLoading: true,
      };
    case actionTypes.GET_CLIENT_USER_DETAILS_SUCCESS:
      return {
        ...state,
        clientUserDetails: action.payload,
        isLoading: false,
        causeError: "",
      };
    case actionTypes.GET_CLIENT_USER_DETAILS_ERROR:
      return {
        ...state,

        isLoading: false,
        causeError: action.payload,
      };
    default:
      return state;
  }
}
