import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React from 'react';

function InviteDialogConfirmation({
  inviteInitiated,
  setInviteInitiated,
  setSelectedUser,
  handleInvitingUser,
  redirectToPage,
  messageDetails,
  usersNotInChangedCase,
}) {
  function hideInviteConfirmationDialog() {
    setInviteInitiated(false);
    setSelectedUser([]);
  }

  const renderFooterTwo = (name) => {
    return (
      <div>
        <Button label="Cancel" onClick={hideInviteConfirmationDialog} className="p-button-text p-button-secondary outline" />
        <Button
          label={'Yes , Invite Users'}
          icon="pi pi-check"
          onClick={() => {
            handleInvitingUser();
          }}
          autoFocus
        />
      </div>
    );
  };

  const handleUserNotInChangedCaseListNavigation = (e, user) => {
    let route = '/';
    const isGroup = user?.user_group_id && user?.res_type === 'user-group';
    const isClient = user?.access_level?.toLowerCase() === 'client';
    if (isGroup) {
      route = '/settings/groups';
    } else if (isClient) {
      route = user?.contact_id ? `/contacts/${user?.contact_id}` : '/contacts';
    } else if (!isClient && !isGroup) {
      route = user?.firm_user_id ? `/settings/team-management/${user?.firm_user_id}` : '/settings/team-management';
    }
    redirectToPage(e, route);
  };

  return (
    <Dialog
      className="common-popup-style"
      header={'User Conflict'}
      visible={inviteInitiated}
      style={{ width: '40vw' }}
      footer={renderFooterTwo('displayBasic2')}
      onHide={hideInviteConfirmationDialog}
    >
      <>
        <p>
          The following users are not linked to the case :{' '}
          <span
            className="fw-bold text-primary-dark pointer"
            onClick={(e) => {
              redirectToPage(e, `/cases/${messageDetails?.case_id}`);
            }}
          >
            {messageDetails?.case_name ? messageDetails?.case_name : 'N/A'}
          </span>
        </p>
        <ul>
          {usersNotInChangedCase?.length
            ? usersNotInChangedCase?.map((user, key) => {
                return (
                  <li
                    key={key}
                    className="capitalize-text redirection-link"
                    onClick={(e) => {
                      handleUserNotInChangedCaseListNavigation(e, user);
                    }}
                  >{`${user?.fullName ? user?.fullName : ''} (${
                    user?.access_level ? user?.access_level : user?.res_type === 'user-group' ? 'Group' : 'Client'
                  })`}</li>
                );
              })
            : null}
        </ul>
        <p>Do you still want to invite the selected users to this message</p>
      </>
    </Dialog>
  );
}

export default InviteDialogConfirmation;
