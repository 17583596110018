import { actionTypes } from "constants/types";
const initialState = {
  contactFormDataFilled: false,
  informationFormDataFilled: false,
  billingPreferenceFormDataFilled: false,
  contactFormData: {},
  informationFormData: {},
  billingFormData: {},
  moveToContactPage: false,
  moveToInformationPage: false,
  moveToBillingPrefernces: false,
  dataCompleted: false,
  readyToSendData: false,
  convertToCaseClicked: false,
  feePaymenDataForConvertToCase: {},
  backwardNavigationStarted: false,
  forwardNaviagtion:false,
  currentIndex:0,
  updateLeadClicked:false,
  moveToInfoAfterContactSave:false
};
export default function LeadLocalReducer(state = initialState, action={}) {
  switch (action.type) {
    case actionTypes.MOVE_TO_CONTACT:
      return {
        ...state,
        // moveToContactPage: true
        moveToContactPage: action.payload
      }
    case actionTypes.MOVE_TO_INFORMATION:
      return {
        ...state,
        // moveToInformationPage: true
        moveToInformationPage: action.payload
      }
    case actionTypes.MOVE_TO_BILLING_PREFERENCES:
      return {
        ...state,
        // moveToBillingPrefernces: true,
        moveToBillingPrefernces: action.payload,
      }

    case actionTypes.SAVE_CONTACT_FORM_DATA:
      return {
        ...state,
        contactFormDataFilled: true,
        contactFormData: action.payload,
      };
    case actionTypes.SAVE_INFORMATION_FORM_DATA:
      return {
        ...state,
        informationFormDataFilled: true,
        informationFormData: action.payload,
      };
    case actionTypes.SAVE_BILLING_PREFERNCE_DATA:
      return {
        ...state,
        billingPreferenceFormDataFilled: true,
        billingFormData: action.payload,
      };
    case actionTypes.SEND_LEAD_DATA:
      return {
        ...state,
        readyToSendData: true
      }
    case actionTypes.LEAD_FORM_DATA_COMPLETED:
      return {
        ...state,
        dataCompleted: true
      }
    case actionTypes.CONVERT_TO_CASE_CLICKED:
      return {
        ...state,
        convertToCaseClicked: true,

      }

    case actionTypes.RECORD_FEE_PAYMENT_DATA_FOR_CONVERSION:
      try {
        if (Object.keys(action.payload).length === 0) {
          return {
            ...state,
            convertToCaseClicked: false,
            feePaymenDataForConvertToCase: {}
          }
        }
      } catch (error) {

      }
      return {
        ...state,
        convertToCaseClicked: true,
        feePaymenDataForConvertToCase: action.payload
      }
    case actionTypes.RESET_LEAD_LOCAL_DATA:
      return {
        ...state,
        backwardNavigationStarted:false,
        contactFormDataFilled: false,
        informationFormDataFilled: false,
        billingPreferenceFormDataFilled: false,
        contactFormData: {},
        informationFormData: {},
        billingFormData: {},
        moveToInformationPage: false,
        moveToBillingPrefernces: false,
        dataCompleted: false,
        readyToSendData: false,
        convertToCaseClicked: false,
        feePaymenDataForConvertToCase: {}
      }
    case actionTypes.STARTED_BACKWARD_NAVIGATION:
      return {
        ...state,
        backwardNavigationStarted: true,
        forwardNaviagtion:false,
        currentIndex:action.payload
      }
    case actionTypes.STARTED_FORWARD_NAVIGATION:
      return {
        ...state,
        backwardNavigationStarted: false,
        forwardNaviagtion:true,
        currentIndex:action.payload
      }
     
    case actionTypes.UPDATE_LEAD_CLICKED:
      return {
        ...state,
        updateLeadClicked:true
      }
      case actionTypes.MOVE_TO_INFORMATION_AFTER_CONTACT_SAVE:
        return {
          ...state,
          moveToInfoAfterContactSave:action.payload
        }
    
    default:
      return state;
  }
}
