import { actionTypes } from "constants/types";

export const getActiveTimerHours = (data) => {

  return (dispatch) => {
    return dispatch({
      type: actionTypes?.GET_ACTIVE_TIMER_HOURS,
      payload: data,
    });
  };
};
