import { Dropdown } from 'primereact/dropdown';
import React from 'react';
import { Controller } from 'react-hook-form';
import { showFormErrorMessage } from 'modules/billing/TemporaryFund/helpers/temporaryFundHelpers';
import { formValidationOptions } from 'modules/billing/TemporaryFund/configs/temporaryFundConfigs';

const TemporaryFundTrustAccount = (props) => {
  const { defaultTrustAccountDetails, trustAccountsList, control, errors } = props;
  return (
    <div>
      <div className="d-flex align-items-top py-2 flex-wrap">
        <div className="col-12 col-sm-4 pt-2">
          Account{!defaultTrustAccountDetails?.account_name && trustAccountsList?.length ? <span className="alert-star">*</span> : null}
        </div>
        <div className="col-12 col-sm-8 pt-2">
          {defaultTrustAccountDetails?.account_name ? (
            defaultTrustAccountDetails?.account_name
          ) : (
            <Controller
              control={control}
              name={formValidationOptions?.trustAccount?.name}
              rules={formValidationOptions?.trustAccount?.rules}
              render={({ field }) => (
                <Dropdown
                  className="input-shadow w-100 border-0 p-0"
                  placeholder="Select"
                  options={trustAccountsList}
                  name={field?.name}
                  value={field?.value}
                  onChange={(e) => field.onChange(e)}
                  filter
                  optionLabel="account_name"
                />
              )}
            />
          )}
          {showFormErrorMessage(formValidationOptions?.trustAccount?.name, errors)}
        </div>
      </div>
    </div>
  );
};

export default TemporaryFundTrustAccount;
