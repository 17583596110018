import { performRequest, performRequestSecond, performRequestThird } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';
import { getTenantDetails } from 'utils/utils';
import Cookies from 'universal-cookie';
const cookie = new Cookies();

export const createLead = (data) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replaceAll('#', '%23') : '';
  return performRequest(apiEndPoints.methodType.POST, `${apiEndPoints.createLead}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`, data);
};

export const viewLeadDetails = (tenantId, tenantSk, pk, sk) => {
  //  const tenantId = cookie.get('tenantId') || null;
  // const tenant_sk = cookie.get('tenantSk') || null;
  // let tenantSk = tenant_sk ? tenant_sk.replaceAll("#", "%23") : "";
  // return performRequest(
  //   apiEndPoints.methodType.GET,
  //   `${apiEndPoints.viewLeadDetails}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&pk=${pk}&sk=${sk}`
  // );
};

export const viewLeadDetailsModified = (pk = '', sk = '', lead_id = '') => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replaceAll('#', '%23') : '';
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.viewLeadDetails}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${sk ? `&lead_sk=${sk}` : ''}${
      lead_id ? `&lead_id=${lead_id}` : ''
    }`
  );
};

export const getLeads = (data = false, assigned_to = false, keyword = false, limit = 1000) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replaceAll('#', '%23') : '';
  let assignedTo = assigned_to ? assigned_to.replaceAll('#', '%23') : '';
  return performRequest(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.listLeads}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${assigned_to ? `&assigned_to=${assignedTo}` : ''}${
      keyword ? `&keyword=${keyword}` : ''
    }&limit=${limit}`,
    data
  );
};

// export const getLeads = (data= false,assigned_to = false,keyword=false) => {
//   const tenantId = cookie.get('tenantId') || null;
//   const tenant_sk = cookie.get('tenantSk') || null;
//   let tenantSk = tenant_sk ? tenant_sk.replaceAll("#", "%23") : "";
//   let assignedTo = assigned_to ? assigned_to.replaceAll("#", "%23") : "";
//   return performRequest(
//     apiEndPoints.methodType.GET,
//     `${apiEndPoints.listLeads}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
//   );
// };

export const updateLeadDetails = (lead_sk, data) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replaceAll('#', '%23') : '';
  let leadSK = lead_sk ? lead_sk.replaceAll('#', '%23') : '';
  return performRequest(
    apiEndPoints.methodType.PUT,
    `${apiEndPoints.updateLeadDetails}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&lead_sk=${leadSK}`,
    data
  );
};

export const deleteLead = (lead_sk) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replaceAll('#', '%23') : '';
  let leadSK = lead_sk ? lead_sk.replaceAll('#', '%23') : '';
  return performRequest(
    apiEndPoints.methodType.DELETE,
    `${apiEndPoints.deleteLead}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&lead_sk=${leadSK}`
  );
};

export const closeLead = (lead_sk, data) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replaceAll('#', '%23') : '';
  let leadSK = lead_sk ? lead_sk.replaceAll('#', '%23') : '';
  return performRequest(
    apiEndPoints.methodType.PATCH,
    `${apiEndPoints.closeLead}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&lead_sk=${leadSK}`,
    data
  );
};

export const convertToCase = (lead_sk) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replaceAll('#', '%23') : '';
  let leadSK = lead_sk ? lead_sk.replaceAll('#', '%23') : '';
  return performRequest(
    apiEndPoints.methodType.PATCH,
    `${apiEndPoints.convertToCase}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&lead_sk=${leadSK}`
  );
};

export const saveLeadCloseReason = (leadSk, data, is_lead) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replaceAll('#', '%23') : '';
  return performRequestSecond(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.leadCloseReason}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&lead_sk=${leadSk}&is_lead=${is_lead}`,
    data
  );
};

export const viewLeadClosureReasons = (lead_sk, is_lead) => {
  const [tenantId, tenantSk] = getTenantDetails();
  // let leadSK = lead_sk ? lead_sk.replaceAll("#", "%23") : "";
  return performRequestSecond(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.leadClosureReasons}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&is_lead=${is_lead}`
  );
};

export const listLeadReferrals = () => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(apiEndPoints.methodType.GET, `${apiEndPoints.listLeadReferrals}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`);
};

export const createLeadReferral = (is_lead, data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.createLeadReferral}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&is_lead=${is_lead}`,
    data
  );
};

export const updateLeadUser = (leadId, data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let lead_id = leadId ? leadId.replaceAll('#', '%23') : '';
  return performRequest(
    apiEndPoints.methodType.PATCH,
    `${apiEndPoints.updateLeadUser}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&lead_id=${lead_id}`,
    data
  );
};

export const createLeadSource = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.createLeadSource}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};

export const listLeadSource = () => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(apiEndPoints.methodType.GET, `${apiEndPoints.listLeadSources}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`);
};
