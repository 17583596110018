import { actionTypes } from "constants/types";
import { listBusinessRole } from "services/Settings";

export const getBusinessRoleAction = (pageLimit=10,pagination_token="",pagination_direction="forward") => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.GET_BUSINESS_ROLE_REQUEST });
    try {
      const response = await listBusinessRole(pageLimit,pagination_token,pagination_direction);
      return dispatch({
        type: actionTypes.GET_BUSINESS_ROLE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      return dispatch({
        type: actionTypes.GET_BUSINESS_ROLE_ERROR,
        payload: error.response,
      });
    }
  };
};
