/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { withRouter, Link, useHistory, useLocation } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { leadsPagesOptions } from 'constants/dropdownOptions';
import { useUserDetailsContext } from 'context/userDetailsContext';

function withSidebar(WrappedComponent) {
  function SideBar({ match, ...rest }) {
    const location = useLocation();
    const [menu, setMenu] = useState('');
    const { checkSubPermission, subscriptionAccess } = useUserDetailsContext();

    const history = useHistory();

    const handleMenuChange = (e) => {
      setMenu(e.value);
      if (e.value === '') {
        history.push(match.url?.replace(/\/$/, ''));
      }
      history.push(`${match.url?.replace(/\/$/, '')}/${e.value}`);
    };

    const leadOptionFiltered = useMemo(
      () =>
        leadsPagesOptions.filter((o) => {
          if (o.value === 'tasks') {
            return checkSubPermission('task_management');
          } else if (o.value === 'text-messages') {
            return checkSubPermission('texting');
          } else return true;
        }),
      [subscriptionAccess]
    );

    useEffect(() => {
      let splitted = location?.pathname?.split('/');
      setMenu(splitted[3] ?? '');
    }, [match]);

    return (
      <div className="container-fluid mt-3">
        <div className="row pt-1">
          <div className="col-sm-2 col-md-3 col-lg-2 side-nav shadow-middle height-full d-none d-md-block">
            <div className="d-flex flex-column my-3 side-nav-inner">
              {leadOptionFiltered.map((v, i) => {
                return (
                  <Link
                    onClick={() => setMenu(v.path)}
                    key={i}
                    to={v.path ? `${match.url}/${v.path}` : match.url}
                    className={menu === v.path ? 'current-nav' : ''}
                  >
                    <i className={menu === v.path ? v.icon_active : v.icon} /> {v.label}
                  </Link>
                );
              })}
            </div>
          </div>
          <Dropdown
            className="d-md-none mb-3"
            options={leadOptionFiltered}
            optionValue="path"
            value={menu}
            onChange={handleMenuChange}
            filter
          />
          <div className="col-sm-12 col-md-9 col-lg-10 ps-0 ps-lg-5 ps-md-3 pe-0 case-dm">
            <WrappedComponent match={match} {...rest} />
          </div>
        </div>
      </div>
    );
  }

  return withRouter(SideBar);
}

export default withSidebar;
