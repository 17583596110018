import { actionTypes } from "constants/types";
let initialState = {
  isGlobalTagLoading: false,
  globalTagList: [],
  globalTagError: "",
};

export default function  GlobalTagReducer(state = initialState, action={}) {
  switch (action.type) {
    case actionTypes.GET_GLOBAL_TAG_REQUEST:
      return {
        ...state,
        isGlobalTagLoading:true,
        globalTagList: [],
        globalTagError: "",
      };
    case actionTypes.GET_GLOBAL_TAG_SUCCESS:
      return {
        ...state,
        globalTagList: action.payload,
        globalTagError: "",
        isGlobalTagLoading:false
      };
    case actionTypes.GET_GLOBAL_TAG_ERROR:
      return {
        ...state,
        globalTagList: [],
        globalTagError: action.payload,
        isGlobalTagLoading:false
      };

    default:
      return state;
  }
}
