import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import StageHeader from './StageHeader';
import { ItemList } from './ItemList';

export const Column = React.memo(function Column({ column, index, stage, totalLength, cardAction, stages }) {
  const isDragDisabled = index === 0 || index === totalLength - 1;
  const isClosedStage = stage?.lead_stage_name?.toLowerCase() === 'closed';

  return (
    <Draggable draggableId={index + 'IDD'} index={index} isDragDisabled={isDragDisabled}>
      {(provided) => (
        <div className="column" {...provided.draggableProps} ref={provided.innerRef}>
          <StageHeader data={column} stage={stage} {...provided.dragHandleProps} />
          <ItemList column={column} index={index} cardAction={cardAction} isClosedStage={isClosedStage} stages={stages} />
        </div>
      )}
    </Draggable>
  );
});
