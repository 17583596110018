import React, { useContext } from 'react';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';

import { UserDetailsContext } from 'context/userDetailsContext';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';

import CustomHeader from '../CustomHeader';
import TitleTemplate from './TitleTemplate';
import UploadedTemplate from './UploadedTemplate';
import ActionsTemplate from './ActionsTemplate';
// import ShareTemplate from './ShareTemplate';
// import LastEditedTemplate from './LastEditedTemplate';

function FileVersionsModal() {
  const { modals, selectedItem } = useFileManagerContext();
  const { showUploadedVersionsModal, setShowUploadedVersionsModal } = modals;

  const userContext = useContext(UserDetailsContext);

  return (
    <Dialog
      header={<CustomHeader title="File Versions" onHide={() => setShowUploadedVersionsModal(false)} />}
      visible={showUploadedVersionsModal}
      style={{ width: '50%' }}
      onHide={() => setShowUploadedVersionsModal(false)}
      className="rounded casefile-wrap datatable-responsive"
      closable={false}
      draggable={false}
    >
      <DataTable
        value={selectedItem?.versions?.length ? selectedItem?.versions : []}
        responsiveLayout="scroll"
        className="p-datatable-responsive"
        sortField="version_uploaded" //default sort field for table
        sortOrder={-1} // -1 for descending and 1 for ascending order
      >
        <Column
          className="col-lg-45"
          field="display_name"
          header="Title"
          body={(rowData) => <TitleTemplate rowData={rowData} />}
          sortable
        />
        {/* <Column field="sharing" header="Sharing" body={(rowData) => <ShareTemplate rowData={rowData} />} /> */}

        <Column
          field="version_uploaded"
          header="Date Uploaded"
          body={(rowData) => <UploadedTemplate userContext={userContext} rowData={rowData} />}
          sortable
        />
        {/* <Column
          field="version_edited"
          header="Last Edited"
          body={(rowData) => <LastEditedTemplate userContext={userContext} rowData={rowData} />}
          sortable
        /> */}

        <Column body={(rowData, e) => <ActionsTemplate rowData={rowData} e={e} />} header="Actions" exportable={false} />
      </DataTable>
    </Dialog>
  );
}

export default FileVersionsModal;
