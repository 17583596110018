import { getOfficeLocations } from "services/Settings";
import { actionTypes } from "constants/types";
export const getOfficeLocation = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_OFFICE_LOCATIONS_REQUEST });
    return getOfficeLocations()
      .then((response) => {
        dispatch({
          type: actionTypes.GET_OFFICE_LOCATIONS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_OFFICE_LOCATIONS_ERROR,
          payload: error.data,
        });
      });
  };
};
