import printJS from 'print-js';

import { presignedURLGet } from 'modules/file_manager/services';
import { deleteFile, downloadAttachments } from 'services/calendarServices';
import { Storage, Auth } from 'aws-amplify';
import { FILEUPLOAD_S3, FILEUPLOAD_S3_LEVEL, SetS3Config } from 'config/amplify_config';
import Cookies from 'universal-cookie';
import { uuidv4 } from 'common/uuidGenerator';
const cookie = new Cookies();

// to print pdf
export const pdfTronPrintFile = async (file) => {
  let extension = file.display_name.split('.').slice(-1)[0].toLowerCase(); //it may be case sensitive sometimes so used the lowercase
  let response = await presignedURLGet(file.file_path, file.version_id);
  if (extension === 'pdf') {
    fetch(response.data?.file_url)
      .then((res) => res.blob()) // Gets the response and returns it as a blob
      .then((blob) => {
        //optionally save the blob to a file or upload to a server
        //  const data = new Blob([blob], { type: "application/pdf" });
        printJS(URL.createObjectURL(blob));
      });
  } else {
    window?.Core?.setWorkerPath('webviewer/core');
    await window?.PDFNet?.initialize();
    const options = {
      // l: licenseKey,
      extension,
    };

    // perform the conversion with no optional parameters
    const buf = await window?.Core?.officeToPDFBuffer(response.data?.file_url, options);
    const blob = new Blob([buf], { type: 'application/pdf' });
    printJS(URL.createObjectURL(blob));
  }
};

// to download a file
export const downloadFile = async (file) => {
  downloadAttachments(file)
    .then((data) => {
      let link = document.createElement('a');
      link.download = file.display_name;
      let url = window.URL.createObjectURL(data.Body);
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((err) => {
      console.log('error-downloading-attachment', err);
    });
};

/** --- Function to delete attachment from S3 bucket --- **/
export const deleteAttachment = async (file) => {
  deleteFile(file)
    .then((response) => {})
    .catch((err) => {
      console.log('error-deleting-attachment', err);
    });
};

// Assuming you have the necessary imports for Auth and Storage

export async function uploadFiles(filesObj, module, case_id = '', userId = '', progressCallBack) {
  let attachments = [];
  const tenantId = (await cookie.get('tenantId')) || '';

  const files = Object.values(filesObj); // Extract the files from the object

  if (!files || files.length === 0) {
    return attachments;
  }

  try {
    const user = await Auth.currentAuthenticatedUser();
    // TODO:multi tenancy change
    // const userId = user.attributes.sub; //In this userId is equal to the cognito_username . For multi-tenancy we need to replace cognito_username with either firm_use_id or contact_id
    const caseId = case_id ? `/${case_id}` : ''; // Include case_id if provided, otherwise an empty string
    SetS3Config(FILEUPLOAD_S3, FILEUPLOAD_S3_LEVEL);

    const uploadPromises = files.map(async (file) => {
      try {
        console.log(file.type, 'FILE TYPEEEEEE');
        //TODO:multi-tenancy change
        // const response = await Storage.put(`${module}${caseId}/${userId}/${file.name}`, file, {
        const response = await Storage.put(`${module}/${tenantId}/${userId}${caseId}/${uuidv4()}#$#${file?.name}`, file, {
          contentType: file.type,
          progressCallback: (progress) => {
            const { loaded, total } = progress;
            let percent = Math.floor((loaded * 100) / total);
            if (percent <= 100) {
              if (progressCallBack) {
                progressCallBack(percent);
              }
            }
          },
          useAccelerateEndpoint: true,
        });

        if (response.key) {
          let file_type = file?.type?.toString();

          let file_details = {
            file_name: file?.name,
            display_name: file?.name,
            size: file?.size?.toString(),
            file_path: 'public/' + response.key,
            file_type: file_type,
            created_by: user?.attributes,
            isFileUploaded: true,
          };

          attachments.push(file_details);
        }
      } catch (error) {
        // If any error occurs during file upload, you may choose to handle it here
        console.error('Error uploading file:', error);
        throw new Error(`Failed to upload ${file?.name}: ${error?.message}`);
      }
    });

    await Promise.all(uploadPromises);
    return attachments;
  } catch (error) {
    // If any error occurs during user authentication or other parts of the function, you may handle it here
    console.error('Error:', error);
    throw new Error('File upload process failed: ' + error.message);
  }
}

export function getFileType(type) {
  switch (type.toLowerCase()) {
    case 'pdf':
      return 'pdf';
    case 'docx':
    case 'doc':
    case 'rtf':
    case 'ods':
      return 'doc';
    case 'png':
    case 'bmp':
    case 'tif':
    case 'gif':
    case 'webp':
    case 'jpg':
    case 'jpeg':
      return 'image';
    case 'mp4':
    case 'mpg':
    case '3gp':
    case 'wmv':
    case 'avi':
    case 'mkv':
    case 'mov':
    case 'ogg':
    case 'wav':
    case 'mp3':
    case 'wma':
    case 'aac':
      return 'video';
    case 'txt':
      return 'text';
    case 'xlsx':
    case 'xls':
    case 'csv':
      return 'xls';
    default:
      return 'folder';
  }
}

export function getFileExtension(fileName) {
  if (fileName) {
    const lastIndex = fileName?.lastIndexOf('.');
    if (lastIndex === -1) {
      return '';
    } else {
      return fileName.substring(lastIndex + 1);
    }
  }
  return '';
}

export const loadIcon = (format) => {
  switch (format?.toLowerCase()) {
    case 'pdf':
      return <i className="fas fa-file-pdf"></i>;
    case 'docx':
    case 'doc':
    case 'rtf':
    case 'ods':
      return <i className="fas fa-file"></i>;
    case 'xlsx':
    case 'csv':
    case 'xls':
      return <i className="fas fa-file-excel"></i>;
    case 'png':
    case 'jpg':
    case 'jpeg':
      return <i className="fas fa-file-image"></i>;
    case 'mp4':
    case 'avi':
    case 'mkv':
    case 'mov':
    case 'ogg':
    case 'mpg':
    case '3gp':
    case 'wmv':
      return <i className="fas fa-file-video"></i>;
    case 'wav':
    case 'mp3':
    case 'wma':
    case 'aac':
      return <i className="fas fa-file-audio"></i>;
    case 'txt':
      return <i className="fas fa-file-alt"></i>;
    default:
      return <i className="fas fa-folder"></i>;
  }
};
