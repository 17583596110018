import { actionTypes } from "constants/types";

let initialState = {
  activeTimerHours: 0.1,
};

const { GET_ACTIVE_TIMER_HOURS } = actionTypes;

const TimerReducer = (state = initialState, action) => {
  switch (action?.type) {
    case GET_ACTIVE_TIMER_HOURS:
      return {
        activeTimerHours: action?.payload,
      };
    default:
      return state;
  }
};

export default TimerReducer;
