import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { Dropdown } from 'primereact/dropdown';
import { createContact } from 'services/contact';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import _ from 'lodash';
import moment from 'moment';
import { getCustomFieldList } from 'redux/actions/CustomFieldAction';
import { useSelector, useDispatch } from 'react-redux';
import { SelectButton } from 'primereact/selectbutton';
import { stateList, countriesList } from 'constants/stateList';
import { Checkbox } from 'primereact/checkbox';
import { getRelationRolesAction } from 'redux/actions/RelationRoleAction';
import NumberFormat from 'react-number-format';
import { totalCountEvent } from 'services/generalServices';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

export const AddContact = (props) => {
  const customFieldData = useSelector((state) => state.customField);

  //const handleClose = () => props.setShow(false);
  const [radioValue, setRadioValue] = useState('person');
  const [phoneMatch, setPhoneMatch] = useState();
  const [dob, setDob] = useState();
  const [contactFields, setContactFields] = useState([]);
  const [contactFieldsValue, setContactFieldsValue] = useState([]);
  const [addCustomField, setAddCustomField] = useState(false);
  const [isCourtLocation, setIsCourtLocation] = useState(false);
  const [enableLawftAccess, setEnableLawftAccess] = useState(false);
  const formRef = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRelationRolesAction());
  }, []);
  const roleList = useSelector((state) => state.roleList.roleList);

  let [initialValues, setInitialValues] = useState({
    first_name: '',
    last_name: '',
    middle_name: '',
    email: '',
    phone: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    title: '',
    role: '',
  });
  const radios = [
    { name: 'Person', value: 'person' },
    { name: 'Business', value: 'business' },
  ];
  const types = [
    { label: 'Person', value: 'person' },
    { label: 'Business', value: 'business' },
    { label: 'Location', value: 'location' },
  ];
  const titles = [
    { label: 'Mr.', value: 'Mr.' },
    { label: 'Ms.', value: 'Ms.' },
    { label: 'Mrs.', value: 'Mrs.' },
    { label: 'Miss.', value: 'Miss.' },
  ];

  const Roles = [
    { label: 'Client', value: 'client' },
    { label: 'Spouse', value: 'spouse' },
    { label: 'Child', value: 'child' },
    { label: 'Parent', value: 'parent' },
    { label: 'Witness', value: 'witness' },
    { label: 'Party', value: 'party' },
    { label: 'Prosecutor', value: 'prosecutor' },
  ];
  const fields = [
    { label: 'Email', value: 'email' },
    { label: 'Phone', value: 'phone' },
    /* { label: "Group", value: "group" }, */
  ];
  const countries = countriesList.map((index) => {
    return {
      label: index.name,
      value: index.name,
    };
  });
  let [states, setStates] = useState([]);

  const [selectedState, setSelectedState] = useState();
  const [selectedCountry, setSelectedCountry] = useState('United States');
  const [title, setTitle] = useState(titles[0].value);
  const [relations, setRelations] = useState([]);
  const personSchema = Yup.object().shape({
    first_name: Yup.string()
      .max(50, 'Maximum 50 characters required')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed')
      .required('Required'),
    middle_name: Yup.string()
      .max(50, 'Maximum 50 characters required')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed'),
    last_name: Yup.string()
      .max(50, 'Maximum 50 characters required')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed')
      .required('Required'),
    email: Yup.string().email('Invalid email'),
    role: Yup.string().required('Required'),
    phone: Yup.string()
      // .matches(
      //   /^(?!000-000-0000)[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}-[0-9]{4}$/i,
      //   "Invalid phone number"
      // )
      .min(10, 'Invalid phone number')
      .max(14, 'Invalid phone number')
      .required('Required'),
  });
  const businessSchema = Yup.object().shape({
    title: Yup.string().min(2, 'Minimum 2 characters required').required('Required'),
    email: Yup.string().email('Invalid email'),
    role: Yup.string().required('Required'),
    phone: Yup.string()
      // .matches(
      //   /^(?!000-000-0000)[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}-[0-9]{4}$/i,
      //   "Invalid phone number"
      // )
      .min(10, 'Invalid phone number')
      .max(14, 'Invalid phone number')
      .required('Required'),
  });

  const [contactSchema, setContactSchema] = useState(personSchema);
  const { addToast } = useToast();
  const [show, setShow] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [telephone, setTelephone] = useState('');
  const overlayRef = useRef(null);
  const [phoneMatchLoading, setPhoneMatchLoading] = useState(false);
  useEffect(() => {
    // dispatch(getCustomFieldList());
    totalCountEvent('custom-field').then((response) => {
      dispatch(getCustomFieldList('', response.data));
    });
  }, []);

  useEffect(() => {
    if (customFieldData && customFieldData.customFieldList && customFieldData.customFieldList.length) {
      let data = customFieldData.customFieldList.filter((val) => val.module === 'contact');
      setContactFields(data);
    } else {
      setContactFields([]);
    }
  }, [customFieldData]);

  useEffect(() => {
    if (!addCustomField) {
      setContactFieldsValue([]);
    }
  }, [addCustomField]);

  useEffect(() => {
    setInitialValues({
      first_name: '',
      last_name: '',
      middle_name: '',
      email: '',
      title: '',
      role: '',
      phone: '',
    });
    setPhoneMatch();
    setRelations([]);
    setDob();
    setTelephone();
    setRadioValue('person');
    if (!props.contactDetails) {
      onChangeCounty({ target: { value: 'United States' } });
    }

    setSelectedState();
    setIsCourtLocation(false);
  }, [props.show]);

  const onAddContact = async (values, { resetForm }) => {
    values.contact_type = radioValue;
    values.custom_fields = contactFieldsValue;
    /*  values.country = selectedCountry ? selectedCountry : "";
      values.state = selectedState ? selectedState.value : ""; */
    values.is_create_client_portal = enableLawftAccess;
    values.relations = [];
    // values.phone = "";
    values.country = 'United States';
    values.country_code = '+1';
    if (radioValue === 'person') {
      if (dob) values.dob = moment(dob).format('MM/DD/yyyy');
      values.title = title;
    } else {
      values.first_name = '';
      values.middle_name = '';
      values.last_name = '';
    }
    if (radioValue === 'location') {
      values.is_court_location = isCourtLocation;
    }

    let msg = props.isEdit ? toastConstant.message.CONTACT_UPDATE_SUCCESS : toastConstant.message.CONTACT_CREATE_SUCCESS;
    let error = props.isEdit ? toastConstant.message.CONTACT_UPDATE_FAILED : toastConstant.message.CONTACT_CREATE_FAILED;
    try {
      let response = '';

      if (radioValue === 'person') {
        values.relations = [];
        relations.forEach((index) => {
          let obj = {
            name: index.name,
            role: index.role,
            sk: index?.sk,
            pk: index?.pk,
          };
          if (index.fields && index.fields.length > 0) {
            index.fields.forEach((field) => {
              obj[field] = index[field];
            });
          }
          values.relations.push(obj);
        });
      }
      if (props.newCaseContactRelation && props.caseId && props.clientId) {
        values.case_id = props.caseId;
        values.client_id = props.clientId;
        values.role = 'witness';
      }
      response = await createContact(values, props.isLeadSpecific);

      if (response.data) {
        delete response.data.message;
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, msg);
        let data = {
          ...response.data,
          ...values,
        };
        props?.onSubmit(data);
      }
    } catch (err) {
      let errorMsg = err?.response?.data;
      error = errorMsg ? errorMsg : error;
      addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, error);
    }
  };

  const setContactDetails = (e) => {
    initialValues[e.target.name] = e.target.value;
    setInitialValues({ ...initialValues });
  };

  const handleCustomFieldChange = (e) => {
    try {
      let prevdata = [...contactFieldsValue];
      let indexVal = prevdata.findIndex((val) => {
        return Object.keys(val)[0] === e.target.name;
      });

      if (indexVal === -1) {
        setContactFieldsValue([...contactFieldsValue, { [e.target.name]: e.target.value }]);
      } else {
        let dat = [...contactFieldsValue];
        dat[indexVal][e.target.name] = e.target.value;
        setContactFieldsValue(dat);
      }
    } catch (e) {}
  };

  const onChangeCounty = (e) => {
    setSelectedCountry(e.target.value);
    if (e.target.value === 'United States') {
      let state_list = stateList.map((index) => {
        return {
          label: index.abbreviation + ' - ' + index.name,
          value: index.abbreviation + ' - ' + index.name,
        };
      });

      setStates([...state_list]);
    } else {
      setStates([]);
      setSelectedState();
    }
  };

  const submitContact = () => {
    formRef.current?.submitForm();
  };

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onAddContact} validationSchema={contactSchema} innerRef={formRef}>
      {({ errors, touched, resetForm }) => (
        <>
          <Form>
            <div className="p-2">
              <div className="row d-flex align-items-center py-2">
                <div className="col-12 col-sm-4 p-medium">Contact Type</div>
                <div className="col-12 col-sm-8 d-flex select-btn">
                  {!props.isEdit && (
                    <SelectButton
                      value={radioValue}
                      options={radios}
                      onChange={(e) => {
                        setRadioValue(e.value);

                        resetForm({});
                        setInitialValues({
                          first_name: '',
                          last_name: '',
                          middle_name: '',
                          email: '',
                          phone: '',
                          street: '',
                          city: '',
                          state: '',
                          zip: '',
                          country: '',
                          title: '',
                          education: '',
                          role: '',
                        });
                        if (e.value === 'person') {
                          setContactSchema(personSchema);
                        } else {
                          setContactSchema(businessSchema);
                        }
                      }}
                      optionLabel="name"
                      unselectable={false}
                    />
                  )}
                  <div className="mt-2">
                    {props.isEdit && (
                      <Dropdown
                        value={radioValue}
                        options={types}
                        onChange={(e) => {
                          e.stopPropagation();
                          setTitle(e.value);
                        }}
                        disabled={true}
                        placeholder="Title"
                        className=""
                        filter
                        //showClear={(radioValue ==="" )? false : true}
                      />
                    )}
                  </div>
                </div>
              </div>
              {radioValue === 'person' && (
                <>
                  <div className="row d-flex align-items-center py-2">
                    <div className="col-12 col-sm-4 p-medium">Title</div>
                    <div className="col-12 col-sm-8 ">
                      <Dropdown
                        value={title}
                        options={titles}
                        onChange={(e) => {
                          setTitle(e.value);
                        }}
                        placeholder="Title"
                        className="w-100 input-height"
                        filter
                        //showClear={(title ==="" )? false : true}
                      />
                    </div>
                  </div>
                  <div className="row d-flex align-items-center py-2">
                    <div className="col-12 col-sm-4 p-medium">
                      First Name<span style={{ color: 'red' }}>*</span>
                    </div>
                    <div className="col-12 col-sm-8">
                      <Field
                        id="first_name"
                        name="first_name"
                        placeholder="First Name"
                        value={initialValues.first_name}
                        className="border-0 w-100 form-control input-shadow input-height F-size14"
                        onChange={setContactDetails}
                      />
                      {errors.first_name && touched.first_name ? <div className="danger-text">{errors.first_name}</div> : null}
                    </div>
                  </div>
                  <div className="row d-flex align-items-center py-2">
                    <div className="col-12 col-sm-4 p-medium">Middle Name</div>
                    <div className="col-12 col-sm-8">
                      <Field
                        id="middle_name"
                        name="middle_name"
                        placeholder="Middle Name"
                        className="border-0 w-100 form-control input-shadow input-height F-size14"
                        value={initialValues.middle_name}
                        onChange={setContactDetails}
                      />
                      {errors.middle_name && touched.middle_name ? <div className="danger-text">{errors.middle_name}</div> : null}
                    </div>
                  </div>

                  <div className="row d-flex align-items-center py-2">
                    <div className="col-12 col-sm-4 p-medium">
                      Last Name<span style={{ color: 'red' }}>*</span>
                    </div>
                    <div className="col-12 col-sm-8">
                      <Field
                        id="last_name"
                        name="last_name"
                        placeholder="Last Name"
                        className="border-0 input-shadow w-100 form-control input-height F-size14"
                        value={initialValues.last_name}
                        onChange={setContactDetails}
                      />
                      {errors.last_name && touched.last_name ? <div className="danger-text">{errors.last_name}</div> : null}
                    </div>
                  </div>

                  {/* <div className="row d-flex align-items-center py-2"> 
                    <div className="col-12 col-sm-4 p-medium">
                      Role<span style={{ color: "red" }}>*</span>
                    </div>
                    <div className="col-12 col-sm-8">
                      <Dropdown
                        id="role"
                        name="role"
                        placeholder="Select"
                        // className="border rounded w-100 form-control"
                        className="border rounded w-100"
                        value={initialValues.role}
                        options={Roles}
                        onChange={setContactDetails}
                      />
                      {errors.role && touched.role ? (
                        <div className="danger-text">{errors.role}</div>
                      ) : null}
                    </div>
                  </div> */}
                </>
              )}

              {radioValue !== 'person' && (
                <div className="row d-flex align-items-center py-2">
                  <div className="col-12 col-sm-4 p-medium">
                    Title<span style={{ color: 'red' }}>*</span>
                  </div>
                  <div className="col-12 col-sm-8">
                    <Field
                      id="title"
                      name="title"
                      placeholder="Title"
                      className="border-0 input-shadow w-100 form-control"
                      onChange={setContactDetails}
                      value={initialValues.title}
                    />
                    {errors.title && touched.title ? <div className="danger-text">{errors.title}</div> : null}
                  </div>
                </div>
              )}

              <div className="row d-flex align-items-center py-2">
                <div className="col-12 col-sm-4 p-medium">
                  Email
                  {radioValue === 'person' && enableLawftAccess && <span style={{ color: 'red' }}>*</span>}
                </div>
                <div className="col-12 col-sm-8">
                  <Field
                    id="email"
                    name="email"
                    placeholder="Email"
                    className="border-0 input-shadow w-100 form-control input-height F-size14"
                    onChange={setContactDetails}
                    value={initialValues.email}
                  />
                  {errors.email && touched.email ? <div className="danger-text">{errors.email}</div> : null}
                  {initialValues.email && (
                    <div className="mt-2 d-flex align-items-center">
                      <Checkbox
                        checked={enableLawftAccess}
                        onChange={(e) => {
                          setEnableLawftAccess(e.checked);
                        }}
                      />{' '}
                      <span className="ms-2"> Enable Lawft Access</span>
                    </div>
                  )}
                </div>
              </div>

              <div className="row d-flex align-items-center py-2">
                <div className="col-12 col-sm-4 p-medium">
                  Phone Number
                  <span className="text-danger">*</span>
                </div>
                <div className="col-12 col-sm-8">
                  <NumberFormat
                    className="border-0 input-shadow w-100 form-control number-format input-height"
                    placeholder="Phone"
                    format="(###) ###-####"
                    mask="_"
                    onValueChange={(e) => {
                      Object.defineProperty(e, 'target', {
                        value: {
                          name: 'phone',
                          value: e.value,
                        },
                      });

                      setContactDetails(e);
                    }}
                    name="phone"
                    value={initialValues?.phone}
                  />
                  {errors.phone && touched.phone ? <div className="text-danger">{errors.phone}</div> : null}
                </div>
              </div>

              <div className="row d-flex align-items-center py-2">
                <div className="col-12 col-sm-4 p-medium">
                  Role <span className="text-danger">*</span>
                </div>
                <div className="col-12 col-sm-8">
                  <Dropdown
                    value={initialValues.role}
                    onChange={setContactDetails}
                    name="role"
                    options={radioValue === 'business' ? props.businessRoleList : roleList}
                    optionLabel={radioValue === 'business' ? 'name' : 'relation_role'}
                    optionValue={radioValue === 'business' ? 'name' : 'relation_role'}
                    placeholder="Role"
                    className="border-0 input-shadow w-100 input-height"
                    filter
                    //showClear={(initialValues.role ==="" )? false : true}
                  />
                  {errors.role && touched.role ? <div className="danger-text">{errors.role}</div> : null}
                </div>
              </div>
              <div className="row d-flex align-items-center py-2">
                <div className="col-12 col-sm-4 p-medium">Primary Contact</div>
                <div className="col-12 col-sm-8">
                  <Checkbox
                    name="is_primary_contact"
                    checked={initialValues?.is_primary_contact}
                    onChange={(e) => {
                      Object.defineProperty(e, 'target', {
                        value: {
                          name: 'is_primary_contact',
                          value: e.checked,
                        },
                      });
                      setContactDetails(e);
                    }}
                    className="border-0 input-shadow w-100"
                  />
                </div>
              </div>

              <div className="row d-flex align-items-center py-2">
                <div className="col-12 col-sm-4 p-medium">Billable Contact</div>
                <div className="col-12 col-sm-8">
                  <Checkbox
                    name="is_primary_contact"
                    checked={initialValues?.is_billing_contact}
                    onChange={(e) => {
                      Object.defineProperty(e, 'target', {
                        value: {
                          name: 'is_billing_contact',
                          value: e.checked,
                        },
                      });
                      setContactDetails(e);
                    }}
                    className="border-0 input-shadow w-100"
                  />
                </div>
              </div>
            </div>

            {(!props.isEdit || contactFieldsValue.length === 0) && (
              <div className="row d-flex align-items-center p-2">
                <div className="col-12 col-sm-7">
                  <span
                    className="addfield"
                    onClick={(e) => {
                      e.preventDefault();
                      setAddCustomField(!addCustomField);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <i className="icon-add"></i> <span>Add Custom Fields</span>
                  </span>
                </div>
              </div>
            )}

            {addCustomField && contactFields && contactFields.length
              ? contactFields.map((val, index) => (
                  <div className="">
                    <div className="d-flex align-items-center p-2">
                      <div className="col-12 col-sm-4 p-medium">{val.name}</div>
                      <div className="col-12 col-sm-8">
                        {val.type === 'Text(short)' && (
                          <TextSnippetWrapper
                            type="text"
                            className="w-100 input-shadow p-2"
                            placeholder={val.name}
                            name={val.name}
                            onChange={(e) => handleCustomFieldChange(e)}
                            value={
                              contactFieldsValue.filter((index) => index.hasOwnProperty(val.name)).length
                                ? contactFieldsValue.filter((index) => index.hasOwnProperty(val.name))[0][val.name]
                                : ''
                            }
                          />
                        )}

                        {val.type === 'Text(long)' && (
                          <TextSnippetWrapper
                            type="textarea"
                            className="w-100 input-shadow p-2"
                            rows={5}
                            cols={30}
                            name={val.name}
                            onChange={(e) => handleCustomFieldChange(e)}
                            placeholder={val.name}
                            value={
                              contactFieldsValue.filter((index) => index.hasOwnProperty(val.name)).length
                                ? contactFieldsValue.filter((index) => index.hasOwnProperty(val.name))[0][val.name]
                                : ''
                            }
                          />
                        )}

                        {val.type === 'Number' && (
                          <input
                            className="w-100 rounded border p-2"
                            name={val.name}
                            onChange={(e) => handleCustomFieldChange(e)}
                            type="number"
                            placeholder={val.name}
                            value={
                              contactFieldsValue.filter((index) => index.hasOwnProperty(val.name)).length
                                ? contactFieldsValue.filter((index) => index.hasOwnProperty(val.name))[0][val.name]
                                : ''
                            }
                          />
                        )}

                        {val.type === 'Currency' && (
                          <input
                            className="w-100 rounded border p-2"
                            name={val.name}
                            onChange={(e) => handleCustomFieldChange(e)}
                            type="number"
                            placeholder={val.name}
                            value={
                              contactFieldsValue.filter((index) => index.hasOwnProperty(val.name)).length
                                ? contactFieldsValue.filter((index) => index.hasOwnProperty(val.name))[0][val.name]
                                : ''
                            }
                          />
                        )}

                        {val.type === 'Date' && (
                          <input
                            className="w-100 rounded border p-2"
                            name={val.name}
                            onChange={(e) => handleCustomFieldChange(e)}
                            type="date"
                            placeholder={val.name}
                            value={
                              contactFieldsValue.filter((index) => index.hasOwnProperty(val.name)).length
                                ? contactFieldsValue.filter((index) => index.hasOwnProperty(val.name))[0][val.name]
                                : ''
                            }
                          />
                        )}

                        {val.type === 'List' && (
                          <TextSnippetWrapper
                            type="text"
                            className="w-100 rounded border p-2"
                            placeholder={val.name}
                            name={val.name}
                            onChange={(e) => handleCustomFieldChange(e)}
                            value={
                              contactFieldsValue.filter((index) => index.hasOwnProperty(val.name)).length
                                ? contactFieldsValue.filter((index) => index.hasOwnProperty(val.name))[0][val.name]
                                : ''
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ))
              : null}
            {/* <div className="row d-flex p-2">
              <div className="col-12"> */}
            <div className="d-flex p-2">
              <div className="me-3">
                <Button
                  type="submit"
                  className="p-button p-button-secondary outline"
                  onClick={(e) => {
                    props.hideAddContactPopup(false);
                    props.setEmptySuggestions({});
                  }}
                >
                  <span className="p-button-label p-c">Cancel</span>
                </Button>
              </div>
              {/* <div className="col-12"> */}
              <div className="">
                <Button
                  type="submit"
                  className="p-button p-button-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    submitContact();
                  }}
                >
                  {' '}
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};
