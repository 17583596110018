import { useState } from 'react';
import { useDebounce } from 'hooks/useDebounce';

export function useCaseListFilter(defaultFilter) {
  const [filter, setFilter] = useState(defaultFilter);
  const filterDebounced = useDebounce(filter, 400);

  const filterOnChange = (e) => {
    setFilter((pre) => ({ ...pre, [e.target.name]: e.target.value || null }));
  };
  const applyFilter = (filterParams) => {
    setFilter((pre) => ({ ...pre, ...filterParams }));
  };

  const refreshList = (filter = {}) => setFilter((pre) => ({ reload: !pre.reload, ...filter }));

  return { filterDebounced, filter, filterOnChange, applyFilter, refreshList };
}
