import { useEffect, useState } from 'react';
import { listBusinessRole } from 'services/Settings';
import { totalCountEvent } from 'services/generalServices';

export function useBusinessRole() {
  const [List, setList] = useState([]);

  const loadBusinessRoles = () => {
    totalCountEvent('business_role').then((response) => {
      listBusinessRole(response.data).then((res) => {
        setList(res.data?.business_role);
      });
    });
  };

  useEffect(() => {
    loadBusinessRoles();
  }, []);

  return { businessRoleList: List };
}
