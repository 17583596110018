import { performRequest, performRequestThird } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';
import { getTenantDetails } from 'utils/utils';

export const listContacts = (obj) => {
  const {
    contactType,
    contactStatus = 'ACTIVE',
    keyWord = '',
    is_court_location = '',
    is_event_location = '',
    pageLimit = 10,
    pagination_token = '',
    pagination_direction = 'forward',
    in_lead = false,
  } = obj;

  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.contact.list}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&contact_type=${contactType}&contact_status=${contactStatus}&keyword=${keyWord}&is_court_location=${is_court_location}&is_event_location=${is_event_location}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}&in_lead=${in_lead}`;
  return performRequest(apiEndPoints.methodType.GET, url);
};

export const emailDuplicateCheck = (email) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.contact.emailValidation}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&email=${encodeURIComponent(email)}`;
  return performRequestThird(apiEndPoints.methodType.GET, url, null, '', true, true);
};
