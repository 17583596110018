import React, { useEffect } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { useForm, Controller } from 'react-hook-form';

import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import { generateFullName } from 'utils/generateFullNameUtils';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import { getFileClients, shareFile } from 'services/fileManager/fileManagerServices';
import { ModalHeader } from 'shared/ModalHeader';
import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';

function ShareFileModal() {
  const { modals, isLeadFileManager, isCaseFileManager, selectedItem, caseDetails, selectedRows, setSelectedRows, setFileManagerFilters } =
    useFileManagerContext();
  const { showShareFileModal, setShowShareFileModal } = modals;
  const { addToast } = useToast();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [clientOptions, setClientOptions] = React.useState([]);
  const [sharedContacts, setSharedContacts] = React.useState([]);

  useEffect(() => {
    let caseId = selectedRows?.[0]?.case_id ? selectedRows?.[0]?.case_id : '';
    fetchClients(caseId);
  }, [selectedRows]);

  useEffect(() => {
    if (selectedRows && selectedRows.length > 0 && clientOptions.length > 0) {
      const defaultAssigneeList = selectedRows.reduce((acc, row) => {
        if (row.file_shared_contact_id) {
          row.file_shared_contact_id.forEach((fileContactId) => {
            const foundClient = clientOptions.find((client) => client.contact_id === fileContactId);
            if (foundClient) {
              acc.push({
                ...foundClient,
                display_name: row.display_name, // Ensure display_name is added here
              });
            }
          });
        }
        return acc;
      }, []);
      setSharedContacts(defaultAssigneeList);
    }
  }, [clientOptions, selectedRows]);

  const fetchClients = (caseId = '') => {
    let obj = {
      case_id: caseId,
    };
    getFileClients(obj)
      .then((response) => {
        if (response && response.data && response.data.length) {
          let data = [];
          response.data.forEach((element) => {
            let label = generateFullName(element);
            data.push({
              label: label,
              value: element?.client_cognito_username ? element?.client_cognito_username : element?.contact_id,
              email: element?.email,
              is_create_client_portal: element?.is_create_client_portal,
              contact_id: element?.contact_id ? element?.contact_id : '',
              file_name: element?.display_name ? element?.display_name : '',
            });
          });
          setClientOptions([...data]);
        } else {
          setClientOptions([]);
        }
      })
      .catch(() => {
        setClientOptions([]);
      });
  };

  const shareFileToClient = (data) => {
    if (selectedRows?.length > 0) {
      let sendData = {
        file_list: selectedRows?.map((row) => ({ file_pk: row.pk, file_sk: row.sk })),
      };
      sendData.client_cognito_username = data.assignee;
      let obj = {
        case_id: selectedRows?.[0]?.case_id,
        data: sendData,
        is_lead: isLeadFileManager,
      };
      shareFile(obj)
        .then(() => {
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.FILES_SHARED);
          setShowShareFileModal(false);
          setSelectedRows([]);
          setFileManagerFilters((prev) => ({ ...prev, refresh: true }));
        })
        .catch((error) => {
          console.log(error);
          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.ERROR, toastConstant.message.FILE_SHARED_FAILED);
        });
    }
  };

  function checkOptionDisabled(index) {
    if (index.email === '' || !index.is_create_client_portal) {
      return true;
    }
    return false;
  }

  function ClientsOptionTemplate(e) {
    return (
      <div>
        {e.label} {!e.email ? '( Email address not found )' : !e.is_create_client_portal ? '( Lawft Access Disabled )' : ''}
      </div>
    );
  }

  const customFooter = () => {
    return (
      <div className="footer-btn">
        <Button label="Cancel" onClick={() => setShowShareFileModal(false)} className="p-button p-button-secondary me-3 outline" />
        <Button label="Share" className="p-button p-button-secondary" onClick={handleSubmit(shareFileToClient)} autoFocus />
      </div>
    );
  };

  return (
    <Dialog
      className="new-task-popup"
      onHide={() => setShowShareFileModal(false)}
      header={ModalHeader}
      footer={customFooter}
      title="Share File"
      visible={showShareFileModal}
      style={{ width: '40vw' }}
    >
      {/* <div className="mb-3 p-medium">
        <span>File Names : {selectedRows?.map((v) => v.display_name)?.join(', ')}</span>
      </div> */}
      <div className="d-flex align-items-center flex-wrap">
        <div className="col-lg-4 col-12 p-medium">Select Contact (s)</div>
        <div className="col-lg-8 col-12 select-tag-input">
          <Controller
            name="assignee"
            control={control}
            defaultValue={[]}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <MultiSelect
                  options={clientOptions}
                  onChange={(e) => field.onChange(e.value)}
                  placeholder="Select Contact (s)"
                  className="input-shadow w-100 F-size14"
                  filter
                  id={field.name}
                  value={field.value}
                  optionLabel="label"
                  optionValue="value"
                  display="chip"
                  emptyFilterMessage="No records found"
                  optionDisabled={(index) => checkOptionDisabled(index)}
                  itemTemplate={ClientsOptionTemplate}
                />
              </>
            )}
          />
          {errors?.assignee && <InputErrorMessage>Required</InputErrorMessage>}
        </div>
        <div className="mt-3">
          {selectedRows?.map((file, index) => (
            <div key={index}>
              <div className="p-medium">
                <span>File Name: {file.display_name}</span>
              </div>
              <div className="col-12 mb-3 mt-2">
                {sharedContacts
                  .filter((contact) => contact.display_name === file.display_name)
                  .map((assignee, idx) => (
                    <span key={idx} className="badge bg-secondary mx-1">
                      {assignee.label}
                    </span>
                  ))}
                {sharedContacts.filter((contact) => contact.display_name === file.display_name).length === 0 && (
                  <div className="d-flex align-items-center">
                    <i className="icon icon-Alert me-2 cursor-pointer F-size16"></i>
                    <span className="text-muted">File not shared</span>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Dialog>
  );
}

export default ShareFileModal;
