import React, { useState } from 'react';

import { Controller } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';

import AddEventTypeForm from './AddEventTypeForm';
import LabelName from 'components/UI/LabelName/LabelName';
import { Tooltip } from 'primereact/tooltip';
import { useCalendarContext } from 'modules/calendar/Context/CalendarContext';

function EventType({ control, setValue, resetField }) {
  const { typeList } = useCalendarContext();
  const [addEventType, setAddEventType] = useState(false);

  const eventTypeOptionTemplate = (item) => {
    return (
      <div className="d-flex align-items-center">
        {item.event_type_icon && (
          <span className="mx-2">
            <i className={item.event_type_icon} />
          </span>
        )}{' '}
        {item.event_type}
      </div>
    );
  };

  const eventTypeValueTemplate = (item, props) => {
    if (item) {
      return (
        <div className="d-flex align-items-center">
          {item.event_type_icon && (
            <span className="mx-2">
              <i className={item.event_type_icon} />
            </span>
          )}{' '}
          {item.event_type}
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  return (
    <div className="d-flex align-items-center py-2 flex-wrap">
      <div className="col-md-4 col-12 p-medium">
        <LabelName required={false} htmlFor="event_type">
          Event Type
        </LabelName>
      </div>
      <div className="col-md-8 col-12">
        {addEventType ? (
          <AddEventTypeForm setAddEventType={setAddEventType} setValue={setValue} />
        ) : (
          <div className="d-flex align-items-center">
            <Controller
              name="event_type"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Dropdown
                  id={field.name}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={typeList}
                  optionLabel={'event_type'}
                  itemTemplate={eventTypeOptionTemplate}
                  valueTemplate={eventTypeValueTemplate}
                  placeholder="Event Type"
                  className="flex-grow-1"
                  filter
                  showClear={field.value ? true : false}
                  resetFilterOnHide={true}
                />
              )}
            />
            <div className="col-sm-1 d-flex justify-content-end ps-2">
              <span className="pointer">
                <i
                  className="fas fa-plus-circle event-type"
                  onClick={() => {
                    setAddEventType(true);
                    resetField('event_type');
                  }}
                ></i>
                <Tooltip content="Add Event Type" position="top" target=".event-type" showDelay={500} />
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default EventType;
