import React from 'react';

import CalendarView from './Components/CalendarView/CalendarView';
import CalendarHeader from './Components/CalendarHeader/CalendarHeader';
import CalendarSettings from './Components/CalendarHeader/CalendarSettings';

import CreateEventModal from './Components/CreateEventModal/CreateEventModal';
import EventPreviewModal from './Components/EventPreviewModal/EventPreviewModal';

import { useCalendarContext } from './Context/CalendarContext';

function CalendarContainer() {
  const { showCreateEventModal, showEventPreviewModal, showCalendarSettings } = useCalendarContext();

  return (
    <>
      {showCreateEventModal && <CreateEventModal showCreateEventModal={showCreateEventModal} />}
      {showEventPreviewModal && <EventPreviewModal showEventPreviewModal={showEventPreviewModal} />}
      {showCalendarSettings && <CalendarSettings />}
      <div className="full-calendar-wrap px-2">
        <CalendarHeader />
        <CalendarView />
      </div>
    </>
  );
}

export default CalendarContainer;
