import React from 'react';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { tableCell } from '../BillingDetails/helpers/dataTableCommonHelpers';
import { formatNumbersToCurrencyString } from 'utils/utils';

const UnPaidInvoice = (props) => {
  const { unPaidInvoices, selectedUnpaidInvoices, setSelectedUnpaidInvoices } = props || {};
  return (
    <div className="mt-4">
      <div className="caption-bold mb-3">Unpaid Invoice Balance Forward</div>
      <div className="shadow-small row m-0 datatable-responsive p-2">
        <DataTable value={unPaidInvoices} className="p-datatable-responsive">
          <Column
            header="Forward Invoice	"
            body={(rowData, props) => {
              return (
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      let obj = {
                        pk: rowData?.pk,
                        sk: rowData?.sk,
                        due_amount: rowData?.due_amount,
                        case_id: rowData?.invoice?.case_id ? rowData?.invoice?.case_id : rowData?.case_id,
                        invoice_number: rowData?.invoice_number,
                        invoice_status: rowData?.invoice_status,

                        paid_amount: rowData?.paid_amount,
                        invoice_amount: rowData?.total_amount ? rowData?.total_amount : rowData?.invoice_amount,
                      };

                      selectedUnpaidInvoices.push(obj);
                      setSelectedUnpaidInvoices([...selectedUnpaidInvoices]);
                    } else {
                      let invoice_number = rowData?.invoice_number;
                      if (selectedUnpaidInvoices && selectedUnpaidInvoices.length > 0) {
                        selectedUnpaidInvoices.forEach((data, index) => {
                          if (data.invoice_number === invoice_number) {
                            selectedUnpaidInvoices.splice(index, 1);
                          }
                        });
                        setSelectedUnpaidInvoices([...selectedUnpaidInvoices]);
                      }
                    }
                  }}
                  checked={
                    selectedUnpaidInvoices.filter((index) => index.invoice_number === rowData.invoice_number).length > 0 ? true : false
                  }
                />
              );
            }}
          />
          <Column header="Invoice #" field="invoice_number" body={tableCell} />
          <Column
            header="Invoice Total" /* field="total_amount" */
            body={(rowData) => {
              let total_amount = rowData?.total_amount ? rowData?.total_amount : rowData?.invoice_amount;
              return (
                <>
                  <span className="p-column-title text-break">Invoice Total</span>
                  <span className="text-break">{formatNumbersToCurrencyString(total_amount)}</span>
                </>
              );
            }}
          />
          <Column
            header="Amount Paid" /* field="paid_amount" */
            body={(rowData) => {
              return (
                <>
                  <span className="p-column-title text-break">Amount Paid</span>
                  <span className="text-break">{formatNumbersToCurrencyString(rowData?.paid_amount)}</span>
                </>
              );
            }}
          />
          <Column
            header="Balance Due" /* field="due_amount" */
            body={(rowData) => {
              return (
                <>
                  <span className="p-column-title text-break">Balance Due</span>
                  <span className="text-break">{formatNumbersToCurrencyString(rowData?.due_amount)}</span>
                </>
              );
            }}
          />
        </DataTable>
      </div>
    </div>
  );
};

export default UnPaidInvoice;
