import React from 'react';

import MenuIcon from './MenuIcon';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';

function Tags({ item, index, itemsRef }) {
  const { modals, setSelectedItem, isClosed } = useFileManagerContext();

  const { setShowAddTag, setTagModalType } = modals;

  function handleTagClick(type) {
    setShowAddTag(true);
    setTagModalType(type);
    setSelectedItem(item);
  }

  return (
    <div className="row mt-2">
      <div className="col-10">
        <div className=" tag-wrp">
          {item && item.tags && item.tags.length ? (
            <>
              <span
                className={'tag m-1 pointer ' + (isClosed ? 'pe-none opacity-25' : '')}
                onClick={() => {
                  handleTagClick('edit');
                }}
              >{` #${item.tags[0]}  `}</span>
              {item?.tags && item.tags.length && item.tags.length - 1 ? (
                <span
                  className="tag m-1 pointer"
                  onClick={() => {
                    handleTagClick('edit');
                  }}
                >
                  {`+${item.tags.length - 1}`}
                </span>
              ) : null}
            </>
          ) : (
            <span
              onClick={() => {
                handleTagClick('add');
              }}
              className={'tag m-1 pointer ' + (isClosed ? 'pe-none opacity-25' : '')}
            >
              <i className="icon-add"></i>Add Tag
            </span>
          )}
        </div>
      </div>
      <div className={'col-2 ' + (isClosed ? 'pe-none opacity-25' : '')}>
        <MenuIcon item={item} index={index} itemsRef={itemsRef} />
      </div>
    </div>
  );
}

export default Tags;
