import { actionTypes } from "constants/types";
let initialState = {
  isLoading: "",
  caseStageList: "",
  caseStageError: "",
};

export default function CaseStageReducer(state = initialState, action={}) {
  switch (action.type) {
    case actionTypes.GET_CASE_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.GET_CASE_STATUS_SUCCESS:
      return {
        ...state,
        caseStageList: action.payload,
        isLoading: false
      };
    case actionTypes.GET_CASE_STATUS_ERROR:
      return {
        ...state,
        isLoading: false,
        caseStageError: action.payload,
      };

    default:
      return state;
  }
}
