import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import { uuidv4 } from 'common/uuidGenerator';
import './SentimentAnalysis.scss';

export const SentimentDot = ({ type, positive, neutral, negative, mixed, typeOnly, tooltipPosition = 'left' }) => {
  const id = uuidv4();
  return (
    <span className="dot-container">
      <span className={`dot dot-${id} ${type?.toLowerCase()}`} data-pr-position={tooltipPosition} />
      <Tooltip target={`.dot-${id}`} style={{ padding: 10 }}>
        <div className="tooltip-header">Sentiment Analysis - {type}</div>
        {!typeOnly && (
          <div className="d-flex flex-column pt-3" style={{ borderTop: '1px solid #dee2e6' }}>
            <div className="d-flex mb-2">
              <div className="tooltip-item">
                <span className="dot positive"></span> {positive || 0} Positive
              </div>
              <div className="tooltip-item">
                <span className="dot neutral"></span> {neutral || 0} Neutral
              </div>
            </div>
            <div className="d-flex">
              <div className="tooltip-item">
                <span className="dot negative"></span> {negative || 0} Negative
              </div>
              <div className="tooltip-item">
                <span className="dot mixed"></span> {mixed || 0} Mixed
              </div>
            </div>
          </div>
        )}
      </Tooltip>
    </span>
  );
};
