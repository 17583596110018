import React from 'react';

import FileManagerContainer from './FileManagerContainer';
import GlobalLoader from 'components/GlobalLoader/GlobalLoader';

import { FileManagerContextProvider } from './context/FileManagerContext';

function NewFileManager(props) {
  return (
    <FileManagerContextProvider {...props}>
      <GlobalLoader />
      <FileManagerContainer />
    </FileManagerContextProvider>
  );
}

export default NewFileManager;
