import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';
import CustomHeader from 'modules/file_manager/components/FileManagerView/Modals/CustomHeader';

export default function CreateSnippetModal({ onHide, selectedItem, onSubmit }) {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    if (selectedItem) {
      reset(selectedItem);
    }
  }, [selectedItem]);

  return (
    <Dialog
      className="rounded-radius FM-popup new-task-popup"
      header={<CustomHeader title="" onHide={() => onHide(false)} />}
      footer={<></>}
      visible
      style={{ width: '40vw' }}
      modal
      onHide={() => onHide(false)}
      closable={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="modal-heading F-size16 text-bold p-0">Add Text Snippet</div>
          <div className="d-flex align-items-center p-0 flex-wrap">
            <div className="col-md-3 col-12 p-medium">
              Snippet
              <span style={{ color: 'red' }}>*</span>
            </div>
            <div className="col-md-9 col-12">
              <Controller
                name="text_snippet_keyword"
                control={control}
                defaultValue={''}
                rules={{ required: true, pattern: /^\S*$/ }}
                render={({ field }) => (
                  <InputText
                    type="text"
                    {...field}
                    onChange={(e) => field.onChange(e)}
                    placeholder="Enter Snippet"
                    className="input-shadow w-100 mt-2"
                  />
                )}
              />
              {errors?.text_snippet_keyword?.type === 'required' && <InputErrorMessage>Required</InputErrorMessage>}
              {errors?.text_snippet_keyword?.type === 'pattern' && <InputErrorMessage>Space Not Allowed</InputErrorMessage>}
            </div>
          </div>
          <div className="d-flex align-items-center p-0 flex-wrap mt-2">
            <div className="col-md-3 col-12 p-medium">
              Expanded Text
              <span style={{ color: 'red' }}>*</span>
            </div>
            <div className="col-md-9 col-12">
              <Controller
                name="text_snippet_expansion"
                control={control}
                defaultValue={''}
                rules={{ required: true }}
                render={({ field }) => (
                  <InputText
                    type="text"
                    {...field}
                    onChange={(e) => field.onChange(e)}
                    placeholder="Enter Text"
                    className="input-shadow w-100 mt-2"
                  />
                )}
              />
              {errors?.text_snippet_expansion && <InputErrorMessage>Required</InputErrorMessage>}
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <span className="d-flex p-0" style={{ color: 'rgba(0, 0, 0, 0.6)', lineHeight: '18px' }}>
            <i className="pi pi-info-circle pe-2"></i>
            Press space or tab to expand the text in the input field.
          </span>
        </div>

        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-9 d-flex justify-content-end px-0 mt-3">
            <Button className="ms-1 p-button-secondary outline mx-2" type="reset" onClick={onHide}>
              Cancel
            </Button>
            <Button type="submit" className="p-button-secondary">
              Save
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
}
