import { performRequestSecond } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';
import { getTenantDetails } from 'utils/utils';

export const getActivityLogs = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  data.tenant_id = tenantId;
  return performRequestSecond(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.getAuditLogs}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};
