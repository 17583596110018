import { formValidationOptions } from 'modules/billing/TemporaryFund/configs/temporaryFundConfigs';
import { showFormErrorMessage } from 'modules/billing/TemporaryFund/helpers/temporaryFundHelpers';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import { Controller } from 'react-hook-form';

const TemporaryFundContact = (props) => {
  const { control, errors, temporaryFundModalOptions, toggleAddNewContact, setToggleAddNewContact, caseContactsList } = props;
  const isDebitFromLawftTemporaryFund = temporaryFundModalOptions?.entryType === 'db';

  const dropDownProps = {
    placeholder: 'Select',
    options: caseContactsList,
    filter: true,
    optionLabel: 'fullName',
  };

  const inputTextProps = {
    placeholder: 'Type in',
  };

  const Component = isDebitFromLawftTemporaryFund ? Dropdown : InputText;

  const componentProps = isDebitFromLawftTemporaryFund ? dropDownProps : inputTextProps;

  return (
    <div className="d-flex align-items-top py-2 flex-wrap">
      <div className="col-12 col-sm-4 pt-2">
        {isDebitFromLawftTemporaryFund ? 'To' : 'From'}
        <span className="alert-star">*</span>
      </div>
      <div className="col-12 col-sm-8">
        {/* list of client contacts */}

        <div className="client-contact-list">
          <Controller
            control={control}
            name={formValidationOptions?.fromOrToContact?.name}
            rules={formValidationOptions?.fromOrToContact?.rules}
            render={({ field }) => (
              <Component
                className="input-shadow w-100"
                name={field?.name}
                value={field?.value}
                onChange={(e) => {
                  field?.onChange(e);
                }}
                {...componentProps}
              />
            )}
          />

          {/* add button + temporaryly disabled */}
          {/* {!toggleAddNewContact && (
            <span className="pointer">
              <i
                className="fas fa-plus-circle"
                onClick={() => {
                  setToggleAddNewContact(true);
                }}
              ></i>
            </span>
          )} */}

          {showFormErrorMessage(formValidationOptions?.fromOrToContact?.name, errors)}
        </div>

        {/* adding new contact to the dropdown list */}
        {toggleAddNewContact && (
          <div className="p-inputgroup align-items-center">
            <InputText
              // value={newJobTitle}
              onChange={(e) => {
                // setNewJobTitle(e.target.value.trimStart())
              }}
              className="input-shadow w-100"
            />

            <Button
              icon="pi pi-check"
              className="p-button-secondary mx-2"
              type="button"
              // onClick={handleAddJobTitle}
              // loading={isAddJobTitleLoading}
            />
            <Button
              icon="pi pi-times"
              className="p-button-secondary outline"
              type="button"
              onClick={() => {
                setToggleAddNewContact(false);
                // setNewJobTitle(null);
              }}
              // disabled={isAddJobTitleLoading}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TemporaryFundContact;
