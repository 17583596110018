import React from 'react';

import FileDownload from '../../ListView/Templates/ActionsTemplate/FileDownload';
import PrintFile from '../../ListView/Templates/ActionsTemplate/PrintFile';

function ActionsTemplate({ rowData, e }) {
  return (
    <React.Fragment>
      <div key={e.rowIndex} className="d-flex">
        <span className="p-column-title text-break">Actions</span>

        {rowData.entry_type !== 'folder' && (
          <>
            <PrintFile rowData={rowData} />
            <FileDownload rowData={rowData} />
          </>
        )}
      </div>
    </React.Fragment>
  );
}

export default ActionsTemplate;
