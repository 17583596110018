import usePrintFile from 'modules/file_manager/hooks/usePrintFile';
import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import { useReactToPrint } from 'react-to-print';

function PrintFile(props) {
  const { rowData, isFromFilePreviewModal, filePreviewModalIconClassName, imageRef } = props;

  const { pdfTronPrintFile } = usePrintFile();

  const handlePrint = useReactToPrint({
    content: () => imageRef.current,
  });

  const printFile = (rowData) => {
    if (rowData?.file_type.includes('image')) {
      handlePrint();
    } else {
      pdfTronPrintFile(rowData);
    }
  };

  const iconClassName =
    isFromFilePreviewModal && filePreviewModalIconClassName ? filePreviewModalIconClassName : 'icon-Print me-3 text-primary-main pointer';

  return (
    <>
      <i onClick={() => printFile(rowData)} className={iconClassName} aria-hidden="true" />
      <Tooltip content="Print" position="top" target=".icon-Print" showDelay={500} />
    </>
  );
}

export default PrintFile;
