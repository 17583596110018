import React, { useContext } from 'react';

import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Tooltip } from 'primereact/tooltip';
import { Dropdown } from 'primereact/dropdown';
import moment from 'moment';

import { useSecureMessageContext } from '../../Context/SecureMessageContext';
import { UserDetailsContext, useUserDetailsContext } from 'context/userDetailsContext';
import { convertToTimezoneFormat } from 'utils/utility_functions/timezone';
import { msgStatusOptions, participantOptions, sentimentOptions } from 'constants/dropdownOptions';
import CaseListDropDown from 'components/CaseListDropDown/CaseListDropDown';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

function FilterComponent() {
  let {
    isGlobalMessagesScreen,
    fetchCaseMessage,
    authorsList,
    clientsList,
    filterCaseId,
    setFilterCaseId,
    filterMsgStatus,
    setFilterMsgStatus,
    filterAuthorId,
    setFilterAuthorId,
    dateForFilter,
    setDateForFilter,
    searchKeyWord,
    setSearchKeyWord,
    refreshCase,
    isFromClientPortal,
    filterSentiment,
    setFilterSentiment,
    filterParticipant,
    setFilterParticipant,
  } = useSecureMessageContext();

  const { userDetails } = useUserDetailsContext();
  const userContext = useContext(UserDetailsContext);

  const pickExtractedDate = (dateArray) => {
    return {
      message_date: dateArray?.[0] ? convertToTimezoneFormat(moment(dateArray[0]).startOf('day'), userDetails.timezone) : '',
      to_limit: dateArray?.[0]
        ? convertToTimezoneFormat(moment(dateArray[1] ? dateArray[1] : dateArray[0]).endOf('day'), userDetails.timezone)
        : '',
    };
  };

  const searchMessage = () => {
    let authorArr = filterSendAuthorID(filterAuthorId);

    let obj = {
      case_id: filterCaseId,
      author_id: authorArr,
      is_read: filterMsgStatus,
      keyword: searchKeyWord,
      participant: filterParticipant,
      sentiment_filter: filterSentiment,
      ...pickExtractedDate(dateForFilter),
    };
    fetchCaseMessage(obj);
  };

  const searchByTitle = (keyWord) => {
    setSearchKeyWord(keyWord);
  };

  const filterByCase = (e) => {
    setFilterCaseId(e);
    let authorArr = filterSendAuthorID(filterAuthorId);
    let obj = {
      case_id: e?.id,
      author_id: authorArr,
      is_read: filterMsgStatus,
      keyword: searchKeyWord,
      participant: filterParticipant,
      sentiment_filter: filterSentiment,
      ...pickExtractedDate(dateForFilter),
    };

    // if (e?.id) {
    fetchCaseMessage(obj);
    // } else if (!e?.id) {
    //   fetchCaseMessage({});
    // }
  };

  const filterByMsgStatus = (e) => {
    let statusVal = e.value === 'All' ? '' : e.value;
    setFilterMsgStatus(statusVal);

    let authorArr = filterSendAuthorID(filterAuthorId);
    let obj = {
      case_id: filterCaseId,
      author_id: authorArr,
      is_read: statusVal,
      keyword: searchKeyWord,
      participant: filterParticipant,
      sentiment_filter: filterSentiment,
      ...pickExtractedDate(dateForFilter),
    };
    fetchCaseMessage(obj);
  };
  const filterByAuthor = (e) => {
    setFilterAuthorId(e.value);
    let authorArr = filterSendAuthorID(e.value);
    let obj = {
      case_id: filterCaseId,
      author_id: authorArr,
      is_read: filterMsgStatus,
      keyword: searchKeyWord,
      participant: filterParticipant,
      sentiment_filter: filterSentiment,
      ...pickExtractedDate(dateForFilter),
    };
    fetchCaseMessage(obj);
  };

  const filterByDate = (e) => {
    if (e.value) {
      setDateForFilter(e.value);
      let authorArr = filterSendAuthorID(filterAuthorId);
      let obj = {
        case_id: filterCaseId,
        author_id: authorArr,
        is_read: filterMsgStatus,
        keyword: searchKeyWord,
        participant: filterParticipant,
        sentiment_filter: filterSentiment,
        ...pickExtractedDate(e.value),
      };
      fetchCaseMessage(obj);
    } else {
      setDateForFilter('');
      fetchCaseMessage({});
    }
  };

  const filterSendAuthorID = (arr = []) => {
    try {
      if (arr && arr.length) {
        let clientCognitoUsername = clientsList
          ? clientsList.filter((val) => val.client_cognito_username).map((v) => v.client_cognito_username)
          : [];
        let dat = arr.filter((val) => clientCognitoUsername.includes(val));
        return dat;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  };

  const filterChange = (e, type) => {
    if (type === 'sentiment') {
      setFilterSentiment(e.value);
    } else if (type === 'participants') {
      setFilterParticipant(e.value);
    }

    let authorArr = filterSendAuthorID(filterAuthorId);
    let obj = {
      case_id: filterCaseId,
      author_id: authorArr,
      is_read: filterMsgStatus,
      keyword: searchKeyWord,
      participant: type === 'participants' ? e.value : filterParticipant,
      sentiment_filter: type === 'sentiment' ? e.value : filterSentiment,
      ...pickExtractedDate(dateForFilter),
    };
    fetchCaseMessage(obj);
  };

  const assignToItemTemplate = (option) => {
    return (
      <>
        {option?.firm_user_id && <i class="fas fa-user-tie me-2"></i>}
        {option?.label}
      </>
    );
  };

  return (
    <div className="filter-wrap d-flex align-items-center justify-content-between flex-wrap my-3">
      {isGlobalMessagesScreen && (
        <div className="p-2 autowidth">
          <span className="p-input-icon-left input-shadow autowidth">
            <i className="icon-search F-size16 cursor-pointer" onClick={searchMessage} />
            <TextSnippetWrapper
              type="text"
              placeholder="Search"
              value={searchKeyWord}
              onChange={(e) => {
                searchByTitle(e.target.value);
              }}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  searchMessage();
                }
              }}
              className="input-search"
            />
          </span>
        </div>
      )}

      <div className="d-flex align-items-center msg-filters filter-wraper p-2">
        <span className="text-bold black-600 mx-2">Filters: </span>
        {isGlobalMessagesScreen && !isFromClientPortal && (
          <div className="d-flex flex-column autowidth me-2">
            <CaseListDropDown
              isClient={isFromClientPortal}
              value={filterCaseId}
              onChange={(e) => {
                if (filterCaseId !== e) {
                  filterByCase(e);
                }
              }}
              placeholder="By Case/Lead"
              noOptionsMessage="No Cases/Leads Found"
              name="case"
              isClearable
            />
          </div>
        )}
        {isGlobalMessagesScreen && isFromClientPortal && userContext?.clientCaseList.length > 1 && (
          <div className="d-flex flex-column autowidth me-2">
            <CaseListDropDown
              isClient={isFromClientPortal}
              value={filterCaseId}
              onChange={(e) => {
                if (filterCaseId !== e) {
                  filterByCase(e);
                }
              }}
              name="case"
              isClearable
            />
          </div>
        )}
        <MultiSelect
          value={filterAuthorId}
          options={authorsList}
          onChange={filterByAuthor}
          placeholder="By Author"
          className="me-2 input-shadow F-size14"
          optionLabel="label"
          optionValue="value"
          filter
          itemTemplate={assignToItemTemplate}
        />
        <Calendar
          id="basic"
          value={dateForFilter}
          onChange={(e) => filterByDate(e)}
          placeholder="By Date"
          className="me-2 cursor input-shadow by-date"
          monthNavigator
          yearNavigator
          selectionMode="range"
          yearRange="2010:2030"
          showButtonBar
        />
        <Dropdown
          value={filterMsgStatus}
          getCaseList
          options={msgStatusOptions}
          onChange={filterByMsgStatus}
          placeholder="By Message Status"
          className="me-2"
        />
        {!isFromClientPortal && (
          <Dropdown
            value={filterParticipant}
            getCaseList
            options={participantOptions}
            onChange={(e) => filterChange(e, 'participants')}
            placeholder="By Participants"
            className="me-2"
          />
        )}
        {!isFromClientPortal && (
          <Dropdown
            value={filterSentiment}
            getCaseList
            options={sentimentOptions}
            onChange={(e) => filterChange(e, 'sentiment')}
            placeholder="By Sentiment"
            className="me-2"
          />
        )}
        <div className="pointer me-0 p-2">
          <i className="fas fa-sync-alt" onClick={refreshCase}></i>
          <Tooltip content="Clear Filter" position="top" target=".fa-sync-alt" showDelay={500} />
        </div>
      </div>
    </div>
  );
}

export default FilterComponent;
