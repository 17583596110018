import React from 'react';

import FileManagerHeader from './components/FileManagerHeader/FileManagerHeader';
import FileManagerView from './components/FileManagerView/FileManagerView';

function FileManagerContainer() {
  return (
    <div className="px-2 r-px-0">
      <FileManagerHeader />
      <FileManagerView />
    </div>
  );
}

export default FileManagerContainer;
