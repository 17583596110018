import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useToast } from 'context/ToastContext.js';
import { useLeadContext } from 'modules/lead/Context/LeadContext';
import { convertToCase } from 'services/lead/leadServices';
import { toastConstant } from 'constants/toastmessage';
import { formatPaymentStructure } from 'modules/lead/helpers/helpers';
import { useToggle } from 'hooks/useToggle';

const useConvertCaseForm = ({ onHide, leadDetails }) => {
  const { paymentStructures } = useLeadContext();
  const [loading, setLoading] = useToggle(false);
  const { addToast } = useToast();

  const { control, ...formProps } = useForm();

  useEffect(() => {
    formProps.reset(formatPaymentStructure(leadDetails));
  }, []);

  const updateLeadData = (data) => {
    if (data && data?.billing_preferences) {
      setLoading();
      const { billing_preferences, case_description, case_contact, case_relation } = data || {};
      convertToCase(data.sk, { billing_preferences, case_description, case_contact, case_relation })
        .then(() => {
          onHide('converted');
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.LEAD_CONVERT_SUCCESS);
        })
        .catch(() => {
          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.LEAD_CONVERT_FAILURE);
        })
        .finally(() => setLoading());
    }
  };

  return { paymentStructures, control, formProps, loading, updateLeadData };
};

export default useConvertCaseForm;
