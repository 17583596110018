import { actionTypes } from "constants/types";

let initialState = {
  isLoading: false,
  roleListData: [],
  error: "",
};

export default function RelationListReducer(state = initialState, action={}) {
  switch (action.type) {
    case actionTypes.GET_RELATION_ROLE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_RELATION_ROLE_SUCCESS:
      return {
        ...state,
        //roleList: [...action.payload],
        roleListData:action.payload,
        isLoading: false,
      };
    case actionTypes.GET_RELATION_ROLE_ERROR:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
}
