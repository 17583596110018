import { useState } from 'react';

export default function useBreadCrumbs() {
  const [breadCrumbs, setBreadCrumbs] = useState([]);

  function handleBreadCrumbs(e) {
    setBreadCrumbs((prev) => [
      ...prev,
      {
        label: e.display_name,
        value: e.object_id,
      },
    ]);
  }

  function filterBreadCrumbs(folder_id) {
    let folderIndex = breadCrumbs?.findIndex((item) => item.value === folder_id);
    let _breadCrumbs = breadCrumbs;
    setBreadCrumbs(_breadCrumbs.splice(0, folderIndex + 1));
  }

  return { breadCrumbs, setBreadCrumbs, handleBreadCrumbs, filterBreadCrumbs };
}
