import { useState, useEffect } from 'react';
import { googleConfig } from 'config/calendar_config';
import { getCalendarSettings } from 'services/calendarServices';
import { getCalendarList } from 'services/Calender/googleServices';
import { useNavbarContext } from 'context/NavbarContext';

export function useGoogleCalendar() {
  const [calendarSettings, setCalendarSettings] = useState();
  const { showConfirmDialog } = useNavbarContext();

  const isTokenExpired = () => {
    const expiryTime = localStorage.getItem('expiryTime');
    const currentTime = new Date().getTime();
    return currentTime > expiryTime;
  };

  const getCalendarId = async () => {
    let calendarId = localStorage.getItem('google_calendar_id');
    if (!calendarId) {
      let googleAccessToken = localStorage?.getItem('google_access_token');
      const calendarList = await getCalendarList({ accessToken: googleAccessToken });
      const options = calendarList?.data?.items.map((v) => ({ label: v.summary, value: v.id }));
      if (options.length > 1) {
        const result = await showConfirmDialog({
          formFields: [
            {
              name: 'calendarId',
              label: 'Select a google calendar to sync',
              type: 'dropdown',
              options: options || [],
              placeholder: 'Select calendar',
              required: true,
            },
          ],
        });
        if (result?.calendarId) {
          calendarId = result?.calendarId;
          localStorage.setItem('google_calendar_id', result?.calendarId);
          return result?.calendarId;
        } else return calendarId;
      }
      return options?.[0]?.value;
    }
    return calendarId;
  };

  const handleCallbackResponse = (response) => {
    if (response?.access_token) {
      const expiresIn = response.expires_in;
      const expiryTime = new Date().getTime() + expiresIn * 1000;

      localStorage.setItem('google_access_token', response?.access_token);
      localStorage.setItem('expiryTime', expiryTime);
    } else {
      console.log('Google Auth Failed');
    }
  };

  const initClient = (callback = handleCallbackResponse) => {
    try {
      let client = window.google.accounts.oauth2.initTokenClient({
        client_id: googleConfig.clientId,
        scope: googleConfig.scopes,
        callback,
        prompt: 'consent',
        access_type: 'offline',
        // prompt:"select_account"
        include_granted_scopes: true,
      });

      return client?.requestAccessToken();
    } catch (error) {
      console.log('error', error);
    }
  };

  const googleSignOut = () => {
    let googleAccessToken = localStorage?.getItem('google_access_token');
    return window?.google?.accounts.oauth2.revoke(googleAccessToken, () => {
      console.log('access token revoked');
      localStorage?.removeItem('expiryTime');
      localStorage?.removeItem('google_access_token');
      localStorage?.removeItem('google_calendar_id');
    });
  };

  const getCalendarSetting = () => {
    return getCalendarSettings()
      .then((res) => {
        setCalendarSettings(res?.data?.calendar_settings?.[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCalendarSetting();
  }, []);

  return { calendarSettings, initClient, googleSignOut, isTokenExpired, getCalendarSetting, getCalendarId };
}
