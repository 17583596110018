import { toastConstant } from 'constants/toastmessage';
import { useToast } from 'context/ToastContext';
import { addTeamMember, deleteCaseTeamMember } from 'services/CaseServices';
import { updateTeamMember } from 'services/teamManagement.service';

export default function useTeamMember({ caseDetails, isLeadSpecific, getDetails }) {
  const { addToast } = useToast();

  const handleTeamMemberLawftAcces = (data) => {
    let team_member = JSON.parse(JSON.stringify(data));
    team_member.notify_case_updates = !team_member.notify_case_updates;
    return updateTeamMemberService({ team_member: [team_member] });
  };

  const updateTeamMemberService = async (sendData) => {
    //Automated Time entry code
    return updateTeamMember(sendData, isLeadSpecific)
      .then(() => {
        typeof getDetails === 'function' && getDetails();
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.UPDATE_TEAM_MEMBER_SUCCESS);
      })
      .catch(() => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.UPDATE_TEAM_MEMBER_FAILURE);
      });
  };

  const deleteTeamMember = async (teamMember) => {
    return deleteCaseTeamMember(teamMember?.sk, teamMember?.pk, isLeadSpecific)
      .then(() => {
        typeof getDetails === 'function' && getDetails();
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.TEAM_MEMBER_DELETE_SUCCESS);
      })
      .catch(() => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.TEAM_MEMBER_DELETE_FAILED);
      });
  };

  const addTeamMembers = async (selectedMembers) => {
    let old_team_member = null;
    if (isLeadSpecific && caseDetails?.lead_assign_to?.[0]) {
      old_team_member = caseDetails?.lead_assign_to?.[0];
    }

    return addTeamMember({ team_member: selectedMembers, old_team_member }, '', caseDetails?.case_id, isLeadSpecific)
      .then((response) => {
        typeof getDetails === 'function' && getDetails();
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.TEAM_MEMBER_CASE_ADD_SUCCESS);
      })
      .catch(() => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.TEAM_MEMBER_CASE_ADD_FAILED);
      });
  };

  const updateTeamMembers = async (selectedMembers) => {
    return updateTeamMember({ team_member: selectedMembers }, isLeadSpecific)
      .then((response) => {
        typeof getDetails === 'function' && getDetails();
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.TEAM_MEMBER_CASE_ADD_SUCCESS);
      })
      .catch(() => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.TEAM_MEMBER_CASE_ADD_FAILED);
      });
  };

  return { handleTeamMemberLawftAcces, addTeamMembers, deleteTeamMember, updateTeamMembers };
}
