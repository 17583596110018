import React from 'react';
import EventContent from './EventContent.jsx';

function MoreEvents({ moreEvents, timezone }) {
  return (
    <>
      {moreEvents?.allSegs?.map((event, index) => {
        return (
          <div className="mb-2" key={index}>
            <EventContent arg={event} timezone={timezone} />
          </div>
        );
      })}
    </>
  );
}

export default MoreEvents;
