import React from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';

import { useToast } from 'context/ToastContext';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import { toastConstant } from 'constants/toastmessage';
import useUpdateFiles from 'modules/file_manager/hooks/useUpdateFiles';

function DeleteConfirmationModal() {
  const { modals, handleGetFiles, pageLimit, selectedRows, setSelectedRows } = useFileManagerContext();
  const { showBulkDeleteModal, setShowBulkDeleteModal } = modals;

  const { fileBulkDelete } = useUpdateFiles();

  const { addToast } = useToast();

  const deleteFile = () => {
    let file_ids = selectedRows.map((item) => {
      return {
        pk: item.pk,
        sk: item.sk,
      };
    });
    let payload = {
      file_ids,
      case_id: selectedRows[0].case_id !== 'undefined' ? selectedRows[0].case_id : '',
    };
    let is_lead = false;
    if (selectedRows[0].case_id !== 'undefined') {
      // logic to check if the selected case is a lead
    }

    fileBulkDelete(payload, 'delete', is_lead)
      .then(() => {
        handleGetFiles(pageLimit);
        setShowBulkDeleteModal(false);
        setSelectedRows([]);
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.FILE_DELETE_SUCCESS);
      })
      .catch((error) => {
        console.log(error);
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.ERROR, toastConstant.message.FILE_DELETE_FAILED);
      });

    // let updated_file = {
    //   deleted: true,
    //   pk: selectedItem['pk'],
    //   sk: selectedItem['sk'],
    //   type: selectedItem['entry_type'],
    // };

    // let obj = {
    //   data: updated_file,
    //   is_lead: isLeadFileManager,
    // };

    // updateFiles(obj)
    //   .then((response) => {
    //     handleGetFiles(pageLimit);

    //     addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.FILE_DELETE_SUCCESS);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.ERROR, toastConstant.message.FILE_DELETE_FAILED);
    //   });
  };

  const CustomMessage = () => {
    return (
      <div>
        <div className="P-medium text-bold"></div>
        <h6 className="mt-2">Are you sure you want to delete {selectedRows.length} files?</h6>
        {
          // selectedRows.map(item=> (
          //    <li>{item.display_name}</li>
          // ))
        }
        {/* {selectedItem.entry_type === 'folder' ? 'Folder Name' : 'File Name'} : {selectedItem.display_name} */}
      </div>
    );
  };

  return (
    <ConfirmDialog
      visible={showBulkDeleteModal}
      onHide={() => setShowBulkDeleteModal(false)}
      message={<CustomMessage />}
      header="Confirm Delete"
      accept={deleteFile}
      closable={false}
      rejectClassName="p-button-secondary outline"
      className="common-popup-style confirm-delete-popup"
      icon="pi pi-exclamation-triangle"
      reject={() => setShowBulkDeleteModal(false)}
    />
  );
}

export default DeleteConfirmationModal;
