import { actionTypes } from "constants/types";
let initialState = {
  isJobTitleLoading: false,
  jobTitleList: [],
  jobTitleError: "",
};

export default function JobTitleReducer(state = initialState, action={}) {
  switch (action.type) {
    case actionTypes.GET_JOB_TITLE_REQUEST:
      return {
        ...state,
        isJobTitleLoading:true,
        jobTitleList: [],
        jobTitleError: "",
      };
    case actionTypes.GET_JOB_TITLE_SUCCESS:
      return {
        ...state,
        jobTitleList: action.payload,
        jobTitleError: "",
        isJobTitleLoading:false
      };
    case actionTypes.GET_JOB_TITLE_ERROR:
      return {
        ...state,
        jobTitleList: [],
        jobTitleError: action.payload,
        isJobTitleLoading:false
      };

    default:
      return state;
  }
}
