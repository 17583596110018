import { performRequest, performRequestThird } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';
import Cookies from 'universal-cookie';
import { getTenantDetails } from 'utils/utils';
const cookie = new Cookies();

export const connectToSocket = (tenant_id, user_id, access_level) => {
  let socket_url = `${process.env.REACT_APP_WEB_SOCKET_URL}?tenant_id=${tenant_id}&user_id=${user_id}`;

  if (access_level) socket_url += `&access_level=${access_level}`;

  const notificationSocket = new WebSocket(socket_url);
  return notificationSocket;
};

export const getNotifications = (notification_type = '', limit = 10, paginationToken = '', paginationDirection = 'forward') => {
  let tenantId = cookie.get('tenantId');
  let tenantSk = cookie.get('tenantSk');
  tenantSk = tenantSk ? tenantSk.replaceAll('#', '%23') : '';
  let url = `${apiEndPoints.getNotifications}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&notification_type=${notification_type}&limit=${limit}&pagination_token=${paginationToken}&pagination_direction=${paginationDirection}`;
  return performRequest(apiEndPoints.methodType.GET, url, null, '', true, true);
};

export const markNotification = (is_read, notification_sks) => {
  let tenantId = cookie.get('tenantId');
  let tenantSk = cookie.get('tenantSk');
  tenantSk = tenantSk ? tenantSk.replaceAll('#', '%23') : '';
  let url = `${apiEndPoints.markNotification}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&is_read=${is_read}`;
  return performRequest(apiEndPoints.methodType.PATCH, url, { notification_sks }, '', true, true);
};

export const timeDifference = (old_date, new_date) => {
  const getDurationSting = (timeString, durationType) => {
    let durationString = Number(time_string) > 1 ? `${durationType}s` : durationType;
    return ` ${durationString}`;
  };

  // get total seconds between the times
  let delta = Math.abs(new_date - old_date) / 1000;

  // calculate (and subtract) whole days
  let days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  let hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  let minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  // what's left is seconds
  var seconds = delta % 60;
  seconds = seconds ? seconds.toFixed(0) : null;
  let time_string = '';

  if (days) {
    time_string = `${time_string} ${days}`;
    time_string += getDurationSting(time_string, 'day');
  } else if (hours) {
    // time_string = `${time_string} ${hours} hours`;
    time_string = `${time_string} ${hours}`;
    time_string += getDurationSting(time_string, 'hour');
  } else if (minutes) {
    // time_string = `${time_string} ${minutes} minutes`;
    time_string = `${time_string} ${minutes}`;
    time_string += getDurationSting(time_string, 'minute');
  } else if (seconds) {
    time_string = `${time_string} ${seconds}`;
    time_string += getDurationSting(time_string, 'second');
  }
  return time_string;
};

export const clearNotifications = () => {
  let tenantId = cookie.get('tenantId');
  let tenantSk = cookie.get('tenantSk');
  tenantSk = tenantSk ? tenantSk.replaceAll('#', '%23') : '';
  let url = `${apiEndPoints.clearAllNotifications}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequest(apiEndPoints.methodType.DELETE, url);
};
export const getNotificationCount = () => {
  let tenantId = cookie.get('tenantId');
  let tenantSk = cookie.get('tenantSk');
  tenantSk = tenantSk ? tenantSk.replaceAll('#', '%23') : '';
  let url = `${apiEndPoints.getNotificationCount}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestThird(apiEndPoints.methodType.GET, url);
};

export const clearClientNotifications = (module, contact_id) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.clientNotificationMarkAsRead}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&module=${module}&contact_id=${contact_id}`;
  return performRequestThird(apiEndPoints.methodType.GET, url);
};

export const clientNotificationCount = (contact_id) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.clientNotificationCount}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&role=client&contact_id=${contact_id}`;
  return performRequestThird(apiEndPoints.methodType.GET, url);
};
