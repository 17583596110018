import { listRelationRoles } from 'services/Settings';
import { actionTypes } from 'constants/types';

export const getRelationRolesAction = (pageLimit = 10, pagination_token = '', pagination_direction = 'forward') => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.GET_RELATION_ROLE_REQUEST });
    try {
      const response = await listRelationRoles(pageLimit, pagination_token, pagination_direction);
      return dispatch({
        type: actionTypes.GET_RELATION_ROLE_SUCCESS,
        payload: response?.data,
      });
    } catch (error) {
      return dispatch({
        type: actionTypes.GET_RELATION_ROLE_ERROR,
        payload: error.response,
      });
    }
  };
};
