import { useState } from 'react';

import { getSignedUrl } from '../services';
import { fetchFiles } from 'services/fileManager/fileManagerServices';
import { getLocalStorageItem } from 'utils/localStorageUtils';

export default function useGetFiles(isGlobalFileManager, isCaseFileManager, isLeadFileManager, isClientFileManager, userContext) {
  const [pageLimit, setPageLimit] = useState(getLimit() ?? 10);
  const [files, setFiles] = useState([]);

  const [nextToken, setNextToken] = useState();
  const [transitionToken, setTransitionToken] = useState();
  const [paginationDirection, setPaginationDirection] = useState('forward');

  function getLimit() {
    if (isGlobalFileManager && userContext) {
      return userContext?.firmDetails?.pagination_preference?.file_manager?.self;
    } else if (isCaseFileManager && userContext) {
      return userContext?.firmDetails?.pagination_preference?.case?.file_manager;
    } else if (isLeadFileManager && userContext) {
      return userContext?.firmDetails?.pagination_preference?.leads?.file_manager;
    } else if (isClientFileManager && userContext) {
      return userContext?.firmDetails?.pagination_preference?.client_portals?.filemanager?.self;
    }
  }

  const onNextPageClick = (obj, currentView, pageLimit) => {
    obj.pagination_direction = 'forward';
    obj.pagination_token = paginationDirection === 'reverse' ? transitionToken : nextToken;
    getFiles(obj, currentView, pageLimit);
    setPaginationDirection('forward');
  };

  const onPrevPageClick = (obj, currentView, pageLimit) => {
    obj.pagination_direction = 'reverse';
    obj.pagination_token = paginationDirection === 'forward' ? transitionToken : nextToken;
    getFiles(obj, currentView, pageLimit);
    setPaginationDirection('reverse');
  };

  const getFiles = (obj, currentView, pageLimit) => {
    if (currentView === 'grid' || getLocalStorageItem('viewFile')) {
      //todo: add lazy load pagination
      obj.pageLimit = 1000;
    } else {
      if (isGlobalFileManager && userContext) {
        obj.pageLimit = userContext?.firmDetails?.pagination_preference?.file_manager?.self;
      } else {
        obj.pageLimit = pageLimit;
      }
    }

    fetchFiles(obj)
      .then(async (response) => {
        let arr = response.data.data;
        const _dataArray = await Promise.all(
          arr.map(async (item) => {
            if (item.file_path) {
              const url = await getSignedUrl(item);
              return { ...item, url };
            }
            return item;
          })
        );

        setFiles(_dataArray);
        setNextToken(response.data.next_token);
        setTransitionToken(response.data.transition_token);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return {
    files,
    getFiles,
    nextToken,
    transitionToken,
    pageLimit,
    setPageLimit,
    paginationDirection,
    setPaginationDirection,
    onNextPageClick,
    onPrevPageClick,
  };
}
