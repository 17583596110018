import React, { useState } from 'react';
import { Card } from 'primereact/card';

import Form from './Form';
import { listAvailablePhoneNumbers, purchasePhoneNumber } from 'services/textMessageService';
import { toastConstant } from 'constants/toastmessage';
import { useUserDetailsContext } from 'context/userDetailsContext';
import { useToast } from 'context/ToastContext';
import { useToggle } from 'hooks/useToggle';

export default function SelectNumber() {
  const userContext = useUserDetailsContext();
  const { addToast } = useToast();

  const [numberOptions, setNumberOptions] = useState([]);
  const [loading, setLoading] = useToggle();

  const fetchPhoneNumbers = (area_code = 817) => {
    setLoading();
    listAvailablePhoneNumbers('US', area_code, 20)
      .then((res) => {
        setLoading();
        if (res?.data?.available_phone_numbers?.length) {
          setNumberOptions(res.data.available_phone_numbers.map((v) => ({ label: v.phone_number, value: v.phone_number })));
        } else {
          setNumberOptions([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading();
      });
  };

  const submit = async ({ selectedNumber }) => {
    purchasePhoneNumber(selectedNumber)
      .then(async () => {
        await userContext.loadUserDetails();
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.NUMBER_PURCHASED_SUCCESS);
      })
      .catch(() => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.NUMBER_PURCHASED_FAILED);
      });
  };

  return (
    <Card title="Please select a phone number" className="text-center shadow-middle mt-3">
      <Form submit={submit} fetchPhoneNumbers={fetchPhoneNumbers} loading={loading} numberOptions={numberOptions} />
    </Card>
  );
}
