import { actionTypes } from 'constants/types';
import { getNotifications } from 'services/notification/notificationService';

export const notificationAction = (notification_type, limit, next) => {
  return (dispatch, state) => {
    dispatch({ type: actionTypes?.GET_NOTIFICATIONS_STARTED, isLoading: true });
    getNotifications(notification_type, limit, next)
      .then((response) => {
        if (response?.data) {
          if (response?.data?.length > 0) {
            response.data = response?.data?.filter((index) => index?.notification_type !== 'dashboard');
          }
          dispatch({
            type: actionTypes?.GET_NOTIFICATIONS_SUCCESS,
            data: response?.data,
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        dispatch({ type: actionTypes?.GET_NOTIFICATIONS_FAILURE, error: err, isLoading: false });
      });
  };
};
