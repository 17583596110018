import React from 'react';
import Dropzone from 'react-dropzone';
import { Controller } from 'react-hook-form';

import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';

function UploadInput({ control, errors }) {
  return (
    <div className="dropzone-wraper d-flex align-items-center py-2 flex-wrap">
      <div className="col-md-3 col-12">
        <label className="p-medium">
          Source <span style={{ color: 'red' }}>*</span>
        </label>
      </div>
      <div className="col-md-9 col-12">
        <Controller
          name="files"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <Dropzone
              onDrop={(acceptedFiles, rejectedFiles) => {
                const allowedExtensions = [
                  'jpg',
                  'jpeg',
                  'png',
                  'pdf',
                  'doc',
                  'docx',
                  'xls',
                  'xlsx',
                  'txt',
                  'mov',
                  'wav',
                  'mp3',
                  'rtf',
                  'mpeg',
                  'mp4',
                  'aac',
                  '3gp',
                  'avi',
                  'wmv',
                  'wma',
                  'csv',
                ];
                const filteredFiles = acceptedFiles.filter((file) => {
                  const fileNameParts = file.name.split('.');
                  const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
                  return allowedExtensions.includes(fileExtension);
                });
                onChange(filteredFiles);

                if (rejectedFiles.length > 0) {
                  // You can show an error message or handle rejected files here
                }
              }}
              // Add accepted file types here
              accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .txt, .mov, .wav, .mp3, .rtf, .mpeg, .mp4, .aac, .3gp, .avi, .wmv, .wma, .csv"
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} directory="" />

                    <div className="text-center d-flex flex-column justify-content-center align-items-center file-manager-uploader shadow-small mb-2">
                      <i className="icon-doc-add mb-2 pointer"></i>
                      <p className="F-size14">Click or drag file/folder to this area to upload</p>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          )}
        />
        {errors?.files && <InputErrorMessage>Required</InputErrorMessage>}
      </div>
    </div>
  );
}

export default UploadInput;
