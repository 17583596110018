import React, { useContext, useState, useEffect } from 'react';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import multiMonthPlugin from '@fullcalendar/multimonth';

import { Dialog } from 'primereact/dialog';

import eventContentFunction from './Components/eventContent';
import MoreEvents from './Components/MoreEvents';

import { UserDetailsContext } from 'context/userDetailsContext';
import { useCalendarContext } from 'modules/calendar/Context/CalendarContext';

import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import constants from 'constants/index';
import { useLocation } from 'react-router-dom';
import { getLocalStorageParseItem, removeLocalStorageItem } from 'utils/localStorageUtils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { handleRedirection } from 'utils/utils';

function CalendarView() {
  const {
    eventList,
    googleEventList,
    outlookEventList,
    calendarSettings,
    setShowEventPreviewModal,
    setSelectedEvent,
    showMoreEventsModal,
    setShowMoreEventsModal,
    currentDates,
    setCurrentDates,
    currentView,
    setCurrentView,
  } = useCalendarContext();
  const userContext = useContext(UserDetailsContext);

  const [moreEvents, setMoreEvents] = useState([]);
  const location = useLocation();
  const history = useHistory();

  let timezone = userContext?.userDetails?.timezone;

  const onEventClick = (info) => {
    // console.log('info__', info);
    let eventSelected;
    if (info?.event !== undefined) {
      eventSelected = info.event.toJSON();
    } else {
      eventSelected = {
        extendedProps: info,
        title: info?.title,
        start: info?.meeting_start_time ? info?.meeting_start_time : info?.start,
        end: info?.meeting_end_time ? info?.meeting_end_time : info?.end,
      };
    }
    // console.log('eventSelected', eventSelected);
    setSelectedEvent(eventSelected);
    if (eventSelected.extendedProps.res_type === 'user') {
      const arr = eventSelected?.extendedProps?.sk?.split('#');
      const contactId = arr && arr.length > 0 && arr[arr.length - 1];
      const url = contactId ? `/contacts/${contactId}` : '/contacts';
      handleRedirection(history, url);
    } else {
      setShowEventPreviewModal(true);
    }
  };

  const options = {
    plugins: [multiMonthPlugin, dayGridPlugin, timeGridPlugin, interactionPlugin],

    initialView: 'dayGridMonth',
    headerToolbar: {
      left: 'prev,next,today',
      center: 'title',
      right: 'multiMonthYear,dayGridMonth,timeGridWeek,timeGridDay',
    },
    editable: false,
    showNonCurrentDates: true,
    dayPopoverFormat: (info) => {
      // moment(info?.date?.marker)?.format("MM-DD-YYYY")
      return handleDateTimeOffset(userContext?.userDetails?.timezone, info?.date?.marker, constants.month_date_year_format);
    },
    events: function (info, successCallback, failureCallback) {
      let event_list = Array.isArray(eventList) ? eventList : [];
      let google_event_list = Array.isArray(googleEventList) ? googleEventList : [];
      let outlook_event_list = Array.isArray(outlookEventList) ? outlookEventList : [];

      const concatenatedArray = [...event_list, ...google_event_list, ...outlook_event_list];

      successCallback(concatenatedArray);
    },

    eventContent: function (arg, createElement) {
      return eventContentFunction(arg, timezone, calendarSettings?.show_business_times_only, currentView, createElement);
    },
    eventClick: onEventClick,

    weekends: calendarSettings?.show_weekends,
    slotMinTime: calendarSettings?.show_business_times_only ? '09:00' : '00:00:00',
    slotMaxTime: calendarSettings?.show_business_times_only ? '17:00' : '24:00:00',
    hiddenDays: calendarSettings?.show_business_times_only ? [0, 6] : [],
    views: {
      timelineCustom: {
        type: 'timeGrid',
        dateIncrement: { years: 1 },
        slotDuration: { months: 1 },
        visibleRange: function (currentDate) {
          let d = new Date();
          return {
            start: `${d.getFullYear()}-01-01 `,
            end: `${d.getFullYear()}-12-31`,
          };
        },
      },
    },
  };

  const handleViewDidMount = (data) => {
    setCurrentView(data.view?.type);
  };

  const handleDatesSet = (dateInfo) => {
    let current_start = dateInfo?.view?.currentStart;
    let current_end = dateInfo?.view?.currentEnd;
    //to avoid infinite recursion
    if (current_start.valueOf() !== currentDates.currentStartValue || current_end.valueOf() !== currentDates.currentEndValue) {
      setCurrentDates({
        currentStartValue: current_start.valueOf(),
        currentEndValue: current_end.valueOf(),
        currentStartDate: current_start,
        currentEndDate: current_end,
      });
    }
  };

  function more(e) {
    try {
      setShowMoreEventsModal(true);
      setMoreEvents(e);
    } catch (err) {
      console.log(err);
    }
    return 'null';
  }

  const generateFormattedEventDetails = (data = {}) => {
    const { title, meeting_start_time, meeting_end_time, sk, pk, icon = '' } = data;

    //Note: This object structure is created based on the console.log() output of "info.event.toJSON()" function in "onEventClick()" function. This structure is used to open an pariticular event detail on (direct or open in new tab) click from other modules . Some data like "start","end","created_by_details" etc.. are missing from other modules and that needs to be checked only if it is not working properly.
    let formattedEventDetails = {
      allDay: false,
      title: title,
      // start: '2024-01-11T15:13:03+05:30',
      // end: '2024-01-11T15:43:03+05:30',
      extendedProps: {
        meeting_start_time,
        meeting_end_time,
        res_type: 'event',
        icon,
        // created_by_details: {
        //   calendar_color: '#ceaff2',
        // },
        sk,
        pk,
      },
    };
    return formattedEventDetails;
  };

  const handleDateClick = (arg) => {
    if (arg?.jsEvent?.target?.classList?.contains('fc-daygrid-day-number')) {
      const calendarApi = arg.view.calendar;
      calendarApi.changeView('timeGridDay', arg.date);
    }
  };

  useEffect(() => {
    let selectedEventDetails = getLocalStorageParseItem('stateData')?.viewEvent || location?.state?.viewEvent;
    if (selectedEventDetails) {
      setSelectedEvent(generateFormattedEventDetails(selectedEventDetails));
      setShowEventPreviewModal(true);
      removeLocalStorageItem('stateData');
    }
  }, [window?.localStorage, location]);

  return (
    <div className="row mx-0">
      <div className="col-12 px-0">
        <div className="Calendar-wrap shadow-sm mb-3">
          {showMoreEventsModal && (
            <>
              <Dialog
                header={handleDateTimeOffset(timezone, moreEvents.date, constants.month_date_year_format)}
                visible={showMoreEventsModal}
                draggable={true}
                style={{ minWidth: '350px' }}
                onHide={() => setShowMoreEventsModal(false)}
              >
                <MoreEvents moreEvents={moreEvents} timezone={timezone} />
              </Dialog>
            </>
          )}
          <FullCalendar
            {...options}
            // moreLinkClick={more}
            // dayMaxEvents={currentView === "dayGridMonth" ? 2 : 3}
            dayMaxEvents={2}
            eventBackgroundColor="white"
            eventBorderColor="white"
            eventOverlap={false}
            slotEventOverlap={false}
            viewDidMount={handleViewDidMount}
            datesSet={handleDatesSet}
            lazyFetching={true}
            multiMonthMaxColumns={3}
            // showNonCurrentDates={false}
            dateClick={handleDateClick}
            height={'auto'}
          />
        </div>
      </div>
    </div>
  );
}

export default CalendarView;
