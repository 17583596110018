import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputSwitch } from 'primereact/inputswitch';
import { Checkbox } from 'primereact/checkbox';
import { confirmDialog } from 'primereact/confirmdialog';

import { useToggle } from 'hooks/useToggle';
import { useFirmUserList } from 'hooks/useFirmUserList';
import { generateFullName } from 'utils/generateFullNameUtils';
import { handleRedirection } from 'utils/utils';
import { handleSettingSkAndPkToLocalStorage } from 'utils/localStorageUtils';
import useTeamMember from 'modules/cases/hooks/useTeamMember';
import ImageComponent from 'shared/ImageComponent';
import { Tag } from 'primereact/tag';

export default function TeamMemberLeads({ caseDetails, isLeadSpecific, isCaseClosed, isLeadClosed, getDetails }) {
  const [isTeamMemberAdd, toggleAddView] = useToggle(false);

  const history = useHistory();
  const { userList } = useFirmUserList();
  const { deleteTeamMember, handleTeamMemberLawftAcces, addTeamMembers } = useTeamMember({ caseDetails, isLeadSpecific, getDetails });

  const [selectedList, setSelectedList] = useState([]);
  const teamMembers = useMemo(
    () => caseDetails?.team_member?.map((v) => ({ ...v, is_selected: true }))?.sort((a, b) => a?.user_type - b?.user_type),
    [caseDetails]
  );

  const onChangeHandler = (e) => {
    setSelectedList((preVal) =>
      preVal?.map((val) => {
        // if (val?.client_cognito_username === e.value) {
        if (val?.firm_user_id === e.value) {
          if (Boolean(val?.is_selected) === false && e.target.name !== 'is_selected') {
            return { ...val, [e.target.name]: e.checked, is_selected: true };
          }
          if (e.target.name === 'is_selected' && !e.checked) {
            return { ...val, is_selected: false, is_lead_associate: false, is_lead_attorney: false, notify_case_updates: false };
          }
          let otherField = e.target.name === 'is_lead_associate' ? 'is_lead_attorney' : 'is_lead_associate';
          return { ...val, [e.target.name]: e.checked, [otherField]: false };
        } else if ((e.target.name === 'is_selected' && !isLeadSpecific) || e.target.name === 'notify_case_updates') {
          return val;
        }
        return { ...val, [e.target.name]: false };
      })
    );
  };

  const confirmDeleteMember = (tMember) => {
    confirmDialog({
      message: 'Are you sure want to delete this team member?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => deleteTeamMember(tMember),
    });
  };

  const handleSave = () => {
    addTeamMembers(selectedList?.filter((v) => v?.is_selected)).then(() => toggleAddView());
  };

  const nameTemplate = (rowData) => {
    const { sk, pk, firm_user_id } = rowData;
    let url = '/settings/team-management';
    let stateToPass = {};

    if (firm_user_id) {
      url = firm_user_id ? `/settings/team-management/${firm_user_id}` : '/settings/team-management';
      stateToPass = rowData;
    }

    return (
      <div
        className="d-flex flex-column flex-sm-row w-100"
        onClick={() => {
          handleRedirection(history, url, stateToPass);
          handleSettingSkAndPkToLocalStorage(sk, pk);
        }}
      >
        <span className="p-column-title text-break d-none">Lead Attorney</span>
        <ImageComponent filePath={rowData?.profile_image} fileName={rowData?.first_name} fileSize="small.jpg" className="col-1 me-2" />
        <span className="d-flex align-items-center text-break redirection-link">
          {firm_user_id && <i class="fas fa-user-tie me-2"></i>}
          {generateFullName(rowData)}
        </span>
      </div>
    );
  };

  const notifyTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column flex-sm-row w-100">
        <span className="p-column-title text-break d-none">Enable Lawft Access</span>
        <Checkbox
          id="team_notify_checkbox"
          checked={rowData.notify_case_updates}
          name="notify_case_updates"
          // value={rowData.contact_id}
          value={rowData.firm_user_id}
          onChange={onChangeHandler}
          disabled={!(rowData?.status === 'ACTIVE')}
        />
      </div>
    );
  };

  const leadAttorneyTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column flex-sm-row w-100">
        <span className="p-column-title text-break d-none">Lead Attorney</span>
        <Checkbox
          id="team_lead_checkbox"
          checked={rowData?.is_lead_attorney}
          name="is_lead_attorney"
          // value={rowData?.contact_id}
          value={rowData?.firm_user_id}
          onChange={onChangeHandler}
          disabled={!(rowData?.status === 'ACTIVE')}
        />
      </div>
    );
  };

  const leadAssociateTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column flex-sm-row w-100">
        <span className="p-column-title text-break d-none">Lead Attorney</span>
        <Checkbox
          id="team_lead_checkbox"
          checked={rowData?.is_lead_associate}
          name="is_lead_associate"
          // value={rowData?.contact_id}
          value={rowData?.firm_user_id}
          onChange={onChangeHandler}
          disabled={!(rowData?.status === 'ACTIVE')}
        />
      </div>
    );
  };

  const selectColumnTemplate = (rowData) => {
    return (
      <Checkbox
        id="is_selected"
        checked={rowData?.is_selected}
        name="is_selected"
        // value={rowData.contact_id}
        value={rowData.firm_user_id}
        onChange={onChangeHandler}
        disabled={!(rowData.status === 'ACTIVE')}
      />
    );
  };

  const tagFormatting = (option) => {
    if (option?.is_confirmed === false && option?.status === 'ACTIVE') {
      return <Tag className="m-1 ms-0 tag-cases border-0" value="UNCONFIRMED" rounded />;
    } else if (option?.is_confirmed && option?.status === 'ARCHIVED') {
      return <Tag className="m-1 ms-0 tag-cases border-0" value={option?.status} rounded />;
    } else if (option?.is_confirmed === false && option?.status === 'ARCHIVED') {
      return <Tag className="m-1 ms-0 tag-cases border-0" value={option?.status} rounded />;
    }
  };

  useEffect(() => {
    //TODO:multi tenancy change
    // setSelectedList(userList?.filter((user) => !Boolean(teamMembers?.find((u) => u.contact_id === user?.contact_id))));
    setSelectedList(userList?.filter((user) => !Boolean(teamMembers?.find((u) => u.firm_user_id === user?.firm_user_id))));
  }, [userList, teamMembers]);

  return (
    <Card title={isLeadSpecific ? 'Assign To' : 'Team Members'}>
      {isTeamMemberAdd ? (
        <>
          <div className="d-flex">
            <div className="col-12 text-end">
              <Button
                className="p-button p-button-secondary"
                onClick={handleSave}
                disabled={!Boolean(selectedList?.filter((v) => v?.is_selected)?.length)}
              >
                {isLeadSpecific ? 'Update' : 'Add Team Member'}
              </Button>
              <Button className="p-button p-button-secondary outline ms-2" onClick={toggleAddView}>
                Cancel
              </Button>
            </div>
          </div>

          <div className="d-flex mb-3">
            <div className="col-12">
              <DataTable
                value={selectedList}
                responsiveLayout="scroll"
                emptyMessage="No Data Found."
                selectionMode="checkbox"
                className="case-team-member"
              >
                <Column body={selectColumnTemplate} style={{ width: 50 }} />
                <Column field="name" header="Name" className="add-contact-name" body={nameTemplate} />
                {!Boolean(isLeadSpecific) && <Column field="lead_attorney" header="Lead Atorney" body={leadAttorneyTemplate} />}
                {!Boolean(isLeadSpecific) && <Column field="lead_associate" header="Lead Associate" body={leadAssociateTemplate} />}
                {!Boolean(isLeadSpecific) && (
                  <Column
                    field="notify"
                    header="Notify About Case Updates"
                    style={{ width: 100, textAlign: 'center' }}
                    body={notifyTemplate}
                  />
                )}
              </DataTable>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="d-flex mb-3 pb-3 border-bottom">
            <div className="col col-sm-4 text-muted F-size12">Name</div>
            <div className="col col-sm-3 text-muted F-size12">Role</div>
            <div className="col col-sm-5 text-muted F-size12">Notify About Case Updates</div>
          </div>

          {teamMembers?.map((m, i) => (
            <div className="d-flex mb-3 align-items-center avatarcurved" key={i}>
              <div
                className="col col-sm-4 text-capitalize d-flex align-items-center pe-2 text-break"
                onClick={() => {
                  const { sk, pk, firm_user_id } = m;
                  const url = firm_user_id ? `/settings/team-management/${firm_user_id}` : '/settings/team-management';
                  handleSettingSkAndPkToLocalStorage(sk, pk);
                  handleRedirection(history, url);
                }}
              >
                <ImageComponent
                  filePath={`${m?.tenant_id}/${m?.firm_user_id ? m?.firm_user_id : m?.user_id}/profile_image`}
                  fileName={m?.first_name}
                  fileSize="small.jpg"
                  className="col-1 me-2"
                />
                {m?.firm_user_id && <i class="fas fa-user-tie me-2"></i>}
                <span className="pointer F-size12 redirection-link">{`${m.first_name} ${m.last_name}`}</span>
                {tagFormatting(m)}
              </div>
              <div className={`col col-sm-3 text-capitalize ${!m?.is_lead_associate ? 'F-size12' : ''}`}>
                {m?.is_lead_attorney ? 'Lead Attorney' : m?.is_lead_associate ? 'Lead Associate' : m?.user_type}
              </div>
              <div className="col col-sm-5 d-flex align-items-center justify-content-between">
                <InputSwitch
                  className="input-swich"
                  checked={m.notify_case_updates}
                  onChange={() => handleTeamMemberLawftAcces(m)}
                  disabled={!(m && m.status && m.status === 'ACTIVE') || isCaseClosed || isLeadClosed}
                />
                <i
                  className={'icon-delete icon-red cursor-pointer ' + (isCaseClosed || isLeadClosed ? 'pe-none opacity-25' : '')}
                  onClick={() => confirmDeleteMember(m)}
                  disabled={!(m && m.status && m.status === 'ACTIVE')}
                />
                <Tooltip content="Delete Team Member" position="left" target=".icon-delete" showDelay={500} />
              </div>
            </div>
          ))}
          {!teamMembers?.length && (
            <div className="d-flex mb-3 align-items-center">
              <div className="col-12">N/A</div>
            </div>
          )}
          <div className="my-2">
            <a
              onClick={toggleAddView}
              className={'addfield cursor-pointer ' + (isCaseClosed || isLeadClosed ? 'pe-none opacity-25' : '')}
              href
            >
              <i className="icon-add F-size14 me-1" />
              {isLeadSpecific ? 'Change Assign to' : 'Add Team Member'}
            </a>
          </div>
        </>
      )}
    </Card>
  );
}
