import React from 'react';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

function SearchFiles() {
  const { query, setQuery } = useFileManagerContext();

  function onChangeSearchFiles(value) {
    setQuery(value);
  }

  return (
    <div className="d-flex align-items-center filtersearch pe-lg-4 autowidth p-2 p-lg-0">
      <div className="p-input-icon-left input-shadow w-100 ">
        <i className="icon-search F-size16" />
        <TextSnippetWrapper
          type="text"
          className=" w-100 input-search"
          value={query || ''}
          onChange={(e) => onChangeSearchFiles(e.target.value)}
          placeholder="Search"
        />
      </div>
    </div>
  );
}

export default SearchFiles;
